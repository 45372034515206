import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import parse from 'html-react-parser';
import moment from 'moment';

import { IBindingCallback1, IBindingAction } from 'models/callback';

import NewsCommentsList from 'components/NewsCommentsList';
import LoaderWrapper from 'components/LoaderWrapper';

import { IPostComment } from 'screens/News/models/comment/postComment';
import { INewsItemDetails, ISynchronizable } from 'screens/News/models/news/newsItemDetails';

import {
  fetchNewsDetailsByIdRoutine,
  addCommentRoutine,
  removeNewsDetailsRoutine,
  removeNewsFromModalRoutine,
  removeNewsRoutine,
  syncNewsForUpdateWithOriginByIdRoutine,
  publishNewsRoutine
} from '../../routines';
import styles from './styles.module.scss';
import { AddEditSidebar } from 'screens/News/components/AddEditSidebar';
import ConfirmModalContent from 'components/ConfirmModalContent';
import { canUserDelete, isAllowedToCRUD, isAllowedToEditAnothers } from 'helpers/roles.helper';
import { getById } from 'screens/Authorization/services/userService';

export interface IExtendedNewsProps {
  newsDetails?: (INewsItemDetails & ISynchronizable);
  newsId: string;
  match: any;
  requests: {
    [propName: string]: {
      loading: boolean;
      error: object | string;
    };
  };
  user: any;
  removeNewsDetails: IBindingAction;
  removeNews: IBindingCallback1<string>;
  removeNewsFromModal: IBindingAction;
  syncNews: IBindingCallback1<string>;
  addComment: IBindingCallback1<IPostComment>;
  fetchNewsDetailsById: IBindingCallback1<string>;
  history: any;
  publishNews: IBindingCallback1<string>;
}

const NewsItemDetails: React.FunctionComponent<IExtendedNewsProps> = ({
  newsDetails,
  newsId,
  match,
  removeNewsDetails,
  removeNewsFromModal,
  removeNews,
  syncNews,
  addComment,
  fetchNewsDetailsById,
  history,
  requests,
  user,
  publishNews
}) => {
  useEffect(() => {
    fetchNewsDetailsById(newsId);
  }, [match.params]);

  useEffect(() => () => removeNewsDetails(), []);

  const [isModalOpened, toggleModalState] = useState(false);
  const [editingNewsId, updateEditingNewsId] = useState(null);
  const [isDeletionConfirmOpen, toggleDeletionConfirmState] = useState(false);
  const [isPublishConfirmOpen, togglePublishConfirmState] = useState(false);
  const [isSyncConfirmOpen, toggleSyncConfirmState] = useState(false);
  const [canEditNews, toggleCanEditNews] = useState(false);

  useEffect(() => {
    if (!newsDetails) {
      return;
    }

    if (newsDetails.authorId === user.id) {
      toggleCanEditNews(true);
    } else {
      getById(newsDetails.authorId)
        .then(author => toggleCanEditNews(isAllowedToEditAnothers(user.roles, author.roles)));
    }
  }, [newsDetails]);

  useEffect(() => {
    if (requests.newsDetails.error) {
      history.push('/news');
      updateEditingNewsId(null);
      removeNewsFromModal();
    }
  }, [requests.newsDetails.error]);

  const goBack = () => {
    updateEditingNewsId(null);
    removeNewsFromModal();
    history.goBack();
  };

  const onSave = () => {
    fetchNewsDetailsById(newsId);
    updateEditingNewsId(null);
    toggleModalState(false);
    removeNewsFromModal();
  };

  const onPublish = () => {
    publishNews(newsId);
    history.goBack();
    togglePublishConfirmState(false);
  };

  const openModal = () => {
    updateEditingNewsId(newsId);
    toggleModalState(true);
  };

  const closeModal = () => {
    updateEditingNewsId(null);
    toggleModalState(false);
    removeNewsFromModal();
  };

  const confirmDeletion = () => {
    removeNews(newsId);
    history.push('/news');
    toggleDeletionConfirmState(false);
  };

  const confirmSync = () => {
    syncNews(newsId);
    toggleSyncConfirmState(false);
  };

  const canDelete = () => canUserDelete(user.id, newsDetails.authorId, user.roles);

  const confirmPublishing = () => {
    onPublish();
    togglePublishConfirmState(false);
  };

  const onCommentSave = (text: string) => addComment({ text, newsId });

  return (
    <>
      {isModalOpened && <AddEditSidebar onClose={closeModal} onSave={onSave} editingNewsId={editingNewsId} />}
      <ConfirmModalContent
        opened={isPublishConfirmOpen}
        description={`Would you like to publish news "${newsDetails?.title ?? 'No title presented'}"?`}
        header="Publish news"
        onConfirm={confirmPublishing}
        confirmButtonText="Yes, publish it"
        onCancel={() => togglePublishConfirmState(false)}
      />
      <ConfirmModalContent
        opened={isDeletionConfirmOpen}
        description={`Would you like to delete news "${newsDetails?.title ?? 'No title presented'}"?`}
        header="Delete news"
        onConfirm={confirmDeletion}
        onCancel={() => toggleDeletionConfirmState(false)}
      />
      <ConfirmModalContent
        header="Confirm synchronization"
        onConfirm={confirmSync}
        onCancel={() => toggleSyncConfirmState(false)}
        opened={isSyncConfirmOpen}
        description={
          `Would you like to delete news "${newsDetails?.title ?? 'No title presented'}" with mailchump?`
        }
        confirmButtonText="Confirm"
      />
      <div className={styles.loadingWrapper}>
        <LoaderWrapper loading={requests.newsDetails.loading}>
          {newsDetails && (
          <div className={styles.container}>
            <div className={styles.newsContainer}>
              <div className={styles.startBlock}>
                <div className={styles.backDate}>
                  <div className={styles.goBackButton}>
                    <Button icon labelPosition="left" onClick={goBack}>
                      back
                      <Icon name="arrow left" />
                    </Button>
                  </div>
                  <div className={styles.dates}>
                    {moment(newsDetails.date).format('DD/MM/YYYY')}
                  </div>
                </div>
                <div className={styles.actionButtons}>
                  {
                    !newsDetails.published
                    && (
                    <Button
                      basic
                      color="green"
                      onClick={() => togglePublishConfirmState(true)}
                      disabled={newsDetails.syncing}
                    >
                      <Icon name="send" />
                      Publish
                    </Button>
                    )
                  }
                  {
                    isAllowedToCRUD(user.roles) && canEditNews
                    && (
                    <Button basic color="blue" onClick={openModal} disabled={newsDetails.syncing}>
                      <Icon name="pencil" />
                      Edit
                    </Button>
                    )
                  }
                  {
                    newsDetails.externalLink && isAllowedToCRUD(user.roles) && canEditNews
                    && (
                    <Button basic onClick={() => toggleSyncConfirmState(true)} loading={newsDetails.syncing}>
                      <Icon name="sync alternate" />
                      Sync
                    </Button>
                    )
                  }
                  {
                    canDelete()
                    && (
                      <Button
                        basic
                        color="red"
                        onClick={() => toggleDeletionConfirmState(true)}
                        disabled={newsDetails.syncing}
                      >
                        <Icon name="remove" />
                        Delete
                      </Button>
                    )
                  }

                </div>
              </div>
              <div className={styles.headerContainer}>
                <h1 className={styles.header}>{parse(newsDetails.title)}</h1>
                <h2 className={styles.description}>
                  {parse(newsDetails.description)}
                </h2>
              </div>
              <div className={styles.newsContent}>
                {parse(newsDetails.html)}
              </div>

              {
                newsDetails.published
                && (
                <div className={styles.commentsContainer}>
                  <NewsCommentsList
                    comments={newsDetails.comments}
                    onCommentSave={onCommentSave}
                  />
                </div>
                )
              }
            </div>
          </div>
          )}
        </LoaderWrapper>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const {
    requests: { newsDetails: newsDetailsRequest },
    newsDetails
  } = state.news;
  return {
    newsDetails,
    user: state.user.user,
    newsId: props.match.params.newsId,
    requests: {
      newsDetails: newsDetailsRequest
    }
  };
};

const mapDispatchToProps = {
  addComment: addCommentRoutine,
  removeNewsDetails: removeNewsDetailsRoutine,
  removeNews: removeNewsRoutine,
  removeNewsFromModal: removeNewsFromModalRoutine,
  syncNews: syncNewsForUpdateWithOriginByIdRoutine,
  fetchNewsDetailsById: fetchNewsDetailsByIdRoutine,
  publishNews: publishNewsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsItemDetails);

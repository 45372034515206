import { createRoutine } from 'redux-saga-routines';

export const fetchAdminUserInfoRoutine = createRoutine('GET_USERS');
export const fetchUserMentorsRoutine = createRoutine('GET_USER_MENTORS');
export const createUserRoutine = createRoutine('CREATE_USER');
export const openUpdateFormRoutine = createRoutine('UPDATE_USER_FORM');
export const deleteUserRoutine = createRoutine('DELETE_USER');
export const closeUpdateModalRoutine = createRoutine('CLOSE_UPDATE_MODAL');
export const updateUserRoutine = createRoutine('UPDATE_USER');
export const clearAdminPageStateRoutine = createRoutine('CLEAR_ADMIN_STATE');
export const importFromGoogleRoutine = createRoutine('IMPORT_FROM_GOOGLE');

export const uploadFileRoutine = createRoutine('UPLOAD_FILE');
export const downloadFileRoutine = createRoutine('DOWNLOAD_FILE');

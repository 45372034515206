import { all } from 'redux-saga/effects';
import newsSaga from '../containers/NewsList/sagas';
import newsDetailsSaga from '../containers/NewsItemDetails/sagas';
import newsForUpdateSaga from '../containers/AddNewsForm/sagas';

export default function* rootSaga() {
  yield all([
    newsSaga(),
    newsDetailsSaga(),
    newsForUpdateSaga()
  ]);
}

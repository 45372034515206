import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { IBindingCallback2 } from '../../../../models/callback';
import styles from './styles.module.scss';

interface IModalCreateTeamplate {
  saveTemplate: IBindingCallback2<string, boolean>;
  formName: string;
}

const ModalCreateTeamplate = ({ saveTemplate,
  formName }: IModalCreateTeamplate) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(formName);
  const [isPublic, setIsPublic] = useState(false);
  useEffect(() => {
    setName(formName);
    setIsPublic(false);
  }, [open]);

  const onClose = () => {
    setOpen(false);
  };
  const onSave = () => {
    saveTemplate(name, isPublic);
    setOpen(false);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={(
        <Button
          basic
          className={styles.formButton}
          primary
          content="Save as Template"
        />
      )}
    >
      <Modal.Header>Save as Template</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label="Template name"
            required
            placeholder=""
            value={name}
            onChange={(e, o) => setName(o.value)}
          />
          <Form.Checkbox
            toggle
            label="Is Public"
            className={styles.checkboxToggle}
            checked={isPublic}
            onChange={() => setIsPublic(!isPublic)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          labelPosition="right"
          icon="remove"
          className={styles.formButton}
          onClick={() => onClose()}
        />
        <Button
          content="Save"
          primary
          className={styles.formButton}
          disabled={!name}
          onClick={() => onSave()}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { feedbackPage: { createAssign: { feedbackId } } } = state;
  return {
    feedbackId
  };
};

export default connect(mapStateToProps)(ModalCreateTeamplate);

import React, { FunctionComponent } from 'react';
import { history } from '../../../../helpers/history';
import styles from './styles.module.scss';
import { IUser } from 'screens/Team/models/IUser';
import Avatar from 'components/UserAvatar';

export interface ITeamMemberProps {
  user: IUser;
}

const TeamMember: FunctionComponent<ITeamMemberProps> = ({
  user: {
    id,
    fullName,
    logoLink,
    position
  }
}) => (
  <div className={styles.teamMember} key={id} role="presentation">
    <div
      role="presentation"
      className={styles.teamMemberBlock}
      onKeyDown={() => history.push(`profile/${id}`)}
      onClick={() => history.push(`profile/${id}`)}
    >
      <div className={styles.avatarContainer}>
        <Avatar
          width="100%"
          height="100%"
          name={`${fullName}`}
          logoLink={logoLink}
          fontSize="40px"
        />
      </div>
      <div className={styles.userData}>
        <div className={styles.name}>
          {fullName}
        </div>
        <div className={styles.position}>
          {position}
        </div>
      </div>
    </div>
  </div>
);

export default TeamMember;

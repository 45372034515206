import React from 'react';
import { List, Button, Loader } from 'semantic-ui-react';
import { IGlobalState } from 'models/global-state';
import { connect } from 'react-redux';
import { IBindingAction, IBindingFunction } from 'models/callback';
import { NotificationItem } from 'screens/Notifications/components/NotificationItem';
import { boundMethod } from 'autobind-decorator';
import { toastr } from 'react-redux-toastr';
import {
  readNotificationsRoutine,
  fetchMoreNotificationsRoutine,
  fetchNotificationsRoutine
}
  from 'screens/Notifications/routines';
import { INotificationModel } from 'screens/Notifications/models/notificationModel';
import styles from './styles.module.scss';

export interface INotificationsListProps {
  notifications?: INotificationModel[];
  requests: {
    [propName: string]: {
      loading: boolean;
      error: object | string;
    };
  };

  fetchNotifications: IBindingAction;
  fetchMoreNotifications: IBindingAction;
  readNotifications: IBindingFunction<string[], void>;
}

interface INotificationsListState {
  areNotificationsFetched: boolean;
}

class NotificationsList extends React.Component<INotificationsListProps, INotificationsListState> {
  constructor(props: INotificationsListProps) {
    super(props);

    this.state = {
      areNotificationsFetched: false
    };
  }

  componentDidMount() {
    const { fetchNotifications } = this.props;
    fetchNotifications();
    this.setState({ areNotificationsFetched: true });
  }

  @boundMethod
  viewAll() {
    const { fetchMoreNotifications } = this.props;
    fetchMoreNotifications();
  }

  @boundMethod
  markAllAsRead() {
    const { notifications, readNotifications } = this.props;

    const ids = notifications
      .filter(n => !n.read)
      .map(({ id }) => id);
    if (!ids.length) {
      toastr.info('You have not unread notifications');
      return;
    }
    readNotifications(ids);
  }

  @boundMethod
  markOneAsRead(id: string) {
    const { notifications, readNotifications } = this.props;
    const notification = notifications.find(n => n.id === id);
    if (notification.read) {
      return;
    }
    readNotifications([id]);
  }

  renderListOfNotifications = notifications => (
    <List divided>
      {notifications.map((notification: INotificationModel) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onSelect={this.markOneAsRead}
        />
      ))}
    </List>
  );

  renderContent = notifications => (
    !notifications.length
      ? <h3 className={styles.noNotifications}>No notifications</h3>
      : this.renderListOfNotifications(notifications)
  );

  render() {
    const { notifications, requests } = this.props;
    const { areNotificationsFetched } = this.state;
    const dataLoaded = Boolean(!requests.notifications.loading && areNotificationsFetched);

    return !dataLoaded ? (
      <div className={styles.spinnerWrapper}>
        <Loader active inline="centered" style={{ margin: '30px' }} size="big" />
      </div>
    ) : (
      <div>
        <div className={styles.wrapper}>
          <Button onClick={this.viewAll} className={styles.viewAll}>
            View All
          </Button>
          <Button onClick={this.markAllAsRead} className={styles.markAll}>
            Read All
          </Button>
        </div>
        {this.renderContent(notifications)}
      </div>
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const { notifications } = state.notifications.requests;
  return {
    notifications: state.notifications.notifications,
    requests: {
      notifications
    }
  };
};

const mapDispatchToProps = {
  readNotifications: readNotificationsRoutine,
  fetchNotifications: fetchNotificationsRoutine,
  fetchMoreNotifications: fetchMoreNotificationsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);

import React from 'react';
import { IBindingCallback1 } from 'models/callback';
import { connect } from 'react-redux';
import { confirmEmailRoutine } from 'screens/Authorization/routines';
import PageLayout from 'screens/Authorization/components/PagesLayout';

const getUrlSearchParamFromLocation = (location: any, searchParam: string) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(searchParam);
};

export interface IConfirmEmailPageProps {
  loading: boolean;
  location: any;
  confirmEmail: IBindingCallback1<string>;
  message: string;
}

class ConfirmEmailPage extends React.Component<IConfirmEmailPageProps> {
  componentDidMount() {
    const { location, confirmEmail } = this.props;
    confirmEmail(getUrlSearchParamFromLocation(location, 'code'));
  }

  render() {
    return (
      <PageLayout loading />
    );
  }
}

const mapDispatchToProps = {
  confirmEmail: confirmEmailRoutine
};

export default connect(null, mapDispatchToProps)(ConfirmEmailPage);

import { Routine } from 'redux-saga-routines';
import { IQuestion } from '../../model/Questions/IQuestion';
import {
  fetchFormsRoutine,
  selectedFormChangedRoutine,
  formDeletedRoutine,
  formSavedRoutine,
  formUpdateRoutine,
  fetchTemplatesRoutine
} from '../../routines';
import { IForm } from '../../model/Forms/IForm';

export interface IFormsState {
  forms: IForm[];
  searchResults: IQuestion[];
  selectedForm: IForm;
  templates: IForm[];
}

const initialState: IFormsState = {
  forms: [],
  searchResults: [],
  selectedForm: null,
  templates: []
};

export const forms = (
  state = initialState,
  action: Routine<any>
): IFormsState => {
  switch (action.type) {
    case fetchFormsRoutine.SUCCESS: {
      return {
        ...state, forms: [...action.payload]
      };
    }
    case formSavedRoutine.SUCCESS: {
      return {
        ...state,
        forms: [action.payload, ...state.forms]
      };
    }
    case formDeletedRoutine.SUCCESS: {
      return {
        ...state, forms: [...state.forms.filter(f => f.id !== action.payload)]
      };
    }
    case selectedFormChangedRoutine.SUCCESS: {
      return {
        ...state,
        selectedForm: action.payload
      };
    }
    case formUpdateRoutine.SUCCESS: {
      return {
        ...state, forms: state.forms.map(f => (f.id === action.payload.id ? action.payload : f))
      };
    }
    case fetchTemplatesRoutine.SUCCESS: {
      return {
        ...state,
        templates: action.payload
      };
    }
    default:
      return state;
  }
};

import { createRoutine } from 'redux-saga-routines';

export const fetchUserInfoRoutine = createRoutine('USER_PROFILE_GET');
export const updateUserInfoRoutine = createRoutine('USER_PROFILE_UPDATE');
export const updateUserPasswordRoutine = createRoutine('USER_PASSWORD_UPDATE');
export const updateAvatarRoutine = createRoutine('UPDATE_USER_AVATAR');
export const fetchUserOKRsRoutine = createRoutine('FETCH_USER_OKRS');
export const fetchUserReviewsRoutine = createRoutine('FETCH_USER_REVIEWS');
export const fetchUserFeedbackListRoutine = createRoutine('FETCH_USER_FEEDBACKS');
export const clearUserProfileStateRoutine = createRoutine('CLEAR_USER_PROFILE');

import React, { useState, useEffect } from 'react';
import { INewsItemShort } from 'screens/News/models/news/newsItemShort';
import ListItem from 'screens/News/components/ListItem';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { history } from 'helpers/history';
import styles from './styles.module.scss';
import 'react-toggle/style.css';
import {
  fetchNewsRoutine,
  removeNewsRoutine,
  removeNewsFromModalRoutine,
  syncNewsWithOriginByIdRoutine,
  publishNewsRoutine
} from '../../routines';
import { NoDataContainer } from 'components/NoDataContainer';
import PageHeader from 'components/PageHeader';
import { store } from 'store';
import { isAllowedToCRUD } from 'helpers/roles.helper';
import InfiniteScroll from 'react-infinite-scroller';
import { INewsBounds } from '../../models/news/newsBounds';
import { AddEditSidebar } from 'screens/News/components/AddEditSidebar';
import { ISynchronizable } from 'screens/News/models/news/newsItemDetails';

export interface INewItemProps {
  news?: (INewsItemShort & ISynchronizable)[];
  fetchNews: IBindingCallback1<INewsBounds>;
  hasMoreNews: boolean;
  removeNewsFromModal: IBindingAction;
  removeNews: IBindingCallback1<string>;
  publishNews: IBindingCallback1<string>;
  syncNews: IBindingCallback1<string>;
  skip: number;
  limit: number;
  showDrafts: boolean;
}

const NewsList: React.FunctionComponent<INewItemProps> = ({
  news,
  fetchNews,
  hasMoreNews,
  removeNewsFromModal,
  removeNews,
  publishNews,
  syncNews,
  skip,
  limit,
  showDrafts
}) => {
  const [isModalOpened, toggleModalState] = useState(false);
  const [editingNewsId, updateEditingNewsId] = useState(null);
  const loadNews = () => {
    if (hasMoreNews) {
      fetchNews({
        skip,
        limit,
        showDrafts
      });
    }
  };
  useEffect(() => {
    if (!news.length) {
      loadNews();
    }
  }, [news]);

  const onSave = () => {
    updateEditingNewsId(null);
    toggleModalState(false);
    removeNewsFromModal();
  };

  const onGetSpecificNews = (id: string) => {
    history.push(`/news/${id}`);
  };

  const onDeleteNews = (id: string) => removeNews(id);

  const onPublishNews = (id: string) => publishNews(id);

  const onSyncNews = (id: string) => syncNews(id);

  const openModal = (newsId?: string) => {
    updateEditingNewsId(newsId);
    toggleModalState(true);
  };

  const closeModal = () => {
    updateEditingNewsId(null);
    toggleModalState(false);
    removeNewsFromModal();
  };

  const { user } = store.getState().user;

  const getTextIfCanCreate = () => (isAllowedToCRUD(user.roles) ? 'Add news' : '');
  const noDataContainer = (
    <div className={styles.container}>
      <NoDataContainer
        text={showDrafts ? 'You don\'t have draft news' : 'No news right now'}
        buttonText={getTextIfCanCreate()}
        background
        onButtonClick={() => toggleModalState(true)}
      />
    </div>
  );

  return (
    <>
      {isModalOpened && <AddEditSidebar onClose={closeModal} onSave={onSave} editingNewsId={editingNewsId} />}
      <div className={styles.container}>
        <div className={styles.layoutContainer}>
          <InfiniteScroll
            pageStart={0}
            loader={<Loader style={{ marginBottom: '20px' }} key={0} active inline="centered" />}
            loadMore={loadNews}
            hasMore={hasMoreNews}
            useWindow={false}
          >
            <div className={styles.contentContainer}>
              <PageHeader
                buttonText={getTextIfCanCreate()}
                headerText={showDrafts ? 'Drafts' : 'News'}
                buttonAction={() => toggleModalState(true)}
              />
              {news.length ? (
                <div className={styles.newsContainer}>
                  {news.slice(0, news.length).map(newsitem => (
                    <ListItem
                      key={newsitem.id}
                      newsItem={newsitem}
                      onSelect={() => onGetSpecificNews(newsitem.id)}
                      onUpdate={() => openModal(newsitem.id)}
                      onDelete={() => onDeleteNews(newsitem.id)}
                      onPublish={showDrafts ? () => onPublishNews(newsitem.id) : undefined}
                      onSync={() => onSyncNews(newsitem.id)}
                      syncLoading={newsitem.syncing}
                      hasLink={newsitem.externalLink && newsitem.externalLink.trim() !== ''}
                    />
                  ))}
                </div>
              ) : noDataContainer}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const {
    news: {
      news,
      hasMoreNews,
      skip,
      limit,
      showDrafts
    }
  } = state.news;

  return {
    news,
    hasMoreNews,
    skip,
    limit,
    showDrafts
  };
};

const mapDispatchToProps = {
  fetchNews: fetchNewsRoutine,
  removeNews: removeNewsRoutine,
  removeNewsFromModal: removeNewsFromModalRoutine,
  syncNews: syncNewsWithOriginByIdRoutine,
  publishNews: publishNewsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsList);

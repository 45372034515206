import React from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { boundMethod } from 'autobind-decorator';
import { IGlobalState } from 'models/global-state';
import { IBindingCallback1 } from 'models/callback';
import { IUser } from 'screens/OKR/models/user';
import SaveOkrForm from '../SaveOkrForm';
import OkrGridItem from '../../components/OkrGridItem';
import { IOkr, IOkrToSave } from '../../models/okr';
import { saveOkrRoutine, fetchOkrs } from '../../routines';
import styles from './styles.module.scss';
import { NoDataContainer } from 'components/NoDataContainer';
import PageHeader from 'components/PageHeader';

export interface IOkrGridProps {
  okrs?: IOkr[];
  requests: {
    [propName: string]: {
      loading: boolean;
      error: object | string;
    };
  };
  fetchOkrs: IBindingCallback1<string>;
  saveOkr: IBindingCallback1<IOkrToSave>;
  user: IUser;
}

interface IOkrGridState {
  isModalOpened: boolean;
  editingOkr?: IOkr;
  areOkrsFetched: boolean;
}

class OkrGridView extends React.Component<IOkrGridProps, IOkrGridState> {
  constructor(props: IOkrGridProps) {
    super(props);

    this.state = {
      isModalOpened: false,
      editingOkr: null,
      areOkrsFetched: false
    };
  }

  componentDidMount() {
    const { areOkrsFetched } = this.state;
    const { okrs, user, fetchOkrs: ftOrks } = this.props;

    if (!areOkrsFetched && !okrs.length && user) {
      ftOrks(user.id);
    }
    this.setState({ areOkrsFetched: true });
  }

  @boundMethod
  onSaveOkr(data: {
    startTime: Moment;
    endTime: Moment;
    name: string;
    description: string;
    isSabbatical: boolean;
  }) {
    const { startTime, endTime, name, description, isSabbatical } = data;
    const { editingOkr } = this.state;
    const { user, saveOkr } = this.props;

    saveOkr({
      id: editingOkr?.id,
      userId: user.id,
      startTime: startTime.toDate(),
      endTime: endTime.toDate(),
      name: name.trim(),
      description: description.trim(),
      isSabbatical
    });

    this.setState({
      isModalOpened: false,
      editingOkr: null
    });
  }

  @boundMethod
  openModal(okrId?: string) {
    const { okrs } = this.props;
    const editingOkr = okrs.find(({ id }) => id === okrId);
    this.setState({ isModalOpened: true, editingOkr });
  }

  @boundMethod
  closeModal() {
    this.setState({
      isModalOpened: false,
      editingOkr: null
    });
  }

  render() {
    const { okrs, requests } = this.props;
    const {
      editingOkr,
      isModalOpened,
      areOkrsFetched
    } = this.state;
    const dataLoaded = (!requests.okrs.loading && areOkrsFetched) || !!okrs.length;
    const filteredOkrs = okrs.sort((firstOkr, secondOkr) => {
      if (firstOkr.isClosed === secondOkr.isClosed) {
        return moment(firstOkr.startTime).diff(secondOkr.startTime) > 0 ? -1 : 1;
      }

      return firstOkr.isClosed ? 1 : -1;
    });
    return (
      dataLoaded && (
        <div>
          <SaveOkrForm
            okr={editingOkr}
            onSave={this.onSaveOkr}
            onCancel={this.closeModal}
            isOpened={isModalOpened}
          />
          {filteredOkrs.length ? (
            <div className={styles.okrContainer}>
              <PageHeader
                headerText="My OKR's"
                buttonText="Add OKR"
                buttonAction={() => this.openModal()}
              />
              <div className={styles.gridBox}>
                {filteredOkrs.map(okr => (
                  <OkrGridItem
                    key={okr.id}
                    okr={okr}
                    onClick={() => this.openModal(okr.id)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.noDataContainer}>
              <NoDataContainer
                text="You don’t have any OKR’s, maybe it’s time to create your first one?"
                buttonText="Create OKR"
                onButtonClick={() => this.openModal()}
              />
            </div>
          )}

        </div>
      )
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const {
    user: { user },
    okr: { okrs, requests }
  } = state;

  return {
    user,
    okrs,
    requests: {
      okrs: requests.okrs
    }
  };
};

const mapDispatchToProps = {
  fetchOkrs,
  saveOkr: saveOkrRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(OkrGridView);

import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { fetchAnnouncementsRoutine, removeAnnouncementsRoutine } from '../../routines';
import styles from './styles.module.scss';
import LoaderWrapper from 'components/LoaderWrapper';
import { IAnnouncement } from 'screens/Announcements/models/IAnnouncement';
import PageHeader from 'components/PageHeader';
import AnnouncementItem from 'screens/Announcements/components/Announcementtem';
import DataSidebar from 'components/DataSidebar';
import FormWrapper from 'components/FormWrapper';
import AddAnnouncementForm from '../AddAnnouncementForm';
import { history } from 'helpers/history';
import { NoDataContainer } from '../../../../components/NoDataContainer';
import { isAllowedToCRUD } from '../../../../helpers/roles.helper';
import { store } from '../../../../store';

export interface IAnnouncementsListProps {
  fetchAnnouncements: IBindingAction;
  deleteAnnouncement: IBindingCallback1<string>;
  announcements: IAnnouncement[];
  loading: boolean;
}

const AnnouncementsList: React.FunctionComponent<IAnnouncementsListProps> = ({
  fetchAnnouncements,
  deleteAnnouncement,
  announcements,
  loading
}) => {
  useEffect(() => {
    fetchAnnouncements();
  }, []);
  const [isModalOpened, toggleModal] = useState(false);
  const [editingAnnouncementId, setEditingAnnouncementId] = useState<string>(null);

  const onDelete = (id: string) => {
    deleteAnnouncement(id);
  };

  const onUpdate = (id: string) => {
    setEditingAnnouncementId(id);
    toggleModal(true);
  };

  const onSelectAnnouncement = (id: string) => {
    history.push(`/news/announcements/${id}`);
  };

  const { user } = store.getState().user;

  const getTextIfCanCreate = () => (isAllowedToCRUD(user.roles) ? 'Add announcements' : '');

  const sidebar = isModalOpened ? (
    <DataSidebar
      onClose={() => toggleModal(false)}
    >
      <FormWrapper
        headerText={editingAnnouncementId ? 'Edit announcement' : 'Create'}
        onClose={() => {
          toggleModal(false);
          setEditingAnnouncementId(null);
        }}
      >
        <AddAnnouncementForm
          announcementId={editingAnnouncementId}
          onSave={() => toggleModal(false)}
        />
      </FormWrapper>
    </DataSidebar>
  ) : '';

  return (
    <div className={styles.loadingWrapper}>
      <LoaderWrapper loading={loading}>
        {sidebar}
        {announcements.length ? (
          <Container className={styles.layoutContainer}>
            <div className={styles.contentContainer}>
              <PageHeader
                buttonText={getTextIfCanCreate()}
                headerText="Announcements"
                buttonAction={() => toggleModal(true)}
              />
              <div>
                {announcements.map((announcement: IAnnouncement) => (
                  <AnnouncementItem
                    key={announcement.id}
                    announcement={announcement}
                    onUpdate={() => onUpdate(announcement.id)}
                    onDelete={() => onDelete(announcement.id)}
                    onSelect={() => onSelectAnnouncement(announcement.id)}
                  />
                ))}
              </div>
            </div>
          </Container>
        ) : (
          <NoDataContainer
            text="No announcements right now"
            buttonText={getTextIfCanCreate()}
            background
            onButtonClick={() => toggleModal(true)}
          />
        )}
      </LoaderWrapper>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    requests: { announcements: request },
    announcements: { announcements }
  } = state.announcements;

  return {
    announcements,
    loading: request.loading
  };
};

const mapDispatchToProps = {
  fetchAnnouncements: fetchAnnouncementsRoutine,
  deleteAnnouncement: removeAnnouncementsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementsList);

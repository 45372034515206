import { combineReducers } from 'redux';
import { reducerCreator } from 'helpers/reducerHelper';
import { userList } from '../containers/Users/reducer';
import { fetchAdminUserInfoRoutine, openUpdateFormRoutine, createUserRoutine } from '../routines';
import { loadingFile } from '../components/fileActionButtons/reducer';

const requests = combineReducers({
  getUser: reducerCreator([openUpdateFormRoutine.TRIGGER]),
  getUsers: reducerCreator([fetchAdminUserInfoRoutine.TRIGGER]),
  load: reducerCreator([
    openUpdateFormRoutine.TRIGGER,
    createUserRoutine.TRIGGER
  ])
});

export default combineReducers({
  userList,
  loadingFile,
  requests
});

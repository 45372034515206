import { Routine } from 'redux-saga-routines';
import { updateQuestionRoutine, createQuestionRoutine,
  fetchQuestionsRoutine, deleteQuestionRoutine } from '../../routines';
import { IQuestion } from 'screens/Feedback/model/Questions/IQuestion';

export interface IQuestionsState {
  blockQuestions: IQuestion[];
}

const initialState: IQuestionsState = {
  blockQuestions: null
};

export const questions = (
  state = initialState,
  action: Routine<any>
): IQuestionsState => {
  switch (action.type) {
    case fetchQuestionsRoutine.FULFILL: {
      return {
        ...state,
        blockQuestions: action.payload
      };
    }
    case deleteQuestionRoutine.TRIGGER:
    case updateQuestionRoutine.TRIGGER:
    case createQuestionRoutine.TRIGGER: {
      return state;
    }
    case createQuestionRoutine.FULFILL: {
      return {
        ...state,
        blockQuestions: [...state.blockQuestions, action.payload]
      };
    }
    case updateQuestionRoutine.FULFILL: {
      return {
        ...state,
        blockQuestions: state.blockQuestions.map(q => (q.id === action.payload.id ? action.payload : q))
      };
    }
    case deleteQuestionRoutine.SUCCESS: {
      return {
        ...state,
        blockQuestions: state.blockQuestions.filter(q => q.id !== action.payload)
      };
    }
    default:
      return initialState;
  }
};

import { createRoutine } from 'redux-saga-routines';

export const fetchMoreKeyResultsRoutine = createRoutine('FETCH_MORE_KR');
export const scanKeyResultsForDuplicatesRoutine = createRoutine('SCAN_KEY_RESULTS_FOR_DUPLICATES');
export const updateKeyResultsFiltersRoutine = createRoutine('UPDATE_KEY_RESULT_FILTERS');

export const toggleIsCustomKeyResultStatusRoutine = createRoutine('TOGGLE_KEY_RESULT_CUSTOM_STATUS');
export const toggleIsHiddenKeyResultStatusRoutine = createRoutine('TOGGLE_KEY_RESULT_HIDDEN_STATUS');
export const saveKeyResultAdminRoutine = createRoutine('SAVE_KEY_RESULT_ADMIN');
export const deleteKeyResultRoutine = createRoutine('DELETE_KEY_RESULT');

export const fetchMoreObjectivesRoutine = createRoutine('FETCH_MORE_OBJ');
export const updateObjectiveFiltersRoutine = createRoutine('UPDATE_OBJ_FILTERS');

export const toggleIsCustomObjectiveStatusRoutine = createRoutine('TOGGLE_OBJECTIVE_CUSTOM_STATUS');
export const toggleObjectiveIsHiddenStatusRoutine = createRoutine('TOGGLE_OBJECTIVE_HIDDEN_STATUS');
export const saveObjectiveAdminRoutine = createRoutine('SAVE_OBJ_ADMIN');
export const deleteObjectiveRoutine = createRoutine('DELETE_OBJ');

export const removeKeyResultsRoutine = createRoutine('REMOVE_KR');
export const removeObjectiveRoutine = createRoutine('REMOVE_OBJ');

export const toggleIsMergeStatusRoutine = createRoutine('TOGGLE_KR_MERGE_STATUS');

export const mergeKeyResultsRoutine = createRoutine('MERGE_KR');

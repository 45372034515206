import { all } from 'redux-saga/effects';
import announcementsSaga from '../containers/AnnouncementsList/sagas';
import updateAnnouncementSagas from '../containers/AddAnnouncementForm/sagas';

export default function* rootSaga() {
  yield all([
    announcementsSaga(),
    updateAnnouncementSagas()
  ]);
}

import { Routine } from 'redux-saga-routines';
import io from 'socket.io-client';
import { env } from 'env';
import { authService } from 'screens/Authorization/services/authService';
import {
  connectSocketsRoutine,
  connectActionRoutine,
  disconnectSocketsRoutine,
  disconnectActionRoutine
} from './routines';
import { IBindingAction } from 'models/callback';
import { createFileUrl } from 'helpers/fileUrl.helper';
import { pathToRoutes } from 'helpers/pathToRoutes.helper';

export interface IConnectedAction {
  functionName: string;
  action: IBindingAction;
  addEvenIfExists?: boolean;
  showSystemNotification?: boolean;
}

interface INotificationsState {
  socketIoClient: SocketIOClient.Socket;
  actionsToConnect: IConnectedAction[];
  connectedActions: IConnectedAction[];
}

const initialState: INotificationsState = {
  socketIoClient: null,
  actionsToConnect: [],
  connectedActions: []
};

const showNotification = (name: string, notificationData?: NotificationOptions, link?: string) => {
  if (Notification.permission === 'granted') {
    const notification = new Notification(name, {
      ...notificationData,
      icon: createFileUrl(notificationData?.icon)
    });

    notification.onclick = event => {
      event.preventDefault();
      window.open(link ?? '/', '_blank');
    };
  }
};

const socketNotifications = (state = initialState, action: Routine<any>): INotificationsState => {
  const { type, payload } = action;

  const connectAction = (actionData: IConnectedAction, socketConnection: SocketIOClient.Socket) => {
    let removed = false;
    if (!actionData.addEvenIfExists) {
      if (state.connectedActions.find(a => a.functionName === actionData.functionName)) {
        socketConnection.off(actionData.functionName);
        removed = true;
      }
    }
    socketConnection.on(actionData.functionName, notification => {
      if (actionData.showSystemNotification) {
        const data = JSON.parse(notification);
        if (!data) return;
        const link = `${pathToRoutes[data.routeName]}/${data.subRouteId ?? ''}`;

        showNotification(data.title, data, link);
      }
      actionData.action();
    });
    return removed;
  };

  switch (type) {
    case connectSocketsRoutine.TRIGGER:
      if (Notification.permission === 'default') {
        Notification.requestPermission();
      }

      if (state.socketIoClient) {
        return state;
      }

      const socketClient = io(env.pushService, {
        query: {
          token: authService.tokenValue.accessToken
        }
      });
      state.actionsToConnect.forEach(a => connectAction(a, socketClient));

      if (payload.offices !== null && payload.offices !== undefined && payload.offices.length > 0) {
        socketClient.emit('connect_offices', payload.offices);
      }

      return {
        ...state,
        socketIoClient: socketClient,
        connectedActions: [...state.connectedActions, ...state.actionsToConnect],
        actionsToConnect: []
      };

    case connectActionRoutine.TRIGGER:
      const connectedAction = payload as IConnectedAction;
      if (!state.socketIoClient || !state.socketIoClient.connected) {
        return { ...state, actionsToConnect: [...state.actionsToConnect, connectedAction] };
      }

      const updated = connectAction(connectedAction, state.socketIoClient);
      const currentActions = updated
        ? state.connectedActions.filter(a => a.functionName !== connectedAction.functionName)
        : state.connectedActions;

      return {
        ...state,
        connectedActions: [...currentActions, connectedAction]
      };

    case disconnectActionRoutine.TRIGGER:
      const nameToDisconnect = payload.functionName;
      state.socketIoClient.off(nameToDisconnect);

      return { ...state, connectedActions: state.connectedActions.filter(a => a.functionName !== nameToDisconnect) };

    case disconnectSocketsRoutine.TRIGGER:
      state.socketIoClient.disconnect();
      return initialState;

    default:
      return state;
  }
};

export default socketNotifications;

import { Routine } from 'redux-saga-routines';
import {
  fetchNotificationsRoutine,
  fetchMoreNotificationsRoutine,
  readNotificationsRoutine
} from 'screens/Notifications/routines';
import { INotificationModel } from 'screens/Notifications/models/notificationModel';

export const notifications = (
  state = [],
  action: Routine<any>
): INotificationModel[] => {
  switch (action.type) {
    case fetchNotificationsRoutine.SUCCESS:
      return action.payload;
    case fetchMoreNotificationsRoutine.SUCCESS:
      return action.payload;
    case readNotificationsRoutine.SUCCESS:
      return state.map((notification: INotificationModel) => {
        if (notification.read) {
          return { ...notification };
        }
        return { ...notification, read: action.payload.includes(notification.id) };
      });
    default:
      return state;
  }
};

import React, { useState } from 'react';
import { Image, Popup } from 'semantic-ui-react';
import AddToMyOkrForm, { IKeyResultData } from 'screens/OKR/containers/AddToMyOkrForm';
import Sidebar from 'components/DataSidebar';
import FormWrapper from 'components/FormWrapper';
import { plus } from 'assets/icons';

interface IAddToMyOkrWrapperProps {
  keyResult: IKeyResultData;
  fromOkrId: string;
}

const AddToMyOkrWrapper: React.FunctionComponent<IAddToMyOkrWrapperProps> = ({
  keyResult,
  fromOkrId
}: IAddToMyOkrWrapperProps) => {
  const [opened, toggleOpen] = useState(false);
  const onClose = () => toggleOpen(false);

  const modal = opened ? (
    <Sidebar onClose={onClose}>
      <FormWrapper headerText={`Add "${keyResult.name}" to my OKR`} onClose={onClose}>
        <AddToMyOkrForm keyResult={keyResult} onClose={onClose} fromOkrId={fromOkrId} />
      </FormWrapper>
    </Sidebar>
  ) : null;

  return (
    <>
      <Popup
        content="Add to my OKR"
        position="top center"
        trigger={<Image src={plus} alt="" onClick={() => toggleOpen(true)} style={{ cursor: 'pointer' }} />}
      />
      {modal}
    </>
  );
};

export default AddToMyOkrWrapper;

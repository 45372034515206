import { fetchAchievementsRoutine } from 'screens/UserAchievements/routines';
import { IAchievement } from 'screens/UserAchievements/model/IAchievement';

interface IUserAchievementsState {
  achievements: IAchievement[];
}

export const okrAchievements = (state: IUserAchievementsState = {
  achievements: []
}, action: any): IUserAchievementsState => {
  const { type, payload } = action;

  switch (type) {
    case fetchAchievementsRoutine.SUCCESS:
      return { ...state, achievements: payload };
    default:
      return state;
  }
};

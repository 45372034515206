import { Complexity } from '../enums';

export const getComplexityColors = (complexityLevel: Complexity) => {
  switch (complexityLevel) {
    case Complexity.basic:
      return { backgroundColor: '#adeabc', color: '#048104' };
    case Complexity.intermediate:
      return { backgroundColor: '#f1e8c6', color: '#938112' };
    case Complexity.advanced:
      return { backgroundColor: '#ffd3e1', color: '#7A4657' };
    case Complexity.notAssigned:
      return { backgroundColor: '#ebebeb', color: '#434343' };
    default:
      return { backgroundColor: '#888' };
  }
};

import { env } from 'env';
import logo from '../assets/images/user.svg';

export const createFileUrl = (filePath: string) => (
  env.fileServerUrl ? `${env.fileServerUrl}${filePath}` : filePath
);

export const getFileUrlIfExists = (filePath: string, additionalUrl: string) => (
  filePath ? createFileUrl(filePath) : additionalUrl
);

export const getUserLogoIfExists = (filePath: string) => (
  filePath ? createFileUrl(filePath) : logo
);


import React, { FunctionComponent } from 'react';
import { Image, Button } from 'semantic-ui-react';
import img from 'assets/images/email_placeholder.svg';
import styles from './styles.module.scss';
import { history } from 'helpers/history';

export const NoActivatedEmailPage: FunctionComponent = () => (
  <div style={{ height: '100vh' }}>
    <div className={styles.wrapper}>
      <Image src={img} />
      <p className={styles.title}>
        You are almost there!
      </p>
      <p className={styles.additional}>
        Confirmation link was sent to your email!
      </p>
      <Button primary content="To login page" onClick={() => history.push('/login')} />
    </div>
  </div>
);

export default NoActivatedEmailPage;


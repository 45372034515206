import React, { useState, useEffect } from 'react';
import { Menu } from 'semantic-ui-react';

import styles from './styles.module.scss';
import { Route } from 'react-router-dom';
import { history } from 'helpers/history';
import NewsList from '../containers/NewsList';
import AnnouncementsList from 'screens/Announcements/containers/AnnouncementsList';

interface INewsMenuProps {
  location: any;
}

const routes = [
  {
    name: 'news',
    menuText: 'News',
    path: '/news',
    component: NewsList
  },
  {
    name: 'announcements',
    menuText: 'Announcements',
    path: '/news/announcements',
    component: AnnouncementsList
  }
];

const NewsMenu: React.FunctionComponent<INewsMenuProps> = ({
  location
}: INewsMenuProps) => {
  const [activeItem, toggleActiveItem] = useState('news');

  const updatePath = (pathname: string) => {
    const path = routes.find(route => route.path === pathname)?.name;
    const defaultPage = routes[0];
    if (!path) {
      history.push(defaultPage.path);
    }
    toggleActiveItem(path ?? defaultPage.name);
  };

  useEffect(() => {
    updatePath(location.pathname);
  }, [location.pathname]);

  const handleMenuClick = (e, data) => {
    toggleActiveItem(data.name);
    history.push(routes.find(route => route.name === data.name).path);
  };

  const renderMenu = () => (
    <div className={styles.menu}>
      <Menu pointing secondary>
        {routes.map(({ name, menuText }) => (
          <Menu.Item
            key={name}
            active={activeItem === name}
            onClick={handleMenuClick}
            name={name}
          >
            {menuText}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );

  return (
    <div>
      {renderMenu()}
      {routes.map(({ path, component }) => (
        <Route exact path={path} component={component} key={path} />
      ))}
    </div>
  );
};

export default NewsMenu;

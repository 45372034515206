import React, { useEffect } from 'react';
import { IRequest } from '../../model/Requests/IRequest';
import { IBindingCallback1 } from '../../../../models/callback';
import styles from './styles.module.scss';
import { NoDataContainer } from '../../../../components/NoDataContainer';
import {
  fetchAssignDetailRequestByLinkRoutine,
  sendAnswersByLinkRoutine
} from '../../routines';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from 'assets/images/company_name.svg';
import { Menu, Image } from 'semantic-ui-react';
import { RequestByLink } from 'screens/Feedback/components/RequestByLink';

interface IManageRequestsProps {
  getDetailedRequest: IBindingCallback1<string>;
  sendAnswers: IBindingCallback1<any>;
  selectedRequest: IRequest;
}

const ManageRequests: React.FunctionComponent<IManageRequestsProps> = (
  { selectedRequest, getDetailedRequest, sendAnswers }: IManageRequestsProps
) => {
  const { id } = useParams();
  useEffect(() => {
    getDetailedRequest(id);
  }, []);
  useEffect(() => {
    getDetailedRequest(id);
  }, [id]);
  return (
    <>
      <Menu secondary className={styles.menu}>
        <Menu.Item
          className={styles.logoMenuItem}
          content={(
            <Image src={logo} alt="" className={styles.logo} id="binaryLogo" />
          )}
        />
      </Menu>
      <div className={styles.sectionContainer}>
        <div className={styles.formsViewContainer}>
          {selectedRequest
            ? (
              <RequestByLink
                request={selectedRequest}
                sendAnswers={sendAnswers}
                id={id}
              />
            )
            : (
              <div className="fullHeightData">
                <NoDataContainer text=" Not found ..." />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { feedbackPage: { manageRequestsByLink: { selectedRequest } } } = state;
  return {
    selectedRequest
  };
};

const mapDispatchToProps = {
  getDetailedRequest: fetchAssignDetailRequestByLinkRoutine,
  sendAnswers: sendAnswersByLinkRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRequests);

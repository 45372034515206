import {
  fetchNewsRoutine,
  saveNewsRoutine,
  removeNewsRoutine,
  syncNewsWithOriginByIdRoutine,
  updateNewsRoutine,
  syncNewsForUpdateWithOriginByIdRoutine,
  setShowDraftsRoutine,
  publishNewsRoutine
} from 'screens/News/routines';
import { Routine } from 'redux-saga-routines';
import { INewsItemShort } from 'screens/News/models/news/newsItemShort';

interface INewsState {
  news: INewsItemShort[];
  hasMoreNews: boolean;
  skip: number;
  limit: number;
  showDrafts: boolean;
}

const initialState: INewsState = {
  news: [],
  hasMoreNews: true,
  skip: 0,
  limit: 10,
  showDrafts: false
};

export const news = (state: INewsState = initialState, action: Routine<any>): INewsState => {
  const {
    type,
    payload
  } = action;
  switch (type) {
    case setShowDraftsRoutine.SUCCESS:
      return {
        ...initialState,
        showDrafts: payload
      };
    case fetchNewsRoutine.SUCCESS:
      return {
        ...state,
        skip: state.skip + state.limit,
        news: [...state.news, ...payload],
        hasMoreNews: Boolean(payload.length)
      };
    case publishNewsRoutine.SUCCESS:
      return {
        ...state,
        news: state.showDrafts ? state.news.filter(value => value.id !== payload.id) : [payload, ...state.news]
      };
    case saveNewsRoutine.SUCCESS:
      return {
        ...state,
        news: (state.showDrafts && !payload.isPublished) || (!state.showDrafts && payload.isPublished)
          ? [...state.news, payload]
          : state.news
      };
    case updateNewsRoutine.SUCCESS:
      return {
        ...state,
        news: state.news.map(x => (x.id === payload.id ? payload : x))
      };
    case removeNewsRoutine.SUCCESS:
      return {
        ...state,
        news: state.news.filter(value => value.id !== payload)
      };
    case syncNewsWithOriginByIdRoutine.TRIGGER:
      return {
        ...state,
        news: state.news.map(_news => ((_news.id === payload) ? {
          ..._news,
          syncing: true
        } : _news))
      };
    case syncNewsWithOriginByIdRoutine.FAILURE:
      return {
        ...state,
        news: state.news.map(_news => ((_news.id === payload.id) ? {
          ..._news,
          syncing: false
        } : _news))
      };
    case syncNewsWithOriginByIdRoutine.SUCCESS:
    case syncNewsForUpdateWithOriginByIdRoutine.SUCCESS:
      return {
        ...state,
        news: state.news.map(_news => ((_news.id === payload.id) ? {
          ..._news,
          logo: payload.logo,
          syncing: false
        } : _news))
      };
    default:
      return state;
  }
};


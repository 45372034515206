import React, { SyntheticEvent } from 'react';
import { boundMethod } from 'autobind-decorator';
import {
  Loader,
  Input,
  InputOnChangeData,
  Table,
  Checkbox,
  Confirm,
  Icon,
  Label,
  Divider
} from 'semantic-ui-react';
import addIfNotExisted from 'helpers/array.helper';
import InfiniteScroll from 'react-infinite-scroller';
import { IKeyResult, IKeyResultFilter } from 'screens/OKR/models/key-result';
import debounce from 'debounce';
import { Complexity, KeyResultType, Searchable } from 'screens/OKR/enums';
import { getComplexityColors } from 'screens/OKR/helpers/complexityColorHelper';
import { ITag, ITagFilter } from 'screens/OKR/models/tag';
import { getTags } from 'screens/OKR/services/tagService';
import { ActionMeta } from 'react-select/src/types';
import Select from 'react-select';
import { IBindingCallback1, IBindingAction } from 'models/callback';
import parentStyles from '../LibraryView/styles.module.scss';
import checkboxStyles from '../../../../customStyles/styles.module.scss';
import { NoDataContainer } from 'components/NoDataContainer';
import { connect } from 'react-redux';
import {
  scanKeyResultsForDuplicatesRoutine,
  fetchMoreKeyResultsRoutine,
  updateKeyResultsFiltersRoutine,
  deleteKeyResultRoutine,
  toggleIsCustomKeyResultStatusRoutine,
  removeKeyResultsRoutine,
  toggleIsHiddenKeyResultStatusRoutine,
  toggleIsMergeStatusRoutine
} from '../../routines';
import LinksReplacer from 'components/LinksReplacer/linksReplacer';
import styles from './styles.module.scss';
import { IModalData } from 'screens/Library/model/IModalData';
import { IToggleStatus } from 'screens/Library/model/IToggleStatus';
import { ModalType } from 'screens/Library/model/enums/IModalType';
import { KR_MERGE_STYLES, SELECT_MENU_HEIGHT } from './common';

interface IKeyResultsAdminPageState {
  tags: ITag[];
  selectedDuplicates: number[];
  loadingTags: boolean;
  isConfirmModalOpen: boolean;
  isFiltersShown: boolean;
  globalKeyResultId: string;
  modalData?: IModalData;
}

interface IKeyResultsAdminPageProps {
  keyResults?: IKeyResult[];
  keyResultDuplicates?: IKeyResult[][];
  keyResultsToMerge: IKeyResult[];
  toggleSidebar: IBindingCallback1<string>;
  updateKeyResult: IBindingCallback1<IKeyResult>;
  fetchMoreKR: IBindingCallback1<IKeyResultFilter>;
  scanKeyResultsForDuplicates: IBindingAction;
  hasMoreKeyResults: boolean;
  isScanMode: boolean;
  updateFilters: IBindingAction;
  deleteKeyResult: IBindingCallback1<string>;
  toggleIsCustomStatus: IBindingCallback1<IToggleStatus>;
  toggleKeyResultIsHiddenStatus: IBindingCallback1<IToggleStatus>;
  removeKeyResults: IBindingAction;
  toggleIsMergeStatus: IBindingCallback1<IKeyResult>;
  skip: number;
  limit: number;
}

class KeyResultsAdminPage extends React.Component<IKeyResultsAdminPageProps, IKeyResultsAdminPageState> {
  defaultTagCount = 10;

  keyResultFilter: IKeyResultFilter = {
    from: 0,
    count: this.props.limit,
    types: [],
    complexities: [],
    name: '',
    tags: [],
    searchable: null,
    showHidden: false
  };

  tagFilter: ITagFilter = {
    from: 0,
    count: this.defaultTagCount,
    name: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      selectedDuplicates: [],
      loadingTags: true,
      isConfirmModalOpen: false,
      globalKeyResultId: '',
      modalData: { status: null, modalType: ModalType.Delete },
      isFiltersShown: true
    };

    this.onChangeKeyResultName = debounce(this.onChangeKeyResultName, 500);
  }

  componentDidMount() {
    this.loadTags();
  }

  componentDidUpdate(prevProps) {
    const { scanKeyResultsForDuplicates, isScanMode } = this.props;
    if (prevProps.isScanMode !== isScanMode) {
      if (!prevProps.isScanMode) {
        scanKeyResultsForDuplicates();
      } else {
        this.loadKeyResults();
      }
    }
  }

  componentWillUnmount() {
    const { removeKeyResults } = this.props;
    removeKeyResults();
  }

  onChangeKeyResultName = (_: SyntheticEvent, data: InputOnChangeData) => this.onChangeData('name', data.value.trim());

  onChangeData = (fieldName, value) => {
    this.updateFilters();

    this.keyResultFilter[fieldName] = value;
    this.loadKeyResults();
  }

  onChangeMultiSelect = (fieldName: string) => (data, actionMeta: ActionMeta<any>) => this.onChangeData(
    fieldName, this.toggleChange(actionMeta, this.keyResultFilter[fieldName])
  )

  @boundMethod
  onChangeTagName(value: string) {
    this.tagFilter.name = value;
    this.loadTags();
  }

  handleDeleteConfirm = () => {
    const { globalKeyResultId } = this.state;
    const { deleteKeyResult } = this.props;

    deleteKeyResult(globalKeyResultId);
    this.setState({ isConfirmModalOpen: false });
  }

  toggleShowFilters = () => {
    this.setState(prevState => ({
      isFiltersShown: !prevState.isFiltersShown
    }));
  }

  toggleDuplicateMergeSelection = duplicateGroupId => {
    this.setState(prevState => {
      const { selectedDuplicates } = prevState;
      if (selectedDuplicates.includes(duplicateGroupId)) {
        return {
          selectedDuplicates: selectedDuplicates.filter(id => duplicateGroupId !== id)
        };
      }
      return {
        selectedDuplicates: [...selectedDuplicates, duplicateGroupId]
      };
    });
  }

  toggleIsCustomConfirm = () => {
    const { globalKeyResultId, modalData: { status } } = this.state;
    const { toggleIsCustomStatus } = this.props;
    const { searchable } = this.keyResultFilter;

    const filterStatus = searchable === null ? null : searchable === Searchable.Custom;

    toggleIsCustomStatus({ id: globalKeyResultId, status: !status, filterStatus });
    this.setState({ isConfirmModalOpen: false });
  }

  getConfirmModalText = ({ modalType, status }: IModalData) => {
    switch (modalType) {
      case ModalType.Delete:
        return 'arhive this key result?';
      case ModalType.IsCustom:
        return `make this key result ${status ? 'global' : 'searchable'}?`;
      case ModalType.IsHidden:
        return status ? 'make this key result visible?' : 'hide this key result?';
      default:
        return 'default placeholder';
    }
  }

  handleCancel = () => {
    this.setState({ isConfirmModalOpen: false });
  }

  toggleHideStatus = () => {
    const { globalKeyResultId, modalData: { status } } = this.state;
    const { toggleKeyResultIsHiddenStatus } = this.props;
    const { showHidden } = this.keyResultFilter;

    toggleKeyResultIsHiddenStatus({ id: globalKeyResultId, status: !status, filterStatus: showHidden });
    this.handleCancel();
  }

  getConfirmEvent = (modalType: ModalType) => {
    switch (modalType) {
      case ModalType.Delete:
        return this.handleDeleteConfirm;
      case ModalType.IsCustom:
        return this.toggleIsCustomConfirm;
      case ModalType.IsHidden:
        return this.toggleHideStatus;
      default:
        return () => ({});
    }
  }

  toggleChange<T>(actionMeta: any, array: T[]): T[] {
    const { action } = actionMeta;
    switch (action) {
      case 'select-option':
        return [...array, actionMeta.option.value];
      case 'remove-value':
        return array.filter(type => type !== actionMeta.removedValue.value);
      case 'clear':
        return [];
      default:
        return array;
    }
  }

  updateFilters() {
    const { updateFilters } = this.props;
    updateFilters();
  }

  openConfirmModal(e: SyntheticEvent, keyResultId: string, modalData: IModalData) {
    e.stopPropagation();
    this.setState({ isConfirmModalOpen: true, globalKeyResultId: keyResultId, modalData });
  }

  @boundMethod
  loadKeyResults() {
    const { fetchMoreKR, hasMoreKeyResults, skip } = this.props;
    const krFilter = {
      ...this.keyResultFilter,
      from: skip
    };

    if (hasMoreKeyResults) {
      fetchMoreKR(krFilter);
    }
  }

  @boundMethod
  scanKRsForDuplicates() {
    const { scanKeyResultsForDuplicates, hasMoreKeyResults } = this.props;

    if (hasMoreKeyResults) {
      scanKeyResultsForDuplicates();
    }
  }

  @boundMethod
  loadTags(fetchMore?: boolean) {
    if (!fetchMore) {
      this.tagFilter.from = 0;
    }

    this.setState({ loadingTags: true }, async () => {
      const tags = await getTags(this.tagFilter);
      const { tags: stateTags } = this.state;
      this.setState({
        tags: fetchMore ? addIfNotExisted<ITag>(stateTags, tags) : tags,
        loadingTags: false
      });

      this.tagFilter.from += this.tagFilter.count;
    });
  }

  renderFilters = () => {
    const { tags: tagsOpt, loadingTags, isFiltersShown } = this.state;

    const customStyles = {
      container: base => ({
        ...base,
        minHeight: SELECT_MENU_HEIGHT
      })
    };
    const tagOptions = tagsOpt.map(({ name }) => ({ label: name, value: name }));
    const complexityOptions = Object.values(Complexity).map(compl => ({ label: compl, value: compl }));
    const typeOptions = Object.values(KeyResultType).map(type => ({ label: type, value: type }));
    const searchableOptions = Object.entries(Searchable).map(a => ({ label: a[0], value: a[1] }));
    const hiddenStatusOption = [
      { label: 'All except hidden', value: false },
      { label: 'Hidden only', value: true }
    ];

    return (
      <div>
        <div
          className={styles.filterDivider}
          role="presentation"
        >
          <Divider
            horizontal
            onClick={this.toggleShowFilters}
          >
            {
              isFiltersShown
                ? (
                  <span>
                    {'hide filters '}
                    <Icon name="chevron up" />
                  </span>
                ) : (
                  <span>
                    {'show filters '}
                    <Icon name="chevron down" />
                  </span>
                )
            }
          </Divider>
        </div>
        <div className={isFiltersShown ? styles.expandedFilters : styles.collapsedFilters}>
          <div className={styles.filtersWrapper}>
            <Input
              placeholder="Search By Name"
              icon="search"
              onChange={this.onChangeKeyResultName}
              className={styles.input}
            />
            <Select
              placeholder="Complexity"
              options={complexityOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={this.onChangeMultiSelect('complexities')}
              className={styles.select}
              styles={customStyles}
            />
            <Select
              placeholder="Type"
              options={typeOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={this.onChangeMultiSelect('types')}
              className={styles.select}
              styles={customStyles}
            />
            <Select
              placeholder="Tag"
              options={tagOptions}
              isSearchable
              isMulti
              closeMenuOnSelect={false}
              isLoading={loadingTags}
              onInputChange={this.onChangeTagName}
              onChange={this.onChangeMultiSelect('tags')}
              onMenuScrollToBottom={() => this.loadTags(true)}
              className={styles.select}
              styles={customStyles}
            />
            <Select
              isClearable
              placeholder="Searchable"
              options={searchableOptions}
              onChange={(data: any) => this.onChangeData('searchable', data?.value)}
              className={styles.select}
              styles={customStyles}
            />
            <Select
              isClearable
              placeholder="Hidden items"
              options={hiddenStatusOption}
              onChange={(data: any) => this.onChangeData('showHidden', data?.value)}
              className={styles.select}
              defaultValue={hiddenStatusOption[0]}
              styles={customStyles}
            />
          </div>
        </div>
      </div>
    );
  }

  renderHeader = (onScan: boolean) => (
    <Table className={styles.tableHeader}>
      <Table.Header>
        <Table.Row>
          {onScan && <Table.HeaderCell width={1} />}
          <Table.HeaderCell width={4}>Name</Table.HeaderCell>
          <Table.HeaderCell width={4}>Details</Table.HeaderCell>
          <Table.HeaderCell width={3}>Tags</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width={1}>Complexity</Table.HeaderCell>
          <Table.HeaderCell width={1}>Type</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width={1}>Searchable</Table.HeaderCell>
          <Table.HeaderCell width={2} />
        </Table.Row>
      </Table.Header>
    </Table>
  )

  renderTableRow = (keyResult: IKeyResult) => {
    const { keyResultsToMerge, toggleSidebar, updateKeyResult, toggleIsMergeStatus } = this.props;

    const onToggleMergeStatus = () => {
      toggleIsMergeStatus(keyResult);
    };

    const willMerge = keyResultsToMerge.some(it => it.id === keyResult.id);

    return (
      <Table.Row key={keyResult.id}>
        <Table.Cell width={4}>
          {keyResult.name}
        </Table.Cell>
        <Table.Cell width={4}>
          <LinksReplacer showDomain data={keyResult.details} />
        </Table.Cell>
        <Table.Cell width={3}>
          <div className={styles.tagsCol}>
            {keyResult.tags.map(t => (
              <Label
                key={t.id}
                content={t.name}
              />
            ))}
          </div>
        </Table.Cell>
        <Table.Cell verticalAlign="middle" width={1}>
          <div className={styles.complexity}>
            <div style={getComplexityColors(keyResult.complexity)} />
            {keyResult.complexity}
          </div>
        </Table.Cell>
        <Table.Cell verticalAlign="middle" width={1}>
          {keyResult.keyResultType}
        </Table.Cell>
        <Table.Cell textAlign="center" verticalAlign="middle" width={1}>
          <Checkbox
            checked={!keyResult.isCustom}
            className={checkboxStyles.animatedCheckbox}
            onClick={e => this.openConfirmModal(
              e, keyResult.id, { modalType: ModalType.IsCustom, status: keyResult.isCustom }
            )}
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <div className={styles.actionsContainer}>
            <div
              role="presentation"
              className={styles.comments}
              onClick={() => toggleSidebar(keyResult.id)}
            >
              <Icon
                name="comment outline"
                className={styles.icon}
              />
              <div className={styles.ratings}>
                {keyResult.ratingsCount ?? 0}
              </div>
            </div>
            <Icon
              name="pencil"
              className={styles.icon}
              onClick={e => { e.stopPropagation(); updateKeyResult(keyResult); }}
            />
            <Icon
              name="trash"
              className={styles.icon}
              onClick={e => this.openConfirmModal(e, keyResult.id, { modalType: ModalType.Delete, status: true })}
            />
            <Icon
              name={keyResult.isHidden ? 'eye slash' : 'eye'}
              style={{ color: 'rgba(80, 80, 80, 1)', cursor: 'pointer' }}
              onClick={e => this.openConfirmModal(
                e, keyResult.id, { modalType: ModalType.IsHidden, status: keyResult.isHidden ?? false }
              )}
            />
            <Icon
              name="random"
              style={willMerge ? KR_MERGE_STYLES : undefined}
              onClick={onToggleMergeStatus}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderDuplicateTableRow = (keyResultDuplicates: IKeyResult[], id) => {
    const { toggleSidebar, toggleIsMergeStatus } = this.props;
    const { selectedDuplicates } = this.state;

    const onMergeDuplicates = () => {
      keyResultDuplicates.forEach(kr => toggleIsMergeStatus(kr));
      this.toggleDuplicateMergeSelection(id);
    };

    const willMerge = selectedDuplicates.some(selectedId => selectedId === id);

    return (
      <Table.Row key={id}>
        <Table.Cell verticalAlign="middle" width={1}>
          <Icon
            name="random"
            style={willMerge ? KR_MERGE_STYLES : undefined}
            onClick={onMergeDuplicates}
          />
        </Table.Cell>
        {
          keyResultDuplicates.map(keyResult => (
            <Table.Row>
              <Table.Cell width={4}>
                {keyResult.name}
              </Table.Cell>
              <Table.Cell width={4}>
                <LinksReplacer showDomain data={keyResult.details} />
              </Table.Cell>
              <Table.Cell width={3}>
                <div className={styles.tagsCol}>
                  {keyResult.tags.map(t => (
                    <Label
                      key={t.id}
                      content={t.name}
                    />
                  ))}
                </div>
              </Table.Cell>
              <Table.Cell verticalAlign="middle" width={1}>
                <div className={styles.complexity}>
                  <div style={getComplexityColors(keyResult.complexity)} />
                  {keyResult.complexity}
                </div>
              </Table.Cell>
              <Table.Cell verticalAlign="middle" width={1}>
                {keyResult.keyResultType}
              </Table.Cell>
              <Table.Cell textAlign="center" verticalAlign="middle" width={1}>
                <Checkbox
                  checked={!keyResult.isCustom}
                  className={checkboxStyles.animatedCheckbox}
                  onClick={e => this.openConfirmModal(
                    e, keyResult.id, { modalType: ModalType.IsCustom, status: keyResult.isCustom }
                  )}
                />
              </Table.Cell>
              <Table.Cell width={2}>
                <div className={styles.actionsContainer}>
                  <div
                    role="presentation"
                    className={styles.comments}
                    onClick={() => toggleSidebar(keyResult.id)}
                  >
                    <Icon
                      name="comment outline"
                      className={styles.icon}
                    />
                    <div className={styles.ratings}>
                      {keyResult.ratingsCount ?? 0}
                    </div>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ))
        }
      </Table.Row>
    );
  }

  renderConfirm = (isConfirmModalOpen: boolean, modalData: IModalData) => (
    <Confirm
      content={`Still sure you want to ${this.getConfirmModalText(modalData)}`}
      open={isConfirmModalOpen}
      onCancel={this.handleCancel}
      onConfirm={this.getConfirmEvent(modalData.modalType)}
    />
  );

  render() {
    const { keyResults, keyResultDuplicates, hasMoreKeyResults, isScanMode } = this.props;
    const {
      isConfirmModalOpen,
      modalData
    } = this.state;

    return (
      <div>
        {!isScanMode && this.renderFilters()}
        {this.renderHeader(isScanMode)}
        {this.renderConfirm(isConfirmModalOpen, modalData)}
        <div className={parentStyles.infiniteScrollWrapper}>
          {isScanMode ? (
            <InfiniteScroll
              pageStart={0}
              loader={<Loader style={{ padding: '20px' }} key={0} active inline="centered" />}
              loadMore={this.scanKRsForDuplicates}
              hasMore={hasMoreKeyResults}
              useWindow={false}
            >
              { !keyResultDuplicates.length && !hasMoreKeyResults
                ? (
                  <div className={styles.noDataContainer}>
                    <NoDataContainer text="There are no key result duplicates" />
                  </div>
                )
                : (
                  <Table striped className={styles.tableBody} selectable>
                    <Table.Body>
                      {keyResultDuplicates.map((duplicates: IKeyResult[], id) => (
                        this.renderDuplicateTableRow(duplicates, id)
                      ))}
                    </Table.Body>
                  </Table>
                )}
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              pageStart={0}
              loader={<Loader style={{ padding: '20px' }} key={0} active inline="centered" />}
              loadMore={this.loadKeyResults}
              hasMore={hasMoreKeyResults}
              useWindow={false}
            >
              { !keyResults.length && !hasMoreKeyResults
                ? (
                  <div className={styles.noDataContainer}>
                    <NoDataContainer text="There are no key results" />
                  </div>
                )
                : (
                  <Table striped className={styles.tableBody} selectable>
                    <Table.Body>
                      {keyResults.map((keyResult: IKeyResult) => (
                        this.renderTableRow(keyResult)
                      ))}
                    </Table.Body>
                  </Table>
                )}
            </InfiniteScroll>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    keyResults: { keyResults, keyResultDuplicates, keyResultsToMerge, hasMoreKeyResults, skip, limit }
  } = state.okr.library;
  return {
    keyResults,
    keyResultDuplicates,
    keyResultsToMerge,
    hasMoreKeyResults,
    skip,
    limit
  };
};

const mapDispatchToProps = {
  fetchMoreKR: fetchMoreKeyResultsRoutine,
  scanKeyResultsForDuplicates: scanKeyResultsForDuplicatesRoutine,
  updateFilters: updateKeyResultsFiltersRoutine,
  deleteKeyResult: deleteKeyResultRoutine,
  toggleIsCustomStatus: toggleIsCustomKeyResultStatusRoutine,
  toggleKeyResultIsHiddenStatus: toggleIsHiddenKeyResultStatusRoutine,
  removeKeyResults: removeKeyResultsRoutine,
  toggleIsMergeStatus: toggleIsMergeStatusRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyResultsAdminPage);

import DataSidebar from "components/DataSidebar";
import FormWrapper from "components/FormWrapper";
import { IBindingAction } from "models/callback";
import React from "react";
import AddNewsForm from "screens/News/containers/AddNewsForm";

export interface IAddEditSidebarProps {
	onClose: IBindingAction;
	onSave: IBindingAction;
	editingNewsId: string;
  }

export const AddEditSidebar: React.FunctionComponent<IAddEditSidebarProps> = ({
	onClose, onSave, editingNewsId
}) =>
	<DataSidebar onClose={onClose}>
		<FormWrapper headerText={editingNewsId ? 'Edit news' : 'Create news'} onClose={onClose}>
			<AddNewsForm newsId={editingNewsId}	onSave={onSave} />
		</FormWrapper>
	</DataSidebar>;
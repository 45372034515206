import { IRequest } from '../../model/Requests/IRequest';
import { Routine } from 'redux-saga-routines';
import { fetchAssignDetailRequestByLinkRoutine } from '../../routines';

export interface IManageRequestsState {
  selectedRequest: IRequest;
}

const initialState: IManageRequestsState = {
  selectedRequest: null
};

export const manageRequestsByLink = (
  state = initialState,
  action: Routine<any>
): IManageRequestsState => {
  switch (action.type) {
    case fetchAssignDetailRequestByLinkRoutine.SUCCESS: {
      return {
        ...state,
        selectedRequest: action.payload
      };
    }
    case fetchAssignDetailRequestByLinkRoutine.FAILURE: {
      return {
        ...state,
        selectedRequest: null
      };
    }
    default:
      return state;
  }
};

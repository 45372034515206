import React from 'react';
import { Header, Grid, Image, Icon } from 'semantic-ui-react';
import { IUserInfo } from 'screens/UserProfile/models/IUserInfo';
import { IProject } from 'screens/Projects/model/IProject';
import styles from './styles.module.scss';
import { geo, birthday, technology, intro } from 'assets/icons';
import { formatDateToFormat } from 'screens/UserProfile/helpers/date.helpers';

export interface IUserProfileTabProps {
  userInfo: IUserInfo;
  projects: IProject[];
}

const getLink = (link: string) => {
  const dataParts = link.split(' ');
  return (
    <a href={dataParts[0]} target="_blank" rel="noopener noreferrer">
      {dataParts.length ? dataParts[1] : 'Link'}
    </a>
  );
};

// const projectLink = (project: IProject) => (
//   <div className={styles.projectContainer} key={project.id}>
//     <div
//       className={styles.imageContainer}
//       style={{
//         background: `url(${getFileUrlIfExists(
//           project.logoLink,
//           'https://www.hrinasia.com/wp-content/uploads/2016/01/project-management-6.jpg'
//         )})`
//       }}
//     />
//     <div className={styles.projectData}>
//       <div className={styles.projectName}>
//         <a href={project.linkToCaseStudy} target="_blank" rel="noopener noreferrer">
//           {project.title}
//         </a>
//       </div>
//       <div className={styles.projectInfo}>
//         <div className={styles.projectTags}>
//           {project.tags.map(tag => (
//             <div className={styles.projectTag} key={tag.id}>
//               {tag.name}
//             </div>
//           ))}
//         </div>
//         <div className={styles.startDate}>
//           {`Since ${formatDateToFormat(project.startDate, 'MMM YYYY')}`}
//         </div>
//       </div>
//     </div>
//   </div>
// );

const dataBlock = (header: string, data: string[]) => (
  data ? (
    <div className={styles.block}>
      <Header size="medium" content={header} />
      <ul>
        {data.map((element, index) => {
          const key = index + element;
          return (element ? <li key={key}>{element}</li> : '');
        })}
      </ul>
    </div>
  ) : ''
);

const socialNetworkLinksBlock = (socialNetworkLinks: string) => (
  socialNetworkLinks ? (
    <div className={styles.block}>
      <Header size="medium" content="Social networks" />
      <Grid columns={3}>
        {socialNetworkLinks?.split('\n').map((link: string) => (
          <Grid.Column key={link}>
            <Icon name="hashtag" />
            {getLink(link)}
          </Grid.Column>
        ))}
      </Grid>
    </div>
  ) : ''
);

export const UserProfileTab: React.FunctionComponent<IUserProfileTabProps> = ({
  userInfo = {}
  // projects = []
}) => {
  // const bsaGraduateInfo = userInfo.bsaGraduatedYearInfo
  //   ? `Graduate academy at ${userInfo?.bsaGraduatedYearInfo}`
  //   : '';
  // const bsaRolesInfo = userInfo.bsaRoles ? `Was ${userInfo?.bsaRoles}` : '';
  const introLetterLinkData = userInfo?.introLetterLink
    ? <a href={userInfo.introLetterLink} target="_blank" rel="noopener noreferrer">Intro letter</a>
    : <span className={styles.noData}>Intro letter link</span>;

  const noDataString = <span className={styles.noData}> Not set yet </span>;

  return (
    <div className={styles.container}>
      <div className={styles.gridColumn}>
        <div className={styles.block}>
          <Header size="medium" className={styles.baseInfoHeader} content="Base Info" />
          <div className={styles.elementContainer}>
            <Image src={intro} className={styles.elementData} />
            {introLetterLinkData}
          </div>
          <div className={styles.elementContainer}>
            <Image src={geo} className={styles.elementData} />
            {`Office - ${userInfo.offices?.join(', ')}`}
          </div>
          <div className={styles.elementContainer}>
            <Image src={technology} className={styles.elementData} />
            Technology - &nbsp;
            {userInfo.technology || noDataString}
          </div>
          <div className={styles.elementContainer}>
            <Image src={birthday} className={styles.elementData} />
            {`Birthday - ${formatDateToFormat(userInfo.birthDate.toString())}`}
          </div>
          {/* {bsaGraduateInfo || bsaRolesInfo ? (
            <>
              {bsaGraduateInfo ? <p>{bsaGraduateInfo}</p> : ''}
              {bsaRolesInfo ? <p>{bsaRolesInfo}</p> : ''}
            </>
          ) : (
            ''
          )} */}
        </div>
        {/* {projects.length > 0 ? (
          <div className={styles.block}>
            <Header size="medium" content="Projects" />
            {projects.map(project => (
              projectLink(project)
            ))}
          </div>
        ) : (
          ''
        )} */}
      </div>
      <div className={styles.gridColumn}>
        {socialNetworkLinksBlock(userInfo.socialNetworkLinks)}
        {userInfo.hobbies
          ? dataBlock('Hobbies', userInfo.hobbies?.trim().split('\n'))
          : ''}
      </div>
    </div>
  );
};

import { Routine } from 'redux-saga-routines';
import { takeEvery, put, call, all } from 'redux-saga/effects';
import * as okrService from '../../services/okrService';
import { fetchOkrs, saveOkrRoutine, deleteOkrRoutine } from '../../routines';

function* okrsRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.getOkrs, payload);

    yield put(fetchOkrs.success(response));
  } catch (error) {
    yield put(fetchOkrs.failure(error.message));
  }
}
function* saveOkrRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.saveOkr, payload);
    yield put(
      saveOkrRoutine.success({
        ...response,
        userId: response.user.id,
        editing: !!payload.id,
        isClosed: false,
        user: undefined
      })
    );
  } catch (error) {
    yield put(saveOkrRoutine.failure(error.message));
  }
}

function* deleteOkrRequest({ payload }: Routine<any>) {
  try {
    yield call(okrService.deleteOkr, payload);
    yield put(deleteOkrRoutine.success({ id: payload }));
  } catch (error) {
    yield put(deleteOkrRoutine.failure(error.message));
  }
}

function* watchOkrsRequest() {
  yield takeEvery(fetchOkrs.TRIGGER, okrsRequest);
}

function* watchSaveOkrRequest() {
  yield takeEvery(saveOkrRoutine.TRIGGER, saveOkrRequest);
}

function* watchDeleteOkrRequest() {
  yield takeEvery(deleteOkrRoutine.TRIGGER, deleteOkrRequest);
}

export default function* okrsSagas() {
  yield all([
    watchOkrsRequest(),
    watchSaveOkrRequest(),
    watchDeleteOkrRequest()
  ]);
}

import { takeEvery, put, call, all } from 'redux-saga/effects';
import { getUserDetails } from 'screens/Authorization/services/userService';
import { fetchUserRoutine, logInRoutine } from 'screens/Authorization/routines';
import { Routine } from 'redux-saga-routines';
import * as authService from 'screens/Authorization/services/authService';
import { history } from 'helpers/history';
import { connectSocketsRoutine } from 'containers/Notifications/routines';

function* fetchUserRequest() {
  try {
    const response = yield call(getUserDetails);
    yield put(fetchUserRoutine.success(response));

    yield put(connectSocketsRoutine.trigger(response));
  } catch (error) {
    yield put(fetchUserRoutine.failure(error.message));
  }
}

function* logInRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(authService.login, payload);
    yield put(logInRoutine.success(response));

    yield put(fetchUserRoutine.trigger());
    history.push('/');
  } catch (error) {
    yield put(logInRoutine.failure(error.message));
  }
}

function* watchLogInRequest() {
  yield takeEvery(logInRoutine.TRIGGER, logInRequest);
}

function* watchUserRequest() {
  yield takeEvery(fetchUserRoutine.TRIGGER, fetchUserRequest);
}

export default function* userSaga() {
  yield all([
    watchUserRequest(),
    watchLogInRequest()
  ]);
}

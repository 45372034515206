import React from 'react';
import logo from 'assets/images/company_name.svg';
import styles from './styles.module.scss';
import LoaderWrapper from 'components/LoaderWrapper';

interface ILoginPageLayoutProps {
  children?: any;
  loading?: boolean;
}

class PageLayout extends React.Component<ILoginPageLayoutProps> {
  componentDidMount() {
    window.addEventListener('mousemove', this.parallax);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.parallax);
  }

  parallax = e => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const deltaX = (0.5 * w - e.clientX) / (0.5 * w);
    const deltaY = (0.5 * h - e.clientY) / (0.5 * h);
    const leftColumn = document.getElementById('leftColumn');
    leftColumn.style.backgroundPositionX = `${50 - (deltaX * 20)}%`;
    leftColumn.style.backgroundPositionY = `${20 + (deltaY * 5)}%`;
  }

  render() {
    const { children, loading } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.leftColumn} id="leftColumn">
          <div className={styles.squareOne} />
          <div className={styles.logoContainer}>
            <img src={logo} alt="" />
            <div className={styles.text}>
              Engineering world-class software together
              <div className={styles.squareThree} />
            </div>
          </div>
          <div className={styles.squareTwo} />
        </div>
        <div className={`${styles.rightColumn} fullHeightLoader`}>
          <LoaderWrapper loading={loading}>
            {children ? (
              <div className={styles.childrenContainer}>
                {children}
              </div>
            ) : null}
          </LoaderWrapper>
        </div>
      </div>
    );
  }
}

export default PageLayout;

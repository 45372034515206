import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as userService from '../../services/userService';
import * as okrService from '../../services/okrService';
import {
  fetchUsersRoutine,
  fetchUserOkrsRoutine,
  fetchOkrRoutine,
  saveOkrRoutine,
  addObjectiveToOkr,
  addNoteToOkr
} from 'screens/UsersOkrs/routines';
import { Routine } from 'redux-saga-routines';
import { IOkrToSave, IOkrNoteToSave, IOkrExtended, IObjectivesToOkrsIds } from 'screens/OKR/models/okr';
import { IObjective } from 'screens/OKR/models/objective';

function* fetchUsers({ payload }: Routine<any>) {
  try {
    const response = yield call(userService.getAll, payload);
    yield put(fetchUsersRoutine.success({ response, showMentees: payload.onlyMentees }));
  } catch (error) {
    yield put(fetchUsersRoutine.failure(error.message));
  }
}

function* fetchUserOkrs({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.getCurrent, payload);
    yield put(fetchUserOkrsRoutine.success({ response, userId: payload }));
  } catch (error) {
    yield put(fetchUserOkrsRoutine.failure(error.message));
  }
}

function* fetchOkr({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.getOkr, payload);
    yield put(fetchOkrRoutine.success(response));
  } catch (error) {
    yield put(fetchOkrRoutine.failure(error.message));
  }
}

function* saveOkr({ payload }: Routine<any>) {
  try {
    const okrToSave = payload as IOkrToSave;

    const response = yield call(okrService.saveOkr, okrToSave);

    const newOkr = {
      ...response,
      objectives: []
    } as IOkrExtended;

    yield put(saveOkrRoutine.success(newOkr));
  } catch (error) {
    yield put(saveOkrRoutine.failure(error.message));
  }
}

function* addNote({ payload }: Routine<any>) {
  try {
    const noteToSave = payload as IOkrNoteToSave;

    const response = yield call(okrService.addNoteToOkr, noteToSave);

    const updatedOkr = {
      ...response
    } as IOkrExtended;

    yield put(addNoteToOkr.success(updatedOkr));
  } catch (error) {
    yield put(addNoteToOkr.failure(error.message));
  }
}

function* addObjectiveToOkrRequest({ payload }: Routine<any>) {
  try {
    const objectiveToSaveIds = payload as IObjectivesToOkrsIds;
    const response = yield call(okrService.addObjectiveToOkr, objectiveToSaveIds);

    const newObjective = {
      ...response,
      keyResults: []
    } as IObjective;

    yield put(addObjectiveToOkr.success(newObjective));
  } catch (error) {
    yield put(addObjectiveToOkr.failure(error.message));
  }
}

function* watchFetchOkr() {
  yield takeEvery(fetchOkrRoutine.TRIGGER, fetchOkr);
}

function* watchFetchUsers() {
  yield takeEvery(fetchUsersRoutine.TRIGGER, fetchUsers);
}

function* watchFetchUserOkrs() {
  yield takeEvery(fetchUserOkrsRoutine.TRIGGER, fetchUserOkrs);
}

function* watchSaveOkr() {
  yield takeEvery(saveOkrRoutine.TRIGGER, saveOkr);
}

function* watchAddNote() {
  yield takeEvery(addNoteToOkr.TRIGGER, addNote);
}

function* watchAddObjectiveToOkrRequest() {
  yield takeEvery(addObjectiveToOkr.TRIGGER, addObjectiveToOkrRequest);
}

export default function* userOkrsSagas() {
  yield all([
    watchFetchUsers(),
    watchFetchUserOkrs(),
    watchFetchOkr(),
    watchSaveOkr(),
    watchAddNote(),
    watchAddObjectiveToOkrRequest()
  ]);
}

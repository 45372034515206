import { Routine } from 'redux-saga-routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import * as requestsService from '../../services/requestsService';
import { importFeedbacksCsvRoutine } from '../../routines';

function* importCsv({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(requestsService.importFeedbacksCsv, payload);

    if (res.errors) {
      toastr.error('Error on feedbacks csv import!', res.errors);
    }
    if (res.success) {
      toastr.success('Success!', res.success ?? 'Import feedbacks from file finished.');
    }

    yield put(importFeedbacksCsvRoutine.success(res));
  } catch (error) {
    yield put(importFeedbacksCsvRoutine.failure(error));
  }
}

function* watchImportCsv() {
  yield takeEvery(importFeedbacksCsvRoutine.TRIGGER, importCsv);
}

export default function* importCsvSagas() {
  yield all([
    watchImportCsv()
  ]);
}

import React from 'react';
import { List } from 'semantic-ui-react';

import KeyResultInfo from '../KeyResultInfo';

import styles from './styles.module.scss';

const ObjectiveItem = ({ objective, okr, notMarkedOkr }) => (
  <List.Item key={objective.id}>
    <List.Content>
      <List.Header className={styles.objectiveHeader}>
        <div role="presentation">
          <div className={styles.name}>
            {objective.name}
          </div>
        </div>
      </List.Header>
      <List.List className={styles.list}>
        <List.Item>
          <List.Content>
            <div>
              {objective.keyResults.length > 0 && (
                objective.keyResults.map(keyResult => (
                  <KeyResultInfo
                    isOkrClosed={okr.isClosed}
                    keyResult={keyResult}
                    notMarked={notMarkedOkr.includes(
                      keyResult
                    )}
                    key={keyResult.id}
                  />
                )))}
            </div>
          </List.Content>
        </List.Item>
      </List.List>
    </List.Content>
  </List.Item>
);

export default ObjectiveItem;

import { combineReducers } from 'redux';
import { reducerCreator } from 'helpers/reducerHelper';
import { fetchUserOkrsStatsRoutine } from '../routines';
import { dashboardPage } from '../containers/DashboardPage/reducer';

const requests = combineReducers({
  okrs: reducerCreator([fetchUserOkrsStatsRoutine.TRIGGER])
});

export default combineReducers({
  dashboard: dashboardPage,
  requests
});

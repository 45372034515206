import React, { useMemo } from 'react';
import { Chart } from 'react-charts';
import styles from './styles.module.scss';

interface IChartData {
  question: string;
  avg: number;
}
interface ITotalChartProps {
  dataChart: IChartData[];
  title: string;
}

const TotalChart: React.FC<ITotalChartProps> = ({
  dataChart,
  title
}: ITotalChartProps) => {
  const chartData = dataChart.map(a => ([dataChart.findIndex(el => el === a), a.avg]));

  const data = useMemo(
    () => [
      {
        label: 'Questions',
        data: chartData
      },
      {
        label: 'Questions',
        data: chartData
      }
    ],
    []
  );

  const series = React.useCallback(
    (s, i) => ({
      type:
        i % 2 === 0
          ? 'area'
          : 'line'
    }),
    []
  );

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        position: 'bottom',
        type: 'linear',
        show: false
      },
      {
        position: 'left',
        type: 'linear',
        show: true,
        hardMax: 4,
        hardMin: 0
      }
    ],
    []
  );

  const getSeriesStyle = React.useCallback(
    s => ({
      color: s.index % 2 === 0 ? 'url(#1)' : '#58C2F1'
    }),
    []
  );
  const tooltip = React.useMemo(
    () => ({
      render: props => (
        <span style={{ fontSize: '1rem' }}>
          <span>{`${dataChart[props?.datum?.originalDatum[0]]?.question}?`}</span>
          <p>
            AVG:
            {`${dataChart[props?.datum?.originalDatum[0]]?.avg}`}
          </p>
        </span>
      )
    }),
    []
  );

  const defs = (
    <defs>
      <linearGradient id="1" x1="0" x2="0" y1="1" y2="0">
        <stop offset="0%" stopColor="#58c3f10a" />
        <stop offset="100%" stopColor="#58c3f1d3" />
      </linearGradient>
    </defs>
  );
  return (
    <div className={styles.activityBlock}>
      <span>{title}</span>
      <div className={styles.chartBlock}>
        <Chart
          data={data}
          series={series}
          axes={axes}
          getSeriesStyle={getSeriesStyle}
          primaryCursor={{ showLabel: false }}
          getDatumStyle={datum => ({
            color: datum.originalDatum.color
          })}
          renderSVG={() => defs}
          tooltip={tooltip}
        />
      </div>
    </div>
  );
};

export default TotalChart;

import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { IBindingAction } from 'models/callback';
import noDataImage from '../../assets/images/noData.svg';
import { Button } from 'semantic-ui-react';

export interface INoDataContainerSettigns {
  onButtonClick?: IBindingAction;
  buttonText?: string;
  text: string;
  background?: boolean;
  imageWidth?: string;
}

export const NoDataContainer: FunctionComponent<INoDataContainerSettigns> = ({
  onButtonClick,
  text,
  buttonText,
  background,
  imageWidth = '15%'
}: INoDataContainerSettigns) => (
  <div
    className={styles.noDataContainer}
    style={{ backgroundColor: background ? '#FBFBFB' : '#FFFFFF' }}
  >
    <img src={noDataImage} alt="" width={imageWidth} />
    <p>{text}</p>
    {buttonText ? (
      <Button primary content={buttonText} onClick={onButtonClick} />
    ) : (
      ''
    )}
  </div>
);

import { callGatewayApi, callUserProfileApi } from 'helpers/webApiHelper';

export const getUsers = async () => {
  const response = await callGatewayApi({
    endpoint: '/api/users',
    type: 'GET'
  });
  return response.json();
};

export const getInfo = async (userId: string) => {
  const response = await callGatewayApi({
    endpoint: `/api/users/${userId}`,
    type: 'GET'
  });
  return response.json();
};

export const getProfileInfo = async (userId: string) => {
  const response = await callUserProfileApi({
    endpoint: `/api/userProfile/${userId}`,
    type: 'GET'
  });
  return response.json();
};

export const getUserData = async () => {
  const response = await callUserProfileApi({
    endpoint: '/api/userProfile/maindata',
    type: 'GET'
  });
  return response.json();
};

export const getUsersMentor = async () => {
  const response = await callUserProfileApi({
    endpoint: 'api/userProfile/mentors',
    type: 'GET'
  });
  return response.json();
};

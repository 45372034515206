import React from 'react';
import { Checkbox, Table, Loader, Icon, Label, Popup } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';

import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { getComplexityColors } from '../../helpers/complexityColorHelper';

import styles from './styles.module.scss';
import { NoDataContainer } from 'components/NoDataContainer';
import LoaderWrapper from 'components/LoaderWrapper';

export interface IOkrGridItemProps {
  keyResults: IKeyResult[];
  selectedKeyResults?: IKeyResult[];
  alreadySelectedResults?: string[];
  onSelect: IBindingCallback1<IKeyResult>;
  loadMore?: IBindingAction;
  hasMore?: boolean;
  toggleSidebar: IBindingCallback1<string>;
  loading: boolean;
  isNarrowScreen: boolean;
}

interface ITag {
  id: string;
  name: string;
}

const KeyResultTable: React.FunctionComponent<IOkrGridItemProps> = ({
  keyResults,
  selectedKeyResults,
  alreadySelectedResults = [],
  onSelect,
  loadMore = () => ({}),
  hasMore = false,
  toggleSidebar,
  loading,
  isNarrowScreen
}) => {
  const isAlreadySelected = (id: string) => alreadySelectedResults.some(obj => obj === id);
  const isSelected = id => selectedKeyResults.some(kr => kr.id === id);

  const handleToggleSidebar = (event: Event, keyResultId: string) => {
    event.stopPropagation();
    toggleSidebar(keyResultId);
  };

  const mapKRtags = (tags: ITag[]) => {
    const showOnly = 2;

    const tagsToShow = tags.slice(0, showOnly).map(tag => (
      <div key={tag.id} className={styles.tag}>
        <Popup
          content={tag.name}
          trigger={(
            <Label
              content={tag.name}
            />
          )}
          size="mini"
          inverted
        />
      </div>
    ));

    if (tags.length <= showOnly) {
      return [...tagsToShow];
    }

    const hiddenTagsLabel = (
      <div key={tags.length} className={styles.tag}>
        <Popup
          content={tags.map(tag => <p key={tag.id} className={styles.tooltiptext}>{tag.name}</p>)}
          trigger={(
            <Label
              content={`${tags.length - showOnly} more`}
            />
          )}
          size="mini"
          inverted
        />
      </div>
    );

    return [...tagsToShow, hiddenTagsLabel];
  };

  const renderKr = (kr: IKeyResult) => (
    <Table.Row
      key={kr.id}
      className={isSelected(kr.id) ? styles.selected : ''}
      style={{ cursor: 'pointer' }}
      onClick={() => onSelect(kr)}
    >
      <Table.Cell width="1" collapsing verticalAlign="middle">
        <div className={styles.keyResultCheckbox}>
          <Checkbox
            checked={isSelected(kr.id)}
            onChange={() => onSelect(kr)}
          />
        </div>
      </Table.Cell>
      <Table.Cell width="12" verticalAlign="middle">
        {kr.name}
      </Table.Cell>
      {!isNarrowScreen && (
        <Table.Cell width="1" textAlign="center" verticalAlign="middle">
          {mapKRtags(kr.tags)}
        </Table.Cell>
      )}
      {!isNarrowScreen && (
        <Table.Cell width="1" textAlign="center" verticalAlign="middle">
          <div
            className={`${styles.complexity} ${styles.block}`}
          >
            <div style={getComplexityColors(kr.complexity)} />
            {kr.complexity}
          </div>
        </Table.Cell>
      )}
      <Table.Cell width="1" textAlign="center" verticalAlign="middle">
        <div className={styles.type}>{kr.keyResultType}</div>
      </Table.Cell>
      <Table.Cell width="1" verticalAlign="middle" onClick={event => handleToggleSidebar(event, kr.id)}>
        <div className={styles.comments}>
          <Icon name="comment outline" size="small" />
          <div className={styles.ratings}>
            {kr.ratingsCount}
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );

  return (
    <div className={styles.table}>
      <LoaderWrapper loading={loading}>
        <InfiniteScroll
          pageStart={0}
          loader={<Loader style={{ padding: '20px' }} key={0} active inline="centered" />}
          loadMore={loadMore}
          hasMore={hasMore}
          useWindow={false}
        >
          {(!keyResults.length && !selectedKeyResults.length)
            ? (
              <div className={styles.noDataContainer}>
                <NoDataContainer text="There are no keyresults" />
              </div>
            )
            : (
              <Table>
                <Table.Body>
                  {keyResults.map(kr => !isAlreadySelected(kr.id) && renderKr(kr))}
                </Table.Body>
              </Table>
            )}
        </InfiniteScroll>
      </LoaderWrapper>
    </div>
  );
};

export default KeyResultTable;

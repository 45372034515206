import { Routine } from 'redux-saga-routines';
import {
  fetchNewsDetailsByIdRoutine,
  addCommentRoutine,
  removeNewsDetailsRoutine,
  syncNewsForUpdateWithOriginByIdRoutine
} from 'screens/News/routines';
import { INewsItemDetails, ISynchronizable } from 'screens/News/models/news/newsItemDetails';

export const newsDetails = (state: (INewsItemDetails & ISynchronizable) = null,
  action: Routine<any>): (INewsItemDetails & ISynchronizable) => {
  const { type, payload } = action;

  switch (type) {
    case fetchNewsDetailsByIdRoutine.SUCCESS:
      return payload;
    case addCommentRoutine.SUCCESS:
      return {
        ...state, comments: [...state.comments, payload]
      };
    case removeNewsDetailsRoutine.TRIGGER:
      return null;
    case syncNewsForUpdateWithOriginByIdRoutine.TRIGGER:
      return {
        ...state,
        syncing: true
      };
    case syncNewsForUpdateWithOriginByIdRoutine.FAILURE:
      return {
        ...state,
        syncing: false
      };
    case syncNewsForUpdateWithOriginByIdRoutine.SUCCESS:
      return {
        ...state,
        id: payload.id,
        title: payload.title,
        description: payload.description,
        html: payload.html,
        externalLink: payload.externalLink,
        syncing: false
      };
    default:
      return state;
  }
};

import { NoDataContainer } from 'components/NoDataContainer';
import React from 'react';
import QuestionsBlockForm from 'screens/Feedback/components/QuestionsBlockForm';
import styles from './styles.module.scss';

function getBlocksPageFromIsEdit(isEdit, formSaved, formCanceled) {
  return (isEdit
    ? (
      <QuestionsBlockForm
        title="Edit questions block"
        formSaved={formSaved}
        formCanceled={formCanceled}
      />
    )
    : (
      <div className={styles.centeredTextContainer}>
        <h3>Select questions block to edit</h3>
      </div>
    )
  );
}

export function getEmptyBlocksPageFromIsCreating(isCreate, formSaved, formCanceled) {
  return (isCreate
    ? (
      <QuestionsBlockForm
        title="Add new questions block"
        formSaved={formSaved}
        formCanceled={formCanceled}
      />
    )
    : (
      <div className={`${styles.fullHeightData} fullWidthData`}>
        <NoDataContainer text="There are no questions blocks... Maybe its time to create a new one?" />
      </div>
    )
  );
}

export function getBlocksPageFromIsCreating(
  isCreate,
  createFormSaved,
  createFormCanceled,
  isEdit,
  editFormSaved,
  editFormCanceled
) {
  return (
    <>
      {isCreate
        ? (
          <QuestionsBlockForm
            title="Add new questions block"
            formSaved={createFormSaved}
            formCanceled={createFormCanceled}
          />
        )
        : (
          getBlocksPageFromIsEdit(isEdit, editFormSaved, editFormCanceled)
        )}
    </>
  );
}

import { Routine } from 'redux-saga-routines';
import { fetchTeamUsersRoutine } from '../../routines';
import { IUser } from '../../models/IUser';

export interface ITeamUsersState {
  users: IUser[];
  loadingTeamUsers: boolean;
}

const initialState: ITeamUsersState = {
  users: [],
  loadingTeamUsers: true
};

export const teamUsersPage = (state = initialState, action: Routine<any>): ITeamUsersState => {
  switch (action.type) {
    case fetchTeamUsersRoutine.TRIGGER:
      return { ...state, loadingTeamUsers: true };
    case fetchTeamUsersRoutine.SUCCESS:
      return { ...state, users: action.payload, loadingTeamUsers: false };
    default:
      return state;
  }
};

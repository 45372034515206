import { IUserDaysOffDTO } from 'screens/TimeOffTracker/models/daysOff/userDaysOffDTO';
import { Routine } from 'redux-saga-routines';
import { fetchAllDaysOffRoutine, removeAllDaysOffRoutine } from 'screens/TimeOffTracker/routines';

export const daysOff = (state = [], action: Routine<any>): IUserDaysOffDTO[] => {
  switch (action.type) {
    case fetchAllDaysOffRoutine.SUCCESS:
      return action.payload;
    case removeAllDaysOffRoutine.TRIGGER:
      return [];
    default:
      return state;
  }
};

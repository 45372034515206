import { Routine } from 'redux-saga-routines';
import {
  fetchMoreObjectivesRoutine,
  updateObjectiveFiltersRoutine,
  deleteObjectiveRoutine,
  toggleIsCustomObjectiveStatusRoutine,
  saveObjectiveAdminRoutine,
  removeObjectiveRoutine,
  toggleObjectiveIsHiddenStatusRoutine
} from '../../routines';
import { IBindingFunction } from 'models/callback';
import { IObjectiveStrict } from 'screens/OKR/models/objective/IObjectiveStrict';
import { IToggleStatus } from 'screens/Library/model/IToggleStatus';

interface IObjectivesState {
  objectives: IObjectiveStrict[];
  hasMoreObjectives: boolean;
  skip: number;
  limit: number;
}

const initialState: IObjectivesState = { objectives: [], hasMoreObjectives: true, skip: 0, limit: 20 };

const updateObjectiveStatus = (
  objectivesList: IObjectiveStrict[],
  mapObjective: IBindingFunction<IObjectiveStrict, IObjectiveStrict>,
  payload: IToggleStatus
) => (
  payload.filterStatus !== null && payload.filterStatus !== payload.status
    ? objectivesList.filter(o => o.id !== payload.id)
    : objectivesList.map(o => (o.id === payload.id ? mapObjective(o) : o))
);

export const objectives = (state = initialState, action: Routine<any>): IObjectivesState => {
  switch (action.type) {
    case fetchMoreObjectivesRoutine.SUCCESS:
      return {
        ...state,
        objectives: [...state.objectives, ...action.payload],
        hasMoreObjectives: Boolean(action.payload.length),
        skip: state.skip + state.limit
      };
    case updateObjectiveFiltersRoutine.TRIGGER:
      return initialState;
    case toggleIsCustomObjectiveStatusRoutine.SUCCESS:
      const customPayload = action.payload as IToggleStatus;

      return {
        ...state,
        objectives: updateObjectiveStatus(
          state.objectives,
          o => ({ ...o, isCustom: customPayload.status }),
          customPayload
        )
      };
    case toggleObjectiveIsHiddenStatusRoutine.SUCCESS:
      const hiddenPayload = action.payload as IToggleStatus;

      return {
        ...state,
        objectives: updateObjectiveStatus(
          state.objectives,
          o => ({ ...o, isHidden: hiddenPayload.status }),
          hiddenPayload
        )
      };
    case saveObjectiveAdminRoutine.SUCCESS:
      const isExists = state.objectives.find(o => o.id === action.payload.id);
      return {
        ...state,
        objectives: !isExists
          ? [...state.objectives, action.payload]
          : state.objectives.map(o => (o.id === action.payload.id ? action.payload : o))
      };
    case deleteObjectiveRoutine.SUCCESS:
      return {
        ...state,
        objectives: [...state.objectives.filter(o => o.id !== action.payload)]
      };
    case removeObjectiveRoutine.TRIGGER:
      return initialState;
    default:
      return state;
  }
};

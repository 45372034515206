import { Routine } from 'redux-saga-routines';
import { uploadFileRoutine, downloadFileRoutine, importFromGoogleRoutine } from 'screens/AdminPage/routines';

export interface IUploadingFileState {
  uploading: boolean;
  downloading: boolean;
}

export const loadingFile = (
  state: IUploadingFileState = { uploading: false, downloading: false },
  action: Routine<any>
): IUploadingFileState => {
  switch (action.type) {
    case uploadFileRoutine.TRIGGER:
    case importFromGoogleRoutine.TRIGGER:
      return { ...state, uploading: true };
    case downloadFileRoutine.TRIGGER:
      return { ...state, downloading: true };
    case uploadFileRoutine.SUCCESS:
    case uploadFileRoutine.FAILURE:
    case importFromGoogleRoutine.SUCCESS:
    case importFromGoogleRoutine.FAILURE:
      return { ...state, uploading: false };
    case downloadFileRoutine.SUCCESS:
    case downloadFileRoutine.FAILURE:
      return { ...state, downloading: false };
    default:
      return state;
  }
};

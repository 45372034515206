import { callOkrApi } from 'helpers/webApiHelper';
import { IKeyResult, IKeyResultFilter } from '../models/key-result';
import { IMarkToSave } from '../models/mark/IMarkToSave';
import { IKeyResultBase } from '../models/key-result/IKeyResultBase';
import { IKeyResultsSearch } from '../models/key-result/IKeyResultsSearch';
import { IMergeAction } from 'screens/Library/model';

export const getKeyResult = async (keyResultId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/key-results/${keyResultId}`,
    type: 'GET'
  });

  return response.json();
};

export const getKeyResults = async (filters: IKeyResultFilter | IKeyResultsSearch): Promise<IKeyResult[]> => {
  const response = await callOkrApi({
    endpoint: '/api/key-results/',
    type: 'GET',
    queryParams: filters
  });
  return response.json();
};

export const getKeyResultDuplicates = async (): Promise<IKeyResult[]> => {
  const response = await callOkrApi({
    endpoint: '/api/key-results/duplicates/',
    type: 'GET'
  });
  return response.json();
};

export const getKeyResultsByUserId = async (filters: IKeyResultFilter | IKeyResultsSearch): Promise<IKeyResult[]> => {
  const response = await callOkrApi({
    endpoint: '/api/key-results/byuserid',
    type: 'GET',
    queryParams: filters
  });
  return response.json();
};

export const saveMark = async ({ okrId, objectiveId, keyResultId, mark }: IMarkToSave): Promise<string> => {
  const response = await callOkrApi({
    endpoint: `/api/key-results/${keyResultId}/mark`,
    type: 'POST',
    requestData: {
      okrId,
      objectiveId,
      keyResultId,
      mark
    }
  });
  return response.json();
};

export const saveKeyResult = async (requestData: IKeyResultBase) => {
  const response = await callOkrApi({
    endpoint: '/api/key-results',
    type: 'POST',
    requestData
  });
  return response.json();
};

export const toggleIsCustomStatus = async ({ keyResultId, isCustom }): Promise<string> => {
  const response = await callOkrApi({
    endpoint: `/api/key-results/${keyResultId}/toggleIsCustom`,
    type: 'PUT',
    requestData: {
      keyResultId,
      isCustom
    }
  });
  return response.json();
};

export const toggleIsHiddenStatus = async ({ keyResultId, isHidden }): Promise<string> => {
  const response = await callOkrApi({
    endpoint: `/api/key-results/${keyResultId}/toggleIsHidden`,
    type: 'PUT',
    requestData: {
      keyResultId,
      isHidden
    }
  });
  return response.json();
};

export const deleteKeyResult = async (keyResultId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/key-results/${keyResultId}`,
    type: 'DELETE'
  });
  return response.json();
};

export const getTopMarkedKeyResults = async (date: string) => {
  const response = await callOkrApi({
    endpoint: '/api/key-results/top',
    type: 'GET',
    queryParams: { date }
  });
  return response.json();
};

export const mergeKeyResults = async (payload: IMergeAction) => {
  const response = await callOkrApi({
    endpoint: '/api/key-results/merge',
    type: 'PUT',
    requestData: {
      ...payload
    }
  });
  return response.json();
};

import { callFeedbackApi, callImportFeedbackApi } from '../../../helpers/webApiHelper';
import { IRequest } from '../model/Requests/IRequest';

export const getRequests = async (): Promise<IRequest[]> => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/requests/all',
    type: 'GET'
  });
  return response.json();
};
export const getAssignRequests = async (id): Promise<IRequest[]> => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/requests/assign',
    type: 'GET',
    queryParams: { id }
  });
  return response.json();
};

export const getAssignDetailsRequest = async (id): Promise<IRequest[]> => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/requests/assigndetail',
    type: 'GET',
    queryParams: { id }
  });
  return response.json();
};

export const getRequestDetails = async (requestId): Promise<IRequest> => {
  const response = await callFeedbackApi({
    endpoint: `/api/feedback/requests/${requestId}`,
    type: 'GET'
  });
  return response.json().then(res => res.data);
};

export const importFeedbacksCsv = async ({ userId, file, creatorId }): Promise<void> => {
  const response = await callImportFeedbackApi({
    endpoint: '/api/feedback/csv/import',
    queryParams: { userId, creatorId },
    body: file
  });
  return response.json().then(res => res.data);
};

export const createRequest = async (request): Promise<void> => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/requests/create',
    type: 'POST',
    requestData: request
  });
  return response.json().then(res => res.data);
};

export const generateLink = async request => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/requests/generate',
    type: 'POST',
    requestData: request
  });
  return response.json().then(res => res.data);
};

export const getAssignInfo = async (id): Promise<void> => {
  const response = await callFeedbackApi({
    endpoint: `/api/feedback/requests/${id}/assign`,
    type: 'GET'
  });
  return response.json().then(res => res.data);
};


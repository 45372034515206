import { Routine } from 'redux-saga-routines';
import {
  fetchAdminUserInfoRoutine,
  createUserRoutine,
  deleteUserRoutine,
  openUpdateFormRoutine,
  closeUpdateModalRoutine,
  updateUserRoutine,
  clearAdminPageStateRoutine,
  fetchUserMentorsRoutine
} from 'screens/AdminPage/routines';
import { ISaveUser } from 'screens/AdminPage/model/ISaveUser';
import { IAdminUserInfo } from 'screens/AdminPage/model/IAdminUserInfo';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';

export interface IUserListState {
  users: IAdminUserInfo[];
  updatedUser: ISaveUser;
  usersMentor: IUserMentorData[];
}

const initialState: IUserListState = {
  updatedUser: null,
  users: null,
  usersMentor: null
};

export const userList = (
  state = initialState,
  action: Routine<any>
): IUserListState => {
  switch (action.type) {
    case fetchAdminUserInfoRoutine.SUCCESS:
      return { ...state, users: action.payload };
    case createUserRoutine.SUCCESS:
      return { ...state, users: [...state.users, action.payload] };
    case deleteUserRoutine.SUCCESS:
      return { ...state, users: state.users.filter(x => x.id !== action.payload) };
    case openUpdateFormRoutine.SUCCESS:
      return { ...state, users: state.users, updatedUser: action.payload };
    case closeUpdateModalRoutine.SUCCESS:
      return { ...state, users: state.users, updatedUser: undefined };
    case updateUserRoutine.SUCCESS:
      const user = action.payload;
      const { id } = user;
      return { ...state, users: state.users.map(u => (u.id === id ? user : u)), updatedUser: undefined };
    case fetchUserMentorsRoutine.SUCCESS:
      return { ...state, usersMentor: action.payload };
    case clearAdminPageStateRoutine.TRIGGER:
      return initialState;
    default:
      return state;
  }
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IRequest } from '../../model/Requests/IRequest';
import styles from './styles.module.scss';
import { IBindingCallback1 } from '../../../../models/callback';

interface IRequestTableProps {
  openRequestDetails: IBindingCallback1<string>;
  requests: IRequest[];
  currentRequestId?: string;
}

const RequestTable: React.FunctionComponent<IRequestTableProps> = (
  { requests, currentRequestId, openRequestDetails }: IRequestTableProps
) => (
  <>
    {requests.map(request => (
      <div
        className={`${styles.formInList} ${currentRequestId === request.id ? styles.selected : ''}`}
        onClick={() => openRequestDetails(request.id)}
      >
        <div className={`${styles.formContainer} ${currentRequestId === request.id ? styles.selected : ''}`}>
          <div className={styles.spanWrapper}>
            <span>
              {request?.form?.name ? request.form.name : 'Feedback'}
            </span>
          </div>
        </div>
      </div>
    ))}
  </>
);

export default RequestTable;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { IRequest } from 'screens/Feedback/model/Requests/IRequest';
import { IBindingCallback1 } from 'models/callback';

interface IRequestInListProps {
  request: IRequest;
  isSelected?: boolean;
  onClick: IBindingCallback1<string>;
}

export const RequestInList: React.FunctionComponent<IRequestInListProps> = ({
  request,
  onClick,
  isSelected = false
}: IRequestInListProps) => (
  <div
    className={`${styles.formInList} ${isSelected ? styles.selected : ''}`}
    onClick={() => onClick(request.id)}
  >
    <div className={`${styles.formContainer} ${isSelected ? styles.selected : ''}`}>
      <div className={styles.spanWrapper}>
        <span>{request?.form?.name}</span>
      </div>
    </div>
  </div>
);

export default connect()(RequestInList);

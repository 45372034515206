import React from 'react';
import { IBindingCallback1 } from 'models/callback';
import styles from './styles.module.scss';
import { discardOKRActionRoutine } from 'screens/OKR/routines';
import { connect } from 'react-redux';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { Image, Popup } from 'semantic-ui-react';
import { cross } from 'assets/icons';
import { IDiscardSuggestion } from 'screens/OKR/models/okr';
import ConfirmModal from 'components/ConfirmModal';

interface ISuggestionDiscardFormProps {
  keyResult: IKeyResult;
  objectiveId: string;
  okrId: string;
  discardSuggestion: IBindingCallback1<IDiscardSuggestion>;
}

const SuggestionDiscardForm: React.FunctionComponent<ISuggestionDiscardFormProps> = ({
  discardSuggestion,
  objectiveId,
  keyResult,
  okrId
}: ISuggestionDiscardFormProps) => {
  const confirm = () => {
    discardSuggestion({
      keyResultId: keyResult.id,
      objectiveId,
      okrId
    });
  };

  return (
    <ConfirmModal
      header={`Would you like to remove your suggestion for keyResult "${keyResult.name}"?`}
      onConfirm={confirm}
      confirmButtonText="Remove suggestion"
    >
      <Popup
        content="Discard suggestion"
        position="top center"
        trigger={<Image className={styles.action} src={cross} />}
      />
    </ConfirmModal>
  );
};

const mapDispatchToProps = {
  discardSuggestion: discardOKRActionRoutine
};

export default connect(null, mapDispatchToProps)(SuggestionDiscardForm);

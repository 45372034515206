import { IProject } from 'screens/Projects/model/IProject';
import { Routine } from 'redux-saga-routines';
import {
  fetchProjectsRoutine,
  saveProjectRoutine,
  fetchProjectRoutine,
  closeModalRoutine,
  deleteProjectRoutine,
  fetchProjectsDataRoutine,
  clearProjectPageStateRoutine
} from 'screens/Projects/routines';
import { IProjectModel } from 'screens/Projects/model/IProjectModel';
import { IUser } from 'screens/Projects/model/IUser';
import { ITag } from 'screens/Projects/model/ITag';

export interface IProjectListState {
  projects: IProject[];
  projectToUpdate: IProjectModel;
  users: IUser[];
  tags: ITag[];
}

export const projectsList = (state: IProjectListState = {
  projects: null,
  projectToUpdate: null,
  users: null,
  tags: null
}, action: Routine<any>): IProjectListState => {
  switch (action.type) {
    case fetchProjectsRoutine.SUCCESS:
      return { ...state, projects: action.payload };
    case saveProjectRoutine.SUCCESS:
      const project: IProject = action.payload;
      const { id } = project;
      const currentProject = state.projects.find(p => p.id === id);
      return {
        ...state,
        projects: currentProject ? state.projects.map(p => (p.id === id ? project : p)) : [project, ...state.projects],
        projectToUpdate: null
      };
    case fetchProjectRoutine.SUCCESS:
      return { ...state, projectToUpdate: action.payload };
    case closeModalRoutine.TRIGGER:
      return { ...state, projectToUpdate: null };
    case deleteProjectRoutine.SUCCESS:
      const deletedProjectId = action.payload;
      return { ...state, projects: state.projects.filter(p => p.id !== deletedProjectId) };
    case fetchProjectsDataRoutine.SUCCESS:
      return { ...state, users: action.payload[0], tags: action.payload[1] };
    case clearProjectPageStateRoutine.TRIGGER:
      return null;
    default:
      return state;
  }
};

import { Routine } from 'redux-saga-routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as requestsService from '../../services/requestsService';
import * as userInfoService from '../../services/userInfoService';
import { fetchRequestDetailsRoutine, fetchRequestsRoutine, fetchUsersMentorsRoutine } from '../../routines';

function* requestsFetch(): Routine<any> {
  try {
    const results = yield call(requestsService.getRequests);
    yield put(fetchRequestsRoutine.success(results));
  } catch (error) {
    yield put(fetchRequestsRoutine.failure(error));
  }
}

function* requestDetailsFetch({ payload }: Routine<any>): Routine<any> {
  try {
    const results = yield call(requestsService.getRequestDetails, payload);

    yield put(fetchRequestDetailsRoutine.success(results));
  } catch (error) {
    yield put(fetchRequestDetailsRoutine.failure(error));
  }
}

function* fetchUsersMentors() {
  try {
    const result = yield call(userInfoService.getUsers);
    yield put(fetchUsersMentorsRoutine.success(result));
  } catch (error) {
    yield put(fetchUsersMentorsRoutine.failure(error));
  }
}

function* watchRequestsFetch() {
  yield takeEvery(fetchRequestsRoutine.TRIGGER, requestsFetch);
}

function* watchRequestDetailsFetch() {
  yield takeEvery(fetchRequestDetailsRoutine.TRIGGER, requestDetailsFetch);
}

function* watchUsersMentorsFetch() {
  yield takeEvery(fetchUsersMentorsRoutine.TRIGGER, fetchUsersMentors);
}

export default function* requestsPageSagas() {
  yield all([
    watchRequestsFetch(),
    watchRequestDetailsFetch(),
    watchUsersMentorsFetch()
  ]);
}

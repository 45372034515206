import React from 'react';
import { IBindingCallback1 } from 'models/callback';
import styles from './styles.module.scss';
import { suggestOKRActionRoutine } from 'screens/OKR/routines';
import { connect } from 'react-redux';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { Image, Popup } from 'semantic-ui-react';
import { cross } from 'assets/icons';
import { IKeyResultUpdatingRequest } from 'screens/OKR/models/okr';
import { KeyResultActionType } from 'screens/OKR/enums/KeyResultActionType';
import ConfirmModal from 'components/ConfirmModal';

interface ISuggestDeletionFormProps {
  keyResult: IKeyResult;
  objectiveId: string;
  okrId: string;
  confirmDeleting: IBindingCallback1<IKeyResultUpdatingRequest>;
}

const SuggestDeletionForm: React.FunctionComponent<ISuggestDeletionFormProps> = ({
  confirmDeleting,
  objectiveId,
  keyResult,
  okrId
}: ISuggestDeletionFormProps) => {
  const confirm = () => {
    confirmDeleting({
      action: KeyResultActionType.DeleteKeyResult,
      keyResultToDelete: keyResult.id,
      objectiveId,
      okrId
    });
  };

  return (
    <ConfirmModal
      header={`Would you like suggest to delete key result "${keyResult.name}"?`}
      onConfirm={confirm}
    >
      <Popup
        content="Suggest deletion"
        position="top center"
        trigger={<Image className={styles.action} src={cross} />}
      />
    </ConfirmModal>
  );
};

const mapDispatchToProps = {
  confirmDeleting: suggestOKRActionRoutine
};

export default connect(null, mapDispatchToProps)(SuggestDeletionForm);

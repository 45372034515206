import React from 'react';
import { Icon } from 'semantic-ui-react';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { getComplexityColors } from '../../helpers/complexityColorHelper';
import Text from 'react-texty';
import 'react-texty/styles.css';
import styles from './styles.module.scss';
import LinksReplacer from 'components/LinksReplacer/linksReplacer';

export interface IOkrGridItemProps {
  keyResult: IKeyResult;
  isOkrClosed?: boolean;
  notMarked?: boolean;
}

const KeyResultInfo: React.FunctionComponent<IOkrGridItemProps> = ({
  keyResult: {
    name,
    complexity,
    mark = null,
    details
  },
  keyResult,
  isOkrClosed
}) => {
  const MarkContainer = () => (
    <div className={styles.currentMark}>
      {mark !== null ? mark : <span className={styles.notGraded}>0/1</span>}
    </div>
  );

  return (
    <>
      <div className={styles.keyResultInfo}>
        <div
          className={styles.container}
          role="presentation"
        >
          <div className={styles.firstBlock}>
            <div className={styles.ellipsis}>
              <Text
                className={styles.keyResultName}
                tooltipClassName={styles.tooltip}
              >
                {name}
              </Text>
            </div>
            {details ? (
              <div className={`${styles.ellipsis} ${styles.details}`}>
                <LinksReplacer showDomain data={details} />
              </div>
            ) : ''}
          </div>
          <div className={styles.secondBlock}>
            <div
              className={styles.complexity}
            >
              <div style={getComplexityColors(complexity)} />
              {complexity}
            </div>
            <div className={styles.comments}>
              <Icon name="comment outline" size="small" />
              <div className={styles.ratings}>
                {keyResult.ratingsCount}
              </div>
            </div>
            <div className={`${mark === null ? styles.notMarked : ''} ${isOkrClosed ? styles.closed : ''}`}>
              <div
                role="presentation"
                className={styles.mark}
              >
                <MarkContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeyResultInfo;

import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IQuestionBlock } from 'screens/Feedback/model/Questions/IQuestionBlock';
import { Form, Button } from 'semantic-ui-react';
import Questions from '../Questions';
import styles from './styles.module.scss';

interface IQuestionsBlockFormProps {
  selectedBlock?: IQuestionBlock;

  title: string;
  formCanceled: () => void;
  formSaved: (form: IQuestionBlock) => void;
}

const QuestionsBlockForm: React.FunctionComponent<IQuestionsBlockFormProps> = ({
  selectedBlock,
  title,
  formSaved,
  formCanceled
}: IQuestionsBlockFormProps) => {
  const [blockName, setBlockName] = useState(selectedBlock?.title);
  const [blockDescription, setBlockDescription] = useState(selectedBlock?.description);

  useLayoutEffect(() => {
    setBlockName(selectedBlock?.title ?? '');
    setBlockDescription(selectedBlock?.description ?? '');
  }, [selectedBlock]);

  const onNameChanged = e => {
    setBlockName(e.target.value);
  };

  const onDescriptionChanged = e => {
    setBlockDescription(e.target.value);
  };

  const saveBlock = () => {
    formSaved({
      ...selectedBlock,
      title: blockName,
      description: blockDescription
    });
  };

  return (
    <div>
      <h3>{title}</h3>
      <Form>
        <Form.Input
          label="Name"
          placeholder="Questions block name"
          name="name"
          className={`fullWidthData ${styles.formInput}`}
          required
          value={blockName}
          onChange={onNameChanged}
        />
        <Form.TextArea
          style={{ minHeight: 100, maxHeight: 100 }}
          label="Description"
          placeholder="Questions block description"
          name="description"
          className={`fullWidthData ${styles.formInput}`}
          value={blockDescription}
          onChange={onDescriptionChanged}
        />
        <Button
          primary
          className={styles.formButton}
          onClick={() => saveBlock()}
        >
          Save
        </Button>
        {title === 'Add new questions block'
          && (
            <Button
              basic
              color="orange"
              className={styles.formButton}
              onClick={() => formCanceled()}
            >
              Cancel
            </Button>
          )}
      </Form>
      { selectedBlock && <Questions />}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      questionsBlocks: {
        selectedQuestionsBlock
      }
    }
  } = state;
  return {
    selectedBlock: selectedQuestionsBlock
  };
};

export default connect(mapStateToProps, {})(QuestionsBlockForm);

import { Routine } from 'redux-saga-routines';
import {
  fetchUsersRoutine,
  fetchUserOkrsRoutine,
  fetchOkrRoutine,
  clearUsersOkrsPageRoutine,
  saveOkrRoutine,
  addNoteToOkr,
  addObjectiveToOkr
} from 'screens/UsersOkrs/routines';
import { IOkrExtended } from 'screens/OKR/models/okr';
import { IUser } from 'screens/UsersOkrs/models/IUser';
import { suggestOKRActionRoutine, discardOKRActionRoutine } from 'screens/OKR/routines';
import { KeyResultActionType } from 'screens/OKR/enums/KeyResultActionType';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { IObjective } from 'screens/OKR/models/objective';

export interface IUsersOkrsState {
  users: IUser[];
  selectedUserId: string;
  okrs: { id: string; name: string }[];
  currentOkr: IOkrExtended;
  firstTimeLoading: boolean;
  okrsCount: number;
  usersCount: number;
  showMentees: boolean;
}

const DEFAULT_INCREMENT_OKR_COUNT = 1;

const updateOkrKeyResults = (
  okr: IOkrExtended,
  objId: string,
  additionalKeyResults: IKeyResult[],
  krIdNotInclude: string = null
) => {
  const { objectives } = okr;
  const objective = objectives.find(o => o.id === objId);

  return {
    ...okr,
    objectives: objectives.map(o => (o.id !== objId ? o : {
      ...o,
      keyResults: [
        ...objective.keyResults.filter(kr => kr.id !== krIdNotInclude),
        ...additionalKeyResults
      ]
    }))
  };
};

const initialState: IUsersOkrsState = {
  users: [],
  selectedUserId: '',
  okrs: null,
  okrsCount: 0,
  usersCount: 0,
  currentOkr: null,
  firstTimeLoading: true,
  showMentees: false
};

export const usersOkrsPage = (state = initialState, action: Routine<any>): IUsersOkrsState => {
  const { payload, type } = action;
  switch (type) {
    case fetchUsersRoutine.TRIGGER:
      return { ...state, users: [], selectedUserId: '', okrs: null, currentOkr: null };
    case fetchUsersRoutine.SUCCESS:
      return { ...state, ...payload.response, firstTimeLoading: false, showMentees: payload.showMentees };
    case fetchUserOkrsRoutine.TRIGGER:
      return { ...state, selectedUserId: payload, okrs: null, currentOkr: null };
    case fetchUserOkrsRoutine.SUCCESS:
      const { userId, response } = payload;
      return { ...state, selectedUserId: userId, okrs: response.okrs, currentOkr: response.currentOkr };
    case fetchOkrRoutine.SUCCESS:
      return { ...state, currentOkr: payload };
    case clearUsersOkrsPageRoutine.TRIGGER:
      return initialState;
    case suggestOKRActionRoutine.SUCCESS:
      const { objectives } = state.currentOkr;
      if (payload.action === KeyResultActionType.DeleteKeyResult) {
        return {
          ...state,
          currentOkr: {
            ...state.currentOkr,
            objectives: objectives.map(o => (o.id !== payload.objId ? o : {
              ...o,
              keyResults: o.keyResults.map(kr => (kr.id === payload.keyResults[0].id ? { ...kr, actionType: 0 } : kr))
            }))
          }
        };
      }
      return {
        ...state,
        currentOkr: updateOkrKeyResults(
          state.currentOkr,
          payload.objId,
          payload.keyResults
        )
      };
    case discardOKRActionRoutine.SUCCESS:
      const objective = state.currentOkr.objectives.find(o => o.id === payload.objectiveId);
      const keyResult = objective.keyResults.find(kr => kr.id === payload.keyResultId);
      const newObjectiveData = keyResult.actionType === KeyResultActionType.DeleteKeyResult
        ? {
          ...objective,
          keyResults: objective.keyResults.map(kr => (kr.id === keyResult.id ? { ...kr, actionType: null } : kr))
        }
        : { ...objective, keyResults: objective.keyResults.filter(kr => kr.id !== keyResult.id) };

      return {
        ...state,
        currentOkr: {
          ...state.currentOkr,
          objectives: state.currentOkr.objectives.map(o => (o.id === objective.id ? newObjectiveData : o))
        }
      };
    case saveOkrRoutine.SUCCESS: {
      const newOkr = action.payload as IOkrExtended;

      const mappedOkrToReducer = {
        id: newOkr.id,
        name: newOkr.name
      };

      const updatedUsers = state.users.reduce((acc, user) => {
        if (user.id === newOkr.user.id) {
          const updatedUser: IUser = {
            ...user,
            okrsCount: (
              Number(user.okrsCount) + DEFAULT_INCREMENT_OKR_COUNT
            ).toString()
          };

          return [...acc, updatedUser];
        }

        return [...acc, user];
      }, []);

      return {
        ...state,
        users: updatedUsers,
        okrs: [...state.okrs, mappedOkrToReducer],
        okrsCount: Number(state.okrsCount) + DEFAULT_INCREMENT_OKR_COUNT,
        currentOkr: newOkr
      };
    }
    case addNoteToOkr.SUCCESS: {
      const { currentOkr } = state;
      const updatedOkr = action.payload as IOkrExtended;

      return {
        ...state,
        currentOkr: {
          ...currentOkr,
          note: updatedOkr.note
        }
      };
    }
    case addObjectiveToOkr.SUCCESS: {
      const { currentOkr } = state;
      const newObjective = action.payload as IObjective;

      return {
        ...state,
        currentOkr: {
          ...currentOkr,
          objectives: [...currentOkr.objectives, newObjective]
        }
      };
    }
    default:
      return state;
  }
};

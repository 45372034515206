import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { ReactComponent as Logo } from 'assets/icons/icons/plus.svg';
import { isAllowedToCRUD } from 'helpers/roles.helper';
import { setShowDraftsRoutine } from 'screens/News/routines';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import { Checkbox } from 'semantic-ui-react';

export interface IPageHeaderProps {
  headerText: string;
  buttonText?: string;
  user: any;
  showDrafts: boolean;
  buttonAction?: IBindingAction;
  setShowDrafts: IBindingCallback1<boolean>;
}

const PageHeader: FunctionComponent<IPageHeaderProps> = ({
  buttonAction,
  setShowDrafts,
  user,
  showDrafts,
  buttonText,
  headerText
}: IPageHeaderProps) => (
  <div className={styles.header}>
    <div className={styles.textAndButton}>
      <h2 className={styles.headingText}>{headerText}</h2>
      {
      buttonText && (
        <div className={styles.button} onClick={buttonAction} role="presentation">
          <Logo />
          <p>{buttonText}</p>
        </div>
      )
    }
    </div>
    {
      isAllowedToCRUD(user.roles) && (
      <div className={styles.toggle}>
        <Checkbox
          label="Show drafts"
          toggle
          defaultChecked={showDrafts}
          onChange={() => setShowDrafts(!showDrafts)}
        />
      </div>
      )
    }
  </div>
);

const mapStateToProps = state => ({
  showDrafts: state.news.news.showDrafts,
  user: state.user.user
});

const mapDispatchToProps = {
  setShowDrafts: setShowDraftsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);

import React from 'react';
import { Form, Button, DropdownItemProps } from 'semantic-ui-react';
import { IBindingCallback1, IBindingAction } from 'models/callback';
import styles from './styles.module.scss';
import { fetchUserOkrsRoutine, fetchOkrObjectivesRoutine, addObjKrToOkrRoutine } from 'screens/OKR/routines';
import { connect } from 'react-redux';
import { IAddToMyOkrState, IOkrsData, IOkrObjective } from './reducer';
import LoaderWrapper from 'components/LoaderWrapper';
import { Formik, FormikProps } from 'formik';
import { IAddKeyResultsToObjectivesToOkrsIds } from 'screens/OKR/models/okr';
import { formatDate } from 'helpers/dateFormatHelper';

export interface IKeyResultData {
  id: string;
  name: string;
}

export interface IAddToMyOkrFormikProps {
  okr: any;
  objective: any;
}

export interface IAddToMyOkrFormProps {
  fetchUserOkrs: IBindingCallback1<string>;
  fetchOkrObjectives: IBindingCallback1<string>;
  addToOkr: IBindingCallback1<IAddKeyResultsToObjectivesToOkrsIds>;
  onClose: IBindingAction;
  keyResult: IKeyResultData;
  userId: string;
  fromOkrId: string;
  okrs: IOkrsData[];
  okrsLoading: boolean;
  objectivesLoading: boolean;
  currentOkrId: string;
  selectedOkrId: string;
  currentOkrObjectives: IOkrObjective[];
}

class AddToMyOkrForm extends React.Component<IAddToMyOkrFormProps> {
  componentDidMount() {
    const { fetchUserOkrs, userId } = this.props;
    fetchUserOkrs(userId);
  }

  addKeyResultToObjective = (values: IAddToMyOkrFormikProps) => {
    const { addToOkr, keyResult, onClose, fromOkrId } = this.props;
    addToOkr({
      okrId: values.okr,
      objectiveId: values.objective,
      keyResultsId: [keyResult.id],
      isCustom: false,
      fromOkrId
    });
    onClose();
  }

  handleOkrChange = okrId => {
    const { fetchOkrObjectives } = this.props;
    fetchOkrObjectives(okrId);
  }

  render() {
    const { okrs, okrsLoading, currentOkrId, currentOkrObjectives, objectivesLoading, selectedOkrId } = this.props;
    const mapName = o => `${o.name} ${o.id === currentOkrId ? '(Current)' : ''}`;

    const okrsDict = okrs.filter(okr => !okr.isClosed).map(o => ({
      key: o.id,
      value: o.id,
      text: mapName(o),
      content: (
        <div className={styles.okrData}>
          <p className={styles.name}>{mapName(o)}</p>
          <p className={styles.date}>{`${formatDate(o.startTime)} - ${formatDate(o.endTime)}`}</p>
        </div>
      )
    }));

    const currentOkr = okrsDict.find(o => o.key === selectedOkrId);
    let objectivesDict: DropdownItemProps[] = (currentOkrObjectives ?? []).map(obj => ({
      key: obj.id, value: obj.id, text: obj.name
    }));

    if (!objectivesDict.length) {
      objectivesDict = [{ key: 'noData', disabled: true, text: 'This OKR has no objectives!' }];
    }

    return (
      <div className={styles.formContainer}>
        <LoaderWrapper loading={okrsLoading}>
          <Formik
            enableReinitialize
            initialValues={{
              okr: currentOkr?.value,
              objective: objectivesDict[0]?.value
            }}
            onSubmit={values => this.addKeyResultToObjective(values)}
          >
            {({ values, setFieldValue, handleSubmit }:
              FormikProps<IAddToMyOkrFormikProps>) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Select
                    options={okrsDict}
                    label="OKR"
                    name="okr"
                    value={values.okr}
                    onChange={(event, o) => {
                      setFieldValue('okr', o.value);
                      this.handleOkrChange(o.value);
                    }}
                  />
                  <Form.Select
                    options={objectivesDict}
                    label="Objective"
                    name="objective"
                    value={values.objective}
                    onChange={(event, o) => {
                      setFieldValue('objective', o.value);
                    }}
                    loading={objectivesLoading}
                  />
                  <Button
                    primary
                    className="submitButton"
                    type="submit"
                    disabled={!values.objective || objectivesLoading}
                    content="Add to my OKR"
                  />
                </Form>
            )}
          </Formik>
        </LoaderWrapper>
      </div>
    );
  }
}

const mapStateToProps = ({
  user: { user: { id } },
  okr: { addToMyOkr }
}) => {
  const addToMyOkrData = addToMyOkr as IAddToMyOkrState;
  return {
    userId: id,
    ...addToMyOkrData
  };
};

const mapDispatchToProps = {
  fetchUserOkrs: fetchUserOkrsRoutine,
  fetchOkrObjectives: fetchOkrObjectivesRoutine,
  addToOkr: addObjKrToOkrRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToMyOkrForm);

import { IObjective } from '../models/objective';

const arraySum = array => array?.reduce((acc, value) => acc + Number(value), 0);

const round = (value: number) => Math.round((value) * 100) / 100;

const calculateArrayAvgSum = array => round(arraySum(array?.map(i => i?.mark)) / (array?.length || 1));

type ObjectiveWithMark = { id: string; mark: number};

export const calcObjectivesMarks = (objectives: IObjective[]) => (objectives
  ? objectives.map(obj => (
    {
      id: obj?.id,
      mark: round(calculateArrayAvgSum(obj?.keyResults))
    }))
  : null);

export const calcOkrsMark = (objectives: ObjectiveWithMark[]) => calculateArrayAvgSum(objectives);


import React, { useState } from 'react';
import parse from 'html-react-parser';
import { IBindingAction } from 'models/callback';
import { INewsItemShort } from 'screens/News/models/news/newsItemShort';
import styles from './styles.module.scss';
import moment from 'moment';
import ConfirmModalContent from 'components/ConfirmModalContent';
import { EditPopup } from '../EditPopup';
import { toastr } from 'react-redux-toastr';
import { store } from '../../../../store';
import { isAllowedToEditAnothers, isAllowedToCRUD, canUserDelete } from 'helpers/roles.helper';
import { getById } from '../../../Authorization/services/userService';

export interface IListItemProps {
  newsItem: INewsItemShort;
  onSelect: IBindingAction;
  onUpdate: IBindingAction;
  onPublish?: IBindingAction;
  onDelete: IBindingAction;
  onSync: IBindingAction;
  syncLoading?: boolean;
  isFirst?: boolean;
  hasLink?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
}

const ListItem: React.FunctionComponent<IListItemProps> = ({
  newsItem,
  onSelect,
  onUpdate,
  onDelete,
  onSync,
  onPublish,
  syncLoading = false,
  isFirst = false,
  hasLink = false
}) => {
  const [isPopupOpen, togglePopupState] = useState(false);
  const [isDeletionConfirmOpen, toggleDeletionConfirmState] = useState(false);
  const [isPublishConfirmOpen, togglePublishConfirmState] = useState(false);
  const [isSyncConfirmOpen, toggleSyncConfirmState] = useState(false);

  const { user } = store.getState().user;
  const { title, description, date, logo, authorId } = newsItem;

  const canDelete = () => canUserDelete(user.id, authorId, user.roles);

  const handleNewsUpdate = () => {
    getById(authorId).then(userData => {
      if (canDelete() && isAllowedToEditAnothers(user.roles, userData.roles)) {
        togglePopupState(false);
        onUpdate();
      } else {
        toastr.error('You don\'t have access to edit this news!');
      }
    });
  };

  const handleDeleteClick = () => {
    toggleDeletionConfirmState(true);
    togglePopupState(false);
  };

  const handlePublishClick = () => {
    togglePublishConfirmState(true);
  };

  const confirmDeletion = () => {
    onDelete();
    toggleDeletionConfirmState(false);
  };

  const confirmPublishing = () => {
    onPublish();
    togglePublishConfirmState(false);
  };

  const handleSyncClick = () => {
    // TODO: to have a confirmation, the context menu needs to be fixed:
    //    it's closed whenever user clicks anywhere on the DOM so if user confirms, it closes
    //    once it's fixed, uncomment the following line to enable confirmation dialog
    //    and comment the line after (onSync())
    // toggleSyncConfirmState(true);
    onSync();
  };

  const confirmSync = () => {
    onSync();
    toggleSyncConfirmState(false);
  };

  const renderPopup = () => (
    <EditPopup
      isPopupOpened={isPopupOpen}
      onDeletePress={handleDeleteClick}
      onPublishPress={handlePublishClick}
      onEditPress={handleNewsUpdate}
      onSyncPress={handleSyncClick}
      syncLoading={syncLoading}
      canBeDeleted={canDelete()}
      canBeEdited={isAllowedToCRUD(user.roles)}
      canBePublished={!!onPublish}
      hasLink={hasLink}
      popupStateAction={togglePopupState}
    />
  );

  return (
    <>
      <ConfirmModalContent
        opened={isPublishConfirmOpen}
        description={`Would you like to publish news "${title}"?`}
        header="Publish news"
        onConfirm={confirmPublishing}
        confirmButtonText="Yes, publish it"
        onCancel={() => togglePublishConfirmState(false)}
      />
      <ConfirmModalContent
        opened={isDeletionConfirmOpen}
        description={`Would you like to delete news "${title}"?`}
        header="Delete news"
        onConfirm={confirmDeletion}
        onCancel={() => toggleDeletionConfirmState(false)}
      />
      <ConfirmModalContent
        header="Confirm synchronization"
        onConfirm={confirmSync}
        onCancel={() => toggleSyncConfirmState(false)}
        opened={isSyncConfirmOpen}
        description={
          `If you synchronize the news "${title}" with mailchimp, you will lose your local
          progress. Don't forget to backup`
        }
        confirmButtonText="Confirm"
      />
      <div
        className={`${styles.mainNewsContainer} ${styles.boxShadow}`}
        onClick={onSelect}
        role="presentation"
      >
        <img
          src={logo
            || 'http://d3ot0t2g92r1ra.cloudfront.net/img/logo@3x_optimized.svg'}
          alt="back"
        />
        <div className={styles.gradient} />
        <div className={styles.layoutContainer}>
          <div className={styles.dateContainer}>
            <p className={styles.dates}>
              {moment(date).format('DD/MM/YYYY')}
            </p>
          </div>
          <div className={styles.headingContainer}>
            <h2
              className={`${styles.title} ${styles.textWrapper}`}
            >
              {parse(title)}
            </h2>
            <p className={`${styles.description} ${styles.textWrapper}`}>
              {parse(description)}
            </p>
          </div>
        </div>
        <div className={styles.popupContainer}>
          {renderPopup()}
        </div>
      </div>
    </>
  );
};

export default ListItem;

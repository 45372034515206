import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as achievementsService from '../../services/achievements.service';
import { Routine } from 'redux-saga-routines';
import { fetchAchievementsRoutine } from 'screens/UserAchievements/routines';

function* fetchAchievements({ payload }: Routine<any>) {
  try {
    const response = yield call(achievementsService.fetchUserAchievements, payload);
    yield put(fetchAchievementsRoutine.success(response));
  } catch (error) {
    yield put(fetchAchievementsRoutine.failure(error.message));
  }
}

function* watchFetchUserAchievements() {
  yield takeEvery(fetchAchievementsRoutine.TRIGGER, fetchAchievements);
}

export default function* userAchievementsSagas() {
  yield all([
    watchFetchUserAchievements()
  ]);
}

import React, { useEffect, useState } from 'react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import {
  fetchFormsRoutine,
  selectedFormChangedRoutine,
  formDeletedRoutine,
  formSavedRoutine,
  formUpdateRoutine,
  formSavedTemplateRoutine
} from 'screens/Feedback/routines';
import { Button, Confirm } from 'semantic-ui-react';
import { IForm } from 'screens/Feedback/model/Forms/IForm';
import { getEmptyFormListPageTemplate, getFormListPageTemplate } from './elements';
import LoaderWrapper from 'components/LoaderWrapper';

interface IFormsPageProps {
  fetchForms: IBindingAction;
  saveForm: IBindingCallback1<IForm>;
  updateForm: IBindingCallback1<IForm>;
  deleteForm: IBindingCallback1<string>;
  selectedFormChanged: IBindingCallback1<string>;
  saveTemplate: IBindingCallback1<IForm>;
  loading: boolean;
  forms?: IForm[];
  selectedForm: IForm;
}

const ManageForms: React.FunctionComponent<IFormsPageProps> = ({
  fetchForms,
  saveForm,
  deleteForm,
  updateForm,
  selectedFormChanged,
  saveTemplate,
  loading,
  forms,
  selectedForm
}: IFormsPageProps) => {
  const [init, setInit] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formList, setFormList] = useState(forms);
  const [modalOpened, setModalOpened] = useState(false);
  const [deletedFormId, setDeletedFormId] = useState<string | undefined>();

  useEffect(() => {
    selectedFormChanged(null);
  }, []);
  useEffect(() => {
    setFormList(forms);
  }, [forms]);

  useEffect(() => {
    if (!init) {
      fetchForms();
      setInit(true);
    }
  }, [init]);

  const addNewButtonHandler = () => {
    setIsEditing(false);
    selectedFormChanged(null);
    setIsCreating(true);
  };

  const createForm = form => {
    saveForm(form);
    selectedFormChanged(null);
    setIsCreating(false);
  };

  const deleteFormClicked = formId => {
    setDeletedFormId(formId);
    setModalOpened(true);
  };

  const deletingFormConfirmedHandler = formId => {
    setFormList(forms.filter(f => f.id !== formId));
    deleteForm(formId);
    setModalOpened(false);
  };

  const selectFormHandler = formId => {
    selectedFormChanged(formId);
    setIsCreating(false);
    setIsEditing(true);
  };

  const createFormSettings = {
    title: 'Create new form',
    changesCommiter: formSavedRoutine,
    formSavedHandler: form => {
      createForm(form);
      setIsCreating(false);
    },
    formCanceledHandler: () => setIsCreating(false)
  };

  const editFormSettings = {
    title: 'Edit form',
    changesCommiter: formSavedRoutine,
    formSavedHandler: form => {
      updateForm(form);
    },
    formSavedTemplateHandler: form => {
      saveTemplate(form);
    },
    formCanceledHandler: () => {
      setIsEditing(false);
    }
  };

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.formsViewContainer}>
        <div className={styles.sectionHeader}>
          <h1>Forms</h1>
          {!isCreating
            ? (
              <Button
                primary
                icon="plus square"
                content="Add new"
                onClick={() => addNewButtonHandler()}
                className={styles.formButton}
              />
            )
            : (
              <>
              </>
            )}
        </div>
        <LoaderWrapper loading={!init || loading}>
          {formList.length
            ? (
              getFormListPageTemplate(
                isCreating,
                isEditing,
                formList,
                createFormSettings,
                editFormSettings,
                selectFormHandler,
                deleteFormClicked,
                selectedForm
              )
            )
            : (
              getEmptyFormListPageTemplate(isCreating, createFormSettings)
            )}
        </LoaderWrapper>
      </div>
      <Confirm
        open={modalOpened}
        header="Are you sure?"
        content="If this form contains questions (or even answers) all of them will be deleted"
        onCancel={() => setModalOpened(false)}
        onConfirm={() => deletingFormConfirmedHandler(deletedFormId)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      forms: {
        forms,
        selectedForm
      }
    }
  } = state;
  const {
    feedbackPage: {
      reducers: {
        getForms: {
          loading
        }
      }
    }
  } = state;
  return {
    forms,
    selectedForm,
    loading
  };
};

const mapDispatchToProps = {
  fetchForms: fetchFormsRoutine,
  saveForm: formSavedRoutine,
  updateForm: formUpdateRoutine,
  deleteForm: formDeletedRoutine,
  selectedFormChanged: selectedFormChangedRoutine,
  saveTemplate: formSavedTemplateRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageForms);

import { Routine } from 'redux-saga-routines';
import { fetchAllRequestsWithUserAndDaysRoutine, updateRequestStatusRoutine } from 'screens/TimeOffTracker/routines';
import { IRequestsWithUserAndDaysDTO } from 'screens/TimeOffTracker/models/requests/requestWithUsersAndDaysDTO';
import { RequestStatus } from 'screens/TimeOffTracker/enums/requestStatus';

export const requestsTimeOffAdminPage = (state = [], action: Routine<any>): IRequestsWithUserAndDaysDTO[] => {
  switch (action.type) {
    case fetchAllRequestsWithUserAndDaysRoutine.SUCCESS:
      return action.payload;
    case updateRequestStatusRoutine.SUCCESS:
      if ([RequestStatus.Approved, RequestStatus.Rejected].includes(action.payload.requestStatus)) {
        return state.filter(((value: IRequestsWithUserAndDaysDTO) => value.id !== action.payload.id));
      }
      return state.map((request: IRequestsWithUserAndDaysDTO) => (
        request.id === action.payload.id
          ? action.payload
          : request));
    default:
      return state;
  }
};

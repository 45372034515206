import { createRoutine } from 'redux-saga-routines';

export const fetchNewsRoutine = createRoutine('FETCH_NEWS');

export const setShowDraftsRoutine = createRoutine('SET_SHOW_DRAFTS');

export const publishNewsRoutine = createRoutine('PUBLISH_NEWS');

export const saveNewsRoutine = createRoutine('SAVE_NEWS');

export const updateNewsRoutine = createRoutine('UPDATE_NEWS');

export const removeNewsRoutine = createRoutine('REMOVE_NEWS');

export const removeNewsDetailsRoutine = createRoutine('REMOVE_NEWS_DETAILS');

export const fetchNewsDetailsByIdRoutine = createRoutine('FETCH_NEWS_DETAILS');

export const fetchNewsByIdRoutine = createRoutine('FETCH_NEWS_FOR_UPDATE');

export const removeNewsFromModalRoutine = createRoutine('REMOVE_NEWS_FROM_MODAL');

export const addCommentRoutine = createRoutine('ADD_COMMENT');

export const syncNewsWithOriginByIdRoutine = createRoutine('SYNC_NEWS_WITH_ORIGIN');

export const syncNewsForUpdateWithOriginByIdRoutine = createRoutine('SYNC_NEWS_FOR_UPDATE_WITH_ORIGIN');

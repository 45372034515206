import React from 'react';
import { IBindingAction } from '../../../../models/callback';
import styles from './styles.module.scss';
import { cross } from 'assets/icons';
import ConfirmModal from 'components/ConfirmModal';
import { Image } from 'semantic-ui-react';

export interface IRemoveButtonProps {
  onRemove: IBindingAction;
}

export const RemoveButton: React.FunctionComponent<IRemoveButtonProps> = ({ onRemove }) => (
  <ConfirmModal
    header="Are you sure you want to delete objective from this OKR?"
    description="You can not undone this action. Still it can be added from existing ones."
    onConfirm={onRemove}
  >
    <Image src={cross} className={styles.deleteButton} />
  </ConfirmModal>
);

import { Role } from '../screens/Authorization/models/role';

export const roleWeights = {
  Developer: 1,
  HR: 2,
  Manager: 3,
  TopManager: 4,
  Administrator: 5
};

function getEssentialRoleWeight(roles: Array<string>): number {
  const mapped: number[] = roles.map(role => roleWeights[role]);
  const max = mapped.reduce((first, second) => Math.max(first, second));
  return max;
}

export function isAllowedToEditAnothers(userRoles: Array<string>, authorRoles: Array<string>): boolean {
  if (userRoles && userRoles.includes(Role.Administrator)) {
    return true;
  }
  const usersEssentialRole = getEssentialRoleWeight(userRoles);
  const authorEssentialRole = getEssentialRoleWeight(authorRoles);
  return usersEssentialRole > authorEssentialRole;
}

export function isAllowedToCRUD(userRoles: Array<string>): boolean {
  const userEssentialRole = getEssentialRoleWeight(userRoles);
  return userEssentialRole > roleWeights.Developer;
}

export function canUserDelete(userId: string, authorId: string, userRoles: Array<string>): boolean {
  const isAuthor = userId === authorId;
  const isAdmin = userRoles.includes(Role.Administrator);
  return (isAuthor || isAdmin) && isAllowedToCRUD(userRoles);
}

import React from 'react';
import Info from '../../components/Info';
import Details from '../../components/Details';
import styles from './styles.module.scss';
import { IUserInfoState } from 'screens/UserProfile/components/Info/reducer';
import { connect } from 'react-redux';
import { NoDataContainer } from 'components/NoDataContainer';
import { clearUserProfileStateRoutine, fetchUserInfoRoutine } from 'screens/UserProfile/routines';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { IUser } from 'screens/Authorization/models/IUser';

interface IProfilePageProps {
  incorrectUser: boolean;
  location: any;
  clearUserProfileState: IBindingAction;
  fetchUserInfo: IBindingCallback1<string>;
  user: IUser;
  userId: string;
}

interface IProfilePageState {
  userId: string;
}

class ProfilePage extends React.Component<IProfilePageProps, IProfilePageState> {
  constructor(props) {
    super(props);
    this.state = {
      userId: undefined
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;
    if (pathname !== prevProps.location.pathname) {
      this.loadUser();
    }
  }

  componentWillUnmount() {
    const { clearUserProfileState } = this.props;
    clearUserProfileState();
  }

  loadUser = () => {
    const { location: { pathname } } = this.props;
    const userId = pathname.split('/')[2];
    const { fetchUserInfo, user } = this.props;
    fetchUserInfo(userId ?? user.id);
    this.setState({ userId });
  }

  render() {
    const { incorrectUser } = this.props;
    const { userId } = this.state;

    if (incorrectUser) {
      return (
        <div className="fullHeightData">
          <NoDataContainer text="Such user doesn't exist" />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <Info userId={userId} />
        <Details />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { userProfile: { userInfo }, user: { user } } = state;
  const userInfoState = userInfo as IUserInfoState;
  return {
    user,
    incorrectUser: userInfoState.incorrectUser
  };
};

const mapDispatchToProps = {
  clearUserProfileState: clearUserProfileStateRoutine,
  fetchUserInfo: fetchUserInfoRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

import React, { FunctionComponent } from 'react';
import reactStringReplace from 'react-string-replace';
import styles from './styles.module.scss';
import { getLinkDomain } from 'helpers/links.helper';

const replaceRegex = /(https?:\/\/\S+)/g;

interface ILinksReplacerProps {
  data: string;
  showDomain?: boolean;
}

const LinksReplacer: FunctionComponent<ILinksReplacerProps> = ({ data, showDomain }) => (
  <div>
    {reactStringReplace(data, replaceRegex, (match, index) => (
      <a key={index} href={match} target="_blank" rel="noopener noreferrer" className={styles.linkBlock}>
        {showDomain ? getLinkDomain(match) : 'Link'}
      </a>
    ))}
  </div>
);

export default LinksReplacer;

import { all } from 'redux-saga/effects';
import newsSaga from 'screens/News/sagas';
import notificationsSaga from 'screens/Notifications/sagas';
import daysOffSaga from 'screens/TimeOffTracker/sagas';
import adminSagas from 'screens/AdminPage/sagas';
import projectsSagas from 'screens/Projects/sagas';
import okrSagas from 'screens/OKR/sagas';
import userSaga from 'containers/Routing/sagas';
import userProfileSagas from 'screens/UserProfile/components/Info/sagas';
import dashboardSagas from 'screens/Dashboard/sagas';
import userOkrsSagas from 'screens/UsersOkrs/containers/UsersOkrsPage/sagas';
import authSagas from 'screens/Authorization/sagas';
import librarySaga from 'screens/Library/sagas';
import achievementsSagas from 'screens/UserAchievements/sagas';
import registerSaga from 'screens/Authorization/containers/RegisterForm/sagas';
import teamUserSagas from 'screens/Team/containers/TeamView/sagas';
import announcementsSaga from 'screens/Announcements/sagas';
import feedbackSagas from 'screens/Feedback/sagas';

export default function* rootSaga() {
  yield all([
    newsSaga(),
    okrSagas(),
    userSaga(),
    daysOffSaga(),
    userProfileSagas(),
    adminSagas(),
    notificationsSaga(),
    projectsSagas(),
    dashboardSagas(),
    userOkrsSagas(),
    authSagas(),
    librarySaga(),
    achievementsSagas(),
    registerSaga(),
    teamUserSagas(),
    announcementsSaga(),
    feedbackSagas()
  ]);
}

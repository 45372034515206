import { createRoutine } from 'redux-saga-routines';

export const fetchAllDaysOffRoutine = createRoutine('ALL_DAYS_OFF');

export const fetchAllByUserIdRequestsRoutine = createRoutine('ALL_USERS_REQUESTS');

export const fetchAllRequestsWithUserAndDaysRoutine = createRoutine('ALL_REQUESTS_WITH_USER_AND_DAYS');

export const removeAllDaysOffRoutine = createRoutine('REMOVE_ALL_DAYSOFF');

export const requestDaysOffRoutine = createRoutine('REQUEST_DAYS_OFF');

export const updateRequestStatusRoutine = createRoutine('UPDATE_REQUEST_STATUS');

export const uploadProofImageRoutine = createRoutine('UPLOAD_PROOF_IMAGE');

export const fetchDaysOffForUserRoutine = createRoutine('DAYS_OFF_FOR_USER');

export const fetchAllUsersRoutine = createRoutine('FETCH_ALL_USERS');

export const AddManyRequestsRoutine = createRoutine('ADD_MANY_REQUESTS');

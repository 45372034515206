import { combineReducers } from 'redux';
import { reducerCreator } from 'helpers/reducerHelper';
import { usersOkrsPage } from '../containers/UsersOkrsPage/reducer';
import { fetchUsersRoutine, fetchUserOkrsRoutine, fetchOkrRoutine, addObjectiveToOkr } from '../routines';

const requests = combineReducers({
  users: reducerCreator([fetchUsersRoutine.TRIGGER]),
  currentOkr: reducerCreator([fetchUserOkrsRoutine.TRIGGER]),
  userOkr: reducerCreator([fetchOkrRoutine.TRIGGER]),
  objectives: reducerCreator([addObjectiveToOkr.TRIGGER])
});

export default combineReducers({
  usersOkrs: usersOkrsPage,
  requests
});

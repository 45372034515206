import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { IBindingFunction } from 'models/callback';
import styles from './styles.module.scss';

export interface IEditorProps {
  onEditorChangeState: IBindingFunction<string, void>;
  defaultValue: string;
}

export interface IEditorState {
  editorState: any;
}

export const EditorComponent: React.FunctionComponent<IEditorProps> = ({
  onEditorChangeState,
  defaultValue
}: IEditorProps) => {
  const [editorState, updateEditorState] = useState(
    defaultValue.length
      ? EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(defaultValue).contentBlocks
        )
      )
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorStateToSet: EditorState) => {
    updateEditorState(editorStateToSet);
    const rawContent = editorStateToSet.getCurrentContent().hasText()
      ? draftToHtml(convertToRaw(editorStateToSet.getCurrentContent()))
      : '';
    onEditorChangeState(rawContent);
  };

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName={styles.editor}
      onEditorStateChange={onEditorStateChange}
      handlePastedText={() => false}
    />
  );
};

import { call, put, takeEvery, all } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import { fetchAllUsersRoutine, AddManyRequestsRoutine } from '../../routines';
import * as timeOffService from '../../services/timeOffService';

function* getAllUsers() {
  try {
    const response = yield call(timeOffService.getAllUsers);

    yield put(fetchAllUsersRoutine.success(response));
  } catch (error) {
    yield put(fetchAllUsersRoutine.failure(error.message));
  }
}

function* addMany({ payload }: Routine<any>) {
  try {
    yield call(timeOffService.addManyRequests, payload);

    yield put(AddManyRequestsRoutine.success());
  } catch (error) {
    yield put(AddManyRequestsRoutine.failure(error.message));
  }
}

function* watchGetAllUsers() {
  yield takeEvery(fetchAllUsersRoutine.TRIGGER, getAllUsers);
}

function* watchAddMany() {
  yield takeEvery(AddManyRequestsRoutine.TRIGGER, addMany);
}

export default function* usersDaysOffSaga() {
  yield all([
    watchGetAllUsers(),
    watchAddMany()
  ]);
}

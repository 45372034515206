import { Routine } from 'redux-saga-routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as requestsService from '../../services/requestsService';
import * as answersService from '../../services/answersService';
import {
  fetchAssignDetailRequestRoutine,
  fetchAssignRequestsRoutine,
  sendAnswersRoutine
} from '../../routines';

function* fetchAssignRequests({ payload }: Routine<any>): Routine<any> {
  try {
    const results = yield call(requestsService.getAssignRequests, payload);

    yield put(fetchAssignRequestsRoutine.success(results));
  } catch (error) {
    yield put(fetchAssignRequestsRoutine.failure(error));
  }
}

function* requestDetailsFetch({ payload }: Routine<any>): Routine<any> {
  try {
    const results = payload ? yield call(requestsService.getRequestDetails, payload) : null;

    yield put(fetchAssignDetailRequestRoutine.success(results));
  } catch (error) {
    yield put(fetchAssignDetailRequestRoutine.failure(error));
  }
}
function* sendAnswers({ payload }: Routine<any>): Routine<any> {
  try {
    const results = yield call(answersService.sendAnswers, payload);

    yield put(sendAnswersRoutine.success(payload));
  } catch (error) {
    yield put(sendAnswersRoutine.failure(error));
  }
}

function* watchSendAnswers() {
  yield takeEvery(sendAnswersRoutine.TRIGGER, sendAnswers);
}

function* watchAssignRequestsFetch() {
  yield takeEvery(fetchAssignRequestsRoutine.TRIGGER, fetchAssignRequests);
}

function* watchAssignDetailRequestsFetch() {
  yield takeEvery(fetchAssignDetailRequestRoutine.TRIGGER, requestDetailsFetch);
}

export default function* manageRequestsPageSagas() {
  yield all([watchAssignRequestsFetch(), watchSendAnswers(), watchAssignDetailRequestsFetch()]);
}

import { callProjectsApi } from 'helpers/webApiHelper';
import { ISaveProject } from '../model/ISaveProject';

export const fetchProjects = async () => {
  const response = await callProjectsApi({
    endpoint: '/api/projects',
    type: 'GET'
  });
  return response.json();
};

export const fetchProject = async (id: string) => {
  const response = await callProjectsApi({
    endpoint: `/api/projects/${id}`,
    type: 'GET'
  });
  return response.json();
};

export const saveProject = async (project: ISaveProject) => {
  const response = await callProjectsApi({
    endpoint: '/api/projects',
    type: 'POST',
    requestData: project
  });
  return response.json();
};

export const deleteProject = async (id: string) => {
  const response = await callProjectsApi({
    endpoint: `/api/projects/${id}`,
    type: 'DELETE'
  });
  return response;
};

export const fetchProjectDocuments = async (id: string) => {
  const response = await callProjectsApi({
    endpoint: `/api/projects/documents/${id}`,
    type: 'GET'
  });
  return response.json();
};

import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Image } from 'semantic-ui-react';
import { boundMethod } from 'autobind-decorator';
import { IBindingCallback1 } from 'models/callback';
import { IOkrExtended, IOkrNoteToSave } from '../../models/okr';
import styles from './styles.module.scss';
import Sidebar from 'components/DataSidebar';
import { cross } from 'assets/icons';

const validationSchema = Yup.object().shape({
  note: Yup.string()
    .trim()
    .min(2, 'Min note length is 2 symbols!')
    .max(500, 'Max note length is 500 symbols!')
    .required('Note field is required')
});

export interface IFormProps {
  note: string;
}

export interface IAddNoteModalProps {
  currentOkr: IOkrExtended;
  addNote: IBindingCallback1<IOkrNoteToSave>;
}

export interface IAddNoteModalState {
  isModalOpened: boolean;
}

class AddNoteModal extends React.Component<IAddNoteModalProps, IAddNoteModalState> {
  constructor(props: IAddNoteModalProps) {
    super(props);

    this.state = {
      isModalOpened: false
    };
  }

  @boundMethod
  onSave(data: {
    note: string;
  }) {
    const { addNote, currentOkr } = this.props;
    addNote({ okrId: currentOkr.id, note: data.note });
    this.toggleModal(false);
  }

  onCancel = () => {
    this.toggleModal(false);
  }

  toggleModal = (value: boolean) => {
    this.setState({
      isModalOpened: value
    });
  }

  render() {
    const { isModalOpened } = this.state;
    const { currentOkr } = this.props;

    const isOkrClosed = currentOkr?.isClosed;

    const sidebar = isModalOpened ? (
      <Sidebar onClose={this.onCancel} shouldNotBlockOverflow>
        <Formik
          initialValues={{
            note: ''
          }}
          onSubmit={this.onSave}
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            setFieldValue,
            values: { note },
            errors,
            touched
          }: FormikProps<IFormProps>) => (
            <>
              <Image src={cross} className={styles.closeButton} onClick={this.onCancel} />
              <p className={styles.header}>
                Add note
              </p>
              <div className={styles.formContainer}>
                <Form onSubmit={handleSubmit}>
                  <Form.TextArea
                    label={
                      errors.note && touched.note
                        ? errors.note
                        : 'Note'
                    }
                    placeholder="Some note"
                    name="note"
                    disabled={!isOkrClosed}
                    value={note || ''}
                    onChange={(_, data) => setFieldValue('note', data.value)}
                    error={errors.note && touched.note}
                  />
                  {isOkrClosed && (
                    <div className={styles.buttonContainer}>
                      <Button type="button" content="Cancel" onClick={this.onCancel} />
                      <Button primary type="submit" content="Add Note" />
                    </div>
                  )}
                </Form>
              </div>
            </>
          )}
        </Formik>
      </Sidebar>
    ) : null;

    return (
      <>
        {sidebar}
        <div className={styles.addButton} onClick={() => this.toggleModal(true)} role="presentation">
          Add Note
        </div>
      </>
    );
  }
}

export default AddNoteModal;

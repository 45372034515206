import { Routine } from 'redux-saga-routines';
import { IQuestion } from '../../model/Questions/IQuestion';
import {
  selectedTemplateChangedRoutine,
  templateDeletedRoutine,
  templateSavedRoutine,
  templateUpdateRoutine,
  fetchAllTemplatesRoutine
} from '../../routines';
import { IForm } from '../../model/Forms/IForm';
import { IQuestionBlock } from 'screens/Feedback/model/Questions/IQuestionBlock';
import { toastr } from 'react-redux-toastr';

export interface ITemplateState {
  templates: IForm[];
  searchResults: IQuestion[];
  selectedForm: IForm;
  selectedQuestionsBlock: IQuestionBlock;
}

const initialState: ITemplateState = {
  templates: [],
  searchResults: [],
  selectedForm: null,
  selectedQuestionsBlock: null
};

export const templates = (
  state = initialState,
  action: Routine<any>
): ITemplateState => {
  switch (action.type) {
    case fetchAllTemplatesRoutine.SUCCESS: {
      return {
        ...state, templates: action.payload
      };
    }
    case templateSavedRoutine.SUCCESS: {
      return {
        ...state,
        templates: [action.payload, ...state.templates]
      };
    }
    case templateDeletedRoutine.SUCCESS: {
      return {
        ...state, templates: [...state.templates.filter(f => f.id !== action.payload)]
      };
    }
    case selectedTemplateChangedRoutine.SUCCESS: {
      return {
        ...state,
        selectedForm: action.payload
      };
    }
    case templateUpdateRoutine.SUCCESS: {
      return {
        ...state, templates: state.templates.map(f => (f.id === action.payload.id ? action.payload : f))
      };
    }
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { IRequest } from '../../model/Requests/IRequest';
import { IBindingCallback1 } from '../../../../models/callback';
import styles from './styles.module.scss';
import { NoDataContainer } from '../../../../components/NoDataContainer';
import {
  fetchAssignDetailRequestRoutine,
  fetchAssignRequestsRoutine,
  sendAnswersRoutine
} from '../../routines';
import { connect } from 'react-redux';
import { RequestInList } from 'screens/Feedback/components/RequestInList';
import { Request } from 'screens/Feedback/components/Request';

interface IManageRequestsProps {
  getRequests: IBindingCallback1<string>;
  getDetailedRequest: IBindingCallback1<string>;
  sendAnswers: IBindingCallback1<any>;
  requests: IRequest[];
  selectedRequest: IRequest;
  id: string;
}

const ManageRequests: React.FunctionComponent<IManageRequestsProps> = (
  { id, getRequests, requests, selectedRequest, getDetailedRequest, sendAnswers }: IManageRequestsProps
) => {
  useEffect(() => {
    getRequests(id);
    getDetailedRequest(null);
  }, []);
  const onCLick = requestId => {
    getDetailedRequest(requestId);
  };
  const onSend = result => {
    sendAnswers(result);
  };
  const isSelected = requestId => (selectedRequest?.id === requestId);
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.formsViewContainer}>
        <div className={styles.sectionHeader}>
          <h1>Requests</h1>
        </div>
        {requests.length !== 0
          ? (
            <div className={`${styles.formListAndActionContainer} ${styles.fullHeightData}`}>
              <div className={styles.formListContainer}>
                {requests.map(request => (
                  <RequestInList
                    request={request}
                    onClick={onCLick}
                    isSelected={isSelected(request.id)}
                  />
                ))}
              </div>
              <div className={styles.formContainer}>
                {selectedRequest
                  ? (
                    <Request
                      request={selectedRequest}
                      userId={id}
                      sendAnswers={onSend}
                    />
                  ) : <NoDataContainer text="There are not requests selected ..." />}
              </div>
            </div>
          )
          : (
            <div className={styles.fullHeightData}>
              <NoDataContainer text="There are not requests created ..." />
            </div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { user: { user: { id } } } = state;
  const { feedbackPage: { manageRequests: { requests, selectedRequest } } } = state;
  return {
    id,
    requests,
    selectedRequest
  };
};

const mapDispatchToProps = {
  getRequests: fetchAssignRequestsRoutine,
  getDetailedRequest: fetchAssignDetailRequestRoutine,
  sendAnswers: sendAnswersRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRequests);

import React, { FunctionComponent } from 'react';
import { IBindingAction } from 'models/callback';
import { Image } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { cross } from 'assets/icons';
import Sidebar from 'components/DataSidebar';

interface ISidebarWrapperProps {
  isOpened: boolean;
  onClose: IBindingAction;
  width?: string;
}

const SidebarWrapper: FunctionComponent<ISidebarWrapperProps> = ({
  isOpened,
  onClose,
  width,
  children
}) => (
  isOpened ? (
    <Sidebar onClose={onClose} width={width}>
      <Image src={cross} className={styles.closeButton} onClick={onClose} />
      {children}
    </Sidebar>
  ) : null
);

export default SidebarWrapper;

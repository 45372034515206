import { Routine } from 'redux-saga-routines';
import { fetchProjectDocumentsRoutine, deleteDocumentRoutine } from 'screens/Projects/routines';
import { IDocument } from '../../model/IDocument';

export interface IDocumentsState {
  documents: IDocument[];
  addLoading: boolean;
}

export const saveProject = (state: IDocumentsState = {
  documents: null,
  addLoading: false
}, action: Routine<any>): IDocumentsState => {
  switch (action.type) {
    case deleteDocumentRoutine.SUCCESS:
      const documentToDeleteId = action.payload;
      return { ...state, documents: state.documents.filter(d => d.id !== documentToDeleteId) };
    case fetchProjectDocumentsRoutine.SUCCESS:
      return { ...state, documents: action.payload };
    default:
      return state;
  }
};

import React from 'react';
import styles from './styles.module.scss';
import { history } from 'helpers/history';
import ResendEmailConfirmationLinkForm from 'screens/Authorization/components/ResendEmailConfirmationLinkForm';
import PageLayout from 'screens/Authorization/components/PagesLayout';

export const ResendEmailConfirmationLinkPage: React.FunctionComponent = () => {
  const toggleLogin = () => history.push('/login');

  return (
    <PageLayout>
      <p className="header">
        Resend email confirmation link
      </p>
      <div className={styles.description}>
        Enter your account email and we will resend you email confirmation link
      </div>
      <ResendEmailConfirmationLinkForm toggleLogin={toggleLogin} />
    </PageLayout>
  );
};

export default ResendEmailConfirmationLinkPage;

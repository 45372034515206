import { call, put, takeEvery, all, takeLatest } from 'redux-saga/effects';
import * as objectivesService from '../../../OKR/services/objectiveService';
import {
  fetchMoreObjectivesRoutine,
  deleteObjectiveRoutine,
  toggleIsCustomObjectiveStatusRoutine,
  saveObjectiveAdminRoutine,
  toggleObjectiveIsHiddenStatusRoutine
} from '../../routines';
import { Routine } from 'redux-saga-routines';

function* fetchMoreObj({ payload }: Routine<any>) {
  try {
    const response = yield call(objectivesService.getObjectivesByFilter, payload);

    yield put(fetchMoreObjectivesRoutine.success(response));
  } catch {
    yield put(fetchMoreObjectivesRoutine.failure());
  }
}

function* toggleObjectiveIsCustomStatus({ payload }: Routine<any>) {
  try {
    yield call(objectivesService.toggleObjectiveIsCustomStatus, { objectiveId: payload.id, isCustom: payload.status });

    yield put(toggleIsCustomObjectiveStatusRoutine.success(payload));
  } catch {
    yield put(toggleIsCustomObjectiveStatusRoutine.failure());
  }
}

function* toggleObjectiveIsHiddenStatus({ payload }: Routine<any>) {
  try {
    yield call(objectivesService.toggleObjectiveIsHiddenStatus, { objectiveId: payload.id, isHidden: payload.status });

    yield put(toggleObjectiveIsHiddenStatusRoutine.success(payload));
  } catch {
    yield put(toggleObjectiveIsHiddenStatusRoutine.failure());
  }
}

function* saveObj({ payload }: Routine<any>) {
  try {
    const response = yield call(objectivesService.saveObjective, payload);

    yield put(saveObjectiveAdminRoutine.success(response));
  } catch {
    yield put(saveObjectiveAdminRoutine.failure());
  }
}

function* deleteObj({ payload }: Routine<any>) {
  try {
    yield call(objectivesService.deleteObjective, payload);

    yield put(deleteObjectiveRoutine.success(payload));
  } catch {
    yield put(deleteObjectiveRoutine.failure());
  }
}

function* watchToggleObjectiveIsCustomStatus() {
  yield takeEvery(toggleIsCustomObjectiveStatusRoutine.TRIGGER, toggleObjectiveIsCustomStatus);
}

function* watchToggleObjectiveIsHiddenStatus() {
  yield takeEvery(toggleObjectiveIsHiddenStatusRoutine.TRIGGER, toggleObjectiveIsHiddenStatus);
}

function* watchFetchMoreObj() {
  yield takeLatest(fetchMoreObjectivesRoutine.TRIGGER, fetchMoreObj);
}

function* watchSaveObj() {
  yield takeEvery(saveObjectiveAdminRoutine.TRIGGER, saveObj);
}

function* watchDeleteObj() {
  yield takeEvery(deleteObjectiveRoutine.TRIGGER, deleteObj);
}

export default function* objectivesSagas() {
  yield all([
    watchSaveObj(),
    watchDeleteObj(),
    watchFetchMoreObj(),
    watchToggleObjectiveIsCustomStatus(),
    watchToggleObjectiveIsHiddenStatus()
  ]);
}

import { Routine } from 'redux-saga-routines';
import { takeEvery, put, call, all } from 'redux-saga/effects';
import * as okrService from '../../services/okrService';
import { fetchUserOkrsRoutine, fetchOkrObjectivesRoutine, addObjKrToOkrRoutine } from '../../routines';
import { toastr } from 'react-redux-toastr';

function* fetchUserOkrsRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.fetchOkrsToAddKeyResult, payload);
    yield put(fetchUserOkrsRoutine.success(response));
  } catch (error) {
    yield put(fetchUserOkrsRoutine.failure(error.message));
  }
}

function* fetchOkrObjectives({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.fetchOkrObjectives, payload);
    yield put(fetchOkrObjectivesRoutine.success({ currentOkrObjectives: response, selectedOkrId: payload }));
  } catch (error) {
    yield put(fetchOkrObjectivesRoutine.failure(error.message));
  }
}

function* addObjKrToOkr({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.addKeyResultToOkr, payload);
    yield put(addObjKrToOkrRoutine.success(response));
    toastr.success('Key result was successfully added');
  } catch (error) {
    toastr.error('Error while adding key result...');
    yield put(addObjKrToOkrRoutine.failure(error.message));
  }
}

function* watchFetchUserOkrsRequest() {
  yield takeEvery(fetchUserOkrsRoutine.TRIGGER, fetchUserOkrsRequest);
}

function* watchFetchOkrObjectivesRequest() {
  yield takeEvery(fetchOkrObjectivesRoutine.TRIGGER, fetchOkrObjectives);
}

function* watchAddObjKrToOkrRequest() {
  yield takeEvery(addObjKrToOkrRoutine.TRIGGER, addObjKrToOkr);
}

export default function* addToMyOkrSagas() {
  yield all([
    watchFetchUserOkrsRequest(),
    watchFetchOkrObjectivesRequest(),
    watchAddObjKrToOkrRequest()
  ]);
}

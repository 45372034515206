import { combineReducers } from 'redux';
import { reducerCreator } from 'helpers/reducerHelper';
import {
  fetchAllDaysOffRoutine,
  requestDaysOffRoutine,
  fetchAllByUserIdRequestsRoutine,
  fetchAllRequestsWithUserAndDaysRoutine,
  fetchDaysOffForUserRoutine,
  fetchAllUsersRoutine
} from '../routines';
import { daysOff } from '../containers/DaysOffAdminPage/reducer';
import { daysOffRequests } from '../containers/TimeOffTrackerTab/reducers';
import { requestsTimeOffAdminPage } from '../containers/RequestsAdminPage/reducer';
import { users } from '../containers/RequestDaysOffAdminPageForm/reducer';

const requests = combineReducers({
  daysOff: reducerCreator([fetchAllDaysOffRoutine.TRIGGER]),
  daysOffRequests: reducerCreator([requestDaysOffRoutine.TRIGGER, fetchAllByUserIdRequestsRoutine.TRIGGER]),
  daysOffUserProfile: reducerCreator([fetchDaysOffForUserRoutine.TRIGGER]),
  requestsTimeOffAdminPage: reducerCreator([fetchAllRequestsWithUserAndDaysRoutine.TRIGGER]),
  users: reducerCreator([fetchAllUsersRoutine.TRIGGER])
});

export default combineReducers({
  daysOffRequests,
  daysOff,
  requests,
  requestsTimeOffAdminPage,
  users
});


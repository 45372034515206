import { callFeedbackApi } from 'helpers/webApiHelper';
import { IUserFeedbackSummary } from '../../Feedback/model/IUserFeedbackSummary';

export const getFeedbackList = async (userId: string): Promise<IUserFeedbackSummary[]> => {
  const response = await callFeedbackApi({
    endpoint: `/api/feedback/requests/${userId}/user`,
    type: 'GET'
  });
  return response.json();
};

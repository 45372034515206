import React, { useEffect, useState } from 'react';
import { Button, Form, Header, Divider, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { createSummaryRoutine,
  updateSummaryRoutine,
  fetchSummaryRoutine } from '../../routines';
import { IBindingAction, IBindingCallback1 } from '../../../../models/callback';
import { fetchAdminUserInfoRoutine } from '../../../AdminPage/routines';
import { Routine } from 'redux-saga-routines';
import styles from './styles.module.scss';
import { ISummary } from '../../model/Summary/ISummary';
import LoaderWrapper from '../../../../components/LoaderWrapper';

interface IFeedbackSummaryProps {
  createSummary: IBindingCallback1<Routine<any>>;
  fetchSummary: IBindingCallback1<string>;
  updateSummary: IBindingCallback1<Routine<any>>;
  summary?: ISummary;
  requestId: string;
  isMentee: boolean;
  fulLRights: boolean;
}

const FeedbackSummary = ({ createSummary, updateSummary,
  summary, fetchSummary, requestId, isMentee, fulLRights }: IFeedbackSummaryProps) => {
  const [openReview, setOpenReview] = useState(summary?.openReview);
  const [closeReview, setCloseReview] = useState(summary?.closeReview);
  const [isInput, setIsInput] = useState(false);
  const [isExist, setIsExist] = useState(summary);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    fetchSummary(requestId);
    setCloseReview(summary?.closeReview);
    setOpenReview(summary?.openReview);
  }, []);

  useEffect(() => {
    setCloseReview(summary?.closeReview);
    setOpenReview(summary?.openReview);
    setIsExist(summary);
  }, [summary]);

  useEffect(() => {
    const result = (openReview !== undefined && closeReview !== undefined
      && closeReview?.length !== 0 && openReview?.length !== 0)
      ? setIsReady(true)
      : setIsReady(false);
  }, [openReview, closeReview]);

  const onSave = () => {
    if (openReview && closeReview) {
      const newSummary = { requestId, openReview, closeReview };
      const result = isExist ? updateSummary(newSummary) : createSummary(newSummary);
      setIsInput(false);
    }
  };

  const onCancel = () => {
    setIsInput(false);
  };

  return !isInput
    ? (
      <>
        <div className={styles.buttonHeader}>
          {(!isMentee && fulLRights)
            && (
              <Button
                primary
                onClick={() => setIsInput(true)}
                icon={isExist ? 'pencil' : 'plus square'}
                content={isExist ? 'Edit' : 'Add summary'}
              />
            )}
        </div>
        <div className={styles.reviewDiv}>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="bullhorn" />
              Review for mentee
            </Header>
          </Divider>
          <p>
            {summary?.openReview
              ? summary?.openReview
              : 'There is not review for mentee ...'}
          </p>
          {(!isMentee && fulLRights)
            && (
              <>
                <Divider horizontal>
                  <Header as="h4">
                    <Icon name="bullhorn" />
                    Post review
                  </Header>
                </Divider>
                <p>
                  {summary?.closeReview
                    ? summary?.closeReview
                    : 'There is not post review ... '}
                </p>
              </>
            )}
        </div>
      </>
    )
    : ((!isMentee && fulLRights)
      && (
        <div className={styles.reviewInputDiv}>
          <Form className={styles.reviewDiv}>
            <Form.TextArea
              required
              label="Review for mentee:"
              placeholder="Tell us more about mentee..."
              value={openReview}
              onChange={(e, data) => {
                const value = data.value as string;
                setOpenReview(value);
              }}
            />
            <Form.TextArea
              required
              label="Post review:"
              placeholder="Tell us more about you meentee..."
              value={closeReview}
              onChange={(e, data) => {
                const value = data.value as string;
                setCloseReview(value);
              }}
            />
            <Button
              primary
              onClick={() => onSave()}
              className={styles.formButton}
              disabled={!isReady}
            >
              Save
            </Button>
            <Button
              basic
              color="orange"
              onClick={() => onCancel()}
              className={styles.formButton}
            >
              Cancel
            </Button>
          </Form>
        </div>
      )
    );
};

const mapStateToProps = state => {
  const { feedbackPage: { saveSummary: { summary } } } = state;
  return {
    summary
  };
};

const mapDispatchToProps = {
  createSummary: createSummaryRoutine,
  updateSummary: updateSummaryRoutine,
  fetchSummary: fetchSummaryRoutine,
  fetchAdminUserInfo: fetchAdminUserInfoRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackSummary);

import { callGatewayApi } from 'helpers/webApiHelper';
import { INewUser } from '../model/INewUser';
import { ISaveUser } from '../model/ISaveUser';

export const createUser = async (user: INewUser) => {
  const response = await callGatewayApi({
    endpoint: '/api/users',
    type: 'POST',
    requestData: user
  });
  return response.json();
};

export const deleteUser = async (userId: string) => {
  const response = await callGatewayApi({
    endpoint: `/api/users/${userId}`,
    type: 'DELETE'
  });
  return response.status;
};

export const updateUser = async (user: ISaveUser) => {
  const response = await callGatewayApi({
    endpoint: '/api/users',
    type: 'PUT',
    requestData: user
  });
  return response.status;
};

import { callOkrApi } from '../../../helpers/webApiHelper';
import { IRatingNew } from '../models/rating';
import { IGetRating } from '../models/rating/IGetRating';
import { ICommentUpdateData } from '../containers/KeyResultDataSidebar';

export const saveRating = async (payload: IRatingNew): Promise<any> => {
  const response = await callOkrApi({
    endpoint: '/api/ratings',
    type: 'POST',
    requestData: {
      ...payload
    }
  });

  return response.json();
};

export const getByUserIdAndKeyResultId = async (payload: IGetRating) => {
  const response = await callOkrApi({
    endpoint: `/api/ratings/user/${payload.userId}/keyresult/${payload.keyResultId}`,
    type: 'GET'
  });
  return response.json();
};

export const updateRatingComment = async (payload: ICommentUpdateData) => callOkrApi({
  endpoint: '/api/ratings',
  type: 'PUT',
  requestData: payload
});

export default async function getDataStringFromReader(
  reader: ReadableStreamDefaultReader<Uint8Array>
): Promise<string> {
  let receivedLength = 0;
  const chunks = [];
  for (;;) {
    const { done, value } = await reader.read(); // eslint-disable-line

    if (done) break;

    chunks.push(value);
    receivedLength += value.length;
  }

  const chunksAll = new Uint8Array(receivedLength);
  let position = 0;
  chunks.forEach(chunk => {
    chunksAll.set(chunk, position);
    position += chunk.length;
  });

  return new TextDecoder('utf-8').decode(chunksAll);
}

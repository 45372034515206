import { takeEvery, put, call, all } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { Routine } from 'redux-saga-routines';
import * as ratingService from '../../services/ratingService';
import { getRatingRoutine, updateRatingRoutine } from '../../routines';

function* getRatingRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(ratingService.getByUserIdAndKeyResultId, payload);
    yield put(getRatingRoutine.success(response));
  } catch (error) {
    yield put(getRatingRoutine.failure(error.message));
  }
}

function* watchGetRatingRequest() {
  yield takeEvery(getRatingRoutine.TRIGGER, getRatingRequest);
}

function* updateRatingRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(ratingService.saveRating, payload);
    yield put(updateRatingRoutine.success({ ...response, id: payload.keyResultId }));
    toastr.success('Successfully added a comment!');
  } catch (error) {
    yield put(updateRatingRoutine.failure(error.message));
  }
}

function* watchUpdateRatingRequest() {
  yield takeEvery(updateRatingRoutine.TRIGGER, updateRatingRequest);
}

export default function* keyResultRatingSagas() {
  yield all([
    watchGetRatingRequest(),
    watchUpdateRatingRequest()
  ]);
}

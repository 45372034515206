import React from 'react';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { IGlobalState } from 'models/global-state';

import styles from './styles.module.scss';
import OkrsGridView from 'screens/OKR/containers/OkrsGridView';
import { IBindingAction } from 'models/callback';
import { clearDashboardPageRoutine } from 'screens/Dashboard/routines';
import ManagementView from 'screens/Library/containers/LibraryView';
import UsersOkrsPage from 'screens/UsersOkrs/containers/UsersOkrsPage';
import { clearUsersOkrsPageRoutine } from 'screens/UsersOkrs/routines';
import { Route } from 'react-router-dom';
import { history } from 'helpers/history';
import { Role } from 'screens/Authorization/models/role';

interface IOkrMenuState {
  activeItem: string;
}

interface IOkrMenuProps {
  clearDashboard: IBindingAction;
  clearUsers: IBindingAction;
  roles: Role[];
  location: any;
  match: any;
}

const routes = [
  {
    name: 'myList',
    menuText: 'My List',
    path: '/okr/list',
    component: OkrsGridView,
    exact: true
  },
  {
    name: 'users',
    menuText: 'Fellows OKRs',
    path: '/okr/users',
    component: UsersOkrsPage,
    exact: false
  },
  // {
  //   name: 'dashboard',
  //   path: '/okr/dashboard',
  //   component: DashboardPage
  // },
  // {
  //   name: 'achievements',
  //   path: '/okr/achievements',
  //   component: UserAchievementsPage
  // },
  // {
  //   name: 'achievementsManagement',
  //   path: '/okr/achievementsManagement',
  //   component: AchievementsManagementPage,
  //   roles: [Role.Administrator]
  // },
  {
    name: 'library',
    menuText: 'Library',
    path: '/okr/library',
    component: ManagementView,
    roles: [Role.Administrator],
    exact: true
  }
  // {
  //   name: 'admin-dashboard',
  //   path: '/okr/admin-dashboard',
  //   component: AdminDashboard,
  //   roles: [Role.Administrator]
  // }
];

class OKRMenu extends React.Component<IOkrMenuProps, IOkrMenuState> {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 'myList'
    };
  }

  componentDidMount() {
    const { location: { pathname } } = this.props;
    const path = routes.find(route => (route.exact ? pathname === route.path : pathname.startsWith(route.path)))?.name;
    const defaultPage = routes[0];
    if (!path) history.push(defaultPage.path);
    this.setState({ activeItem: path ?? defaultPage.name });
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;
    if (pathname !== prevProps.location.pathname) {
      this.updatePath(pathname);
    }
  }

  componentWillUnmount() {
    const { clearDashboard, clearUsers } = this.props;
    clearDashboard();
    clearUsers();
  }

  handleMenuClick = (e, data) => {
    this.setState({ activeItem: data.name });
    history.push(routes.find(route => route.name === data.name).path);
  }

  updatePath(pathname: string) {
    const path = routes.find(route => (route.exact ? pathname === route.path : pathname.startsWith(route.path)))?.name;
    const defaultPage = routes[0];
    if (!path) history.push(defaultPage.path);
    this.setState({ activeItem: path ?? defaultPage.name });
  }

  renderMenu = () => {
    const { activeItem } = this.state;
    const { roles: userRoles } = this.props;

    const userRolesIncludes = (roles: Role[]) => userRoles.some(userRole => roles.includes(userRole));

    return (
      <div className={styles.menu}>
        <Menu pointing secondary>
          {routes.map(({ name, roles, menuText }) => (
            !roles || userRolesIncludes(roles)
              ? (
                <Menu.Item
                  key={name}
                  active={activeItem === name}
                  onClick={this.handleMenuClick}
                  name={name}
                >
                  {menuText}
                </Menu.Item>
              ) : ''
          ))}
        </Menu>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderMenu()}
        {routes.map(({ path, component, exact }) => (
          <Route exact={exact} path={path} component={component} key={path} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const {
    user: { user: { roles } }
  } = state;

  return {
    roles
  };
};

const mapDispatchToProps = {
  clearDashboard: clearDashboardPageRoutine,
  clearUsers: clearUsersOkrsPageRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(OKRMenu);

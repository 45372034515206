import { Routine } from 'redux-saga-routines';
import { IProject } from 'screens/Projects/model/IProject';
import { IUserInfo } from 'screens/UserProfile/models/IUserInfo';
import { IUserReviews } from 'screens/UserProfile/models/reviews/IUserReviews';
import {
  fetchUserInfoRoutine,
  updateUserInfoRoutine,
  updateUserPasswordRoutine,
  updateAvatarRoutine,
  fetchUserOKRsRoutine,
  clearUserProfileStateRoutine,
  fetchUserReviewsRoutine,
  fetchUserFeedbackListRoutine
} from '../../routines';
import { IOkr } from '../../../OKR/models/okr';
import { IUserFeedbackSummary } from '../../../Feedback/model/IUserFeedbackSummary';

export interface IUserInfoState {
  userInfo: IUserInfo;
  projects: IProject[];
  reviews: IUserReviews[];
  userOkrs: IOkr[];
  userFeedbackList: IUserFeedbackSummary[];
  incorrectUser: boolean;
  loadingOkrTab: boolean;
  loadingFeedbackListTab: boolean;
}

const initialState = {
  projects: [],
  reviews: [],
  userInfo: null,
  userOkrs: null,
  userFeedbackList: null,
  incorrectUser: false,
  loadingOkrTab: true,
  loadingFeedbackListTab: true
};

export const userInfo = (state: IUserInfoState = initialState, action: Routine<any>): IUserInfoState => {
  switch (action.type) {
    case fetchUserInfoRoutine.TRIGGER:
      return initialState;
    case fetchUserInfoRoutine.SUCCESS:
      return { ...state, ...action.payload };
    case fetchUserInfoRoutine.FAILURE:
      return { ...state, incorrectUser: true };
    case updateUserInfoRoutine.SUCCESS:
      const { payload } = action.payload;
      const newResponse = { ...state.userInfo, ...payload };
      return { ...state, userInfo: newResponse };
    case updateUserPasswordRoutine.SUCCESS:
      return state;
    case updateAvatarRoutine.SUCCESS:
      const { avatarPreviewPath } = action.payload;
      return { ...state, userInfo: { ...state.userInfo, avatarPreviewPath } };
    case fetchUserOKRsRoutine.SUCCESS:
      return { ...state, userOkrs: action.payload, loadingOkrTab: false };
    case fetchUserOKRsRoutine.TRIGGER:
      return { ...state, loadingOkrTab: true };
    case fetchUserFeedbackListRoutine.SUCCESS:
      return { ...state, userFeedbackList: action.payload, loadingFeedbackListTab: false };
    case fetchUserFeedbackListRoutine.TRIGGER:
      return { ...state, loadingFeedbackListTab: true };
    case fetchUserReviewsRoutine.SUCCESS:
      return { ...state, reviews: action.payload };
    case clearUserProfileStateRoutine.TRIGGER:
      return initialState;
    default:
      return state;
  }
};

/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { List, Button, Header, Modal, Icon, Input, Label } from 'semantic-ui-react';
import { boundMethod } from 'autobind-decorator';
import moment from 'moment';
import LoaderWrapper from 'components/LoaderWrapper';
import SidebarWrapper from 'components/Sidebar';
import { SabbaticalOkrContainer } from 'components/SabbaticalOkrContainer';
import { IKeyResultsSearch } from 'screens/OKR/models/key-result/IKeyResultsSearch';
import { IBindingCallback1 } from 'models/callback';
import { IUser } from 'screens/Authorization/models/IUser';
import { IKeyResult } from 'screens/OKR/models/key-result/IKeyResult';
import { IGlobalState } from 'models/global-state';
import { IMark, IMarkToSave } from 'screens/OKR/models/mark';
import KeyResultItem from '../../components/KeyResultItem';
import AddKeyResultModal from '../../components/AddKeyResultModal';
import CopyOkrLinkButton from '../../components/CopyOkrLinkButton';
import DateLabel from '../../components/DateLabel';
import { RemoveButton } from './RemoveButton';
import KeyResultData from '../KeyResultDataSidebar';
import { IObjective } from '../../models/objective';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  IKeyResultsToObjectivesToOkrsIds,
  IAddKeyResultsToObjectivesToOkrsIds,
  IObjectivesToOkrsIds,
  IOkrExtended,
  IOkrToSave,
  IKeyResultUpdatingResult
} from '../../models/okr';
import * as objectiveService from '../../services/objectiveService';
import * as keyResultService from '../../services/keyResultService';
import {
  fetchExtendedOkr,
  removeKeyResultFromOkr,
  addKeyResultToOkr,
  saveMark,
  addObjectiveToOkr,
  removeObjectiveFromOkr,
  saveObjectiveRoutine,
  saveKeyResultRoutine,
  updateOKRStatusRoutine,
  saveOkrRoutine,
  deleteOkrRoutine,
  confirmKeyResultSuggestionRoutine,
  moveKeyResultRoutine
} from '../../routines';
import styles from './styles.module.scss';
import AddObjectiveModal from '../../components/AddObjectiveModal';
import { IObjectiveFilter } from '../../models/objective/IObjectiveFilter';
import { calcOkrsMark, calcObjectivesMarks } from 'screens/OKR/helpers/array.helper';
import SaveOkrForm from '../SaveOkrForm';
import OKRDeleteModal from 'screens/OKR/components/OkrDeleteModal';
import ConfirmModal from 'components/ConfirmModal';

export interface IMoveKeyResultData {
  keyResultId: string;
  fromObjectiveId: string;
  toObjectiveId: string;
  okrId: string;
  toIndex: number;
  fromIndex: number;
}

interface IOkrListProps {
  removeKeyResultFromOkr: IBindingCallback1<IKeyResultsToObjectivesToOkrsIds>;
  addKeyResultToOkr: IBindingCallback1<IAddKeyResultsToObjectivesToOkrsIds>;
  removeObjectiveFromOkr: IBindingCallback1<IObjectivesToOkrsIds>;
  addObjectiveToOkr: IBindingCallback1<IObjectivesToOkrsIds>;
  saveObjective: IBindingCallback1<IObjective>;
  saveKeyResult: IBindingCallback1<IKeyResult>;
  fetchExtendedOkr: IBindingCallback1<string>;
  saveMark: IBindingCallback1<IMarkToSave>;
  updateOKRStatus: IBindingCallback1<string>;
  saveOkr: IBindingCallback1<IOkrToSave>;
  deleteOkr: IBindingCallback1<string>;
  moveKeyResult: IBindingCallback1<IMoveKeyResultData>;
  confirmKeyResult: IBindingCallback1<IKeyResultUpdatingResult>;
  keyResultSaving: boolean;
  objectiveSaving: boolean;
  error: string | object;
  okr: IOkrExtended;
  loading: boolean;
  history: any;
  user: IUser;
  match: any;
}

interface IOkrListState {
  notMarkedOkr: IKeyResult[];
  isSidebarOpen: boolean;
  newKeyResultObjectiveId?: string;
  selectedKeyResult?: string;
  isInfoModalOpened: boolean;
  updatingObjectiveIndex?: number;
  newObjectiveName: string;
  isClosedButtonClicked: boolean;
  isEditOpened: boolean;
  activeDroppableId: string;
  isNoteTextShortened: boolean;
  noteTextLength: number;
}

class OkrView extends React.Component<IOkrListProps, IOkrListState> {
  constructor(props) {
    super(props);

    this.state = {
      notMarkedOkr: [],
      isSidebarOpen: false,
      updatingObjectiveIndex: null,
      isInfoModalOpened: false,
      newObjectiveName: '',
      isClosedButtonClicked: false,
      isEditOpened: false,
      activeDroppableId: null,
      isNoteTextShortened: false,
      noteTextLength: 200
    };
  }

  componentDidMount() {
    const { match: { params: { okrId } }, fetchExtendedOkr: ftExtendedOkr, okr } = this.props;
    const { noteTextLength } = this.state;
    const isNoteLong = okr?.note && okr.note.length > noteTextLength;
    this.setState({
      isNoteTextShortened: isNoteLong
    });
    ftExtendedOkr(okrId);
  }

  @boundMethod
  onObjectiveSelect(arrayId) {
    arrayId.forEach(id => {
      const { okr, addObjectiveToOkr: addObjToOkr } = this.props;
      const objectiveExists = okr.objectives.map(({ id: localId }) => localId).includes(id);
      if (!objectiveExists) {
        addObjToOkr({
          okrId: okr.id,
          objectiveId: id
        });
      }
    });
  }

  @boundMethod
  async onObjectiveCreate(inputValue) {
    const obj = await objectiveService.getObjectiveByName(inputValue.name);
    const id = obj ? obj.id : (await objectiveService.saveObjective({
      name: inputValue.name,
      isCustom: inputValue.isCustom
    })).id;

    return { key: id, label: inputValue.name, value: { id } };
  }

  @boundMethod
  async onObjectiveUpdate(currentObjective: IObjective, index: number) {
    const { newObjectiveName: name } = this.state;
    const nameToSet = name.trim();

    if (currentObjective.isCustom && nameToSet.length) {
      const { saveObjective } = this.props;
      if (currentObjective.name !== nameToSet) {
        saveObjective({
          ...currentObjective,
          name: nameToSet
        });
      }
    }
    this.toggleDisplayingObjectiveInputClick(index, '', false);
  }

  @boundMethod
  async onObjectivesLoad({ name, from, count }: IObjectiveFilter) {
    const objectives = await objectiveService.getObjectivesByUserIdAndFilter({
      name,
      from,
      count
    });
    return objectives.map(({ id, name: localName }) => ({
      key: id,
      label: localName,
      value: { id }
    }));
  }

  @boundMethod
  async onKeyResultLoad({ searchQuery, from, count }: IKeyResultsSearch) {
    const keyResults = await keyResultService.getKeyResults({
      searchQuery,
      from,
      count
    });
    return keyResults.map(({ id, name }) => ({
      key: id,
      label: name,
      value: { id }
    }));
  }

  @boundMethod
  onRemoveObjective(objectiveId: string) {
    const { removeObjectiveFromOkr: rmObjectiveFromOkr, okr } = this.props;
    rmObjectiveFromOkr({
      okrId: okr.id,
      objectiveId
    });
  }

  @boundMethod
  onRemoveKeyResult(objectiveId: string, keyResultId: string) {
    const { removeKeyResultFromOkr: rmKeyResultFromOkr, okr } = this.props;
    rmKeyResultFromOkr({
      okrId: okr.id,
      objectiveId,
      keyResultId
    });
  }

  @boundMethod
  onAddKeyResults(
    objectiveId: string,
    isCustom: boolean,
    keyResultsId?: string[],
    newKeyResult?: IKeyResult
  ) {
    const { addKeyResultToOkr: addKRToOkr, okr } = this.props;
    addKRToOkr({
      okrId: okr.id,
      objectiveId,
      isCustom,
      keyResultsId,
      newKeyResult
    });
  }

  @boundMethod
  onSetMark(objectiveId: string, keyResultId: string, mark: number) {
    const { okr: { id }, saveMark: svMark } = this.props;

    return svMark({
      okrId: id,
      objectiveId,
      keyResultId,
      mark
    });
  }

  onSaveOkr = data => {
    const { startTime, endTime, name, description, isSabbatical } = data;
    const { user, saveOkr, okr } = this.props;

    saveOkr({
      id: okr.id,
      userId: user.id,
      startTime: startTime.toDate(),
      endTime: endTime.toDate(),
      name: name.trim(),
      description: description.trim(),
      isSabbatical
    });

    this.setState({
      isEditOpened: false
    });
  }

  onDragEnd = result => {
    this.setState({ activeDroppableId: null });

    if (!result.destination) {
      return;
    }

    const { moveKeyResult, okr: { id } } = this.props;
    moveKeyResult({
      fromObjectiveId: result.source.droppableId,
      toObjectiveId: result.destination.droppableId,
      toIndex: result.destination.index + 1,
      fromIndex: result.source.index + 1,
      keyResultId: result.draggableId,
      okrId: id
    });
  }

  onDragStart = data => this.setState({ activeDroppableId: data.source.droppableId });

  toggleDisplayingObjectiveInputClick = (index: number, defaultObjName: string, setIndex = true) => {
    const { okr } = this.props;
    this.setState(({ updatingObjectiveIndex }) => ({
      newObjectiveName: !updatingObjectiveIndex ? okr.objectives[index].name : defaultObjName,
      updatingObjectiveIndex: updatingObjectiveIndex === null || setIndex ? index : null
    }));
  }

  toggleInfoModalClick = () => this.setState(prevState => ({ isInfoModalOpened: !prevState.isInfoModalOpened }));

  toggleEditModal = () => this.setState(prevState => ({ isEditOpened: !prevState.isEditOpened }));

  handleObjectiveNameEnterClick = (e, objective, index) => {
    if (e.keyCode === 13) {
      this.onObjectiveUpdate(objective, index);
    }
  };

  onOkrDelete = () => {
    const { deleteOkr, okr: { id }, history } = this.props;
    deleteOkr(id);
    history.push('/okr/list');
  }

  handleConfirm = () => {
    const { okr: { endTime } } = this.props;
    if (moment(endTime).isBefore()) {
      this.updateOKRStatus();
    }
  }

  @boundMethod
  updateOKRStatus() {
    const { okr: { objectives, id }, updateOKRStatus } = this.props;
    const notMarkedOkr = objectives.flatMap(
      objective => objective.keyResults.filter(
        keyResult => keyResult.mark === null
      )
    );

    if (!notMarkedOkr.length) {
      updateOKRStatus(id);
    } else {
      this.setState({
        notMarkedOkr,
        isClosedButtonClicked: true
      });
      this.toggleInfoModalClick();
    }
  }

  @boundMethod
  toggleSidebar(selectedKR: string = null) {
    const { selectedKeyResult } = this.state;
    const areSame = selectedKR === selectedKeyResult || !selectedKR;

    this.setState({
      isSidebarOpen: !areSame,
      selectedKeyResult: areSame ? null : selectedKR
    });
  }

  @boundMethod
  goBack() {
    const { history } = this.props;
    history.push('/okr/list');
  }

  renderConfirm = (isClosed: boolean, disabled: boolean) => (
    <div className={styles.closeButton}>
      {disabled ? (
        <Button
          disabled
          content={isClosed ? <span>Your OKR is closed (reopen)</span> : 'Close OKR'}
        />
      ) : (
        <ConfirmModal
          header={`Are you sure you want to ${isClosed ? 'reopen' : 'close'}  the OKR?`}
          description={`OKR will be marked as ${isClosed ? 'opened' : 'closed'} and you ${isClosed ? 'will' : "won't"} be able to edit it!`}
          onConfirm={this.handleConfirm}
          confirmButtonText={`Yes, ${isClosed ? 'open' : 'close'} it!`}
        >
          <Button
            className={styles.reopenBtn}
            content={isClosed ? <span>Your OKR is closed (reopen)</span> : 'Close OKR'}
          />
        </ConfirmModal>
      )}
    </div>
  );

  renderClosedLabel = (isClosed: boolean) => (isClosed ? <Label size="big" content="Closed" /> : '')

  render() {
    const {
      match: { params },
      okr,
      loading,
      saveKeyResult,
      user,
      objectiveSaving,
      keyResultSaving,
      confirmKeyResult
    } = this.props;
    const {
      notMarkedOkr,
      isSidebarOpen,
      selectedKeyResult,
      isInfoModalOpened,
      updatingObjectiveIndex,
      newObjectiveName,
      isClosedButtonClicked,
      isEditOpened,
      activeDroppableId,
      isNoteTextShortened,
      noteTextLength
    } = this.state;

    const isCurrentUserOkr = okr?.user.id === user.id;
    const isObjectiveChangeAvailable = index => !okr.isClosed && isCurrentUserOkr && updatingObjectiveIndex === index;

    const isNoteLong = okr?.note && okr.note.length > noteTextLength;

    const noteText: string = okr?.note && (
      isNoteTextShortened
        ? okr.note.substring(0, noteTextLength).concat('...')
        : okr.note
    );

    const isOkrTimePast = (okrDate: string | number | Date) => moment(okrDate).isAfter();
    const editButtonEvent = () => { if (isCurrentUserOkr && !okr.isClosed) this.toggleEditModal(); };
    const currentObjectives: IObjective[] = !loading && okr ? okr.objectives : [];
    const isLoading = loading || !okr || okr.id !== params.okrId;
    const marks = calcObjectivesMarks(okr?.objectives);
    const averageMark = calcOkrsMark(marks);

    const toggleShortenedNoteText = () => {
      this.setState(prevState => ({ isNoteTextShortened: !prevState.isNoteTextShortened }));
    };

    const toggleSuggestAction = (keyResultId: string, objectiveId: string) => (confirmed: boolean) => {
      confirmKeyResult({
        confirmed,
        keyResultId,
        objectiveId,
        okrId: okr.id
      });
    };

    const renderObjectiveName = (objective, index) => (
      isObjectiveChangeAvailable(index) && objective.isCustom
        ? (
          <Input
            style={{ height: '36px', width: '350px' }}
            ref={ref => ref && ref.focus()}
            value={newObjectiveName}
            size="mini"
            onChange={(_, data) => this.setState({ newObjectiveName: data.value })}
            onKeyDown={e => this.handleObjectiveNameEnterClick(e, objective, index)}
            onBlur={() => this.onObjectiveUpdate(objective, index)}
          />
        ) : (
          <div className={isCurrentUserOkr && !okr.isClosed && objective.isCustom
            ? styles.changeName
            : styles.name}
          >
            {objective.name}
          </div>
        ));

    const renderObjectiveDeleteButtonBlock = (id: string) => !okr.isClosed && isCurrentUserOkr && (
      <div className={styles.objectiveHeaderRightBlock}>
        <div className={okr.isClosed ? styles.objMarkClosedOkr : ''}>
          <span>{marks.find(m => m.id === id).mark}</span>
        </div>
        {!okr.isClosed && isCurrentUserOkr && (
          <RemoveButton
            onRemove={() => this.onRemoveObjective(id)}
          />
        )}
      </div>
    );

    const renderAddKeyResultModal = (objective: IObjective) => isCurrentUserOkr
      && !okr.isClosed
      && (
        <div className={activeDroppableId ? styles.addButtonPlace : ''}>
          <AddKeyResultModal
            toggleSidebar={krId => this.toggleSidebar(krId)}
            selectedResults={objective.keyResults}
            saving={keyResultSaving}
            onAddKeyResults={selectedKeyResults => this.onAddKeyResults(
              objective.id,
              false,
              selectedKeyResults
            )}
            onKeyResultCreate={result => this.onAddKeyResults(
              objective.id,
              true,
              [],
              result
            )}
          />
        </div>
      );

    const renderObjective = (objective: IObjective, index: number) => (
      <Droppable droppableId={objective.id} key={objective.id}>
        {provided => (
          <>
            <List.Item key={objective.id}>
              <List.Content>
                <List.Header className={styles.objectiveHeader}>
                  <div
                    className={styles.objectiveName}
                    role="presentation"
                    onClick={e => {
                      e.stopPropagation();
                      this.toggleDisplayingObjectiveInputClick(index, objective.name);
                    }}
                  >
                    {renderObjectiveName(objective, index)}
                  </div>
                  {renderObjectiveDeleteButtonBlock(objective.id)}
                </List.Header>
                <List.List className={styles.list}>
                  <List.Item>
                    <List.Content>
                      <div
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                      >
                        {objective.keyResults.length > 0 && (
                          objective.keyResults.map((keyResult, krIndex) => (
                            <Draggable
                              key={keyResult.id}
                              draggableId={keyResult.id}
                              index={krIndex}
                              isDragDisabled={okr.isClosed}
                            >
                              {krProvided => (
                                <div
                                  ref={krProvided.innerRef}
                                  className={styles.draggableItem}
                                  {...krProvided.draggableProps}
                                  {...krProvided.dragHandleProps}
                                >
                                  <KeyResultItem
                                    onRemove={() => this.onRemoveKeyResult(
                                      objective.id,
                                      keyResult.id
                                    )}
                                    onSetMark={({ mark }: IMark) => this.onSetMark(
                                      objective.id,
                                      keyResult.id,
                                      mark
                                    )}
                                    keyResult={keyResult}
                                    isOkrClosed={okr.isClosed || !isCurrentUserOkr}
                                    notMarked={notMarkedOkr.includes(
                                      keyResult
                                    )}
                                    onClick={keyResultId => this.toggleSidebar(keyResultId)}
                                    key={keyResult.id}
                                    handleKREdit={saveKeyResult}
                                    isTriedToClose={isClosedButtonClicked}
                                    toggleSuggestAction={toggleSuggestAction(keyResult.id, objective.id)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )))}
                        {renderAddKeyResultModal(objective)}
                      </div>
                    </List.Content>
                  </List.Item>
                </List.List>
              </List.Content>
            </List.Item>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    );

    return (
      (okr && user) && (
        <div style={{ height: 'calc(100vh - 50px)', overflowY: 'auto' }}>
          <LoaderWrapper loading={isLoading}>
            <SidebarWrapper
              isOpened={isSidebarOpen}
              onClose={() => this.toggleSidebar()}
            >
              <KeyResultData keyResultId={selectedKeyResult} />
            </SidebarWrapper>
            <Modal
              open={isInfoModalOpened}
              onClose={this.toggleInfoModalClick}
              size="small"
            >
              <Modal.Content>
                <h3>To close OKR you need to set all marks.</h3>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.toggleInfoModalClick} content="Got it" />
              </Modal.Actions>
            </Modal>
            <SaveOkrForm
              isOpened={isEditOpened}
              okr={okr}
              onSave={this.onSaveOkr}
              onCancel={this.toggleEditModal}
            />
            <div className={styles.listContainerWrapper}>
              {okr.isSabbatical ? (
                <div className={styles.listContainer}>
                  <div className={styles.topButtonContainer}>
                    <div className={styles.goBackButton}>
                      <Button icon labelPosition="left" onClick={() => this.goBack()}>
                        back
                        <Icon name="arrow left" />
                      </Button>
                    </div>
                    <div className={styles.okrControls}>
                      <CopyOkrLinkButton okrId={params.okrId} />
                      <div className={styles.okrActions}>
                        {isCurrentUserOkr ? (
                          this.renderConfirm(okr.isClosed, isOkrTimePast(okr.endTime))
                        ) : this.renderClosedLabel(okr.isClosed) }
                        {isCurrentUserOkr && <OKRDeleteModal onConfirm={this.onOkrDelete} />}
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.okrHeader}
                    style={isCurrentUserOkr && !okr.isClosed ? { cursor: 'pointer' } : {}}
                    onClick={editButtonEvent}
                    role="presentation"
                  >
                    <Header className={styles.listHeader}>
                      <Header.Content className={styles.header}>
                        {okr.name}
                      </Header.Content>
                      <Header.Subheader className={styles.listSubHeader}>
                        {okr.description}
                      </Header.Subheader>
                    </Header>
                  </div>
                  <SabbaticalOkrContainer
                    text="Sabbatical. OKR is stopped, maybe it’s time to get back to work?"
                    buttonText="Edit OKR"
                    height="55vh"
                    onButtonClick={editButtonEvent}
                    isOkrClosed={okr.isClosed}
                  />
                </div>
              ) : (
                <div className={styles.listContainer}>
                  <div className={styles.topButtonContainer}>
                    <div className={styles.goBackButton}>
                      <Button icon labelPosition="left" onClick={() => this.goBack()}>
                        back
                        <Icon name="arrow left" />
                      </Button>
                    </div>
                    <div className={styles.okrControls}>
                      <div className={styles.okrActions}>
                        {isCurrentUserOkr ? (
                          this.renderConfirm(okr.isClosed, isOkrTimePast(okr.endTime))
                        ) : this.renderClosedLabel(okr.isClosed) }
                        {isCurrentUserOkr && <OKRDeleteModal onConfirm={this.onOkrDelete} />}
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.okrHeader}
                    style={isCurrentUserOkr && !okr.isClosed ? { cursor: 'pointer' } : {}}
                    onClick={editButtonEvent}
                    role="presentation"
                  >
                    <Header className={styles.listHeader}>
                      <Header.Content className={styles.header}>
                        {okr.name}
                        <CopyOkrLinkButton okrId={params.okrId} />
                      </Header.Content>
                      <Header.Subheader className={styles.listSubHeader}>
                        {okr.description}
                      </Header.Subheader>
                    </Header>
                    <div className={styles.dateAndEdit}>
                      <div className={styles.okrDate}>
                        <p>
                          <Icon name="calendar outline" />
                          <DateLabel startTime={okr.startTime} endTime={okr.endTime} styles={styles} />
                        </p>
                        {!okr.isClosed && isCurrentUserOkr && (
                          <AddObjectiveModal
                            saving={objectiveSaving}
                            selectedObjectives={currentObjectives}
                            addSelectedObjectives={selectedKeyResults => this.onObjectiveSelect(selectedKeyResults)}
                            onObjectivesLoad={
                              (
                                { name, from, count, searchable }: IObjectiveFilter
                              ) => this.onObjectivesLoad({ name, from, count, searchable })
                            }
                            onObjectiveCreate={this.onObjectiveCreate}
                          />
                        )}
                      </div>
                      <div className={styles.editOkr}>
                        {averageMark !== null ? (
                          <div className={`${okr.isClosed ? styles.closed : ''} ${styles.okrMark}`}>
                            {averageMark}
                          </div>
                        ) : ''}
                        {isCurrentUserOkr && !okr.isClosed
                          && <Icon name="pencil" className={styles.editButton} />}
                      </div>
                    </div>
                    {(isCurrentUserOkr && okr.isClosed && okr.note) && (
                      <div className={styles.note}>
                        <div className={styles.noteMessage}>
                          <p className={styles.noteHeader}>Note</p>
                          <div className={styles.noteText}>
                            {noteText}
                            <div className={styles.showMore_container}>
                              <span
                                className={styles.note_showMore}
                                onClick={() => toggleShortenedNoteText()}
                                role="presentation"
                              >
                                {isNoteLong && (isNoteTextShortened ? 'show more' : 'show less')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {!okr.objectives.length
                    ? (
                      <p className={styles.placeholderText}>
                        You don&apos;t have any objectives, may you add your first one?
                      </p>
                    )
                    : (
                      <List size="massive">
                        <List.Item>
                          <List.Content className={styles.listContent}>
                            <List.List>
                              <DragDropContext
                                onDragEnd={this.onDragEnd}
                                onDragStart={this.onDragStart}
                              >
                                {okr.objectives
                                  .sort((obj1, obj2) => (obj1.name > obj2.name ? 1 : -1))
                                  .map((objective, index) => renderObjective(objective, index))}
                              </DragDropContext>
                            </List.List>
                          </List.Content>
                        </List.Item>
                      </List>
                    )}
                </div>
              )}
            </div>
          </LoaderWrapper>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const {
    okr: {
      extendedOkr,
      requests: {
        extendedOkr: { loading, error },
        objectiveSaving: { loading: objectiveSaving },
        keyResultSaving: { loading: keyResultSaving }
      }
    },
    user: { user }
  } = state;
  return {
    okr: extendedOkr,
    loading,
    error,
    user,
    objectiveSaving,
    keyResultSaving
  };
};

const mapDispatchToProps = {
  fetchExtendedOkr,
  removeKeyResultFromOkr,
  addKeyResultToOkr,
  saveMark,
  addObjectiveToOkr,
  removeObjectiveFromOkr,
  saveObjective: saveObjectiveRoutine,
  saveKeyResult: saveKeyResultRoutine,
  updateOKRStatus: updateOKRStatusRoutine,
  saveOkr: saveOkrRoutine,
  deleteOkr: deleteOkrRoutine,
  confirmKeyResult: confirmKeyResultSuggestionRoutine,
  moveKeyResult: moveKeyResultRoutine
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OkrView);

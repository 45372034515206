import React from 'react';
import { Form, Button, Dropdown } from 'semantic-ui-react';
import { IBindingCallback1 } from 'models/callback';
import styles from './styles.module.scss';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { Formik, FormikProps } from 'formik';
import { Complexity, KeyResultType } from 'screens/OKR/enums';
import { ITag, ITagFilter } from 'screens/OKR/models/tag';
import SelectMulti from 'react-select';
import { boundMethod } from 'autobind-decorator';
import addIfNotExisted from 'helpers/array.helper';
import { getTags } from 'screens/OKR/services/tagService';
import { ISelectItem } from 'models/autocomplete/ISelectItem';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Min name length is 2 symbols!')
    .max(300, 'Max name length is 300 symbols!')
    .required('Name is required'),
  details: Yup.string()
    .trim()
    .min(2, 'Min details length is 2 symbols!')
    .max(1024, 'Max details length is 1024 symbols!')
});

interface IKRSaveFormProps {
  onSave: IBindingCallback1<IKeyResult>;
  keyResult?: IKeyResult;
  defaultGlobal?: boolean;
}

interface IKRSaveFormState {
  tagsFetched: ITag[];
  loadingTags: boolean;
}

interface IKeyResultSaveFormikProps {
  name: string;
  keyResultType: KeyResultType;
  complexity: Complexity;
  details: string;
  tags: ISelectItem[];
}

class KeyResultSaveForm extends React.Component<IKRSaveFormProps, IKRSaveFormState> {
  defaultTagCount = 10;

  tagFilter: ITagFilter = {
    from: 0,
    count: this.defaultTagCount,
    name: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      tagsFetched: [],
      loadingTags: true
    };
  }

  componentDidMount() {
    this.loadTags();
  }

  @boundMethod
  onChangeTagName(value: string) {
    this.tagFilter.name = value;
    this.loadTags();
  }

  handleSave = (values: IKeyResultSaveFormikProps) => {
    const { onSave, keyResult } = this.props;
    onSave({
      ...keyResult,
      ...values,
      name: values.name.trim(),
      details: values.details.trim(),
      tags: values.tags?.map(tag => ({ id: tag.id, name: tag.label })) || []
    });
  };

  mapTag = ({ name, id }) => ({ label: name, value: name, id });

  @boundMethod
  loadTags(fetchMore?: boolean) {
    if (!fetchMore) {
      this.tagFilter.from = 0;
    }

    this.setState({ loadingTags: true }, async () => {
      const tags = await getTags(this.tagFilter);
      const { tagsFetched: stateTags } = this.state;
      this.setState({
        tagsFetched: fetchMore ? addIfNotExisted<ITag>(stateTags, tags) : tags,
        loadingTags: false
      });

      this.tagFilter.from += this.tagFilter.count;
    });
  }

  initializeValues(keyResult: IKeyResult) {
    const { defaultGlobal } = this.props;

    return keyResult
      ? { ...keyResult, tags: keyResult.tags?.map(tag => this.mapTag(tag)) }
      : {
        name: '',
        complexity: Complexity.basic,
        details: '',
        keyResultType: KeyResultType.misc,
        isCustom: !defaultGlobal,
        tags: []
      };
  }

  render() {
    const { keyResult } = this.props;
    const { loadingTags, tagsFetched } = this.state;

    const tagOptions = tagsFetched.map(tag => this.mapTag(tag));
    const complexityOptions = Object.values(Complexity)
      .filter(compl => compl !== 'n/a')
      .map(compl => ({ key: compl, text: compl, value: compl }));
    const typeOptions = Object.values(KeyResultType).map(type => ({ key: type, text: type, value: type }));
    const isNew = !keyResult;
    return (
      <Formik
        initialValues={this.initializeValues(keyResult)}
        onSubmit={kr => this.handleSave(kr)}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, handleSubmit, handleChange, errors, touched }:
          FormikProps<IKeyResultSaveFormikProps>) => (
            <Form onSubmit={handleSubmit}>
              <Form.Input
                width={16}
                name="name"
                placeholder="Key Result name:"
                onChange={handleChange}
                label={errors.name && touched.name ? errors.name : 'Name'}
                value={values.name}
                error={errors.name && touched.name}
              />
              <Form.Group widths="equal">
                <Form.Field
                  width={10}
                  control={Dropdown}
                  label="Complexity"
                  placeholder="Complexity"
                  name="roles"
                  value={values.complexity}
                  onChange={(_, o) => setFieldValue('complexity', o.value)}
                  fluid
                  selection
                  options={complexityOptions}
                />
                <Form.Field
                  width={10}
                  control={Dropdown}
                  label="Type"
                  placeholder="Type"
                  name="keyResultType"
                  value={values.keyResultType}
                  onChange={(_, o) => setFieldValue('keyResultType', o.value)}
                  fluid
                  selection
                  options={typeOptions}
                />
              </Form.Group>
              <Form.TextArea
                name="details"
                value={values.details}
                placeholder="Enter some details..."
                label={errors.details && touched.details ? errors.details : 'Details'}
                onChange={(_, o) => setFieldValue('details', o.value)}
                error={errors.details && touched.details}
              />
              <Form.Field
                label="Tags"
                control={SelectMulti}
                name="tags"
                value={values.tags}
                options={tagOptions}
                isSearchable
                isMulti
                isLoading={loadingTags}
                onInputChange={this.onChangeTagName}
                onChange={value => setFieldValue('tags', value)}
                onMenuScrollToBottom={() => this.loadTags(true)}
                className={styles.tagsField}
              />
              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  primary
                  content={isNew ? 'Create' : 'Update'}
                  disabled={!values.name.length}
                />
              </div>
            </Form>
        )}
      </Formik>
    );
  }
}

export default KeyResultSaveForm;

import { combineReducers } from 'redux';

import { reducerCreator } from 'helpers/reducerHelper';
import {
  fetchOkrs,
  fetchExtendedOkr,
  fetchExtendedKeyResultRoutine,
  addObjectiveToOkr,
  addKeyResultToOkr
} from '../routines';
import { okrs, okrDates } from '../containers/OkrsGridView/reducer';
import { extendedOkr } from '../containers/OkrView/reducer';
import { extendedKeyResult, editKeyResultComment } from '../containers/KeyResultDataSidebar/reducer';
import { keyResultRating } from '../containers/AddRatingForm/reducer';
import { addToMyOkr } from '../containers/AddToMyOkrForm/reducer';
import { adminDashboard } from '../containers/AdminDashboard/AdminDashboard/reducer';
import library from '../../Library/reducers';
import { okrAchievements } from '../../UserAchievements/containers/UserAchievementsPage/reducer';
import { adFetchOkrStatsRoutine } from '../containers/AdminDashboard/routines';

const requests = combineReducers({
  okrs: reducerCreator([fetchOkrs.TRIGGER]),
  extendedOkr: reducerCreator([fetchExtendedOkr.TRIGGER]),
  extendedKeyResult: reducerCreator([fetchExtendedKeyResultRoutine.TRIGGER]),
  objectiveSaving: reducerCreator([addObjectiveToOkr.TRIGGER]),
  keyResultSaving: reducerCreator([addKeyResultToOkr.TRIGGER]),
  okrStats: reducerCreator([adFetchOkrStatsRoutine.TRIGGER])
});

export default combineReducers({
  addToMyOkr,
  adminDashboard,
  extendedKeyResult,
  extendedOkr,
  keyResultRating,
  library,
  okrAchievements,
  okrDates,
  okrs,
  editKeyResultComment,
  requests
});

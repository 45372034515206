import { Routine } from 'redux-saga-routines';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { ISaveUser } from 'screens/AdminPage/model/ISaveUser';
import * as userService from '../../services/userService';
import * as userInfoService from '../../services/userInfoService';
import {
  fetchAdminUserInfoRoutine,
  createUserRoutine,
  deleteUserRoutine,
  openUpdateFormRoutine,
  closeUpdateModalRoutine,
  updateUserRoutine,
  fetchUserMentorsRoutine
} from '../../routines';

function* userInfoRequest(): Routine<any> {
  try {
    const [users, usersData] = yield all([
      call(userInfoService.getUsers),
      call(userInfoService.getUserData)
    ]);
    const result = users.map(({ id, firstName, lastName, email, logoLink, roles }) => {
      const user = usersData.find(u => u.id === id);
      return {
        id,
        fullName: `${firstName} ${lastName}`,
        email,
        roles,
        logoLink,
        phone: user?.phone,
        offices: user?.offices,
        position: user?.position,
        introLetterLink: user?.introLetterLink
      };
    });

    yield put(fetchAdminUserInfoRoutine.success(result));
  } catch (error) {
    yield put(fetchAdminUserInfoRoutine.failure(error));
  }
}

function* createUser({ payload }: Routine<any>): Routine<any> {
  try {
    const response = yield call(userService.createUser, payload);
    const result = {
      id: response.id,
      fullName: `${response.firstName} ${response.lastName}`,
      email: response.email,
      phone: payload.phone,
      office: payload.office,
      position: payload.position,
      introLetterLink: payload.introLetterLink
    };
    yield put(createUserRoutine.success(result));

    yield put(fetchUserMentorsRoutine.trigger());
  } catch (error) {
    yield put(createUserRoutine.failure(error));
  }
}

function* deleteUser({ payload }: Routine<any>): Routine<any> {
  try {
    const responseStatus = yield call(userService.deleteUser, payload);
    yield put(
      responseStatus === 200
        ? deleteUserRoutine.success(payload)
        : deleteUserRoutine.failure()
    );
  } catch (error) {
    yield put(deleteUserRoutine.failure(error));
  }
}

function* getUserInfo({ payload }: Routine<any>): Routine<any> {
  try {
    const [user, userInf] = yield all([
      call(userInfoService.getInfo, payload),
      call(userInfoService.getProfileInfo, payload)
    ]);
    const userInfo: ISaveUser = {
      ...userInf,
      ...user,
      offices: userInf.offices
    };
    yield put(openUpdateFormRoutine.success(userInfo));
  } catch (error) {
    yield put(openUpdateFormRoutine.failure(error));
  }
}
function* updateUser({ payload }: Routine<any>): Routine<any> {
  try {
    const responseStatus = yield call(userService.updateUser, payload);
    if (responseStatus !== 200) {
      yield put(updateUserRoutine.failure());
    }
    const result = {
      id: payload.id,
      fullName: `${payload.firstName} ${payload.lastName}`,
      email: payload.email,
      phone: payload.phone,
      offices: payload.offices,
      position: payload.position,
      introLetterLink: payload.introLetterLink,
      logoLink: payload.logoLink,
      roles: payload.roles
    };
    yield put(updateUserRoutine.success(result));

    yield put(fetchUserMentorsRoutine.trigger());
  } catch (error) {
    yield put(updateUserRoutine.failure(error));
  }
}

function* fetchUsersMentor() {
  try {
    const result = yield call(userInfoService.getUsersMentor);
    yield put(fetchUserMentorsRoutine.success(result));
  } catch (error) {
    yield put(fetchUserMentorsRoutine.failure(error));
  }
}

function* closeUpdateModal() {
  yield put(closeUpdateModalRoutine.success());
}

function* watchUserInfoRequest() {
  yield takeEvery(fetchAdminUserInfoRoutine.TRIGGER, userInfoRequest);
}

function* watchUserCreateRequest() {
  yield takeEvery(createUserRoutine.TRIGGER, createUser);
}

function* watchUserDeleteRequest() {
  yield takeEvery(deleteUserRoutine.TRIGGER, deleteUser);
}

function* watchUserUpdateShow() {
  yield takeEvery(openUpdateFormRoutine.TRIGGER, getUserInfo);
}

function* watchCloseInfoModal() {
  yield takeEvery(closeUpdateModalRoutine.TRIGGER, closeUpdateModal);
}

function* watchUpdateUser() {
  yield takeEvery(updateUserRoutine.TRIGGER, updateUser);
}

function* watchFetchUsersMentor() {
  yield takeEvery(fetchUserMentorsRoutine.TRIGGER, fetchUsersMentor);
}

export default function* adminPageSagas() {
  yield all([
    watchUserInfoRequest(),
    watchUserCreateRequest(),
    watchUserDeleteRequest(),
    watchUserUpdateShow(),
    watchCloseInfoModal(),
    watchUpdateUser(),
    watchFetchUsersMentor()
  ]);
}

import { saveAs } from 'file-saver';
import getDataStringFromReader from './getDataStringFromReaderHelper';

export default async function saveFileFromContent(responseContent: Response) {
  const fileContent = await getDataStringFromReader(
    responseContent.body.getReader()
  );
  const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, 'users.csv');
}

import { Role } from '../../../Authorization/models/role';

const RoleToEditRightsMap: Map<Role, Role[]> = new Map<Role, Role[]>([
  [Role.Developer, []],
  [Role.HR, [Role.Developer]],
  [Role.Manager, [Role.Developer, Role.HR]],
  [Role.TopManager, [Role.Developer, Role.HR, Role.Manager]],
  [Role.Administrator, [Role.Developer, Role.HR, Role.Manager, Role.TopManager, Role.Administrator]]
]);

export default RoleToEditRightsMap;

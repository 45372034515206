import { Console } from 'console';
import { callFeedbackApi } from '../../../helpers/webApiHelper';
import { IQuestion } from '../model/Questions/IQuestion';

export const getQuestions = async (blockId): Promise<IQuestion[]> => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/questions/block',
    type: 'GET',
    queryParams: { blockId }
  });
  return response.json();
};

export const saveChanges = async (questions: IQuestion[]) => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/questions/',
    type: 'POST',
    requestData: questions
  });
  return response.json();
};

export const createQuestion = async (question): Promise<IQuestion> => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/questions/',
    type: 'POST',
    requestData: question
  });
  return response.json().then(res => res.data);
};

export const deleteQuestion = async id => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/questions',
    type: 'DELETE',
    queryParams: { id }
  });
  return response;
};

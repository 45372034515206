import React, { useState, SyntheticEvent } from 'react';
import { Input, Modal, Icon, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { IMark } from 'screens/OKR/models/mark';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { getComplexityColors } from '../../helpers/complexityColorHelper';
import { clearRatingRoutine } from '../../routines';
import AddRatingForm from '../../containers/AddRatingForm';
import Text from 'react-texty';
import 'react-texty/styles.css';
import styles from './styles.module.scss';
import KeyResultSaveForm from '../KeyResultSaveForm';
import { ModalHeaderWithCross } from 'components/ModalHeaderWithCross';
import LinksReplacer from 'components/LinksReplacer/linksReplacer';
import { KeyResultActionType } from 'screens/OKR/enums/KeyResultActionType';
import ConfirmModal from 'components/ConfirmModal';
import { like, dislike, cross, dragAndDrop } from 'assets/icons';

export interface IOkrGridItemProps {
  onClick?: IBindingCallback1<string>;
  onSetMark: IBindingCallback1<IMark>;
  handleKREdit: IBindingCallback1<IKeyResult>;
  toggleSuggestAction: IBindingCallback1<boolean>;

  keyResult: IKeyResult;
  onRemove: IBindingAction;
  clearRating: IBindingAction;
  isOkrClosed?: boolean;
  notMarked?: boolean;
  isTriedToClose: boolean;
}

const KeyResultItem: React.FunctionComponent<IOkrGridItemProps> = ({
  keyResult: {
    id,
    name,
    complexity,
    mark = null,
    isCustom,
    details,
    keyResultType
  },
  keyResult,
  isOkrClosed,
  onRemove,
  onSetMark,
  clearRating,
  onClick,
  handleKREdit,
  isTriedToClose,
  toggleSuggestAction
}) => {
  const [displayingInput, setDisplayingInput] = useState(false);
  const [updatedMark, setUpdatedMark] = useState(mark || '');
  const [isModalOpened, setModalOpen] = useState(false);
  const [isEditModalOpened, setEditModalOpen] = useState(false);

  const validateInput = (value: any) => value >= 0 && value <= 1 && (value as string).length <= 3;

  const isDeleting = keyResult.actionType === KeyResultActionType.DeleteKeyResult;
  const isAdding = keyResult.actionType === KeyResultActionType.AddKeyResult;

  const onMarkChange = (_: SyntheticEvent, { value }) => {
    if (validateInput(value)) {
      setUpdatedMark(value);
    }
  };
  const onBlur = () => {
    setDisplayingInput(false);

    if (!updatedMark) {
      setUpdatedMark(mark || '');
      return;
    }

    onSetMark({
      mark: Math.round((updatedMark as number) * 10) / 10
    });
    setUpdatedMark('');
    setModalOpen(true);
  };

  const handleMarkEnterClick = e => {
    if (e.keyCode === 13) {
      onBlur();
    }
  };

  const handleDisplayingInput = () => {
    if (!isOkrClosed) {
      setUpdatedMark('');
      setDisplayingInput(true);
    }
  };

  const handleAction = () => {
    clearRating();
    setModalOpen(false);
  };

  const handleKeyResultEditing = (kr: IKeyResult) => {
    setEditModalOpen(false);
    handleKREdit(kr);
  };

  const MarkContainer = () => (
    <div className={styles.currentMark}>
      {mark !== null ? mark : <span className={styles.notGraded}>0/1</span>}
    </div>
  );
  const noMarkClass = isTriedToClose ? styles.closing : styles.notMarked;

  return (
    <>
      {isModalOpened ? (
        <AddRatingForm
          keyResultType={keyResultType}
          keyResultId={id}
          handleAction={handleAction}
          isOpened={isModalOpened}
        />
      ) : ''}
      <Modal
        size="tiny"
        open={isEditModalOpened}
        onClose={() => setEditModalOpen(false)}
      >
        <ModalHeaderWithCross headerName="Edit key-result" onClose={() => setEditModalOpen(false)} />
        <div className={styles.formContainer}>
          <KeyResultSaveForm
            keyResult={keyResult}
            onSave={handleKeyResultEditing}
          />
        </div>
      </Modal>
      <div className={styles.keyResultItem}>
        <div
          className={`${styles.container}
          ${isDeleting ? styles.delete : ''}
          ${isAdding ? styles.add : ''}`}
          onClick={() => onClick(id)}
          role="presentation"
        >
          <div className={styles.firstBlock}>
            <div className={styles.ellipsis}>
              <Text
                className={styles.keyResultName}
                tooltipClassName={styles.tooltip}
              >
                {name}
              </Text>
            </div>
            {details ? (
              <div className={`${styles.ellipsis} ${styles.details}`}>
                <LinksReplacer showDomain data={details} />
              </div>
            ) : ''}
          </div>
          <div className={styles.secondBlock}>
            <div
              className={styles.complexity}
            >
              <div style={getComplexityColors(complexity)} />
              {complexity}
            </div>
            <div className={styles.comments}>
              <Icon name="comment outline" size="small" />
              <div className={styles.ratings}>
                {keyResult.ratingsCount}
              </div>
            </div>
            <div className={`${mark === null ? noMarkClass : ''} ${isOkrClosed ? styles.closed : ''}`}>
              <div
                role="presentation"
                className={styles.mark}
                onClick={e => {
                  e.stopPropagation();
                  handleDisplayingInput();
                }}
              >
                {displayingInput
                  ? (
                    <Input
                      style={{ width: '45px', padding: '0px', margin: '0' }}
                      ref={ref => ref && ref.focus()}
                      value={updatedMark}
                      type="number"
                      size="mini"
                      onChange={onMarkChange}
                      onBlur={onBlur}
                      onKeyDown={handleMarkEnterClick}
                    />
                  ) : (
                    <MarkContainer />
                  )}
              </div>
            </div>
            {!isOkrClosed ? (
              <Image src={dragAndDrop} height="15px" className={styles.dragAndDrop} />
            ) : ''}
          </div>
        </div>
        {!isOkrClosed ? (
          <div className={styles.actions}>
            {![0, 1].includes(keyResult.actionType) ? (
              <>
                {isCustom ? (
                  <div className={styles.krActions}>
                    <Icon
                      name="pencil"
                      size="small"
                      onClick={e => {
                        e.stopPropagation();
                        setEditModalOpen(true);
                      }}
                    />
                  </div>
                ) : ''}
                <div className={styles.krActions}>
                  <ConfirmModal
                    header="Are you sure you want to remove key result from this OKR?"
                    description="You can not undone this action. Still it can be added from existing ones."
                    onConfirm={onRemove}
                  >
                    <Image src={cross} />
                  </ConfirmModal>
                </div>
              </>
            ) : (
              <div className={styles.suggestActions}>
                <Image
                  src={like}
                  title="Accept suggestion"
                  onClick={e => {
                    e.stopPropagation();
                    toggleSuggestAction(true);
                  }}
                />
                <Image
                  src={dislike}
                  title="Reject suggestion"
                  onClick={e => {
                    e.stopPropagation();
                    toggleSuggestAction(false);
                  }}
                />
              </div>
            )}
          </div>
        ) : ''}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  clearRating: clearRatingRoutine
};

export default connect(null, mapDispatchToProps)(KeyResultItem);

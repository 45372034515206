import { call, put, all, takeEvery } from 'redux-saga/effects';
import { fetchAllRequestsWithUserAndDaysRoutine, updateRequestStatusRoutine } from 'screens/TimeOffTracker/routines';
import { Routine } from 'redux-saga-routines';
import * as timeOffService from '../../services/timeOffService';

function* getNewRequests() {
  try {
    const response = yield call(timeOffService.getNewRequestsWithUserAndDays);

    yield put(fetchAllRequestsWithUserAndDaysRoutine.success(response));
  } catch (error) {
    yield put(fetchAllRequestsWithUserAndDaysRoutine.failure(error.message));
  }
}

function* updateRequestStatus({ payload }: Routine<any>) {
  try {
    const response = yield call(timeOffService.updateRequestStatus, payload);

    yield put(updateRequestStatusRoutine.success(response));
  } catch (error) {
    yield put(updateRequestStatusRoutine.failure(error.message));
  }
}

function* watchGetNewRequests() {
  yield takeEvery(fetchAllRequestsWithUserAndDaysRoutine.TRIGGER, getNewRequests);
}

function* watchUpdateRequestStatus() {
  yield takeEvery(updateRequestStatusRoutine.TRIGGER, updateRequestStatus);
}

export default function* timeOffAdminPageRequestsSaga() {
  yield all([
    watchGetNewRequests(),
    watchUpdateRequestStatus()
  ]);
}

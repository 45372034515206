import { NoDataContainer } from 'components/NoDataContainer';
import React from 'react';
import { FormInList } from 'screens/Feedback/components/FormInList';
import FormTemplate from 'screens/Feedback/components/FormTemplate';
import RequestAssign from 'screens/Feedback/components/RequestAssign';
import { IForm } from 'screens/Feedback/model/Forms/IForm';
import QuestionsBlocks from '../QuestionsBlocks';
import styles from './styles.module.scss';

export interface IFormTemplateSettings {
  title: string;
  formChanged?: (form: IForm) => void;
  formSavedTemplateHandler?: (form: IForm) => void;
  formSavedHandler?: (form: IForm) => void;
  formCanceledHandler?: () => void;
}

function getFormTemplate(settings: IFormTemplateSettings) {
  return (
    <div className={styles.formActionContainer}>
      <FormTemplate
        title={settings.title}
        formSaved={form => settings.formSavedHandler(form)}
        formCanceled={() => settings.formCanceledHandler()}
        formSavedTemplate={form => settings.formSavedTemplateHandler(form)}
      />
    </div>
  );
}

function getFormListTemplate(
  forms: IForm[],
  selectFormHandler: (formId: string) => void,
  deleteFormHandler: (formId: string) => void,
  selectedForm?: IForm
) {
  return (
    <>
      {
        forms.map(form => (
          <FormInList
            key={form.id}
            form={form}
            isSelected={form.id === selectedForm?.id}
            onClickHandler={selectFormHandler}
            onDeleteHandler={deleteFormHandler}
          />
        ))
      }
    </>
  );
}

function getActionFromIsEdit(
  isEditing: boolean,
  editSettings: IFormTemplateSettings,
  selectedForm?: IForm
) {
  return (
    <>
      {isEditing
        ? (
          <div className={styles.formActionAndQuestionsBlocksContainer}>
            { getFormTemplate(editSettings)}
            {selectedForm
              && (
                <>
                  <QuestionsBlocks formId={selectedForm?.id} />
                  <RequestAssign />
                </>
              )}
          </div>
        ) : (
          <div className={`${styles.fullHeightData} fullWidthData`}>
            <NoDataContainer text="There is not form choosed ." />
          </div>
        )}
    </>
  );
}

export function getEmptyFormListPageTemplate(
  isCreating: boolean,
  settings: IFormTemplateSettings
) {
  return (
    isCreating
      ? (
        getFormTemplate(settings)
      )
      : (
        <div className={`${styles.fullHeightData} fullWidthData`}>
          <NoDataContainer text="There are no forms... Maybe its time to create a new one?" />
        </div>
      )
  );
}

export function getFormListPageTemplate(
  isCreating: boolean,
  isEditing: boolean,
  forms: IForm[],
  createSettings: IFormTemplateSettings,
  editSettings: IFormTemplateSettings,
  selectFormHandler: (formId: string) => void,
  deleteFormHandler: (formId: string) => void,
  selectedForm?: IForm
) {
  return (
    <div className={`${styles.formListAndActionContainer} ${styles.fullHeightData}`}>
      <div className={styles.formListContainer}>
        {
          getFormListTemplate(forms, selectFormHandler, deleteFormHandler, selectedForm)
        }
      </div>
      <div className={styles.formContainer}>
        {isCreating
          ? getFormTemplate(createSettings)
          : getActionFromIsEdit(isEditing, editSettings, selectedForm)}
      </div>
    </div>
  );
}

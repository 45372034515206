import React, { useState, useEffect } from 'react';
import { IBindingAction } from 'models/callback/Callbacks';
import { fetchUserRoutine } from 'screens/Authorization/routines/index';
import { connect } from 'react-redux';
import { IUser } from 'screens/Authorization/models/IUser';
import { Icon, SemanticICONS, Popup } from 'semantic-ui-react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import Routing from 'containers/Routing';
import { withRouter } from 'react-router-dom';
import styles from './styles.module.scss';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { Role } from 'screens/Authorization/models/role';

export interface ISideNavbarState {
  expanded: boolean;
  currentItem: string;
}

export interface ISideNavbarProps {
  fetchUser: IBindingAction;
  isAuthorized: boolean;
  history: any;
  location: Location;
  user: IUser;
}

interface IMenuItem {
  name: string;
  link: string;
  icon: SemanticICONS;
  text: string;
  roles?: Role[];
  pathNameRegex: RegExp;
}

const sideBarMenuItems: IMenuItem[] = [
  {
    name: 'news',
    link: 'news',
    icon: 'newspaper',
    text: 'News',
    pathNameRegex: /\/news\/*/
  },
  {
    name: 'team',
    link: 'team',
    icon: 'group',
    text: 'Team',
    pathNameRegex: /\/team\/*/
  },
  {
    name: 'okr',
    link: 'okr/list',
    icon: 'target',
    text: 'OKR',
    pathNameRegex: /\/okr\/*/
  },
  {
    name: 'users',
    link: 'users',
    icon: 'sliders horizontal',
    text: 'Admin panel',
    roles: [Role.Administrator, Role.TopManager, Role.HR, Role.Manager],
    pathNameRegex: /\/users\/*/
  }
  // {
  //   name: 'feedback',
  //   link: 'feedback',
  //   icon: 'comment alternate',
  //   text: 'Feedback',
  //   roles: [Role.Administrator, Role.TopManager, Role.HR, Role.Manager, Role.Developer],
  //   pathNameRegex: /\/feedback\/*/
  // }
];

const SideNavbar: React.FunctionComponent<ISideNavbarProps> = ({
  fetchUser,
  isAuthorized,
  history,
  location,
  user
}: ISideNavbarProps) => {
  const [expanded, setExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState('news');

  useEffect(() => { fetchUser(); }, []);

  useEffect(() => {
    const active = sideBarMenuItems.find(i => location.pathname.match(i.pathNameRegex));
    setActiveItem(active?.name);
  }, [location.pathname]);

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };

  const handleMenuClick = (selected: string) => {
    const menuItem = sideBarMenuItems.find(item => item.name === selected);
    const breakpointMobile = 768;

    const to = `/${menuItem.link}`;
    if (to !== location.pathname) {
      history.push(to);
    }

    if (window.innerWidth <= breakpointMobile) {
      toggleSidebar();
    }
  };

  const renderMenuItem = ({ name, icon, text }: IMenuItem) => (
    <NavItem eventKey={name} key={name}>
      <NavIcon>
        <Popup
          disabled={expanded}
          content={text}
          position="right center"
          trigger={(
            <div className={styles.iconWrapper}>
              <Icon name={icon} className={styles.iconStyle} />
            </div>
          )}
        />
      </NavIcon>
      <NavText>
        <span className={styles.navText}>
          {text}
        </span>
      </NavText>
    </NavItem>
  );

  const availableMenuItems = user
    ? sideBarMenuItems.filter(item => !item.roles || item.roles.some(role => user.roles.some(r => r === role)))
    : [];

  // const timeoff = user
  // && (user.roles.includes('Manager')
  // || user.roles.includes('Administrator'))
  //   ? renderMenuItem('timeoff', 'calendar times', 'Timeoff')
  //   : '';

  // const projects = user && user.roles.includes('Administrator')
  //   ? renderMenuItem('projects', 'tasks', 'Projects')
  //   : '';

  const insideStyle = expanded ? styles.expanded : styles.collapsed;
  const mainStyle = isAuthorized ? insideStyle : styles.none;
  return (
    <>
      { isAuthorized ? (
        <div className={expanded ? styles.sideNavExpanded : styles.sideNavCollapsed}>
          <SideNav
            expanded={expanded}
            onToggle={toggleSidebar}
            onSelect={handleMenuClick}
          >
            <SideNav.Toggle className={styles.navToggle} />
            <SideNav.Nav selected={activeItem} className={styles.sideNavigation}>
              {availableMenuItems.map(item => renderMenuItem(item))}
              {/* {projects}
              {timeoff} */}
            </SideNav.Nav>
          </SideNav>
        </div>
      ) : '' }
      <main className={mainStyle}>
        <Routing />
      </main>
    </>
  );
};

const mapDispatchToProps = {
  fetchUser: fetchUserRoutine
};

const mapToStateProps = state => {
  const { user: { isLoading, isAuthorized, user }, path } = state;
  return {
    isLoading,
    isAuthorized,
    user,
    path
  };
};

export default connect(mapToStateProps, mapDispatchToProps)(withRouter(SideNavbar));

import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, Modal, Button, Icon, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { importFeedbacksCsvRoutine } from '../../routines';
import { IBindingAction, IBindingCallback1 } from '../../../../models/callback';
import { fetchAdminUserInfoRoutine } from '../../../AdminPage/routines';
import { IAdminUserInfo } from '../../../AdminPage/model/IAdminUserInfo';
import { Routine } from 'redux-saga-routines';
import styles from './styles.module.scss';
import EllipsisText from 'react-ellipsis-text';

interface ICsvImportButton {
  importCsv?: IBindingCallback1<Routine<any>>;
  fetchAdminUserInfo?: IBindingAction;
  fetchRequests: IBindingAction;
  setRequest: IBindingCallback1<string>;
  users?: IAdminUserInfo[];
  creatorId: string;
  requestId?: string;
}

const CsvImportButton = ({ importCsv, fetchAdminUserInfo, users, creatorId,
  requestId, setRequest }: ICsvImportButton) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(undefined);
  const [userId, setUserId] = useState(undefined);
  const [isReady, setIsReady] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchAdminUserInfo();
  }, []);
  useEffect(() => {
    if (requestId !== null && isReady) { setRequest(requestId); }
  }, [requestId]);

  useEffect(() => {
    if (userId !== undefined && file !== undefined) {
      setIsReady(true);
    }
  }, [file, userId]);

  const onClose = () => {
    setUserId(undefined);
    setFile(undefined);
    setOpen(false);
  };

  const uploadCsv = (csv: FileList): void => {
    if (!csv.length) {
      return;
    }
    const fileCvs = new FormData();
    fileCvs.append('file', csv[0]);
    setFile(fileCvs);
  };

  const importFile = () => {
    if (isReady) {
      importCsv({ userId, file, creatorId });
      onClose();
    }
  };

  const userOptions = users?.map(user => ({ key: user.id, text: user.fullName, value: user.id, image: user.logoLink }));
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={<Button primary>Import feedbacks csv</Button>}
    >
      <Modal.Header>Import feedbacks csv</Modal.Header>
      <Modal.Content>
        <input
          ref={fileInputRef}
          type="file"
          hidden
          onChange={e => uploadCsv(e.target.files)}
        />
        <Form>
          <Form.Group>
            <Form.Dropdown
              placeholder="Select user"
              fluid
              width={9}
              search
              selection
              options={userOptions}
              onChange={(e, o) => setUserId(o.value)}
            />
            <Form.Field width={7}>
              <Button
                icon
                labelPosition="right"
                primary
                className={styles.btnUpload}
                onClick={() => fileInputRef.current.click()}
              >
                {file ? <EllipsisText text={file?.get('file').name} length="20" /> : 'Upload file'}
                <Icon name="file alternate outline" />
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          labelPosition="right"
          icon="remove"
          onClick={() => onClose()}
        />
        <Button
          content="Import"
          labelPosition="right"
          icon="checkmark"
          disabled={!isReady}
          onClick={() => importFile()}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { adminPage: { userList } } = state;
  const { feedbackPage: { importCsv: { requestId } } } = state;
  const { user: { user: { id } } } = state;
  return {
    users: userList?.users,
    creatorId: id,
    requestId
  };
};

const mapDispatchToProps = {
  importCsv: importFeedbacksCsvRoutine,
  fetchAdminUserInfo: fetchAdminUserInfoRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(CsvImportButton);

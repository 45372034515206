import { callOkrApi } from 'helpers/webApiHelper';
import { IUserOkrStatistics } from '../models/okrs/IUserOkrStatistics';
import { IUserOkrStats } from '../../OKR/models/okr/IUserOkrStats';

export const uploadOKRStats = async (userId: string): Promise<IUserOkrStatistics> => {
  const response = await callOkrApi({
    endpoint: `/api/users/${userId}/statistic`,
    type: 'GET'
  });
  return response.json();
};

export const getOkrs = async (userId: string): Promise<IUserOkrStats[]> => {
  const response = await callOkrApi({
    endpoint: `/api/users/${userId}/okrstats`,
    type: 'GET'
  });
  return response.json();
};

import { createRoutine } from 'redux-saga-routines';

export const fetchUserRoutine = createRoutine('FETCH_USER');
export const removeUserRoutine = createRoutine('REMOVE_USER');
export const logInRoutine = createRoutine('LOG_IN');

export const resetPasswordRoutine = createRoutine('RESET_PASSWORD');
export const checkCodeRoutine = createRoutine('CHECK_PASSWORD_CODE');
export const updatePasswordRoutine = createRoutine('UPDATE_PASSWORD_AFTER_RESETING');
export const resendEmailConfirmationLinkRoutine = createRoutine('RESEND_EMAIL_CONFIRMATION_LINK');

export const registerRoutine = createRoutine('REGISTER_USER');
export const confirmEmailRoutine = createRoutine('CONFIRM_EMAIL');

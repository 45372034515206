import React, { FunctionComponent } from 'react';
import { Modal, Image } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { cross } from 'assets/icons';

export interface IModalHeaderWithCrossSettigns {
  onClose: Function;
  headerName: string;
}

export const ModalHeaderWithCross: FunctionComponent<IModalHeaderWithCrossSettigns> = ({
  onClose,
  headerName
}: IModalHeaderWithCrossSettigns) => (
  <Modal.Header className={styles.formHeader}>
    {headerName}
    <Image src={cross} onClick={() => onClose()} className={styles.icon} />
  </Modal.Header>
);

import { Routine } from 'redux-saga-routines';
import { importFeedbacksCsvRoutine } from '../../routines';

export interface IUploadingRequestFileState {
  uploading: boolean;
  downloading: boolean;
  requestId: string;
}
export const importCsv = (
  uploadState: IUploadingRequestFileState = { uploading: false, downloading: false, requestId: null },
  action: Routine<any>
): IUploadingRequestFileState => {
  switch (action.type) {
    case importFeedbacksCsvRoutine.TRIGGER:
      return { ...uploadState, uploading: true };
    case importFeedbacksCsvRoutine.SUCCESS:
      return { ...uploadState, uploading: false, requestId: action.payload.requestId };
    case importFeedbacksCsvRoutine.FAILURE:
      return { ...uploadState, uploading: false };
    default:
      return uploadState;
  }
};

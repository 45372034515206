import React from 'react';
import { connect } from 'react-redux';
import { Menu, Loader } from 'semantic-ui-react';
import { IUserInfo } from 'screens/UserProfile/models/IUserInfo';
import { IProject } from 'screens/Projects/model/IProject';
import { IBindingCallback1 } from 'models/callback';
import { fetchUserOKRsRoutine } from '../../routines/index';
import { UserProfileTab } from '../tabs/userProfileTab';
import { IUserInfoState } from '../Info/reducer';
import styles from './styles.module.scss';
import Reviews from '../Reviews';
import OKRTab from '../tabs/okrTab';
import { IOkr } from '../../../OKR/models/okr';
// import FeedbackTab from '../tabs/feedbackTab';

export interface IDetailsState {
  activeItem: string;
  loadingOkrTab?: boolean;
}

export interface IDetailsProps {
  userInfo: IUserInfo;
  projects: IProject[];
  userOkrStats?: IOkr[];
  fetchUserOKRs: IBindingCallback1<string>;
}

const menuItem = (name: string, activeItem: string, clickHandler: (e, name) => void) => (
  <Menu.Item
    key={name}
    name={name}
    className={styles.menuItem}
    active={activeItem === name}
    onClick={(e, props) => clickHandler(e, props.name)}
  />
);

class Details extends React.Component<IDetailsProps, IDetailsState> {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'Profile'
      // loadingOkrTab: false
    };
  }

  handleItemClick = (e, name) => {
    this.setState({ activeItem: name });
    if (name === 'OKR' && !this.props.userOkrStats) {
      this.props.fetchUserOKRs(this.props.userInfo.id);
    }
  }

  render() {
    const { activeItem } = this.state;
    const menu = ['Profile', 'Reviews', 'OKR']; // , 'Feedback']; // , 'OKR', 'Time-off tracker'];
    const { userInfo, projects } = this.props;

    return userInfo ? (
      <div className={styles.menuBlock}>
        <Menu pointing secondary className={styles.menu}>
          <div className={styles.menuWrapper}>
            {menu.map(item => menuItem(item, activeItem, this.handleItemClick))}
          </div>
        </Menu>
        {activeItem === 'Profile'
          ? <UserProfileTab userInfo={userInfo} projects={projects} />
          : ''}
        {activeItem === 'Reviews'
          ? <Reviews />
          : ''}
        {activeItem === 'OKR'
          ? <OKRTab />
          : ''}
        {/* {activeItem === 'Time-off tracker'
          ? <TimeOffTrackerTab userId={userInfo.id} />
          : ''} */}
        {/* {activeItem === 'Feedback'
          ? <FeedbackTab />
          : ''} */}
      </div>
    ) : <Loader active />;
  }
}

const mapStateToProps = state => {
  const { user: { user }, userProfile: { userInfo } } = state;
  const userInf = userInfo as IUserInfoState;
  return {
    user,
    userInfo: userInf?.userInfo,
    projects: userInf?.projects,
    userOkrStats: userInf?.userOkrs,
    loadingOkrTab: userInf?.loadingOkrTab
  };
};

const mapDispatchToProps = {
  fetchUserOKRs: fetchUserOKRsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);

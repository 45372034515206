/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import { Header, Dimmer, Comment, Icon, TextArea } from 'semantic-ui-react';
import moment from 'moment';
import { IGlobalState } from 'models/global-state';
import { IBindingCallback1 } from 'models/callback';
import { IRating } from 'screens/OKR/models/rating/IRating';
import { IKeyResultExtended } from 'screens/OKR/models/key-result/IKeyResultExtended';
import { fetchExtendedKeyResultRoutine, updateCommentRoutine, toggleEditComment } from '../../routines';
import styles from './styles.module.scss';
import LinksReplacer from 'components/LinksReplacer/linksReplacer';
import Avatar from 'components/UserAvatar';
import LoaderWrapper from 'components/LoaderWrapper';
import ConfirmModalContent from 'components/ConfirmModalContent';

export interface ICommentUpdateData {
  id: string;
  comment: string;
  keyResultId: string;
}

interface IKeyResultDataProps {
  userId: string;
  keyResultId: string;
  fetchExtendedKeyResult: IBindingCallback1<string>;
  loading: boolean;
  error?: string;
  extendedKeyResult: IKeyResultExtended;
  updateComment: IBindingCallback1<ICommentUpdateData>;
  toggleEditComment: IBindingCallback1<boolean>;
}

interface IKeyResultDataState {
  commentEditingId: string;
  commentDeleteModalOpened: boolean;
  commentEditing: boolean;
}

class KeyResultData extends React.Component<IKeyResultDataProps, IKeyResultDataState> {
  constructor(props) {
    super(props);
    this.state = {
      commentDeleteModalOpened: false,
      commentEditingId: null,
      commentEditing: false
    };
  }

  componentDidMount() {
    const { keyResultId, fetchExtendedKeyResult: ftExtendedKeyResult } = this.props;
    ftExtendedKeyResult(keyResultId);
  }

  toggleCommentDeleteModal = () => this.setState(prevState => (
    { commentDeleteModalOpened: !prevState.commentDeleteModalOpened }
  ));

  updateComment = (id: string, comment = null) => {
    const { keyResultId } = this.props;
    this.props.updateComment({ id, comment, keyResultId });
    this.setState({ commentEditingId: null, commentEditing: false });
  }

  startRatingUpdating = (id: string, commentEditing = true) => {
    this.setState({ commentEditingId: id, commentEditing });
    this.props.toggleEditComment(true);
  }

  deleteComment = (id: string) => {
    this.startRatingUpdating(id, false);
    this.toggleCommentDeleteModal();
  }

  confirmDeleteComment = () => {
    this.toggleCommentDeleteModal();
    this.updateComment(this.state.commentEditingId);
  }

  commentOnKeyPress = e => {
    const { toggleEditComment } = this.props;
    if (e.shiftKey && e.keyCode === 13) {
      e.target.value += '\n';
      e.preventDefault();
    } else if (e.keyCode === 13) {
      this.updateComment(this.state.commentEditingId, e.target.value);
      toggleEditComment(false);
    } else if (e.keyCode === 27) {
      this.setState({ commentEditing: false });
      toggleEditComment(false);
    }
  }

  handleBlurTextArea = e => {
    this.updateComment(this.state.commentEditingId, e.target.value);
    this.props.toggleEditComment(false);
  }

  renderComment = ({ id, user, createdAt, comment }: IRating, isCommentEditable: boolean, isEditing: boolean) => (
    <Comment style={{ textAlign: 'justify', paddingRight: '1rem' }} key={id} className={styles.comment}>
      <div className={styles.logo}>
        <Avatar name={`${user.firstName} ${user.lastName}`} logoLink={user.logoLink} />
      </div>
      <Comment.Content>
        <Comment.Author className={styles.author}>
          <p>{`${user.firstName} ${user.lastName}`}</p>
          {isCommentEditable && (
            <div className={styles.icons}>
              <Icon
                name="pencil"
                onClick={() => this.startRatingUpdating(id)}
              />
              <Icon name="delete" onClick={() => this.deleteComment(id)} />
            </div>
          )}
        </Comment.Author>
        <Comment.Metadata style={{ marginLeft: 0 }}>
          {moment(createdAt).format('L')}
        </Comment.Metadata>
        <Comment.Text>
          {!isEditing
            ? comment
            : (
              <TextArea
                className={styles.textarea}
                ref={ref => ref && ref.focus()}
                onKeyUp={this.commentOnKeyPress}
                defaultValue={comment}
                onBlur={this.handleBlurTextArea}
              />
            )}
        </Comment.Text>
      </Comment.Content>
    </Comment>
  )

  renderComments() {
    const { extendedKeyResult: { ratings }, userId } = this.props;
    const { commentEditingId, commentEditing } = this.state;
    const isCommentEditing = commentId => commentEditing && commentEditingId === commentId;
    const isCommentEditAvailable = (id, createdAt) => (
      userId === id && moment.duration(moment().diff(createdAt)).asMinutes() < 5);

    return (
      <Comment.Group className={styles.commentsList}>
        {ratings.map((rating: IRating) => (
          rating.comment && (
            this.renderComment(
              rating, isCommentEditAvailable(rating.user.id, rating.createdAt), isCommentEditing(rating.id)
            )
          )
        ))}
      </Comment.Group>
    );
  }

  render() {
    const { extendedKeyResult, loading, error } = this.props;
    const { commentDeleteModalOpened } = this.state;
    const { name, keyResultType, details, tags } = extendedKeyResult;

    const { likes, disLikes } = extendedKeyResult?.ratings?.reduce((acc, rating) => {
      if (rating.isLike) acc.likes += 1;
      if (rating.isDislike) acc.disLikes += 1;
      return acc;
    }, { likes: 0, disLikes: 0 }) ?? { likes: 0, disLikes: 0 };

    const comments = extendedKeyResult?.ratings?.filter(
      (rating: IRating) => rating.comment
    ) ?? [];

    if (error) {
      return (
        <Dimmer active inverted>
          <p className={styles.errorMessage}>
            Something went wrong! Please try again later
          </p>
        </Dimmer>
      );
    }
    return (
      extendedKeyResult && (
        <div className={styles.container}>
          <LoaderWrapper loading={loading}>
            <ConfirmModalContent
              header="Would you like to delete this comment?"
              description="This action cannot be undone!"
              opened={commentDeleteModalOpened}
              onCancel={this.toggleCommentDeleteModal}
              onConfirm={this.confirmDeleteComment}
            />
            <p className={styles.header}>
              {name}
            </p>
            <div className={styles.details}>
              <LinksReplacer showDomain data={details} />
            </div>
            <div className={styles.tagsContainer}>
              {tags
                && tags.map((tag, index) => (
                  <span key={tag.id}>{`${tag.name}${index !== tags.length - 1 ? ',' : ''} `}</span>
                ))}
            </div>
            <div className={styles.contentWrapper}>
              <p>
                {`Type: ${keyResultType}`}
              </p>
              <div>
                <div>
                  <b>{likes}</b>
                  <Icon name="thumbs up outline" />
                </div>
                <div>
                  <b>{disLikes}</b>
                  <Icon name="thumbs down outline" />
                </div>
              </div>
            </div>
            {comments.length ? (
              <div>
                <Header
                  as="h3"
                  textAlign="left"
                  className={styles.commentsHeader}
                >
                  Comments:
                </Header>
                {this.renderComments()}
              </div>
            ) : (
              <p className={styles.commentsMessage}>No comments</p>
            )}
          </LoaderWrapper>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const {
    user: { user: { id: userId } },
    okr: {
      extendedKeyResult,
      requests: {
        extendedKeyResult: { loading, error }
      }
    }
  } = state;

  return {
    userId,
    extendedKeyResult: extendedKeyResult ?? {},
    loading,
    error
  };
};

const mapDispatchToProps = {
  fetchExtendedKeyResult: fetchExtendedKeyResultRoutine,
  toggleEditComment,
  updateComment: updateCommentRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyResultData);

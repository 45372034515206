import { combineReducers } from 'redux';

import { reducerCreator } from 'helpers/reducerHelper';

import { announcements } from '../containers/AnnouncementsList/reducer';
import { announcementDetails } from '../containers/AnnouncementItemDetails/reducer';

import {
  fetchAnnouncementsRoutine,
  fetchAnnouncementByIdRoutine
} from '../routines';

const requests = combineReducers({
  announcements: reducerCreator([fetchAnnouncementsRoutine.TRIGGER]),
  announcementForUpdate: reducerCreator([fetchAnnouncementByIdRoutine.TRIGGER]),
  announcementDetails: reducerCreator([fetchAnnouncementByIdRoutine.TRIGGER])
});

export default combineReducers({
  announcementDetails,
  announcements,
  requests
});

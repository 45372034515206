import { Routine } from 'redux-saga-routines';
import { IOkrExtended } from 'screens/OKR/models/okr';
import { IObjective } from '../../models/objective';
import { IKeyResult } from '../../models/key-result';
import {
  fetchExtendedOkr,
  removeKeyResultFromOkr,
  addKeyResultToOkr,
  saveMark,
  addObjectiveToOkr,
  removeObjectiveFromOkr,
  saveObjectiveRoutine,
  saveKeyResultRoutine,
  updateOKRStatusRoutine,
  saveOkrRoutine,
  updateRatingRoutine,
  moveKeyResultRoutine,
  confirmKeyResultSuggestionRoutine,
  updateCommentRoutine
} from '../../routines';

export const extendedOkr = (state: IOkrExtended = null, action: Routine<any>): IOkrExtended => {
  const currentState = { ...state };
  const { objectives } = currentState;
  let mappedObjectives: IObjective[];

  switch (action.type) {
    case fetchExtendedOkr.SUCCESS:
      return action.payload;
    case removeObjectiveFromOkr.SUCCESS:
      mappedObjectives = objectives.filter(({ id }) => action.payload.objectiveId !== id);
      return {
        ...state,
        objectives: mappedObjectives
      };
    case removeKeyResultFromOkr.SUCCESS:
      mappedObjectives = objectives.map((obj: IObjective) => (obj.id === action.payload.objectiveId
        ? {
          ...obj,
          keyResults: obj.keyResults.filter(({ id }) => action.payload.keyResultId !== id)
        }
        : obj
      ));

      return {
        ...state,
        objectives: mappedObjectives
      };
    case addKeyResultToOkr.SUCCESS:
      const { keyResults: keyResultsToAdd } = action.payload;
      mappedObjectives = objectives.map((obj: IObjective) => (obj.id === action.payload.objectiveId
        ? {
          ...obj,
          keyResults: obj.keyResults.concat(keyResultsToAdd.map(kr => ({
            ...kr,
            mark: null,
            ratingsCount: kr.ratings.filter(r => r.comment).length,
            ratings: undefined
          })))
        }
        : obj
      ));

      return {
        ...state,
        objectives: mappedObjectives
      };
    case saveOkrRoutine.SUCCESS:
      const { startTime, endTime, name: okrName, description, id: changedOkrId, isSabbatical } = action.payload;
      if (state?.id !== changedOkrId) return state;
      return { ...state, startTime, endTime, name: okrName, description, isSabbatical };
    case saveMark.SUCCESS:
      const { keyResultId, objectiveId, mark: updatedMark } = action.payload;
      const updatedObjective = objectives.find((objective: IObjective) => objective.id === objectiveId);

      const keyResultWithUpdatedMark = updatedObjective.keyResults.find(({ id }) => id === keyResultId);

      keyResultWithUpdatedMark.mark = updatedMark;

      const mapKeyResults = (keyResults: IKeyResult[]) => (
        keyResults.map(kr => (kr.id === keyResultId
          ? keyResultWithUpdatedMark
          : kr
        ))
      );

      const mapObjectives = (obj: IObjective) => {
        if (obj.id !== objectiveId) return obj;

        if (obj.keyResults.map(({ id }) => id).includes(keyResultId)) {
          const mappedKeyResults = mapKeyResults(obj.keyResults);

          return {
            ...obj,
            keyResults: mappedKeyResults
          };
        }

        return obj;
      };

      mappedObjectives = objectives.map(mapObjectives);

      return {
        ...state,
        objectives: mappedObjectives
      };
    case updateOKRStatusRoutine.SUCCESS:
      return {
        ...state,
        isClosed: !state.isClosed
      };
    case addObjectiveToOkr.SUCCESS:
      const { id, name, keyResults = [], isCustom } = action.payload;
      mappedObjectives = objectives.concat({ id, name, keyResults, isCustom });

      return {
        ...state,
        objectives: mappedObjectives
      };
    case saveObjectiveRoutine.SUCCESS:
      const objective = action.payload;
      return { ...state, objectives: [...objectives.map(obj => (obj.id === objective.id ? objective : obj))] };
    case saveKeyResultRoutine.SUCCESS:
      const keyResult = action.payload;
      return {
        ...state,
        objectives: [...objectives.map(obj => (
          { ...obj, keyResults: obj.keyResults.map(kr => (kr.id === keyResult.id ? keyResult : kr)) }
        ))]
      };
    case updateRatingRoutine.SUCCESS:
      const { count } = action.payload;
      const { id: krId } = action.payload;
      return {
        ...state,
        objectives: [
          ...objectives.map(obj => (
            {
              ...obj,
              keyResults: obj.keyResults.map(kr => (
                kr.id === krId
                  ? { ...kr, ratingsCount: count }
                  : kr
              ))
            }
          ))
        ]
      };
    case moveKeyResultRoutine.SUCCESS:
      const { fromObjectiveId, toObjectiveId, keyResultId: mvKeyResultId, toIndex } = action.payload;
      const mvObjectives = state.objectives;
      const mvkeyResultData = mvObjectives
        .find(obj => obj.id === fromObjectiveId)
        .keyResults
        .find(kr => kr.id === mvKeyResultId);
      const addElementToTheMiddle = (
        array: any[],
        element: any,
        index: number
      ) => [...array.slice(0, index), element, ...array.slice(index)];
      return {
        ...state,
        objectives: mvObjectives.map(obj => {
          if (fromObjectiveId !== toObjectiveId) {
            switch (obj.id) {
              case fromObjectiveId:
                return { ...obj, keyResults: obj.keyResults.filter(kr => kr.id !== mvKeyResultId) };
              case toObjectiveId:
                return { ...obj, keyResults: addElementToTheMiddle(obj.keyResults, mvkeyResultData, toIndex - 1) };
              default:
                return obj;
            }
          }
          if (obj.id === fromObjectiveId) {
            return { ...obj,
              keyResults: addElementToTheMiddle(
                obj.keyResults.filter(kr => kr.id !== mvKeyResultId),
                mvkeyResultData,
                toIndex - 1
              )
            };
          }
          return obj;
        })
      };
    case confirmKeyResultSuggestionRoutine.SUCCESS:
      const { keyResultId: confrimSuggestionKRId, objectiveId: confrimSuggestionObjId, isAgreed } = action.payload;
      const suggestedKeyResult = state.objectives
        .find(o => o.id === confrimSuggestionObjId)
        .keyResults
        .find(kr => kr.id === confrimSuggestionKRId);

      if (suggestedKeyResult.actionType + isAgreed === 1) {
        return {
          ...state,
          objectives: state.objectives.map(obj => (obj.id === confrimSuggestionObjId ? {
            ...obj,
            keyResults: obj.keyResults.filter(kr => kr.id !== confrimSuggestionKRId)
          } : obj))
        };
      }
      return {
        ...state,
        objectives: state.objectives.map(obj => (obj.id === confrimSuggestionObjId ? {
          ...obj,
          keyResults: obj.keyResults
            .map(kr => (kr.id === confrimSuggestionKRId ? { ...kr, actionType: null } : kr))
        } : obj))
      };
    case updateCommentRoutine.SUCCESS:
      const isDeleting = !action.payload.comment;

      return !isDeleting
        ? state
        : {
          ...state,
          objectives: state.objectives.map(obj => ({
            ...obj,
            keyResults: obj.keyResults.map(kr => (
              kr.id === action.payload.keyResultId
                ? { ...kr, ratingsCount: kr.ratingsCount - 1 }
                : kr
            ))
          }))
        };
    default:
      return state;
  }
};

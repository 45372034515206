import { createRoutine } from 'redux-saga-routines';

export const fetchProjectsRoutine = createRoutine('FETCH_PROJECTS');
export const fetchProjectsDataRoutine = createRoutine('FETCH_PROJECTS_DATA');
export const fetchProjectRoutine = createRoutine('FETCH_PROJECT');
export const saveProjectRoutine = createRoutine('SAVE_PROJECT');
export const deleteProjectRoutine = createRoutine('DELETE_PROJECT');

export const deleteDocumentRoutine = createRoutine('DELETE_DOCUMENT');
export const fetchProjectDocumentsRoutine = createRoutine('FETCH_DOCUMENTS');

export const closeModalRoutine = createRoutine('CLOSE_MODAL');

export const clearProjectPageStateRoutine = createRoutine('CLEAR_PROJECTS_PAGE_STATE');

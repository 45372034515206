import { createRoutine } from 'redux-saga-routines';

export const fetchNotificationsRoutine = createRoutine('NOTIFICATIONS');

export const fetchMoreNotificationsRoutine = createRoutine('MORENOTIFICATIONS');

export const readNotificationsRoutine = createRoutine('READNOTIFICATIONS');

export const updateNotificationsRoutine = createRoutine('UPDATE_COUNTER');

export const increaseCounterRoutine = createRoutine('INCREASECOUNTER');

import React from 'react';
import RegisterForm from 'screens/Authorization/containers/RegisterForm';
import styles from '../../containers/LoginPage/styles.module.scss';
import PageLayout from '../PagesLayout';
import { history } from 'helpers/history';
import { Button } from 'semantic-ui-react';

export interface ILoginPageProps {
  loading: boolean;
}

export const RegisterPage: React.FunctionComponent<ILoginPageProps> = ({
  loading
}: ILoginPageProps) => (
  <PageLayout loading={loading}>
    <p className="header">
      Register
    </p>
    <div className={styles.form}>
      <RegisterForm />
    </div>
    <p className={styles.redirect}>
      <span>Already have an account? </span>
      <Button onClick={() => history.push('/login')} content="Login" />
    </p>
  </PageLayout>
);

export default RegisterPage;

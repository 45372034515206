import { Routine } from 'redux-saga-routines';
import { IQuestion } from '../../model/Questions/IQuestion';
import {
  questionsBlockDeleteRoutine,
  questionsBlockCreateRoutine,
  questionsBlockUpdateRoutine,
  fetchQuestionsBlocksRoutine,
  selectedQuestionsBlockChangedRoutine,
  questionsBlockReorderRoutine
} from '../../routines';
import { IQuestionBlock } from 'screens/Feedback/model/Questions/IQuestionBlock';
import { toastr } from 'react-redux-toastr';

export interface ITemplateState {
  questionsBlocks: IQuestionBlock[];
  selectedQuestionsBlock: IQuestionBlock;
}

const initialState: ITemplateState = {
  questionsBlocks: [],
  selectedQuestionsBlock: null
};

export const questionsBlocks = (
  state = initialState,
  action: Routine<any>
): ITemplateState => {
  switch (action.type) {
    case selectedQuestionsBlockChangedRoutine.SUCCESS: {
      return {
        ...state, selectedQuestionsBlock: action.payload
      };
    }
    case fetchQuestionsBlocksRoutine.SUCCESS: {
      return {
        ...state,
        questionsBlocks: action.payload
      };
    }
    case questionsBlockUpdateRoutine.SUCCESS: {
      return {
        ...state,
        questionsBlocks: [
          ...state.questionsBlocks.filter(b => b.id !== action.payload.id),
          action.payload
        ]
      };
    }
    case questionsBlockDeleteRoutine.SUCCESS: {
      return {
        ...state,
        questionsBlocks: state.questionsBlocks.filter(b => b.id !== action.payload)
      };
    }
    case questionsBlockDeleteRoutine.FAILURE: {
      toastr.error('Error', 'action.payload.errors[0]');
      return {
        ...state
      };
    }
    case questionsBlockCreateRoutine.SUCCESS: {
      return {
        ...state,
        questionsBlocks: [action.payload, ...state.questionsBlocks]
      };
    }
    case questionsBlockReorderRoutine.SUCCESS: {
      return {
        ...state,
        questionsBlocks: action.payload
      };
    }
    default:
      return state;
  }
};

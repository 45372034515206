import { Routine } from 'redux-saga-routines';
import { takeEvery, put, call, all } from 'redux-saga/effects';
import * as keyResultService from '../../services/keyResultService';
import * as ratingsService from '../../services/ratingService';
import { fetchExtendedKeyResultRoutine, updateCommentRoutine } from '../../routines';

function* extendedKeyResultRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(keyResultService.getKeyResult, payload);
    yield put(fetchExtendedKeyResultRoutine.success(response));
  } catch (error) {
    yield put(fetchExtendedKeyResultRoutine.failure(error.message));
  }
}

function* updateRatingComment({ payload }: Routine<any>) {
  try {
    yield call(ratingsService.updateRatingComment, payload);
    yield put(updateCommentRoutine.success(payload));
  } catch (error) {
    yield put(updateCommentRoutine.failure(error));
  }
}

function* watchExtendedKeyResultRequest() {
  yield takeEvery(fetchExtendedKeyResultRoutine.TRIGGER, extendedKeyResultRequest);
}

function* updateRatingCommentRequest() {
  yield takeEvery(updateCommentRoutine.TRIGGER, updateRatingComment);
}

export default function* extendedKeyResultSagas() {
  yield all([
    watchExtendedKeyResultRequest(),
    updateRatingCommentRequest()
  ]);
}

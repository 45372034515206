import { Routine } from 'redux-saga-routines';
import { fetchExtendedKeyResultRoutine, updateCommentRoutine, toggleEditComment } from '../../routines';
import { IKeyResultExtended } from 'screens/OKR/models/key-result';

const initialState: IKeyResultExtended = null;

export const extendedKeyResult = (state = initialState, action: Routine<any>): IKeyResultExtended => {
  const { type, payload } = action;
  switch (type) {
    case fetchExtendedKeyResultRoutine.SUCCESS:
      return payload;
    case updateCommentRoutine.SUCCESS:
      const isDeleting = !payload.comment;
      const newRatings = isDeleting
        ? state.ratings.filter(rating => rating.id !== payload.id)
        : state.ratings.map(rating => (rating.id === payload.id ? { ...rating, comment: payload.comment } : rating));
      return {
        ...state,
        ratings: newRatings
      };
    default:
      return state;
  }
};

export const editKeyResultComment = (state = null, action: Routine<any>) => {
  const { type, payload } = action;
  switch (type) {
    case toggleEditComment.TRIGGER:
      return payload;
    default:
      return state;
  }
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IQuestion } from 'screens/Feedback/model/Questions/IQuestion';
import { Form, Button, Checkbox, Icon, List } from 'semantic-ui-react';
import { IBindingCallback1 } from '../../../../models/callback';
import { questions } from '../Questions/reducer';
import styles from './styles.module.scss';

interface IQuestionProps {
  question: IQuestion;
  updateQuestion: IBindingCallback1<IQuestion>;
  deleteQuestion: IBindingCallback1<string>;
  blockId: string;
}

const Question: React.FunctionComponent<IQuestionProps> = ({
  question,
  deleteQuestion,
  updateQuestion,
  blockId
}: IQuestionProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState(question?.text);
  const [type, setType] = useState(undefined);
  const [isNegative, setIsNegative] = useState(question?.negative);
  const [radioOptions, setRadioOptions] = useState(question?.options);
  const [tempOption, setTempOption] = useState('');
  const options360 = ['strongly agree', 'agree', 'disagree', 'strongly disagree', 'don\'t know'];

  useEffect(() => {
    if (type === 'FEEDBACK_360') {
      setRadioOptions(options360);
    } else if (type === 'OPEN') {
      setRadioOptions([]);
    } else {
      setRadioOptions(question.options);
    }
  }, [type]);

  useEffect(() => {
    setText(question.text);
    setType(question.type);
    setRadioOptions(question.options);
    setIsNegative(question.negative);
  }, [question]);

  const options = [{
    key: 1,
    text: 'Open Answer',
    value: 'OPEN'
  },
  {
    key: 2,
    text: 'Single Choice (custom)',
    value: 'CUSTOM'
  },
  {
    key: 3,
    text: 'Single Choice (360)',
    value: 'FEEDBACK_360'
  }
  ];
  const onCancel = () => {
    setIsEdit(false);
  };
  const onSubmit = () => {
    updateQuestion({
      id: question?.id,
      blockId,
      type,
      options: radioOptions,
      description: null,
      text,
      negative: isNegative
    });
    setIsEdit(false);
  };
  const AddOption = () => {
    setRadioOptions([...radioOptions, tempOption]);
    setTempOption('');
  };

  const deleteOption = option => {
    const newOptions = radioOptions.filter(o => o !== option);
    setRadioOptions(newOptions);
  };
  return isEdit
    ? (
      <>
        <h4>Edit question</h4>
        <Form>
          <Form.Group className={styles.questionsRow}>
            <Form.Input
              width={9}
              label="Text"
              placeholder="Enter text"
              value={text}
              onChange={e => setText(e.target.value)}
              className={`fullWidthData ${styles.formInput}`}
            />
            <Form.Dropdown
              selection
              label="Type"
              options={options}
              value={type}
              onChange={(e, o) => setType(o.value)}
            />
            {type === 'FEEDBACK_360'
              && (
                <Form.Checkbox
                  toggle
                  label="Is Negative"
                  checked={isNegative}
                  onChange={() => setIsNegative(!isNegative)}
                />
              )}
          </Form.Group>
          {(type !== 'OPEN' && type !== undefined) && (
            <>
              <h5>Options</h5>
              {type === 'CUSTOM' && (
                <Form.Group>
                  <Form.Input
                    width={9}
                    placeholder="Enter option"
                    name="name"
                    defaultValue={tempOption}
                    value={tempOption}
                    className={`fullWidthData ${styles.formInput}`}
                    onChange={e => setTempOption(e.target.value)}
                  />
                  <Button
                    primary
                    disabled={!tempOption}
                    className={styles.formButton}
                    onClick={() => AddOption()}
                  >
                    Add Option
                  </Button>
                </Form.Group>
              )}
              <Form.Field width={9}>
                <List
                  relaxed
                  verticalAlign="middle"
                >
                  {radioOptions.map(option => (
                    <List.Item className={styles.itemAssigned}>
                      <List.Content floated="right">
                        {type === 'CUSTOM'
                          && (
                            <Icon
                              name="trash alternate"
                              onClick={() => deleteOption(option)}
                            />
                          )}
                      </List.Content>
                      <List.Content>
                        <Checkbox
                          radio
                          label={option}
                          readOnly
                        />
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Form.Field>
            </>
          )}

          <Button
            primary
            className={styles.formButton}
            onClick={onSubmit}
            disabled={!(text && (type === 'OPEN' || (type && radioOptions.length !== 0)))}
          >
            Save
          </Button>
          <Button
            basic
            color="orange"
            className={styles.formButton}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Form>
      </>
    )
    : (
      <>
        <div className={styles.questionHeader}>
          <h4>{`${question.text}`}</h4>
          <div className={styles.btnDiv}>
            <div onClick={() => setIsEdit(true)} className={styles.btnBlock}>
              <Icon name="pencil" />
            </div>
            <div onClick={() => deleteQuestion(question.id)} className={styles.btnBlock}>
              <Icon name="trash alternate" />
            </div>
          </div>
        </div>
        <Form>
          <Form.Group>
            {(question.type !== 'OPEN' && question.type !== undefined) && (
              <Form.Field width={9}>
                <h5>Options</h5>
                <List
                  relaxed
                  verticalAlign="middle"
                >
                  {question.options.map(option => (
                    <List.Item>
                      <List.Content>
                        <Checkbox
                          radio
                          label={option}
                          readOnly
                        />
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Form.Field>

            )}
            {question.type === 'FEEDBACK_360'
              && (
                <Form.Checkbox
                  toggle
                  label="Is Negative"
                  className={styles.checkboxToggle}
                  checked={question.negative}
                  readOnly
                />
              )}
          </Form.Group>
        </Form>
      </>
    );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      forms: {
        selectedQuestionsBlock
      }
    }
  } = state;
  return {
    selectedBlock: selectedQuestionsBlock
  };
};

export default connect(mapStateToProps, {})(Question);

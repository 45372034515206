import React, { useState } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import styles from './styles.module.scss';

enum PopupMsg {
  Pending = 'Click to copy a URL of this OKR',
  Success = 'Successfully copied!',
  Failure = 'Can\'t perform copy.'
}

const CopyOkrLinkButton = ({ okrId }) => {
  const [popupMsg, setPopupMsg] = useState(PopupMsg.Pending);
  const [isPopupOpen, togglePopup] = useState(false);

  const togglePopupAndSetCopyMsg = (
    s: boolean,
    msg: PopupMsg
  ) => {
    togglePopup(s);
    setPopupMsg(msg);
  };
  const startTimer = () => setTimeout(() => {
    togglePopupAndSetCopyMsg(false, PopupMsg.Pending);
  }, 700);

  const copyToClipBoard = e => {
    e.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(`${window.location.origin}/share/${okrId}`)
        .then(() => {
          togglePopupAndSetCopyMsg(true, PopupMsg.Success);
        })
        .catch(() => {
          togglePopupAndSetCopyMsg(true, PopupMsg.Failure);
        })
        .finally(() => {
          startTimer();
        });
    } else {
      togglePopupAndSetCopyMsg(true, PopupMsg.Failure);
      startTimer();
    }
  };

  return (
    <div className={styles.linkBtnContainer}>
      <Icon.Group>
        <Popup
          trigger={(
            <Button
              className={styles.linkButton}
              onClick={copyToClipBoard}
              onMouseEnter={() => { togglePopupAndSetCopyMsg(true, PopupMsg.Pending); }}
              onMouseLeave={() => { togglePopupAndSetCopyMsg(false, PopupMsg.Pending); }}
            >
              <Icon name="linkify" />
            </Button>
          )}
          position="top right"
          inverted
          size="tiny"
          className={styles.popup}
          open={isPopupOpen}
        >
          {popupMsg}
        </Popup>
      </Icon.Group>
    </div>
  );
};

export default CopyOkrLinkButton;

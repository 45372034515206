import { Routine } from 'redux-saga-routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as byLinkService from '../../services/byLinkService';
import {
  fetchAssignDetailRequestByLinkRoutine,
  sendAnswersByLinkRoutine
} from '../../routines';

function* requestDetailsFetch({ payload }: Routine<any>): Routine<any> {
  try {
    const results = yield call(byLinkService.getDetailedRequest, payload);
    yield put(fetchAssignDetailRequestByLinkRoutine.success(results));
  } catch (error) {
    yield put(fetchAssignDetailRequestByLinkRoutine.failure(error));
  }
}
function* sendAnswers({ payload }: Routine<any>): Routine<any> {
  try {
    const results = yield call(byLinkService.sendAnswers, payload);

    yield put(sendAnswersByLinkRoutine.success(results));
  } catch (error) {
    yield put(sendAnswersByLinkRoutine.failure(error));
  }
}

function* watchSendAnswers() {
  yield takeEvery(sendAnswersByLinkRoutine.TRIGGER, sendAnswers);
}

function* watchAssignDetailRequestsFetch() {
  yield takeEvery(fetchAssignDetailRequestByLinkRoutine.TRIGGER, requestDetailsFetch);
}

export default function* manageRequestsByLinkPageSagas() {
  yield all([watchSendAnswers(), watchAssignDetailRequestsFetch()]);
}

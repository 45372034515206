import { all, takeEvery, call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  uploadFileRoutine,
  downloadFileRoutine,
  fetchAdminUserInfoRoutine,
  importFromGoogleRoutine
} from 'screens/AdminPage/routines';
import { Routine } from 'redux-saga-routines';
import saveFileFromContent from 'screens/AdminPage/helpers/downloadFile';
import * as fileService from 'screens/AdminPage/services/fileService';

function* uploadFile({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(fileService.uploadUserFile, payload);
    if (res.errors) {
      toastr.error('Error!', res.errors);
    }
    if (res.success) {
      toastr.success('Success!', res.success ?? 'Import users from file finished.');
    }
    yield put(uploadFileRoutine.success(res));
    yield put(fetchAdminUserInfoRoutine.trigger());
  } catch (error) {
    yield put(uploadFileRoutine.failure(error));
  }
}

function* downloadFile(): Routine<any> {
  try {
    const response = yield call(fileService.downloadUserFile);
    yield call(saveFileFromContent, response);
    yield put(downloadFileRoutine.success());
  } catch (error) {
    yield put(downloadFileRoutine.failure());
  }
}

function* importFromGoogle({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(fileService.importUsersFromGoogle, payload);
    if (res.errors) {
      toastr.error('Error!', res.errors);
    }
    if (res.success) {
      toastr.success('success!', res.success ?? 'Import users from Google Spreadsheet finished.');
    }
    yield put(importFromGoogleRoutine.success());
    yield put(fetchAdminUserInfoRoutine.trigger());
  } catch (error) {
    yield put(importFromGoogleRoutine.failure(error));
  }
}

function* watchDownloadFile() {
  yield takeEvery(uploadFileRoutine.TRIGGER, uploadFile);
}

function* watchUploadFile() {
  yield takeEvery(downloadFileRoutine.TRIGGER, downloadFile);
}

function* watchImportFromGoogle() {
  yield takeEvery(importFromGoogleRoutine.TRIGGER, importFromGoogle);
}

export default function* loadFileSagas() {
  yield all([
    watchDownloadFile(),
    watchUploadFile(),
    watchImportFromGoogle()
  ]);
}

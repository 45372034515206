import React, { useEffect, useState } from 'react';
import { IRequest } from '../../model/Requests/IRequest';
import { Menu, Table } from 'semantic-ui-react';
import styles from './styles.module.scss';
import FeedbackOpenAnswersTable from '../FeedbackOpenAnswersTable';
import { IFeedbackAnswer } from '../../model/Questions/IFeedbackAnswer';
import FeedbackSummary from '../FeedbackSummary';
import { Role } from 'screens/Authorization/models/role';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';
import { IQuestionAnsweByBlock } from '../../model/Questions/IQuestionAnsweByBlock';
import FeedbackHeatMap from '../FeedbackHeatMap';

interface IFeedbackReportProps {
  detailedRequest: IRequest;
  roles: Role[];
  userId: string;
  usersMentor: IUserMentorData[];
}

const FeedbackReport: React.FunctionComponent<IFeedbackReportProps> = (
  { detailedRequest, roles, userId, usersMentor }: IFeedbackReportProps
) => {
  const [activeMenu, setActiveMenu] = useState(undefined);
  useEffect(() => {
    setActiveMenu(undefined);
  }, [detailedRequest]);

  const setAnswer360ByBlock = (title: string, blockId: string) => {
    const questions = detailedRequest.form.questionsBlocks
      .find(block => block.id === blockId).questions
      .filter(q => q.type === 'FEEDBACK_360');
    const feedbacksAnswerByBlock: IFeedbackAnswer[] = detailedRequest.feedbacks.map(fb => {
      const feedback: IFeedbackAnswer = {
        feedbackId: fb.id,
        authorName: fb.authorName,
        authorType: fb.authorType,
        answers: []
      };
      feedback.answers = fb.answers
        .filter(a => questions.map(q => q.id).find(id => id === a.questionId))
        .sort((a, b) => questions.map(q => q.id)
          .indexOf(a.questionId) - questions.map(q => q.id).indexOf(b.questionId));
      return feedback;
    });
    const qaByCategory: IQuestionAnsweByBlock = {
      questions,
      block: title,
      feedbacks: feedbacksAnswerByBlock
    };
    return qaByCategory;
  };

  const setOpenAnswerByBlock = () => {
    const questions = [];
    detailedRequest.form.questionsBlocks.map(block => block.questions)
      .filter(q => q.length !== 0).forEach(qarr => qarr.forEach(q => { if (q.type === 'OPEN') questions.push(q); }));
    const feedbacksAnswerByCatery: IFeedbackAnswer[] = detailedRequest.feedbacks.filter(fb => fb.answers.length !== 0)
      .map(fb => {
        const feedback: IFeedbackAnswer = {
          feedbackId: fb.id,
          authorName: fb.authorName,
          authorType: fb.authorType,
          answers: []
        };
        feedback.answers = fb.answers
          .filter(answer => questions.find(q => q.id === answer.questionId)?.type === 'OPEN')
          .sort((a, b) => questions.map(q => q.id)
            .indexOf(a.questionId) - questions.map(q => q.id).indexOf(b.questionId));
        return feedback;
      });
    const qaByCategory: IQuestionAnsweByBlock = {
      questions,
      block: 'Open Answers',
      feedbacks: feedbacksAnswerByCatery
    };
    return qaByCategory;
  };

  const checkMentee = (): boolean => (detailedRequest.owner?.userId === userId);

  const availableAddSummary = (): boolean => {
    const requiredRoles = [Role.Administrator, Role.HR];
    return roles.some(userRole => requiredRoles.includes(userRole))
      || usersMentor.find(user => user.userId === detailedRequest.owner.userId)?.mentorId === userId
      || detailedRequest.creator.userId === userId;
  };

  let allQAByBlocks: IQuestionAnsweByBlock[] = detailedRequest.form.questionsBlocks
    .sort((a, b) => (a.index > b.index ? 1 : -1))
    .map(block => setAnswer360ByBlock(block.title, block.id));
  allQAByBlocks.push(setOpenAnswerByBlock());
  allQAByBlocks = allQAByBlocks.filter(block => block.feedbacks.length !== 0 && block.questions.length !== 0);
  if (activeMenu === undefined) setActiveMenu(allQAByBlocks.length === 0 ? 'Summary' : allQAByBlocks[0].block);
  return (
    <div className={styles.result}>
      <div className={styles.menuReport}>
        <Menu pointing secondary>
          {allQAByBlocks.map(blocks => (
            <>
              {(blocks.questions.length !== 0 && blocks.feedbacks.length !== 0)
                && (
                  <Menu.Item
                    name={blocks.block}
                    active={activeMenu === blocks.block}
                    onClick={() => setActiveMenu(blocks.block)}
                  />
                )}
            </>
          ))}
          {detailedRequest
            && (
              <Menu.Item
                name="Summary"
                active={activeMenu === 'Summary'}
                onClick={() => setActiveMenu('Summary')}
              />
            )}
        </Menu>
      </div>
      <div className={styles.reportInfoTable}>
        {allQAByBlocks.map(blocks => (
          <>
            {blocks.block === activeMenu && (
              <>
                {blocks.questions.length !== 0 && blocks.block !== 'Open Answers'
                  && <FeedbackHeatMap currentBlock={blocks} isMentee={checkMentee()} />}
              </>
            )}
            {blocks.block === activeMenu && (
              <>
                {blocks.questions.length !== 0 && blocks.block === 'Open Answers'
                  && <FeedbackOpenAnswersTable currentBlock={blocks} />}
              </>
            )}
          </>
        ))}
        {activeMenu === 'Summary'
          && (
            <div className={styles.summaryDiv}>
              <FeedbackSummary
                requestId={detailedRequest.id}
                isMentee={checkMentee()}
                fulLRights={availableAddSummary()}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default FeedbackReport;

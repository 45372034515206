import { combineReducers } from 'redux';

import { reducerCreator } from 'helpers/reducerHelper';
import { news } from '../containers/NewsList/reducer';
import { newsForUpdate } from '../containers/AddNewsForm/reducer';
import { newsDetails } from '../containers/NewsItemDetails/reducer';
import {
  fetchNewsRoutine,
  fetchNewsDetailsByIdRoutine,
  fetchNewsByIdRoutine,
  syncNewsWithOriginByIdRoutine, syncNewsForUpdateWithOriginByIdRoutine
} from '../routines';

const requests = combineReducers({
  news: reducerCreator([fetchNewsRoutine.TRIGGER]),
  newsForUpdate: reducerCreator([fetchNewsByIdRoutine.TRIGGER]),
  newsDetails: reducerCreator([fetchNewsDetailsByIdRoutine.TRIGGER]),
  syncShortNewsWithOrigin: reducerCreator([syncNewsWithOriginByIdRoutine.TRIGGER]),
  syncNewsForUpdateWithOrigin: reducerCreator([syncNewsForUpdateWithOriginByIdRoutine.TRIGGER])
});

export default combineReducers({
  news,
  newsDetails,
  newsForUpdate,
  requests
});

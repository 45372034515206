import React from 'react';
import { boundMethod } from 'autobind-decorator';
import { Button, Image } from 'semantic-ui-react';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { IBindingCallback1 } from 'models/callback';
import KeyResultsListModalContent from '../../containers/KeyResultsListModalContent';
import styles from './styles.module.scss';
import Sidebar from 'components/DataSidebar';
import { cross } from 'assets/icons';

export interface IAddKeyResultModalProps {
  saving: boolean;
  onAddKeyResults: IBindingCallback1<string[]>;
  selectedResults: IKeyResult[];
  onKeyResultCreate: IBindingCallback1<IKeyResult>;
  toggleSidebar: IBindingCallback1<string>;
}

export interface IAddKeyResultModalState {
  isOpened: boolean;
}

class AddKeyResultModal extends React.Component<IAddKeyResultModalProps, IAddKeyResultModalState> {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false
    };
  }

  componentDidUpdate(prevProps) {
    const { saving } = this.props;
    if (prevProps.saving && !saving) {
      this.closeModal();
    }
  }

  @boundMethod
  handleSubmit(selectedKeyResults) {
    const { onAddKeyResults } = this.props;
    onAddKeyResults(selectedKeyResults);
  }

  toggleModal = () => this.setState(prevState => ({ isOpened: !prevState.isOpened }));

  closeModal = () => this.setState({ isOpened: false });

  @boundMethod
  createKeyResult(inputValue: IKeyResult) {
    const { onKeyResultCreate } = this.props;
    onKeyResultCreate(inputValue);
    this.setState({
      isOpened: false
    });
  }

  render() {
    const { isOpened } = this.state;
    const { selectedResults, toggleSidebar, saving } = this.props;
    const selectedResultsId = selectedResults.map(obj => obj.id);

    const sidebar = isOpened ? (
      <Sidebar
        onClose={this.toggleModal}
        width="100%"
      >
        <Image src={cross} className={styles.closeButton} onClick={this.toggleModal} />
        <KeyResultsListModalContent
          loading={saving}
          toggleSidebar={toggleSidebar}
          selectedResults={selectedResultsId}
          onAddKeyResults={selectedKeyResults => this.handleSubmit(selectedKeyResults)}
          onKeyResultCreate={this.createKeyResult}
        />
      </Sidebar>
    ) : '';

    return (
      <>
        {sidebar}
        <Button
          primary
          className={styles.openModal}
          onClick={this.toggleModal}
          icon="plus"
          content="Add Key Results"
        />
      </>
    );
  }
}

export default AddKeyResultModal;

import React from 'react';
import { Comment, Form, Button, Header } from 'semantic-ui-react';
import { FormikProps, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { sortByDate } from 'helpers/dateFormatHelper';
import { history } from 'helpers/history';

import { IBindingCallback1 } from 'models/callback';

import styles from './styles.module.scss';
import { IComment } from 'screens/BaseModels/IComment';
import Avatar from '../UserAvatar';

export interface INewsCommentsListProps {
  comments: IComment[];
  onCommentSave: IBindingCallback1<string>;
}
interface IFormikProps {
  comment: string;
}

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .required('Text is required!')
    .max(100, 'maximum length is 100 characters')
});

const NewsCommentsList: React.FunctionComponent<INewsCommentsListProps> = ({
  comments,
  onCommentSave
}) => {
  const sortedComments = sortByDate(comments, 'time');

  const handleProfilePressed = (id: string) => {
    history.push(`/profile/${id}`);
  };

  const handleFormSubmit = (values, { resetForm }) => {
    onCommentSave(values.comment);
    resetForm();
  };

  const renderItem = (item: IComment) => (
    <Comment key={item.id} className={styles.commentGroupItem}>
      <div
        role="presentation"
        className={styles.commentGroupItemAvatar}
        onKeyDown={() => handleProfilePressed(item.authorId)}
        onClick={() => handleProfilePressed(item.authorId)}
      >
        <Avatar
          name={item.authorName}
          width="32.5px"
          logoLink={item.authorLogo}
          height="32.5px"
        />
      </div>
      <Comment.Content>
        <Comment.Author
          as="a"
          onClick={() => handleProfilePressed(item.authorId)}
          className={styles.commentGroupItemAuthor}
        >
          {item.authorName}
        </Comment.Author>
        <Comment.Metadata>
          <div>{moment(item.time).fromNow()}</div>
        </Comment.Metadata>
        <Comment.Text className={styles.commentGroupItemContent}>{item.text}</Comment.Text>
      </Comment.Content>
    </Comment>
  );

  return (
    <Formik
      initialValues={{ comment: '' }}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        setFieldValue,
        touched,
        handleSubmit
      }: FormikProps<IFormikProps>) => (
        <Comment.Group size="small" className={styles.commentGroup}>
          <Header as="h3" dividing className={styles.commentGroupHeaderTitle}>
            Comments
          </Header>
          <Form reply onSubmit={handleSubmit} className={styles.commentGroupForm}>
            <Form.TextArea
              type="text"
              name="text"
              rows="2"
              maxrows="5"
              style={{ resize: 'none' }}
              placeholder="Add comment"
              value={values.comment}
              error={touched.comment && errors.comment}
              onChange={(event, o) => setFieldValue('comment', o.value)}
            />
            <Button primary type="submit" content="Post" />
          </Form>
          {sortedComments.length ? (
            <div className={styles.commentGroupContent}>
              {sortedComments.map((item: IComment) => renderItem(item))}
            </div>
          ) : (
            <div className={styles.emptyDataContainer}>
              <p>No comments.</p>
            </div>
          )}
        </Comment.Group>
      )}
    </Formik>
  );
};

export default NewsCommentsList;

import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchProjectsRoutine,
  saveProjectRoutine,
  fetchProjectRoutine,
  fetchProjectsDataRoutine
} from 'screens/Projects/routines';
import * as projectService from 'screens/Projects/services/projects.service';
import * as usersService from 'screens/Projects/services/users.service';
import * as tagsService from 'screens/Projects/services/tags.service';
import { Routine } from 'redux-saga-routines';

function* fetchProjects() {
  try {
    const response = yield call(projectService.fetchProjects);
    yield put(fetchProjectsRoutine.success(response));
  } catch (error) {
    yield put(fetchProjectsRoutine.failure(error));
  }
}

function* fetchProject({ payload }: Routine<any>) {
  try {
    const response = yield call(projectService.fetchProject, payload);
    yield put(fetchProjectRoutine.success(response));
  } catch (error) {
    yield put(fetchProjectRoutine.failure(error));
  }
}

function* saveProject({ payload }: Routine<any>) {
  try {
    const response = yield call(projectService.saveProject, payload);
    yield put(saveProjectRoutine.success(response));
  } catch (error) {
    yield put(saveProjectRoutine.failure(error));
  }
}

function* fetchData() {
  try {
    const response = yield all([
      yield call(usersService.fetchUsers),
      yield call(tagsService.fetchTags)
    ]);
    yield put(fetchProjectsDataRoutine.success(response));
  } catch (error) {
    yield put(fetchProjectsDataRoutine.failure(error));
  }
}

function* watchFetchProjects() {
  yield takeEvery(fetchProjectsRoutine.TRIGGER, fetchProjects);
}

function* watchSaveProject() {
  yield takeEvery(saveProjectRoutine.TRIGGER, saveProject);
}

function* watchFetchProject() {
  yield takeEvery(fetchProjectRoutine.TRIGGER, fetchProject);
}

function* watchfetchUsers() {
  yield takeEvery(fetchProjectsDataRoutine.TRIGGER, fetchData);
}

export default function* projectPageSagas() {
  yield all([
    watchFetchProjects(),
    watchFetchProject(),
    watchSaveProject(),
    watchfetchUsers()
  ]);
}

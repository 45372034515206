import { NotificationType } from '../enums/NotificationType';

export const setNotificationAvatar = (type: NotificationType): string => {
  switch (type) {
    case NotificationType.Global:
      return 'https://image.flaticon.com/icons/png/512/596/596041.png';
    case NotificationType.Group:
      return 'https://image.flaticon.com/icons/png/512/32/32441.png';
    case NotificationType.Personal:
      return 'https://image.flaticon.com/icons/png/512/74/74472.png';
    default:
      return 'https://image.flaticon.com/icons/svg/630/630757.svg';
  }
};

import { callGatewayApi } from 'helpers/webApiHelper';
import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers/handleResponse';
import { ILoginData } from '../models/ILoginData';
import { IUserRegister } from 'screens/Authorization/models/IUserRegister';
import { IPasswordData } from '../containers/ResetPasswordPage';

const tokenSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('token'))
);

const setToken = async (response: Response) => {
  const token = await handleResponse(response);
  localStorage.setItem('token', JSON.stringify(token));
  tokenSubject.next(token);
};

export const authService = {
  token: tokenSubject.asObservable(),
  get tokenValue() {
    return tokenSubject.value;
  }
};

export const login = async (loginData: ILoginData) => {
  const response = await callGatewayApi({
    endpoint: '/api/login',
    requestData: loginData,
    type: 'POST'
  });
  await setToken(response);
};

export const sendMailToResetPassword = async (email: string) => {
  const response = await callGatewayApi({
    endpoint: `/api/reset-password/${email}`,
    type: 'PUT'
  });
  return response.json();
};

export const checkCode = async (code: string) => {
  const response = await callGatewayApi({
    endpoint: `/api/reset-password?code=${code}`,
    type: 'GET'
  });
  return response.json();
};

export const resetPassword = async (data: IPasswordData) => callGatewayApi({
  endpoint: '/api/reset-password',
  type: 'POST',
  requestData: data
});

export const logout = async () => {
  localStorage.removeItem('token');
  tokenSubject.next(null);
};

export const register = async (payload: IUserRegister) => {
  await callGatewayApi({
    endpoint: '/api/register',
    requestData: payload,
    type: 'POST'
  });
};

export const confirmEmail = async (payload: string) => {
  const response = await callGatewayApi({
    endpoint: `/api/activate${payload}`,
    type: 'POST'
  });
  await setToken(response);
};

export const resendEmailConfirmationLink = async (payload: string) => {
  const response = await callGatewayApi({
    endpoint: `/api/register/${payload}`,
    type: 'PUT'
  });
  await setToken(response);
};

import React from 'react';
import { Button, Form, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { IBindingCallback1, IBindingAction } from 'models/callback/Callbacks';
import { logInRoutine } from 'screens/Authorization/routines/';
import { connect } from 'react-redux';
import { ILoginData } from 'screens/Authorization/models/ILoginData';
import styles from '../../containers/LoginPage/styles.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail is not valid!')
    .required('E-mail is required!'),
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('Password is required!')
});

export interface ILoginFormProps {
  logIn: IBindingCallback1<ILoginData>;
  onPasswordForget: IBindingAction;
}

export interface ILoginFormikProps {
  email: string;
  password: string;
}

const LoginForm: React.FunctionComponent<ILoginFormProps> = ({
  logIn,
  onPasswordForget
}: ILoginFormProps) => (
  <Formik
    initialValues={{
      email: '',
      password: ''
    }}
    onSubmit={(values, { setSubmitting }) => {
      logIn(values);
      setSubmitting(false);
    }}
    validationSchema={validationSchema}
  >
    {({ values, errors, touched, handleSubmit, handleChange }: FormikProps<ILoginFormikProps>) => (
      <Form onSubmit={handleSubmit}>
        <Popup
          open={!!(touched.email && errors.email)}
          content={errors.email}
          on={[]}
          trigger={(
            <Form.Input
              fluid
              onChange={handleChange}
              name="email"
              label="Email Address"
              placeholder="Email address"
              error={errors.email && touched.email}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <Popup
          open={!!(touched.password && errors.password)}
          content={errors.password}
          on={[]}
          trigger={(
            <Form.Input
              fluid
              onChange={handleChange}
              label="Password"
              placeholder="Password"
              type="password"
              name="password"
              error={errors.password && touched.password}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <div className={styles.actions}>
          <div className={styles.textWrapper}>
            <div className={styles.text} onClick={onPasswordForget} role="presentation">
              Forgot password?
            </div>
          </div>
          <Button
            primary
            type="submit"
            disabled={!values.password || !values.email}
            content="Login"
          />
        </div>
      </Form>
    )}
  </Formik>
);

const mapDispatchToProps = {
  logIn: logInRoutine
};

export default connect(null, mapDispatchToProps)(LoginForm);

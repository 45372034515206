import { IBindingFunction } from 'models/callback';
import { Image, List } from 'semantic-ui-react';
import React from 'react';
import { setNotificationAvatar } from 'screens/Notifications/helpers/avatar';
import { INotificationModel } from 'screens/Notifications/models/notificationModel';
import { history } from 'helpers/history';
import styles from './styles.module.scss';
import { createFileUrl } from 'helpers/fileUrl.helper';
import { pathToRoutes } from 'helpers/pathToRoutes.helper';
import { removeHtmlTags } from 'helpers/string.helper';

export interface IListItemProps {
  notification: INotificationModel;
  onSelect: IBindingFunction<string, void>;
}

const handleClick = (routeName, subRouteId) => {
  history.push(`${pathToRoutes[routeName]}${subRouteId ? `/${subRouteId}` : ''}`, true);
};

export const NotificationItem: React.FunctionComponent<IListItemProps> = ({
  notification: {
    id,
    read,
    body,
    title,
    type,
    icon,
    routeName,
    subRouteId
  },
  onSelect
}) => (
  <List.Item
    onClick={() => onSelect(id)}
    className={read ? styles.read : styles.unread}
  >
    <Image avatar src={icon ? createFileUrl(icon) ?? setNotificationAvatar(type) : setNotificationAvatar(type)} />
    <List.Content className={styles.content}>
      <List.Header as="a" className={styles.title} onClick={() => handleClick(routeName, subRouteId)}>
        {removeHtmlTags(title)}
      </List.Header>
      <div className={styles.desc}>
        {removeHtmlTags(body)}
      </div>
    </List.Content>
  </List.Item>
);

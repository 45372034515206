import { callFeedbackApi } from '../../../helpers/webApiHelper';

export const sendAnswers = async answer => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/answers/',
    type: 'POST',
    requestData: answer
  });
  return response;
};

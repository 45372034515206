import { callOkrApi } from 'helpers/webApiHelper';
import { ITag, ITagFilter } from '../models/tag';

export const getTags = async (filters: ITagFilter): Promise<ITag[]> => {
  const response = await callOkrApi({
    endpoint: '/api/tags',
    type: 'GET',
    queryParams: filters
  });
  return response.json();
};

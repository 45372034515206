import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import debounce from 'debounce';
import { IBindingCallback1 } from 'models/callback';
import styles from './styles.module.scss';
import { fetchTeamUsersRoutine } from '../../routines';
import LoaderWrapper from 'components/LoaderWrapper';
import { NoDataContainer } from 'components/NoDataContainer';
import { IUser } from 'screens/Team/models/IUser';
import { Header, Input, InputOnChangeData, Dropdown } from 'semantic-ui-react';
import TeamMember from '../../components/TeamMember';
import { IUsersFilter } from 'screens/Team/models/IUsersFilter';

interface ITeamViewProps {
  fetchUsers: IBindingCallback1<IUsersFilter>;
  users?: IUser[];
  loadingTeamUsers: boolean;
}

const TeamView: React.FunctionComponent<ITeamViewProps> = ({
  users,
  fetchUsers,
  loadingTeamUsers
}) => {
  const [usersToShow, updateUsers] = useState<IUser[]>([]);
  const [usersFilter, updateFilter] = useState<IUsersFilter>({ office: null, fullname: null });
  const fetchUsersDeb = debounce(fetchUsers, 500);

  useEffect(() => { fetchUsers(usersFilter); }, []);

  useEffect(() => {
    updateUsers(users);
  }, [users]);

  const handleSearchInputChange = (e: any, { value }: InputOnChangeData) => {
    const newFilter = { ...usersFilter, fullname: value };
    updateFilter(newFilter);
    fetchUsersDeb(newFilter);
  };

  const toggleOffice = (e, data) => {
    const newOffice = data.value;
    if (newOffice !== usersFilter.office) {
      const newFilter = { ...usersFilter, office: data.value };
      updateFilter(newFilter);
      fetchUsersDeb(newFilter);
    }
  };

  const officesDict = ['Lviv', 'Kyiv'].map(o => ({ key: o, value: o, text: o }));
  const show = usersToShow.length || users.length;

  return (
    <div className={styles.loaderWrapper}>
      <div className={show ? styles.container : styles.noData}>
        <div className={styles.searchBox}>
          <Header content="Our team" className={styles.header} />
          <div className={styles.filterFields}>
            <Input
              className={styles.searchName}
              key="key123"
              iconPosition="left"
              icon="search"
              value={usersFilter.fullname}
              placeholder="Search by user name"
              onChange={handleSearchInputChange}
            />
            <Dropdown
              clearable
              placeholder="Office"
              fluid
              selection
              className={styles.filterOffice}
              options={officesDict}
              value={usersFilter.office}
              onChange={toggleOffice}
            />
          </div>
        </div>
        <LoaderWrapper loading={loadingTeamUsers && !users.length}>
          <div className={`${usersToShow.length ? styles.teamContainer : styles.noData}`}>
            <LoaderWrapper loading={loadingTeamUsers}>
              {usersToShow.length
                ? (usersToShow.map(user => (
                  <TeamMember key={user.id} user={user} />
                ))
                ) : (
                  <div className={styles.noDataContainer}>
                    <NoDataContainer
                      text="Nothing to show"
                    />
                  </div>
                )}
            </LoaderWrapper>
          </div>
        </LoaderWrapper>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { teamUsersPage: { users, loadingTeamUsers } } = state;

  return {
    users,
    loadingTeamUsers
  };
};

const mapDispatchToProps = {
  fetchUsers: fetchTeamUsersRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamView);


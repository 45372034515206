import React, { useState } from 'react';
import { IBindingAction } from 'models/callback';
import ConfirmModalContent from 'components/ConfirmModalContent';

interface IConfirmModalProps {
  header: string;
  onConfirm: IBindingAction;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  children: any;
}

const ConfirmModal: React.FunctionComponent<IConfirmModalProps> = ({
  header,
  description,
  onConfirm,
  confirmButtonText = 'Yes, delete it!',
  cancelButtonText = 'Cancel',
  children
}: IConfirmModalProps) => {
  const [modalOpened, toggleConfirm] = useState(false);

  const onConfirmClicked = () => {
    onConfirm();
    toggleConfirm(false);
  };

  return (
    <>
      <ConfirmModalContent
        header={header}
        description={description}
        opened={modalOpened}
        onCancel={() => toggleConfirm(false)}
        onConfirm={onConfirmClicked}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
      />
      <div onClick={() => toggleConfirm(true)} role="presentation">
        {children}
      </div>
    </>
  );
};

export default ConfirmModal;

import React from 'react';
import { Header, Table } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { IQuestionAnsweByBlock } from '../../model/Questions/IQuestionAnsweByBlock';

interface IFeedbackOpenAnswersTableProps {
  currentBlock: IQuestionAnsweByBlock;
}

const FeedbackOpenAnswersTable: React.FunctionComponent<IFeedbackOpenAnswersTableProps> = (
  { currentBlock }: IFeedbackOpenAnswersTableProps
) => (
  <Table compact celled definition textAlign="center">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={4} />
        {currentBlock.questions.map(qa => (
          <Table.HeaderCell key={qa.id} content={`${qa.text}`} className={styles.tableHeader} />
        ))}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {currentBlock.feedbacks.map(fb => (
        <Table.Row>
          <Table.Cell>
            <Header as="h5" content={`${fb.authorName} ${fb.authorType}`} />
          </Table.Cell>
          {fb.answers.map(a => (
            <Table.Cell>
              <Header as="h5" content={`${a.answer}`} />
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default FeedbackOpenAnswersTable;

import { Routine } from 'redux-saga-routines';
import { fetchUserRoutine, removeUserRoutine } from 'screens/Authorization/routines';
import { increaseCounterRoutine, updateNotificationsRoutine } from 'screens/Notifications/routines';

type State = {
  user?: any;
  isAuthorized: boolean;
  isLoading: boolean;
  resetPasswordMessage: string;
};

const initialState: State = {
  isLoading: true,
  isAuthorized: false,
  user: null,
  resetPasswordMessage: null
};

export const user = (state: State = initialState, action: Routine<any>): State => {
  switch (action.type) {
    case fetchUserRoutine.TRIGGER:
      return {
        ...state,
        isLoading: !state.user
      };
    case updateNotificationsRoutine.SUCCESS:
      return {
        ...state,
        user: {
          ...(state.user as []),
          notifications: state.user.notifications - action.payload
        }
      };
    case increaseCounterRoutine.TRIGGER:
      return {
        ...state,
        user: {
          ...(state.user as []),
          notifications: state.user.notifications + 1
        }
      };
    case fetchUserRoutine.SUCCESS:
      const { payload } = action;

      return {
        ...state,
        user: payload,
        isLoading: false,
        isAuthorized: Boolean(user && payload.id)
      };
    case fetchUserRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case removeUserRoutine.TRIGGER:
      return {
        ...state,
        isAuthorized: false,
        user: null
      };
    default:
      return state;
  }
};

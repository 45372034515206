import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IUsersOkrsState } from './reducer';
import styles from './styles.module.scss';
import { fetchUsersRoutine } from 'screens/UsersOkrs/routines';
import { IBindingCallback1 } from 'models/callback/Callbacks';
import UsersList from 'screens/UsersOkrs/components/UsersList';
import OkrList from 'screens/UsersOkrs/components/OkrList';
import LoaderWrapper from 'components/LoaderWrapper';
import SidebarWrapper from 'components/Sidebar';
import KeyResultDataSidebar from 'screens/OKR/containers/KeyResultDataSidebar';
import { IUser } from 'screens/UsersOkrs/models/IUser';
import { NoDataContainer } from 'components/NoDataContainer';
import { history } from 'helpers/history';
import { IFetchFilter } from 'screens/UsersOkrs/models/IFetchFilter';

const getUserId = str => {
  const userRegex = new RegExp('^/okr/users/([^/]{36}).*$');
  const res = str.replace(userRegex, '$1');
  return res.length === str.length ? undefined : res;
};

const getOkrId = (str: string) => {
  const userRegex = new RegExp('^/okr/users/[^/]{36}/okr/([^/]{36})$');
  const res = str.replace(userRegex, '$1');
  return res.length === str.length ? undefined : res;
};

interface IUsersOkrsPageProps {
  fetchUsers: IBindingCallback1<IFetchFilter>;
  users: IUser[];
  loadingUsers: boolean;
  selectedUserId: string;
  currentUserId: string;
  firstTimeLoading: boolean;
  okrsCount: number;
  usersCount: number;
  showMentees: boolean;
  location: Location;
}

const UsersOkrsPage: React.FunctionComponent<IUsersOkrsPageProps> = ({
  currentUserId,
  fetchUsers,
  firstTimeLoading,
  loadingUsers,
  okrsCount,
  selectedUserId,
  showMentees,
  users,
  usersCount,
  location
}) => {
  const [selectedKeyResult, selectKeyResult] = useState<string>(null);
  const [isUserSearchCollapsed, toggleUserSearchCollapse] = useState<boolean>(true);

  useEffect(() => {
    if (!users.length) {
      fetchUsers({
        onlyMentees: false,
        okrId: getOkrId(location.pathname),
        userId: getUserId(location.pathname)
      });
    }
  }, []);

  useEffect(() => {
    history.push(`/okr/users/${selectedUserId}`);
  }, [selectedUserId]);

  const handleToggleChange = (onlyMentees: boolean) => {
    fetchUsers({ onlyMentees });
  };

  const isMentor = currentUserId === users.find(u => u.id === selectedUserId)?.mentorId;

  const getUserById = (userId: string) => users.find(u => u.id === userId);

  return (
    <div className={styles.loaderWrapper}>
      <LoaderWrapper loading={loadingUsers && firstTimeLoading}>
        <SidebarWrapper
          isOpened={Boolean(selectedKeyResult)}
          onClose={() => selectKeyResult(null)}
        >
          <KeyResultDataSidebar keyResultId={selectedKeyResult} />
        </SidebarWrapper>
        <div className={styles.container}>
          <div
            className={
              `${styles.users} ${isUserSearchCollapsed
                ? styles.collapsedUserSearch
                : styles.expandedUserSearch}`
            }
          >
            <UsersList
              hideExpandedUserSearch={() => toggleUserSearchCollapse(!isUserSearchCollapsed)}
              showMentees={showMentees}
              statsText={`${usersCount} users, ${okrsCount} OKRs`}
              users={users}
              selectedUserId={selectedUserId}
              handleToggleChange={handleToggleChange}
              currentUserId={currentUserId}
              loading={loadingUsers}
            />
          </div>
          <div className={styles.okr}>
            {users.length
              ? (
                <OkrList
                  toggleExpandedUserSearch={() => toggleUserSearchCollapse(!isUserSearchCollapsed)}
                  isUserSearchCollapsed={isUserSearchCollapsed}
                  location={location}
                  onKRSelecting={selectKeyResult}
                  user={getUserById(selectedUserId)}
                  isMentor={isMentor}
                />
              ) : (
                <div className={styles.noDataContainer}>
                  <NoDataContainer
                    text="Nothing to show"
                  />
                </div>
              )}
          </div>
        </div>
      </LoaderWrapper>
    </div>
  );
};

const mapStateToProps = state => {
  const { usersOkrs: { usersOkrs, requests } } = state;
  const usersData = usersOkrs as IUsersOkrsState;
  return {
    currentUserId: state.user.user.id,
    users: usersData.users,
    loadingUsers: requests.users.loading,
    selectedUserId: usersData.selectedUserId,
    firstTimeLoading: usersOkrs.firstTimeLoading,
    usersCount: usersData.usersCount,
    okrsCount: usersData.okrsCount,
    showMentees: usersData.showMentees
  };
};

const mapDispatchToProps = {
  fetchUsers: fetchUsersRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersOkrsPage);

import React, { useEffect, useState } from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import styles from './styles.module.scss';
import useClipboard from 'react-hook-clipboard';

interface IPopupForLinkProps {
  feedbackId: string;
}

const PopupForLink = ({ feedbackId }: IPopupForLinkProps) => {
  const [open, setOpen] = useState(false);
  const [clipboard, copyToClipboard] = useClipboard();

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [open]);
  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };
  return (
    <Popup
      content="Copied!"
      trigger={(
        <Icon
          name="linkify"
          onClick={() => copyToClipboard(`${window.location.href
            .split('/feedback/')[0]}/form/${feedbackId}`)}
        />
      )}
      on="click"
      open={open}
      onOpen={() => onOpen()}
      onClose={() => onClose()}
      flowing
      hoverable
    />
  );
};

export default (PopupForLink);

import { callOkrApi } from '../../../helpers/webApiHelper';
import {
  IAddKeyResultsToObjectivesToOkrsIds,
  IKeyResultsToObjectivesToOkrsIds,
  IObjectivesToOkrsIds,
  IOkr,
  IOkrToSave,
  IOkrExtended,
  IKeyResultUpdatingRequest
} from '../models/okr';
import { IMoveKeyResultData } from '../containers/OkrView';

export const getOkrs = async (userId: string): Promise<IOkr[]> => {
  const response = await callOkrApi({
    endpoint: `/api/users/${userId}/okrs`,
    type: 'GET'
  });
  return response.json();
};

export const saveOkr = async (payload: IOkrToSave): Promise<string> => {
  const response = await callOkrApi({
    endpoint: '/api/okrs',
    type: 'POST',
    requestData: {
      ...payload
    }
  });
  return response.json();
};

export const deleteOkr = async (okrId: string): Promise<string> => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}`,
    type: 'DELETE'
  });
  return response.json();
};

export const getExtendedOkr = async (okrId: string): Promise<IOkrExtended> => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}`,
    type: 'GET'
  });

  return response.json();
};

export const removeKeyResultFromOkr = async ({ okrId, objectiveId, keyResultId }: IKeyResultsToObjectivesToOkrsIds) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}/objectives/${objectiveId}/key-results/${keyResultId}`,
    type: 'DELETE'
  });

  return response.json();
};

export const addKeyResultToOkr = async ({
  okrId,
  objectiveId,
  isCustom,
  newKeyResult,
  keyResultsId,
  fromOkrId
}: IAddKeyResultsToObjectivesToOkrsIds) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}/objectives/${objectiveId}/key-results`,
    type: 'PUT',
    requestData: {
      okrId,
      objectiveId,
      isCustom,
      newKeyResult,
      fromOkrId,
      keyResultsId
    }
  });

  return response.json();
};

export const updateOKRStatus = async (okrId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}/updatestatus`,
    type: 'PUT'
  });

  return response.json();
};

export const addObjectiveToOkr = async ({ okrId, objectiveId }: IObjectivesToOkrsIds) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}/objectives`,
    type: 'POST',
    requestData: {
      okrId,
      objectiveId
    }
  });

  return response.json();
};

export const removeObjectiveFromOkr = async ({ okrId, objectiveId }: IObjectivesToOkrsIds) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}/objectives/${objectiveId}`,
    type: 'DELETE'
  });

  return response.json();
};

export const fetchDates = async (userId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/users/${userId}/okrs/dates`,
    type: 'GET'
  });

  return response.json();
};

export const fetchOkrsToAddKeyResult = async (userId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/users/${userId}/okrs/data`,
    type: 'GET'
  });

  return response.json();
};

export const getAllOkrs = async () => {
  const response = await callOkrApi({
    endpoint: '/api/okrs/',
    type: 'GET'
  });

  return response.json();
};

export const getOkrsStats = async filter => {
  const response = await callOkrApi({
    endpoint: '/api/okrs/stats',
    type: 'GET',
    queryParams: filter
  });

  return response.json();
};

export const getTopMarkedOkr = async filter => {
  const response = await callOkrApi({
    endpoint: '/api/okrs/top',
    type: 'GET',
    queryParams: filter
  });

  return response.json();
};

export const fetchOkrObjectives = async (okrId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}/objectives`,
    type: 'GET'
  });

  return response.json();
};

export const moveKeyResult = (data: IMoveKeyResultData) => callOkrApi({
  endpoint: `/api/okrs/move/${data.keyResultId}`,
  type: 'POST',
  requestData: data
});

export const proposeUpdating = async (data: IKeyResultUpdatingRequest) => {
  const response = await callOkrApi({
    endpoint: '/api/okrs/proposeUpdating',
    type: 'PUT',
    requestData: data
  });

  return response.json();
};

export const confirmUpdating = (data: IKeyResultUpdatingRequest) => callOkrApi({
  endpoint: '/api/okrs/confirmUpdating',
  type: 'PUT',
  requestData: data
});

export const removeSuggestion = (data: IKeyResultUpdatingRequest) => callOkrApi({
  endpoint: '/api/okrs/discardSuggestion',
  type: 'PUT',
  requestData: data
});

import { all } from 'redux-saga/effects';
import projectPageSagas from '../containers/ProjectsList/sagas';
import saveProjectSagas from '../components/SaveProjectModal/sagas';

export default function* projectsSagas() {
  yield all([
    projectPageSagas(),
    saveProjectSagas()
  ]);
}

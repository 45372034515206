import React, { useEffect, useState } from 'react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import {
  selectedTemplateChangedRoutine,
  templateDeletedRoutine,
  templateUpdateRoutine,
  templateSavedRoutine,
  fetchAllTemplatesRoutine
} from 'screens/Feedback/routines';
import { Button, Confirm } from 'semantic-ui-react';
import { IForm } from 'screens/Feedback/model/Forms/IForm';
import { getEmptyFormListPageTemplate, getFormListPageTemplate } from './elements';
import LoaderWrapper from 'components/LoaderWrapper';

interface ITemplatesPageProps {
  fetchTemplates: IBindingCallback1<string>;
  saveTemplate: IBindingCallback1<IForm>;
  updateTemplate: IBindingCallback1<IForm>;
  deleteTemplate: IBindingCallback1<string>;
  selectedTemplateChanged: IBindingCallback1<string>;
  loading: boolean;
  templates?: IForm[];
  selectedForm: IForm;
  id: string;
}

const ManageTemplates: React.FunctionComponent<ITemplatesPageProps> = ({
  fetchTemplates,
  saveTemplate,
  deleteTemplate,
  updateTemplate,
  selectedTemplateChanged,
  loading,
  templates,
  selectedForm,
  id
}: ITemplatesPageProps) => {
  const [init, setInit] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formList, setFormList] = useState(templates);
  const [modalOpened, setModalOpened] = useState(false);
  const [deletedFormId, setDeletedFormId] = useState<string | undefined>();

  useEffect(() => {
    selectedTemplateChanged(null);
  }, []);
  useEffect(() => {
    setFormList(templates);
  }, [templates]);

  useEffect(() => {
    if (!init) {
      fetchTemplates(id);
      setInit(true);
    }
  }, [init]);

  const addNewButtonHandler = () => {
    setIsEditing(false);
    selectedTemplateChanged(null);
    setIsCreating(true);
  };

  const createForm = form => {
    saveTemplate(form);
    selectedTemplateChanged(null);
    setIsCreating(false);
  };

  const deleteFormClicked = formId => {
    setDeletedFormId(formId);
    setModalOpened(true);
  };

  const deletingFormConfirmedHandler = formId => {
    setFormList(templates.filter(f => f.id !== formId));
    deleteTemplate(formId);
    setModalOpened(false);
  };

  const selectFormHandler = formId => {
    selectedTemplateChanged(formId);
    setIsCreating(false);
    setIsEditing(true);
  };

  const createFormSettings = {
    title: 'Create new template',
    formSavedHandler: form => {
      createForm(form);
      setIsCreating(false);
    },
    formCanceledHandler: () => setIsCreating(false)
  };

  const editFormSettings = {
    title: 'Edit template',
    formSavedHandler: form => {
      updateTemplate(form);
    },
    formCanceledHandler: () => {
      setIsEditing(false);
    }
  };

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.formsViewContainer}>
        <div className={styles.sectionHeader}>
          <h1>Templates</h1>
          {!isCreating
            ? (
              <Button
                primary
                icon="plus square"
                content="Add new"
                onClick={() => addNewButtonHandler()}
                className={styles.formButton}
              />
            )
            : (
              <>
              </>
            )}
        </div>
        <LoaderWrapper loading={!init || loading}>
          {formList.length
            ? (
              getFormListPageTemplate(
                isCreating,
                isEditing,
                formList,
                createFormSettings,
                editFormSettings,
                selectFormHandler,
                deleteFormClicked,
                selectedForm
              )
            )
            : (
              getEmptyFormListPageTemplate(isCreating, createFormSettings)
            )}
        </LoaderWrapper>
      </div>
      <Confirm
        open={modalOpened}
        header="Are you sure?"
        content="If this template contains questions all of them will be deleted"
        onCancel={() => setModalOpened(false)}
        onConfirm={() => deletingFormConfirmedHandler(deletedFormId)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      templates: {
        templates,
        selectedForm
      }
    }
  } = state;
  const {
    feedbackPage: {
      reducers: {
        getTemplates: {
          loading
        }
      }
    }
  } = state;
  const { user: { user: { id } } } = state;
  return {
    templates,
    selectedForm,
    loading,
    id
  };
};

const mapDispatchToProps = {
  fetchTemplates: fetchAllTemplatesRoutine,
  saveTemplate: templateSavedRoutine,
  updateTemplate: templateUpdateRoutine,
  deleteTemplate: templateDeletedRoutine,
  selectedTemplateChanged: selectedTemplateChangedRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageTemplates);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import parse from 'html-react-parser';
import moment from 'moment';

import NewsCommentsList from 'components/NewsCommentsList';
import LoaderWrapper from 'components/LoaderWrapper';

import { IBindingCallback1, IBindingAction } from 'models/callback';
import { ICommentToAdd } from 'screens/Announcements/models/dto/ICommentToAdd';

import {
  fetchAnnouncementByIdRoutine,
  removeAnnouncementDetailsRoutine,
  commentAnnouncementRoutine
} from '../../routines';

import styles from './styles.module.scss';

export interface IExtendedAnnouncementProps {
  announcementDetails: any;
  announcementId: string;
  match: any;
  requests: {
    [propName: string]: {
      loading: boolean;
      error: object | string;
    };
  };
  fetchAnnouncementById: IBindingCallback1<string>;
  commentAnnouncement: IBindingCallback1<ICommentToAdd>;
  removeAnnouncementDetails: IBindingAction;
  history: any;
}

const AnnouncementItemDetails: React.FunctionComponent<IExtendedAnnouncementProps> = ({
  announcementDetails,
  announcementId,
  match,
  fetchAnnouncementById,
  removeAnnouncementDetails,
  commentAnnouncement,
  history,
  requests
}) => {
  useEffect(() => {
    fetchAnnouncementById(announcementId);
  }, [match.params]);

  useEffect(() => () => removeAnnouncementDetails(), []);

  const goBack = () => history.goBack();

  const onCommentSave = (comment: string) => commentAnnouncement({ comment, announcementId });

  return (
    <div className={styles.loadingWrapper}>
      <LoaderWrapper loading={requests.announcementDetails.loading}>
        {announcementDetails && (
          <div className={styles.container}>
            <div className={styles.announcementContainer}>
              <div className={styles.startBlock}>
                <div className={styles.goBackButton}>
                  <Button icon labelPosition="left" onClick={goBack}>
                    back
                    <Icon name="arrow left" />
                  </Button>
                </div>
                <div className={styles.dates}>
                  {moment(announcementDetails.date).format('DD/MM/YYYY')}
                </div>
              </div>

              <div className={styles.headerContainer}>
                <h1 className={styles.header}>{announcementDetails.title}</h1>
              </div>

              <div className={styles.announcementContent}>
                {parse(announcementDetails.text)}
              </div>

              <div className={styles.commentsContainer}>
                <NewsCommentsList
                  comments={announcementDetails.comments}
                  onCommentSave={onCommentSave}
                />
              </div>
            </div>
          </div>
        )}
      </LoaderWrapper>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { announcementId } = props.match.params;
  const {
    requests: { announcementDetails: announcementDetailsRequest },
    announcementDetails
  } = state.announcements;

  return {
    announcementDetails,
    announcementId,
    requests: {
      announcementDetails: announcementDetailsRequest
    }
  };
};

const mapDispatchToProps = {
  fetchAnnouncementById: fetchAnnouncementByIdRoutine,
  removeAnnouncementDetails: removeAnnouncementDetailsRoutine,
  commentAnnouncement: commentAnnouncementRoutine
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementItemDetails);

import { combineReducers } from 'redux';
import { userInfo } from '../components/Info/reducer';
import updatePassword from '../components/modals/ResetPasswordModal/reducer';
import { reducerCreator } from 'helpers/reducerHelper';
import { fetchUserReviewsRoutine } from '../routines';

const requests = combineReducers({
  updatePassword,
  userReviews: reducerCreator([fetchUserReviewsRoutine.TRIGGER])
});

const userProfileReducer = combineReducers({
  requests,
  userInfo
});

export default userProfileReducer;

import { callOkrApi } from 'helpers/webApiHelper';
import { IOkrToSave, IOkrNoteToSave, IObjectivesToOkrsIds } from 'screens/OKR/models/okr';

export const getCurrent = async (userId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/users/${userId}/okrs/current`,
    type: 'GET'
  });
  return response.json();
};

export const getOkr = async (okrId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}`,
    type: 'GET'
  });
  return response.json();
};

export const saveOkr = async (payload: IOkrToSave): Promise<string> => {
  const response = await callOkrApi({
    endpoint: '/api/okrs',
    type: 'POST',
    requestData: {
      ...payload
    }
  });

  return response.json();
};

export const addObjectiveToOkr = async ({ okrId, objectiveId, userId }: IObjectivesToOkrsIds) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}/objectives`,
    type: 'POST',
    requestData: {
      okrId,
      objectiveId,
      userId
    }
  });

  return response.json();
};

export const addNoteToOkr = async ({ okrId, note }: IOkrNoteToSave) => {
  const response = await callOkrApi({
    endpoint: `/api/okrs/${okrId}/addNote`,
    type: 'PUT',
    requestData: {
      note
    }
  });

  return response.json();
};

import { all } from 'redux-saga/effects';
import requestsPageSagas from '../containers/FeedbackRequests/sagas';
import importCsvSagas from '../components/CsvImportButton/sagas';
import formsPageSagas from '../containers/NewManageForms/sagas';
import saveSummarySagas from '../components/FeedbackSummary/sagas';
import createAssign from '../components/RequestAssign/sagas';
import questionsBlocksPageSagas from '../containers/QuestionsBlocks/sagas';
import QuestionsSagas from '../components/Questions/sagas';
import manageRequestsPageSagas from '../containers/ManageRequests/sagas';
import manageRequestsByLinkPageSagas from '../containers/ManageRequestsByLink/sagas';
import templatesPageSagas from '../containers/ManageTemplates/sagas';

export default function* feedbackSagas() {
  yield all([
    createAssign(),
    requestsPageSagas(),
    importCsvSagas(),
    saveSummarySagas(),
    formsPageSagas(),
    questionsBlocksPageSagas(),
    requestsPageSagas(),
    QuestionsSagas(),
    manageRequestsPageSagas(),
    manageRequestsByLinkPageSagas(),
    templatesPageSagas()
  ]);
}

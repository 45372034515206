import { Routine } from 'redux-saga-routines';
import { IAnnouncement } from 'screens/Announcements/models/IAnnouncement';
import {
  fetchAnnouncementsRoutine,
  fetchAnnouncementByIdRoutine,
  saveAnnouncementsRoutine,
  removeAnnouncementsRoutine,
  commentAnnouncementRoutine
} from 'screens/Announcements/routines';
import { IAnnouncementToSave } from 'screens/Announcements/models/dto/IAnnouncementToSave';

interface IAnnouncementState {
  announcements: IAnnouncement[];
  announcementToUpdate: IAnnouncementToSave;
}

const initialState: IAnnouncementState = { announcements: [], announcementToUpdate: null };

export const announcements = (state = initialState, action: Routine<any>): IAnnouncementState => {
  const { type, payload } = action;

  switch (type) {
    case fetchAnnouncementsRoutine.SUCCESS:
      return { ...state, announcements: payload };
    case fetchAnnouncementByIdRoutine.SUCCESS:
      return { ...state, announcementToUpdate: payload };
    case saveAnnouncementsRoutine.SUCCESS:
      const updatedAnnouncement = payload;
      if (!state.announcements.some(a => a.id === updatedAnnouncement.id)) {
        return { ...state, announcements: [updatedAnnouncement, ...state.announcements] };
      }
      return {
        ...state,
        announcements: state.announcements.map(a => (
          a.id === updatedAnnouncement.id ? { ...updatedAnnouncement, comments: a.comments, author: a.author } : a))
      };
    case commentAnnouncementRoutine.SUCCESS:
      return {
        ...state,
        announcements: state.announcements.map(announcement => (
          announcement.id === payload.announcementId ? {
            ...announcement,
            comments: [action.payload, ...announcement.comments]
          } : announcement
        ))
      };
    case removeAnnouncementsRoutine.SUCCESS:
      return {
        ...state,
        announcements: state.announcements.filter(a => (
          a.id !== payload))
      };
    default:
      return state;
  }
};

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IBindingCallback1 } from 'models/callback';
import { fetchUserOkrsRoutine } from 'screens/UsersOkrs/routines';
import styles from './styles.module.scss';
import { Popup, Input, InputOnChangeData, Checkbox, Icon } from 'semantic-ui-react';
import { IUser } from 'screens/UsersOkrs/models/IUser';
import Avatar from 'components/UserAvatar';
import LoaderWrapper from 'components/LoaderWrapper';
import { Link } from 'react-router-dom';

interface IUsersListProps {
  users: IUser[];
  hideExpandedUserSearch: IBindingCallback1<boolean>;
  toggleUserClick: IBindingCallback1<string>;
  selectedUserId: string;
  handleToggleChange: IBindingCallback1<boolean>;
  currentUserId: string;
  loading: boolean;
  statsText: string;
  showMentees: boolean;
}

const UsersList: React.FunctionComponent<IUsersListProps> = ({
  users,
  selectedUserId,
  hideExpandedUserSearch,
  toggleUserClick,
  handleToggleChange,
  currentUserId,
  loading,
  showMentees,
  statsText
}: IUsersListProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [orderedUsers, setOrderedUsers] = useState(users);

  useEffect(() => {
    setOrderedUsers(users.filter(u => u.fullName.toUpperCase().includes(searchValue)));
  }, [users]);

  const toggleUserBlockClick = (userId: string) => {
    if (selectedUserId !== userId) {
      toggleUserClick(userId);
    }
  };

  const toggleInputChange = (e, data: InputOnChangeData) => {
    setOrderedUsers(
      users.filter(u => u.fullName.toUpperCase().includes(data.value.trim().toUpperCase()))
    );
    setSearchValue(data.value.toUpperCase());
  };

  const handleToggleChanging = () => {
    handleToggleChange(!showMentees);
  };
  const renderMentor = (id: string, name: string, logoLink: string) => (
    name ? (
      <div className={styles.mentorBlock}>
        {currentUserId !== id ? (
          <Popup
            position="right center"
            content={<p>{name}</p>}
            trigger={(
              <div className={styles.mentorAvatar}>
                <Avatar width="15px" height="15px" name={name} logoLink={logoLink} fontSize="8px" />
              </div>
            )}
          />
        ) : <Icon name="star" className={styles.starIcon} />}
      </div>
    ) : ''
  );

  const renderUserBlock = (user: IUser) => (
    <div
      className={`${styles.user} ${user.id === selectedUserId ? styles.selected : ''}`}
      key={user.id}
      onClick={() => toggleUserBlockClick(user.id)}
      role="presentation"
    >
      <div className={styles.logo}>
        <Avatar name={user.fullName} logoLink={user.logoLink} />
        {renderMentor(user.mentorId, user.mentorName, user.mentorLogo)}
      </div>
      <div className={styles.name}>
        <Link to={`/profile/${user.id}`} onClick={e => e.stopPropagation()}>{user.fullName}</Link>
        <div className={styles.okrsCount}>
          {`Okrs - ${user.okrsCount}`}
        </div>
      </div>
    </div>
  );

  const renderNoMentees = () => (
    <p className={styles.noMentee}>You don&apos;t have mentees</p>
  );

  return (
    <div>
      <div className={styles.actionsBlock}>
        <div className={styles.heading}>
          <p className={styles.stats}>{statsText}</p>
          <Icon
            className={styles.hideUserSearchBtn}
            name="close"
            onClick={hideExpandedUserSearch}
          />
        </div>
        <div className={styles.search}>
          <Input onChange={toggleInputChange} iconPosition="left" icon="search" placeholder="Search by user name" />
        </div>
        <div className={styles.toggle}>
          <Checkbox
            checked={showMentees}
            onChange={handleToggleChanging}
          />
          <p onClick={handleToggleChanging} role="presentation">Only my mentees</p>
        </div>
      </div>
      <div className={styles.loaderWrapper}>
        <LoaderWrapper loading={loading}>
          {users.length ? orderedUsers.map(user => renderUserBlock(user)) : renderNoMentees()}
        </LoaderWrapper>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  toggleUserClick: fetchUserOkrsRoutine
};

export default connect(null, mapDispatchToProps)(UsersList);

import React, { SyntheticEvent } from 'react';
import { boundMethod } from 'autobind-decorator';
import {
  Loader,
  Input,
  Checkbox,
  Icon,
  Confirm
} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import { IObjectiveStrict } from 'screens/OKR/models/objective/IObjectiveStrict';
import { IObjectiveFilter } from 'screens/OKR/models/objective/IObjectiveFilter';
import debounce from 'debounce';
import { Searchable } from 'screens/OKR/enums';
import parentStyles from '../LibraryView/styles.module.scss';
import { IBindingCallback1, IBindingAction } from 'models/callback';
import checkboxStyles from '../../../../customStyles/styles.module.scss';
import { NoDataContainer } from 'components/NoDataContainer';
import {
  fetchMoreObjectivesRoutine,
  updateObjectiveFiltersRoutine,
  deleteObjectiveRoutine,
  toggleIsCustomObjectiveStatusRoutine,
  removeObjectiveRoutine,
  toggleObjectiveIsHiddenStatusRoutine
} from '../../routines';
import { connect } from 'react-redux';
import Select, { ValueType } from 'react-select';
import styles from './styles.module.scss';
import { IToggleStatus } from 'screens/Library/model/IToggleStatus';
import { IModalData } from 'screens/Library/model/IModalData';
import { ModalType } from 'screens/Library/model/enums/IModalType';
import { SELECT_MENU_HEIGHT } from '../KeyResultsAdminPage/common';

interface IObjectivesAdminPageState {
  isConfirmModalOpen: boolean;
  globalObjectiveId: string;
  modalData?: IModalData;
}

export interface IObjectiveAdminPageProps {
  objectives?: IObjectiveStrict[];
  updateObjective: IBindingCallback1<IObjectiveStrict>;
  hasMoreObjectives: boolean;
  fetchMoreObjectives: IBindingCallback1<IObjectiveFilter>;
  updateObjectiveFilters: IBindingAction;
  deleteObjective: IBindingCallback1<string>;
  toggleObjectiveIsCustomStatus: IBindingCallback1<IToggleStatus>;
  toggleObjectiveIsHiddenStatus: IBindingCallback1<IToggleStatus>;
  removeObjective: IBindingAction;
  skip: number;
  limit: number;
}

class ObjectivesAdminPage extends React.Component<IObjectiveAdminPageProps, IObjectivesAdminPageState> {
  objectivesFilter: IObjectiveFilter = {
    from: 0,
    count: this.props.limit,
    name: '',
    searchable: null,
    showHidden: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isConfirmModalOpen: false,
      globalObjectiveId: '',
      modalData: { status: null, modalType: ModalType.Delete }
    };

    this.onChangeName = debounce(this.onChangeName, 500);
  }

  componentWillUnmount() {
    const { removeObjective } = this.props;
    removeObjective();
  }

  onChangeName = value => {
    this.onChangeData('name', value.trim());
  }

  onChangeData = (fieldName, value) => {
    const { updateObjectiveFilters } = this.props;
    updateObjectiveFilters();

    this.objectivesFilter[fieldName] = value;
    this.loadObjectives();
  }

  handleDeleteConfirm = () => {
    const { globalObjectiveId } = this.state;
    const { deleteObjective } = this.props;

    deleteObjective(globalObjectiveId);
    this.setState({ isConfirmModalOpen: false });
  }

  toggleIsCustomConfirm = () => {
    const { globalObjectiveId, modalData: { status } } = this.state;
    const { toggleObjectiveIsCustomStatus } = this.props;
    const { searchable } = this.objectivesFilter;

    const filterStatus = searchable === null ? null : searchable === Searchable.Custom;

    toggleObjectiveIsCustomStatus({ id: globalObjectiveId, status: !status, filterStatus });
    this.handleCancel();
  }

  toggleHideStatus = () => {
    const { globalObjectiveId, modalData: { status } } = this.state;
    const { toggleObjectiveIsHiddenStatus } = this.props;
    const { showHidden } = this.objectivesFilter;

    toggleObjectiveIsHiddenStatus({ id: globalObjectiveId, status: !status, filterStatus: showHidden });
    this.handleCancel();
  }

  handleCancel = () => {
    this.setState({ isConfirmModalOpen: false });
  }

  getConfirmModalText = ({ modalType, status }: IModalData) => {
    switch (modalType) {
      case ModalType.Delete:
        return 'arhive this objective?';
      case ModalType.IsCustom:
        return `make this objective ${status ? 'global' : 'searchable'}?`;
      case ModalType.IsHidden:
        return status ? 'make this objective visible?' : 'hide this objective?';
      default:
        return 'default placeholder';
    }
  }

  getConfirmEvent = (modalType: ModalType) => {
    switch (modalType) {
      case ModalType.Delete:
        return this.handleDeleteConfirm;
      case ModalType.IsCustom:
        return this.toggleIsCustomConfirm;
      case ModalType.IsHidden:
        return this.toggleHideStatus;
      default:
        return () => ({});
    }
  }

  openConfirmModal(e: SyntheticEvent, objectiveId: string, modalData: IModalData) {
    e.stopPropagation();
    this.setState({ isConfirmModalOpen: true, globalObjectiveId: objectiveId, modalData });
  }

  @boundMethod
  loadObjectives() {
    const { fetchMoreObjectives, hasMoreObjectives, skip } = this.props;
    this.objectivesFilter.from = skip;

    if (hasMoreObjectives) {
      fetchMoreObjectives(this.objectivesFilter);
    }
  }

  renderFilters = (customStyles, accessibilityOptions, hiddenStatusOption) => (
    <div className={styles.filtersWrapper}>
      <Input
        placeholder="Search By Name"
        icon="search"
        className={styles.input}
        onChange={(_, data) => this.onChangeName(data.value)}
      />
      <Select
        isClearable
        placeholder="Searchable"
        options={accessibilityOptions}
        onChange={(data: ValueType<any, any>) => this.onChangeData('searchable', data?.value as Searchable)}
        className={styles.select}
        styles={customStyles}
      />
      <Select
        isClearable
        placeholder="Hidden items"
        options={hiddenStatusOption}
        onChange={data => this.onChangeData('showHidden', data?.value)}
        className={styles.select}
        defaultValue={hiddenStatusOption[0]}
        styles={customStyles}
      />
    </div>
  )

  renderHeader = () => (
    <div className={styles.tableHeader}>
      <div className={styles.headerCell}>Name</div>
      <div className={styles.headerCell}>Searchable</div>
      <div className={styles.headerCell} />
    </div>
  )

  renderTableRow = (objective: IObjectiveStrict) => {
    const { updateObjective } = this.props;

    return (
      <div className={styles.tableRow} key={objective.id}>
        <div className={styles.tableCell}>
          {objective.name}
        </div>
        <div className={styles.tableCell}>
          <Checkbox
            className={checkboxStyles.animatedCheckbox}
            checked={!objective.isCustom}
            onClick={e => this.openConfirmModal(
              e, objective.id, { modalType: ModalType.IsCustom, status: objective.isCustom }
            )}
          />
        </div>
        <div className={styles.tableCell}>
          <Icon
            name="edit"
            style={{ color: 'rgba(80, 80, 80, 1)', cursor: 'pointer' }}
            onClick={() => updateObjective(objective)}
          />
          <Icon
            name="trash"
            style={{ color: 'rgba(80, 80, 80, 1)', cursor: 'pointer' }}
            onClick={e => this.openConfirmModal(e, objective.id, { modalType: ModalType.Delete, status: true })}
          />
          <Icon
            name={objective.isHidden ? 'eye slash' : 'eye'}
            style={{ color: 'rgba(80, 80, 80, 1)', cursor: 'pointer' }}
            onClick={e => this.openConfirmModal(
              e, objective.id, { modalType: ModalType.IsHidden, status: objective.isHidden ?? false }
            )}
          />
        </div>
      </div>
    );
  }

  renderConfirm = (isConfirmModalOpen: boolean, modalData: IModalData) => (
    <Confirm
      content={`Still sure you want to ${this.getConfirmModalText(modalData)}`}
      open={isConfirmModalOpen}
      onCancel={this.handleCancel}
      onConfirm={this.getConfirmEvent(modalData.modalType)}
    />
  );

  render() {
    const { isConfirmModalOpen, modalData } = this.state;
    const { hasMoreObjectives, objectives } = this.props;
    const searchableOptions = Object.entries(Searchable).map(a => ({ label: a[0], value: a[1] }));
    const hiddenStatusOption = [
      { label: 'All except hidden', value: false },
      { label: 'Hidden only', value: true }
    ];
    const customStyles = {
      container: base => ({
        ...base,
        minHeight: SELECT_MENU_HEIGHT
      })
    };

    return (
      <div>
        {this.renderFilters(customStyles, searchableOptions, hiddenStatusOption)}
        {this.renderHeader()}
        {this.renderConfirm(isConfirmModalOpen, modalData)}
        <div className={parentStyles.infiniteScrollWrapper}>
          <InfiniteScroll
            pageStart={0}
            loader={<Loader style={{ padding: '20px' }} key={0} active inline="centered" />}
            loadMore={this.loadObjectives}
            hasMore={hasMoreObjectives}
            useWindow={false}
          >
            { !objectives.length && !hasMoreObjectives
              ? (
                <div className={styles.noDataContainer}>
                  <NoDataContainer text="There are no key results" />
                </div>
              )
              : (
                <div className={styles.tableBody}>
                  {objectives.map(objective => (
                    this.renderTableRow(objective)
                  ))}
                </div>
              )}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { objectives: { objectives, hasMoreObjectives, skip, limit } } = state.okr.library;

  return {
    objectives,
    hasMoreObjectives,
    skip,
    limit
  };
};

const mapDispatchToProps = {
  fetchMoreObjectives: fetchMoreObjectivesRoutine,
  updateObjectiveFilters: updateObjectiveFiltersRoutine,
  deleteObjective: deleteObjectiveRoutine,
  toggleObjectiveIsCustomStatus: toggleIsCustomObjectiveStatusRoutine,
  toggleObjectiveIsHiddenStatus: toggleObjectiveIsHiddenStatusRoutine,
  removeObjective: removeObjectiveRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectivesAdminPage);

import moment from 'moment';

export const formatDate = (date: string | number | Date) => moment(date).format('Do MMM YYYY');

export const parseDateIntoMoment = (
  date: string | number | Date,
  defaultValue = moment()
) => (date ? moment(date) : defaultValue);

export const sortByDate = (data: any, field: string) => data.sort((a, b) => {
  if (new Date(a[field]) > new Date(b[field])) {
    return -1;
  }

  if (new Date(a[field]) < new Date(b[field])) {
    return 1;
  }

  return 0;
});

import { callOkrApi } from 'helpers/webApiHelper';
import { IAchievement } from '../model/IAchievement';

export const fetchUserAchievements = async (userId: string): Promise<IAchievement[]> => {
  const response = await callOkrApi({
    endpoint: `/api/achievements/${userId}`,
    type: 'GET'
  });
  return response.json();
};

import React from 'react';
import { Grid, Segment, GridRow, GridColumn } from 'semantic-ui-react';
import { IKeyResultBase } from 'screens/OKR/models/key-result/IKeyResultBase';
import AdminDashboardCard from '../AdminDashboardCard';
import AdminDashboardTopTable from '../AdminDashboardTopTable';
import { IObjectiveTop } from 'screens/OKR/models/objective/IObjectiveTop';
import { ITopMarkedOkr } from 'screens/OKR/models/okr/ITopMarkedOkr';
import AdminDashboardTopOkrTable from '../AdminDashboardTopOkrTable';
import LoaderWrapper from 'components/LoaderWrapper';
import styles from './styles.module.scss';
import { IAdminDashboardState } from './reducer';
import {
  adFetchOkrStatsRoutine,
  adFetchTopKeyResultsRoutine,
  adFetchTopMarkedOkrRoutine,
  adFetchTopObjectivesRoutine
} from '../routines';
import { connect } from 'react-redux';
import { IBindingCallback1, IBindingAction } from 'models/callback';

export enum DateType {
  year,
  month
}
interface IAdminDashboardProps {
  fetchOkrsStats: IBindingAction;
  fetchTopData: {
    objectives: IBindingCallback1<DateType>;
    okr: IBindingCallback1<DateType>;
    keyResults: IBindingCallback1<DateType>;
  };

  activeOkrsCount: number;
  avgMark: number;
  topKeyResults: IKeyResultBase[];
  keyResultsThisYearCount: number;
  topObjectives: IObjectiveTop[];
  peopleJoinedThisYearCount: number;
  topMarkedOkr: ITopMarkedOkr;
  loading: boolean;
}

interface IAdminDashboardStates {
  lastFetchedDataDateType: {
    objectives: DateType;
    okr: DateType;
    keyResults: DateType;
  };
}

class AdminDashboard extends React.Component<IAdminDashboardProps, IAdminDashboardStates> {
  constructor(props) {
    super(props);

    this.state = {
      lastFetchedDataDateType: {
        keyResults: DateType.year,
        objectives: DateType.year,
        okr: DateType.year
      }
    };
  }

  componentDidMount() {
    const { fetchOkrsStats } = this.props;
    fetchOkrsStats();
  }

  loadTopData = (dataType: string) => (dateType: DateType) => {
    const { fetchTopData } = this.props;
    const { lastFetchedDataDateType } = this.state;

    if (lastFetchedDataDateType[dataType] !== dateType) {
      fetchTopData[dataType](dateType);

      const newStateData = { ...lastFetchedDataDateType };
      newStateData[dataType] = dateType;
      this.setState({ lastFetchedDataDateType: newStateData });
    }
  }

  render() {
    const {
      activeOkrsCount,
      avgMark,
      keyResultsThisYearCount,
      topKeyResults,
      peopleJoinedThisYearCount,
      topObjectives,
      topMarkedOkr,
      loading
    } = this.props;
    const {
      lastFetchedDataDateType
    } = this.state;

    return (
      <div className={styles.container}>
        <LoaderWrapper loading={loading}>
          <div className={styles.statsCards}>
            <AdminDashboardCard
              header="OKRs in progress"
              description={activeOkrsCount ?? 0}
            />
            <AdminDashboardCard
              header="Average OKR mark in system"
              description={avgMark ?? 'No OKR\'s'}
            />
            <AdminDashboardCard
              header="New key results this year"
              description={keyResultsThisYearCount ?? 0}
            />
            <AdminDashboardCard
              header="New people this year"
              description={peopleJoinedThisYearCount ?? 0}
            />
          </div>
          <Grid stackable>
            <Grid.Row columns="2">
              <Grid.Column>
                <Segment basic>
                  <AdminDashboardTopTable
                    keyResults
                    loadResults={this.loadTopData('keyResults')}
                    data={topKeyResults}
                    activeItem={lastFetchedDataDateType.keyResults}
                    elementsCount={5}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment basic>
                  <AdminDashboardTopTable
                    loadResults={this.loadTopData('objectives')}
                    data={topObjectives}
                    activeItem={lastFetchedDataDateType.objectives}
                    elementsCount={5}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <GridRow columns="2">
              <GridColumn>
                <Segment basic>
                  <AdminDashboardTopOkrTable
                    loadResults={this.loadTopData('okr')}
                    data={topMarkedOkr}
                    activeItem={lastFetchedDataDateType.okr}
                  />
                </Segment>
              </GridColumn>
            </GridRow>
          </Grid>
        </LoaderWrapper>
      </div>
    );
  }
}

const mapStateToProps = ({
  okr: { adminDashboard, requests: { okrStats } }
}) => {
  const adminDashboardState = adminDashboard as IAdminDashboardState;
  return {
    loading: okrStats.loading,
    ...adminDashboardState
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTopData: {
    objectives: date => dispatch(adFetchTopObjectivesRoutine.trigger(date)),
    okr: date => dispatch(adFetchTopMarkedOkrRoutine.trigger(date)),
    keyResults: date => dispatch(adFetchTopKeyResultsRoutine.trigger(date))
  },
  fetchOkrsStats: () => dispatch(adFetchOkrStatsRoutine.trigger())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

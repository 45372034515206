import { Routine } from 'redux-saga-routines';

import { IAnnouncement } from 'screens/Announcements/models/IAnnouncement';

import {
  fetchAnnouncementByIdRoutine,
  commentAnnouncementRoutine,
  removeAnnouncementDetailsRoutine
} from '../../routines';

export const announcementDetails = (
  state: IAnnouncement = null,
  action: Routine<any>
): IAnnouncement => {
  const { type, payload } = action;

  switch (type) {
    case fetchAnnouncementByIdRoutine.SUCCESS:
      return payload;
    case commentAnnouncementRoutine.SUCCESS:
      return {
        ...state,
        comments: [...state.comments, payload]
      };
    case removeAnnouncementDetailsRoutine.TRIGGER:
      return null;
    default:
      return state;
  }
};

import React from 'react';
import { formatDate } from 'helpers/dateFormatHelper';

interface IDateLabelProps {
  startTime: string | number | Date;
  endTime: string | number | Date;
  styles: { [key: string]: string };
}

const DateLabel = ({
  startTime,
  endTime,
  styles
}: IDateLabelProps) => (
  <span className={styles.dates}>
    <span className={styles.date}>
      {formatDate(startTime)}
      {' - '}
    </span>
    <span className={styles.date}>{formatDate(endTime)}</span>
  </span>
);

export default DateLabel;

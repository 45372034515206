import React from 'react';
import { Menu, Popup, Image, Label, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { IUser } from 'screens/Authorization/models/IUser';
import { history } from 'helpers/history';
import { IBindingAction, IBindingCallback1 } from 'models/callback/Callbacks';
import { logout } from 'screens/Authorization/services/authService';
import { withRouter } from 'react-router-dom';
import { increaseCounterRoutine } from 'screens/Notifications/routines';
import { fetchUserRoutine, removeUserRoutine } from 'screens/Authorization/routines';
import styles from './styles.module.scss';
import logo from '../../assets/images/company_name.svg';
import { boundMethod } from 'autobind-decorator';
import Avatar from 'components/UserAvatar';
import { IConnectedAction } from 'containers/Notifications/reducer';
import { connectActionRoutine, disconnectSocketsRoutine } from 'containers/Notifications/routines';
import NotificationsList from 'screens/Notifications/containers/NotificationsList';

export interface IHeaderProps {
  user: IUser;
  removeUser: IBindingAction;
  location: Location;
  increaseCounter: IBindingAction;
  fetchUser: IBindingAction;
  history: any;
  path: string;
  connectAction: IBindingCallback1<IConnectedAction>;
  disconnectSockets: IBindingAction;
}

export interface IHeaderState {
  activeItem: string;
  notificationsCounter: number;
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.user
      ? {
        activeItem: nextProps.path !== prevState.currentItem && (nextProps.path !== null)
          ? nextProps.path : prevState.activeItem,
        notificationsCounter: nextProps.user.notifications
      }
      : null;
  }

  constructor(props) {
    super(props);
    const { location: { pathname }, path } = this.props;
    this.state = {
      activeItem: pathname.substr(1) || path,
      notificationsCounter: 0
    };
  }

  componentDidMount() {
    const { notificationsCounter } = this.state;
    const { increaseCounter, fetchUser, connectAction } = this.props;

    const toggleNotificationMessage = () => {
      this.setState({
        notificationsCounter: notificationsCounter + 1
      });
      increaseCounter();
    };
    connectAction({ functionName: 'notification', action: toggleNotificationMessage, showSystemNotification: true });
    connectAction({ functionName: 'updateUserData', action: () => fetchUser() });
  }

  componentDidUpdate() {
    const { location } = this.props;
    const { activeItem } = this.state;
    if (location.pathname === '/profile' && activeItem !== 'profile') {
      this.setActiveItem('profile');
    } else if (location.pathname !== '/profile' && activeItem === 'profile') {
      this.setActiveItem(undefined);
    }
  }

  componentWillUnmount() {
    const { disconnectSockets } = this.props;
    disconnectSockets();
  }

  setActiveItem = (activeItem: string) => this.setState({ activeItem })

  handleItemClick = (_, name: string) => {
    this.setState({ activeItem: name });
    history.push(`/${name}`);
  };

  @boundMethod
  async logout() {
    const { removeUser } = this.props;
    await logout();
    removeUser();
    history.push('/');
  }

  render() {
    const { activeItem, notificationsCounter } = this.state;
    const { user } = this.props;

    const userprofilePopupContent = () => (
      <div className={styles.userProfilePopup}>
        <div className={styles.popUpHeader}>
          {`${user.firstName} ${user.lastName}`}
        </div>
        <div
          className={styles.popUpItem}
          onClick={e => this.handleItemClick(e, 'profile')}
          role="presentation"
        >
          <p>User Profile</p>
        </div>
        <div
          className={styles.popUpItem}
          onClick={this.logout}
          role="presentation"
        >
          <p>Log out</p>
        </div>
      </div>
    );

    const userProfile = user ? (
      <Menu.Item
        name="profile"
        active={activeItem === 'profile'}
        content={(
          <Popup
            trigger={(
              <div style={{ cursor: 'pointer' }}>
                <Avatar
                  height="30px"
                  width="30px"
                  name={`${user.firstName} ${user.lastName}`}
                  logoLink={user.logoLink}
                />
              </div>
            )}
            content={userprofilePopupContent()}
            on="click"
            position="bottom right"
            style={{ padding: '0' }}
          />
        )}
        style={{ padding: '5px', borderRadius: '50%', margin: '0', cursor: 'pointer' }}
      />
    ) : '';

    const notifications = user ? (
      <Menu.Item style={{ padding: 0, marginRight: '20px' }}>
        <Popup
          trigger={(
            <Icon name="bell outline" className={styles.icon} />
          )}
          on="click"
          className={styles.popup}
          position="bottom right"
        >
          <NotificationsList />
        </Popup>
        {notificationsCounter > 0 && (
        <Label floating circular size="tiny" className={styles.notiticationCounter}>
          {notificationsCounter}
        </Label>
        )}
      </Menu.Item>
    ) : '';

    return (
      user && (
        <Menu secondary className={styles.menu}>
          <Menu.Item
            onClick={e => this.handleItemClick(e, 'news')}
            className={styles.logoMenuItem}
            content={(
              <Image src={logo} alt="" className={styles.logo} id="binaryLogo" />
            )}
          />
          <Menu.Menu position="right" style={{ paddingRight: '15px' }}>
            {notifications}
            {userProfile}
          </Menu.Menu>
        </Menu>
      ));
  }
}

const mapToStateProps = state => {
  const { user: { user }, location, history: hst, path } = state;
  return {
    user,
    location,
    history: hst,
    path
  };
};

const mapDispatchToProps = {
  removeUser: removeUserRoutine,
  increaseCounter: increaseCounterRoutine,
  fetchUser: fetchUserRoutine,
  connectAction: connectActionRoutine,
  disconnectSockets: disconnectSocketsRoutine
};

export default connect(mapToStateProps, mapDispatchToProps)(withRouter(Header));


import { combineReducers } from 'redux';
import { reducerCreator } from '../../../helpers/reducerHelper';
import {
  fetchRequestDetailsRoutine, fetchRequestsRoutine,
  fetchFormsRoutine, fetchAllTemplatesRoutine
} from '../routines';
import { requests } from '../containers/FeedbackRequests/reducer';
import { manageRequests } from '../containers/ManageRequests/reducer';
import { manageRequestsByLink } from '../containers/ManageRequestsByLink/reducer';
import { importCsv } from '../components/CsvImportButton/reducer';
import { createAssign } from '../components/RequestAssign/reducer';
import { saveSummary } from '../components/FeedbackSummary/reducer';
import { questions } from '../components/Questions/reducer';
import { forms } from '../containers/NewManageForms/reducer';
import { templates } from '../containers/ManageTemplates/reducer';
import { questionsBlocks } from '../containers/QuestionsBlocks/reducer';

const reducers = combineReducers({
  getRequests: reducerCreator([fetchRequestsRoutine.TRIGGER]),
  getRequestDetails: reducerCreator([fetchRequestDetailsRoutine.TRIGGER]),
  getForms: reducerCreator([fetchFormsRoutine.TRIGGER]),
  getTemplates: reducerCreator([fetchAllTemplatesRoutine.TRIGGER])
});

export default combineReducers({
  forms,
  requests,
  importCsv,
  createAssign,
  saveSummary,
  reducers,
  questions,
  manageRequests,
  manageRequestsByLink,
  templates,
  questionsBlocks
});

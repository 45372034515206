import React from 'react';
import styles from './styles.module.scss';
import loginStyles from '../LoginPage/styles.module.scss';
import { history } from 'helpers/history';
import ForgetPasswordForm from 'screens/Authorization/components/ForgotPasswordForm';
import PageLayout from 'screens/Authorization/components/PagesLayout';

export const ForgotPasswordPage: React.FunctionComponent = () => {
  const toggleLogin = () => history.push('/login');

  return (
    <PageLayout>
      <p className="header">
        Reset your password
      </p>
      <div className={loginStyles.form}>
        <div className={styles.description}>
          Enter your user account&#39;s verified email address and we will send you a password reset link.
        </div>
        <ForgetPasswordForm toggleLogin={toggleLogin} />
      </div>
    </PageLayout>
  );
};

export default ForgotPasswordPage;

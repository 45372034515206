import React, { useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { connect } from 'react-redux';
import { IGlobalState } from 'models/global-state';
import { Formik, FormikProps, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import LoaderWrapper from 'components/LoaderWrapper';
import { Office } from 'screens/AdminPage/model/enums/office';
import { saveAnnouncementsRoutine, fetchAnnouncementByIdRoutine } from 'screens/Announcements/routines';
import { IAnnouncementToSave } from 'screens/Announcements/models/dto/IAnnouncementToSave';

import styles from './styles.module.scss';
import { EditorComponent } from 'components/EditorComponent';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required!')
    .min(5, 'Minimal length is 5 characters!')
    .max(500, 'Maximum length is 500 characters!'),
  text: Yup.string()
    .max(4096, 'Maximum length is 4096 characters!')
});

export interface IAnnouncementFormFormProps {
  onSave: IBindingAction;
  saveAnnouncement: IBindingCallback1<IAnnouncementToSave>;
  fetchAnnouncementById: IBindingCallback1<string>;
  announcementId: string;
  announcementToUpdate: IAnnouncementToSave;
  loading: boolean;
}

const AddAnnouncementForm: React.FunctionComponent<IAnnouncementFormFormProps> = ({
  announcementId,
  fetchAnnouncementById,
  onSave,
  saveAnnouncement,
  announcementToUpdate,
  loading
}) => {
  useEffect(() => {
    if (announcementId !== null) {
      fetchAnnouncementById(announcementId);
    }
  }, [announcementId]);

  const getAnnouncementForUpdate = (): IAnnouncementToSave => {
    if (!announcementToUpdate || !announcementId) {
      return {
        id: null,
        isTemplate: true,
        offices: [],
        text: '',
        title: ''
      };
    }
    return announcementToUpdate;
  };

  const onSubmit = (data: IAnnouncementToSave) => {
    saveAnnouncement(data);
    onSave();
  };

  const renderForm = (
    values: IAnnouncementToSave,
    errors: FormikErrors<IAnnouncementToSave>,
    touched: FormikTouched<IAnnouncementToSave>,
    setFieldValue,
    handleSubmit
  ) => {
    const officesDict = Object.values(Office).map(o => ({ key: o, value: o, text: o }));

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Input
          name="title"
          label={touched.title && errors.title ? errors.title : 'Input title'}
          placeholder="Title"
          value={values.title}
          error={Boolean(touched.title && errors.title)}
          onChange={(event, o) => setFieldValue('title', o.value)}
        />
        <Form.Select
          multiple
          options={officesDict}
          label="Specific offices"
          name="offices"
          error={Boolean(touched.offices && errors.offices)}
          value={values.offices}
          onChange={(event, o) => setFieldValue('offices', o.value)}
          className={styles.multipleSelect}
        />
        <Form.Checkbox
          label="Template"
          name="isTemplate"
          checked={values.isTemplate}
          onChange={(e, data) => setFieldValue('isTemplate', data.checked)}
        />
        <Form.Field
          error={touched.text && errors.text}
          control={EditorComponent}
          defaultValue={values.text}
          name="text"
          value={values.text}
          onEditorChangeState={data => setFieldValue('text', data)}
        />
        <Button
          primary
          type="submit"
          content={announcementId ? 'Save changes' : 'Create'}
          className="submitButton"
        />
      </Form>
    );
  };

  return (
    <div className="fullHeightLoader">
      <LoaderWrapper loading={loading}>
        <Formik
          initialValues={getAnnouncementForUpdate()}
          onSubmit={data => onSubmit(data)}
          validationSchema={validationSchema}
        >
          {({ values, errors, setFieldValue, touched, handleSubmit }:
            FormikProps<IAnnouncementToSave>) => renderForm(values, errors, touched, setFieldValue, handleSubmit)}
        </Formik>
      </LoaderWrapper>
    </div>
  );
};

const mapStateToProps = (state: IGlobalState) => {
  const { requests, announcements: { announcementToUpdate } } = state.announcements;
  return {
    announcementToUpdate,
    loading: requests.announcementForUpdate.loading
  };
};

const mapDispatchToProps = {
  saveAnnouncement: saveAnnouncementsRoutine,
  fetchAnnouncementById: fetchAnnouncementByIdRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAnnouncementForm);

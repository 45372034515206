import { callUserProfileApi, callGatewayApi, callOkrApi } from 'helpers/webApiHelper';
import { IUpdateUserModel } from '../models/IUpdateUserModel';
import { IUpdatePasswordModel } from '../models/IUpdatePasswordModel';
import { IFileData } from '../models/IFileData';

export const getUserInfo = async (userId: string) => {
  const response = await callGatewayApi({
    endpoint: `/api/users/data/${userId}`,
    type: 'GET'
  });
  return response.json();
};

export const editUserInfo = async (payload: IUpdateUserModel) => callGatewayApi({
  endpoint: '/api/users/data',
  type: 'PUT',
  requestData: payload
});

export const updatePasswordRequest = async (payload: IUpdatePasswordModel) => {
  const response = await callGatewayApi({
    endpoint: '/api/users/resetpwd',
    type: 'POST',
    requestData: payload
  });
  return response.json();
};

export const uploadAvatar = async (payload: IFileData) => {
  const response = await callUserProfileApi({
    endpoint: '/api/userProfile/updateAvatar',
    type: 'POST',
    requestData: payload
  });
  return response.json();
};

export const getUserReviews = async (userId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/users/${userId}/reviews`,
    type: 'GET'
  });
  return response.json();
};

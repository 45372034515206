import { callNotificationsApi } from 'helpers/webApiHelper';
import { ICommentToAdd } from '../models/dto/ICommentToAdd';

export const addComment = async (payload: ICommentToAdd) => {
  const response = await callNotificationsApi({
    endpoint: '/api/announcements/comment',
    type: 'POST',
    requestData: payload
  });
  return response.json();
};

import { Routine } from 'redux-saga-routines';
import { createSummaryRoutine, updateSummaryRoutine, fetchSummaryRoutine } from '../../routines';
import { ISummary } from '../../model/Summary/ISummary';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';

export interface ISummaryState {
  summary: ISummary[];
}

const initialState: ISummaryState = {
  summary: null
};

export const saveSummary = (
  state = initialState,
  action: Routine<any>
): ISummaryState => {
  switch (action.type) {
    case fetchSummaryRoutine.SUCCESS:
    case createSummaryRoutine.SUCCESS:
    case updateSummaryRoutine.SUCCESS: {
      return {
        ...state,
        summary: action.payload
      };
    }
    default:
      return initialState;
  }
};

import { Routine } from 'redux-saga-routines';
import { fetchUserOkrsRoutine, fetchOkrObjectivesRoutine } from '../../routines';

export interface IOkrsData {
  id: string;
  name: string;
  isClosed: boolean;
  startTime: Date;
  endTime: Date;
}

export interface IOkrObjective {
  id: string;
  name: string;
}

export interface IAddToMyOkrState {
  okrs: IOkrsData[];
  currentOkrId: string;
  selectedOkrId: string;
  currentOkrObjectives: IOkrObjective[];
  okrsLoading: boolean;
  objectivesLoading: boolean;
}

export const addToMyOkr = (state: IAddToMyOkrState = {
  okrs: [],
  selectedOkrId: null,
  currentOkrId: null,
  currentOkrObjectives: [],
  okrsLoading: false,
  objectivesLoading: false
}, action: Routine<any>): IAddToMyOkrState => {
  const { type, payload } = action;

  switch (type) {
    case fetchUserOkrsRoutine.TRIGGER:
      return { ...state, okrsLoading: true };
    case fetchUserOkrsRoutine.SUCCESS:
      return { ...state, okrsLoading: false, ...payload, selectedOkrId: payload.currentOkrId };
    case fetchUserOkrsRoutine.FAILURE:
      return { ...state, okrsLoading: false };
    case fetchOkrObjectivesRoutine.TRIGGER:
      return { ...state, objectivesLoading: true, currentOkrObjectives: [] };
    case fetchOkrObjectivesRoutine.SUCCESS:
      return { ...state, objectivesLoading: false, ...payload };
    case fetchOkrObjectivesRoutine.FAILURE:
      return { ...state, objectivesLoading: false };
    default:
      return state;
  }
};

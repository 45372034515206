/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { IRequest } from 'screens/Feedback/model/Requests/IRequest';
import { Tab, Form, Radio, Button, Icon } from 'semantic-ui-react';
import { IBindingCallback1 } from 'models/callback';

interface IRequestProps {
  id: string;
  request: IRequest;
  sendAnswers: IBindingCallback1<any>;
}

export const RequestByLink: React.FunctionComponent<IRequestProps> = ({
  request,
  sendAnswers,
  id
}: IRequestProps) => {
  const [answers, setAnswers] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [active, setActive] = useState(null);
  useEffect(() => {
    const tempAnswers = [];
    request.form.questionsBlocks.sort(((a, b) => a.index - b.index)).map(block => block.questions)
      .filter(q => q.length !== 0).forEach(qarr => qarr.forEach(q => { tempAnswers.push(q); }));
    setAnswers(tempAnswers.map(a => ({ questionId: a.id, answer: '' })));
    setActive(0);
  }, [request]);
  const onChange = (questionId, value) => {
    const tempAnswers = answers
      .map(a => (a.questionId === questionId ? { questionId, answer: value } : a));
    setAnswers(tempAnswers);
  };
  const getValue = questionId => answers.find(a => a.questionId === questionId)?.answer;
  const checkAnswer = (questionId, value) => answers.find(a => a.questionId === questionId)?.answer === value;
  const onClick = () => {
    sendAnswers({ feedbackId: id, answers });
    setIsSent(true);
  };

  const panes = request?.form?.questionsBlocks.sort((a, b) => (a.index > b.index ? 1 : -1)).map(block => ({
    menuItem: block.title,
    render: () => (
      <div className={styles.сontainer}>
        <div className={styles.spanWrapper}>
          <h1>{block.title}</h1>
        </div>
        <h3>{block.description}</h3>
        {block.questions.map(question => (
          question.type === 'OPEN'
            ? (
              <div className={styles.questionBlock}>
                <h4>{question.text}</h4>
                <Form>
                  <Form.Input
                    width={9}
                    placeholder="Enter answer"
                    value={getValue(question.id)}
                    onChange={e => onChange(question?.id, e.target.value)}
                    className={`fullWidthData ${styles.formInput}`}
                  />
                </Form>
              </div>
            ) : (
              <div className={styles.questionBlock}>
                <h4>{question.text}</h4>
                <Form>
                  <Form.Group grouped>
                    {question.options.map(option => (
                      <Form.Field
                        control={Radio}
                        label={option}
                        value={option}
                        checked={checkAnswer(question?.id, option)}
                        onChange={(e, { value }) => onChange(question?.id, value)}
                      />
                    ))}
                  </Form.Group>
                </Form>
              </div>
            )))}

      </div>
    )
  }));

  return (
    <div
      className={styles.formInList}
    >
      {isSent
        ? (
          <div className={styles.formContainer}>
            <div className={styles.spanWrapper}>
              <h1>Thanks for your answers :)</h1>
            </div>
          </div>
        )
        : (
          <>
            <div className={styles.formContainer}>
              <div className={styles.spanWrapper}>
                <h1>{request?.form?.name}</h1>
              </div>
              <h3>{request?.form?.description}</h3>
            </div>
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={panes}
              activeIndex={active}
              onTabChange={(e, { activeIndex }) => setActive(activeIndex)}
            />
            <div className={styles.button}>
              <div className={styles.buttons}>
                <Button
                  content="Previous"
                  className={styles.formButton}
                  disabled={active === 0}
                  onClick={() => setActive(active - 1)}
                />
              </div>
              {((request.form.questionsBlocks.length - 1) === active)
                ? (
                  <Button
                    primary
                    onClick={() => onClick()}
                    disabled={answers.filter(a => a.answer === '').length !== 0}
                    className={styles.formButton}
                  >
                    Send answers
                  </Button>
                ) : (
                  <Button
                    primary
                    content="Next"
                    className={styles.formButton}
                    disabled={(request.form.questionsBlocks.length - 1) === active}
                    onClick={() => setActive(active + 1)}
                  />
                )}
            </div>
          </>
        )}
    </div>
  );
};

export default connect()(RequestByLink);

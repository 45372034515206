import React from 'react';
import { Dropdown, Button } from 'semantic-ui-react';
import { boundMethod } from 'autobind-decorator';
import {
  uploadFileRoutine,
  downloadFileRoutine
} from 'screens/AdminPage/routines';
import { connect } from 'react-redux';
import { IBindingCallback1, IBindingAction } from 'models/callback';
import { IGlobalState } from 'models/global-state';
import { IUploadingFileState } from './reducer';
import styles from './styles.module.scss';
import ImportFromGoogleLinkModal from '../ImportFromGoogleLinkModal';

export interface IFileActionButtonsProps {
  uploadLoading?: boolean;
  downloadLoading?: boolean;
  uploadFile: IBindingCallback1<FormData>;
  downloadFile: IBindingAction;
}

interface IFileButtonsState {
  isGoogleImportModalOpened: boolean;
}

class FileActionButtons extends React.Component<IFileActionButtonsProps, IFileButtonsState> {
  download = () => this.props.downloadFile();

  @boundMethod
  uploadFile(args: FileList) {
    const { uploadFile } = this.props;
    if (!args.length) {
      return;
    }

    const formData = new FormData();
    formData.append('file', args[0]);

    uploadFile(formData);
  }

  render() {
    const { downloadLoading, uploadLoading } = this.props;
    return (
      <div className={styles.fileActions}>
        <Button loading={downloadLoading} className={styles.btnFile} onClick={this.download}>
          Export users
        </Button>
        <Dropdown button loading={uploadLoading} className={styles.btnFile} text="Import users">
          <Dropdown.Menu direction="left">
            <Dropdown.Item>
              From file
              <input
                type="file"
                accept=".csv"
                onChange={e => this.uploadFile(e.target.files)}
              />
            </Dropdown.Item>
            <ImportFromGoogleLinkModal />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const loadingFile = state.adminPage.loadingFile as IUploadingFileState;

  return {
    uploadLoading: loadingFile.uploading,
    downloadLoading: loadingFile.downloading
  };
};

const mapDispatchToProps = {
  uploadFile: uploadFileRoutine,
  downloadFile: downloadFileRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(FileActionButtons);

import {
  fetchFormsRoutine,
  selectedFormChangedRoutine,
  formDeletedRoutine,
  formSavedRoutine,
  formUpdateRoutine,
  resetFormsSelectionRoutine,
  formSavedTemplateRoutine,
  fetchTemplatesRoutine
} from '../../routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import * as formsService from '../../services/formsService';

function* formsRequest(): Routine<any> {
  try {
    const result = yield call(formsService.getForms);

    yield put(fetchFormsRoutine.success(result));
  } catch (error) {
    yield put(fetchFormsRoutine.failure(error));
  }
}

function* templatesRequest({ payload }: Routine<any>): Routine<any> {
  try {
    const result = yield call(formsService.getTemplates, payload);

    yield put(fetchTemplatesRoutine.success(result));
  } catch (error) {
    yield put(fetchTemplatesRoutine.failure(error));
  }
}

function* changedForm({ payload }: Routine<any>): Routine<any> {
  if (payload === null) {
    yield put(selectedFormChangedRoutine.success(payload));
  } else {
    try {
      const result = yield call(formsService.getForm, payload);

      yield put(selectedFormChangedRoutine.success(result));
    } catch (error) {
      yield put(selectedFormChangedRoutine.failure(error));
    }
  }
}

function* updateForm({ payload }: Routine<any>): Routine<any> {
  try {
    const result = yield call(formsService.updateForm, payload);

    yield put(formUpdateRoutine.success(result.data));
  } catch (error) {
    yield put(formUpdateRoutine.failure(error));
  }
}

function* saveForm({ payload }: Routine<any>): Routine<any> {
  try {
    const result = yield call(formsService.saveForm, payload);

    yield put(formSavedRoutine.success(result.data));
  } catch (error) {
    yield put(formSavedRoutine.failure(error));
  }
}

function* saveTemplate({ payload }: Routine<any>): Routine<any> {
  try {
    console.log('here');
    const result = yield call(formsService.saveTemplate, payload);

    yield put(formSavedTemplateRoutine.success(result.data));
  } catch (error) {
    yield put(formSavedTemplateRoutine.failure(error));
  }
}

function* deleteForm({ payload }: Routine<any>): Routine<any> {
  try {
    const id = payload;
    yield call(formsService.deleteForm, payload);

    yield put(formDeletedRoutine.success(id));
  } catch (error) {
    yield put(formDeletedRoutine.failure(error));
  }
}

function* resetState(): Routine<any> {
  try {
    yield put(resetFormsSelectionRoutine.success());
  } catch (error) {
    yield put(resetFormsSelectionRoutine.failure(error));
  }
}

function* watchChangedForm() {
  yield takeEvery(selectedFormChangedRoutine.TRIGGER, changedForm);
}

function* watchUpdateForm() {
  yield takeEvery(formUpdateRoutine.TRIGGER, updateForm);
}

function* watchFormsRequest() {
  yield takeEvery(fetchFormsRoutine.TRIGGER, formsRequest);
}

function* watchTemplatesRequest() {
  yield takeEvery(fetchTemplatesRoutine.TRIGGER, templatesRequest);
}
function* watchFormSave() {
  yield takeEvery(formSavedRoutine.TRIGGER, saveForm);
}

function* watchSaveTemplate() {
  yield takeEvery(formSavedTemplateRoutine.TRIGGER, saveTemplate);
}

function* watchFormDelete() {
  yield takeEvery(formDeletedRoutine.TRIGGER, deleteForm);
}

function* watchResetState() {
  yield takeEvery(resetFormsSelectionRoutine.TRIGGER, resetState);
}

export default function* formsPageSagas() {
  yield all([
    watchChangedForm(),
    watchUpdateForm(),
    watchFormsRequest(),
    watchFormSave(),
    watchFormDelete(),
    watchResetState(),
    watchSaveTemplate(),
    watchTemplatesRequest()
  ]);
}

import { Routine } from 'redux-saga-routines';
import { put, takeEvery, all, call } from 'redux-saga/effects';
import {
  resetPasswordRoutine,
  checkCodeRoutine,
  updatePasswordRoutine,
  resendEmailConfirmationLinkRoutine
} from 'screens/Authorization/routines';
import { toastr } from 'react-redux-toastr';
import { history } from 'helpers/history';
import * as authService from 'screens/Authorization/services/authService';

function* resetPasswordRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(authService.sendMailToResetPassword, payload);
    yield put(resetPasswordRoutine.success(response));
    toastr.success(response);
  } catch (error) {
    yield put(resetPasswordRoutine.failure(error.message));
  }
}

function* resetPasswordCodeCheckRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(authService.checkCode, payload);
    yield put(checkCodeRoutine.success(response));
  } catch (error) {
    yield put(checkCodeRoutine.failure(error.message));
  }
}

function* updatePasswordRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(authService.resetPassword, payload);
    yield put(updatePasswordRoutine.success(response));
    toastr.success('Password was changed');
    history.push('/login');
  } catch (error) {
    yield put(updatePasswordRoutine.failure(error.message));
  }
}

function* resendEmailConfirmationRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(authService.resendEmailConfirmationLink, payload);
    yield put(resendEmailConfirmationLinkRoutine.success(response));
    toastr.success('Email was send, check your mail box :)');
    history.push('/login');
  } catch (error) {
    yield put(resendEmailConfirmationLinkRoutine.failure(error.message));
  }
}

function* watchResendEmailConfirmationRequest() {
  yield takeEvery(resendEmailConfirmationLinkRoutine.TRIGGER, resendEmailConfirmationRequest);
}

function* watchUpdatePasswordRequest() {
  yield takeEvery(updatePasswordRoutine.TRIGGER, updatePasswordRequest);
}

function* watchResetPasswordCodeCheckRequest() {
  yield takeEvery(checkCodeRoutine.TRIGGER, resetPasswordCodeCheckRequest);
}

function* watchResetPasswordRequest() {
  yield takeEvery(resetPasswordRoutine.TRIGGER, resetPasswordRequest);
}

export default function* authSaga() {
  yield all([
    watchResetPasswordRequest(),
    watchResetPasswordCodeCheckRequest(),
    watchUpdatePasswordRequest(),
    watchResendEmailConfirmationRequest()
  ]);
}

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IForm } from 'screens/Feedback/model/Forms/IForm';
import { Button, Form } from 'semantic-ui-react';
import styles from './styles.module.scss';

interface ITemplateProps {
  title: string;
  form?: IForm;
  formSaved: (form: IForm) => void;
  formCanceled: () => void;
  userId: string;
}

const Template: React.FunctionComponent<ITemplateProps> = ({
  title,
  form,
  formSaved,
  formCanceled,
  userId
}: ITemplateProps) => {
  const [init, setInit] = useState(true);
  const [name, setName] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [isPublic, setIsPublic] = useState(form?.isPublic ?? false);

  useEffect(() => {
    if (init) {
      setInit(false);
    }
  });
  useLayoutEffect(() => {
    setName(form?.name ?? '');
    setDescription(form?.description ?? '');
    setIsPublic(form?.isPublic ?? false);
  }, [form]);

  const saveDescription = e => {
    setDescription(e.target.value);
  };
  const savedTemplate = () => {
    formSaved({
      ...form,
      name,
      description,
      isTemplate: true,
      isPublic,
      creatorId: userId
    });
  };
  return (
    <div className={styles.formContainer}>
      <div className={styles.formStyling}>
        <h3>{title}</h3>
        <Form>
          <Form.Input
            label="Name"
            placeholder="Template name"
            name="name"
            onChange={e => setName(e.target.value)}
            value={name}
            className="fullWidthData"
            required
          />
          <Form.TextArea
            style={{ minHeight: 100, maxHeight: 100 }}
            label="Description"
            placeholder="Template description"
            name="description"
            className="fullWidthData"
            onChange={e => saveDescription(e)}
            value={description}
          />
          <Form.Checkbox
            toggle
            label="Is Public"
            className={styles.checkboxToggle}
            checked={isPublic}
            onChange={() => setIsPublic(!isPublic)}
          />
          <Button
            primary
            onClick={() => savedTemplate()}
            className={styles.formButton}
            disabled={!name}
          >
            Save
          </Button>
          {title === 'Create new template'
            && (
              <Button
                basic
                color="orange"
                onClick={() => formCanceled()}
                className={styles.formButton}
              >
                Cancel
              </Button>
            )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      templates: {
        selectedForm
      }
    }
  } = state;
  const { user: { user: { id } } } = state;
  return {
    form: selectedForm,
    userId: id
  };
};

export default connect(mapStateToProps)(Template);

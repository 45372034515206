import { IRequest } from '../../model/Requests/IRequest';
import { Routine } from 'redux-saga-routines';
import { fetchAssignRequestsRoutine, fetchAssignDetailRequestRoutine, sendAnswersRoutine } from '../../routines';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';

export interface IManageRequestsState {
  requests: IRequest[];
  refreshNeeded: boolean;
  selectedRequest: IRequest;
}

const initialState: IManageRequestsState = {
  requests: [],
  refreshNeeded: true,
  selectedRequest: null
};

export const manageRequests = (
  state = initialState,
  action: Routine<any>
): IManageRequestsState => {
  switch (action.type) {
    case fetchAssignRequestsRoutine.SUCCESS: {
      return {
        ...state,
        requests: action.payload,
        refreshNeeded: false
      };
    }
    case fetchAssignDetailRequestRoutine.SUCCESS: {
      return {
        ...state,
        selectedRequest: action.payload
      };
    }
    case sendAnswersRoutine.SUCCESS: {
      return {
        ...state,
        requests: state.requests.filter(r => r.id !== action.payload.requestId)
      };
    }
    default:
      return state;
  }
};

import { IAdminUserInfo } from 'screens/AdminPage/model/IAdminUserInfo';
import { Table, Icon } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import { IBindingCallback1 } from 'models/callback';
import styles from './styles.module.scss';
import Avatar from 'components/UserAvatar';
import { IUser } from '../../../Authorization/models/IUser';
import { isAllowedToEditAnothers } from '../../../../helpers/roles.helper';

interface IUserRowProps {
  user: IAdminUserInfo;
  onUpdate: IBindingCallback1<string>;
  loggedInUser: IUser;
}

export const UserRow: FunctionComponent<IUserRowProps> = ({
  user,
  onUpdate,
  loggedInUser: {
    roles,
    id
  }
}: IUserRowProps) => {
  const introLetter = user.introLetterLink ? (
    <a href={user.introLetterLink} target="_blank" rel="noopener noreferrer">
      <Icon name="info" color="black" size="large" />
    </a>
  ) : (
    ''
  );
  const editButton = (id === user.id || isAllowedToEditAnothers(roles, user.roles)) && (
    <Icon
      className={styles.icon}
      name="edit"
      onClick={() => onUpdate(user.id)}
      size="large"
    />
  );
  return (
    <Table.Row>
      <Table.Cell textAlign="center" className={styles.tableLogoCell}>
        <Avatar name={`${user.fullName}`} logoLink={user.logoLink} />
      </Table.Cell>
      <Table.Cell verticalAlign="middle">
        <p className={styles.ellipsis}>
          {user.fullName}
        </p>
      </Table.Cell>
      <Table.Cell verticalAlign="middle">
        <p className={styles.ellipsis}>
          {user.email}
        </p>
      </Table.Cell>
      <Table.Cell verticalAlign="middle" className={styles.additionalInfo}>
        {user.phone}
      </Table.Cell>
      <Table.Cell verticalAlign="middle" className={styles.additionalInfo}>
        {user.position}
      </Table.Cell>
      <Table.Cell verticalAlign="middle" className={styles.additionalInfo}>
        {user.offices?.join(', ')}
      </Table.Cell>
      <Table.Cell textAlign="center" verticalAlign="middle" className={styles.additionalInfo}>
        {introLetter}
      </Table.Cell>
      <Table.Cell textAlign="center" verticalAlign="middle">
        {editButton}
      </Table.Cell>
    </Table.Row>
  );
};

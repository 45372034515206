import { all } from 'redux-saga/effects';
import timeOffAdminPageDaysSaga from '../containers/DaysOffAdminPage/sagas';
import timeOffAdminPageRequestsSaga from '../containers/RequestsAdminPage/sagas';
import requestsSaga from '../containers/TimeOffTrackerTab/sagas';
import usersDaysOffSaga from '../containers/RequestDaysOffAdminPageForm/sagas';

export default function* rootSaga() {
  yield all([
    timeOffAdminPageDaysSaga(),
    timeOffAdminPageRequestsSaga(),
    requestsSaga(),
    usersDaysOffSaga()
  ]);
}

import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import LoaderWrapper from 'components/LoaderWrapper';
import { NoDataContainer } from 'components/NoDataContainer';
import { history } from '../../../../../helpers/history';
import { IBindingCallback1 } from 'models/callback';
import { fetchUserOKRsRoutine } from '../../../routines';
import { fetchOkrs } from '../../../../OKR/routines';
import { IUser } from '../../../../OKR/models/user';
import { IGlobalState } from 'models/global-state';
import { connect } from 'react-redux';
import OkrItem from '../../OkrItem';
import { IUserOkrStats } from '../../../../OKR/models/okr/IUserOkrStats';

export interface IOkrTabProps {
  okrs?: IUserOkrStats[];
  loadingOkrTab?: boolean;
  fetchOkrs: IBindingCallback1<string>;
  user: IUser;
}

const OKRTab: React.FunctionComponent<IOkrTabProps> = ({
  okrs,
  user,
  loadingOkrTab
}) => {
  useEffect(() => { fetchOkrs(user.id); }, []);

  return (
    <div className={styles.loaderWrapper}>
      <LoaderWrapper loading={!okrs || loadingOkrTab}>
        {okrs && okrs.length ? (
          <div className={styles.gridBox}>
            {okrs.map(okr => (
              <OkrItem
                key={okr.id}
                okr={okr}
                onClick={() => history.push(`/okr/${okr.id}`)}
              />
            ))}
          </div>
        ) : (
          <div className={styles.noDataContainer}>
            <NoDataContainer
              text="This user doesn’t have any OKR."
            />
          </div>
        )}
      </LoaderWrapper>
    </div>
  );
};

const mapStateToProps = (state: IGlobalState) => {
  const {
    user: { user },
    userProfile: { userInfo }
  } = state;

  return {
    user,
    okrs: userInfo.userOkrs,
    dataLoaded: userInfo.loadingOkrTab
  };
};

const mapDispatchToProps = {
  fetchOkrs: fetchUserOKRsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(OKRTab);

import { IKeyResult } from 'screens/OKR/models/key-result';

const getKeyResultsMergeIds = (originId: string, ketResults: IKeyResult[]): string[] => {
  const keyResultsMergeIds = ketResults.reduce((acc, keyResult) => {
    const { id } = keyResult;

    return originId === id ? acc : [...acc, id];
  }, []);

  return keyResultsMergeIds;
};

export { getKeyResultsMergeIds };

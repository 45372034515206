import React from 'react';
import styles from './styles.module.scss';

const AdminDashboardCard = ({ header, description }) => (
  <div className={styles.cardWrapper}>
    <div className={styles.card}>
      <p className={styles.header}>{header}</p>
      <p className={styles.description}>{description}</p>
    </div>
  </div>
);

export default AdminDashboardCard;

import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import styles from '../AdminDashboardTopTable/styles.module.scss';
import { DateType } from '../AdminDashboard';

const AdminDashboardTopOkrTable = ({ loadResults, data, activeItem }) => (
  <>
    <div className={styles.header}>
      <div className={styles.contentWrapper}>
        Top marked OKR this
        <div>
          <Button
            basic
            size="mini"
            style={{ margin: '10px' }}
            onClick={() => { loadResults(DateType.year); }}
            active={activeItem === DateType.year}
            content="Year"
          />
          <Button
            basic
            size="mini"
            onClick={() => { loadResults(DateType.month); }}
            active={activeItem === DateType.month}
            content="Month"
          />
        </div>
      </div>
    </div>
    <Table className={styles.table}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={11}>Name</Table.HeaderCell>
          <Table.HeaderCell width={3}>User</Table.HeaderCell>
          <Table.HeaderCell width={2}>Mark</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data ? (
          <Table.Row>
            <Table.Cell width={12}>
              {data.name}
            </Table.Cell>
            <Table.Cell width={3} textAlign="center" verticalAlign="middle">
              {`${data.firstName} ${data.lastName}`}
            </Table.Cell>
            <Table.Cell width={2} textAlign="center" verticalAlign="middle">
              {data.averageMark}
            </Table.Cell>
          </Table.Row>
        ) : (
          <Table.Row>
            <Table.Cell width={16}>
              There is no data here now...
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  </>
);

export default AdminDashboardTopOkrTable;

import React from 'react';
import { Comment, Icon } from 'semantic-ui-react';
import styles from './styles.module.scss';
import moment from 'moment';

const ReviewTab = ({ reviews, selectKeyResult }) => (
  <div className={styles.userReviewsContainer}>
    <Comment.Group>
      {
      reviews.map(({ keyResultName, keyResultId, createdAt, comment, isDislike, isLike }) => (
        <Comment className={styles.comment}>
          <Comment.Content className={styles.userReviewsItem}>
            <Comment.Author className={styles.author} as="a">
              <p role="presentation" onClick={() => selectKeyResult(keyResultId)}>{keyResultName}</p>
              {isLike ? <Icon className={styles.isLike} name="thumbs up outline" /> : null}
              {isDislike ? <Icon name="thumbs down outline" /> : null}
            </Comment.Author>
            <Comment.Metadata className={styles.metadata}>
              <div>{moment(createdAt).format('LL')}</div>
            </Comment.Metadata>
            <Comment.Text className={styles.text}>{comment}</Comment.Text>
          </Comment.Content>
        </Comment>
      ))
    }
    </Comment.Group>
  </div>
);

export default ReviewTab;

import { combineReducers } from 'redux';
import { reducerCreator } from 'helpers/reducerHelper';
import { fetchNotificationsRoutine } from '../routines';
import { notifications } from '../containers/NotificationsList/reducer';

const requests = combineReducers({
  notifications: reducerCreator([fetchNotificationsRoutine.TRIGGER])
});

export default combineReducers({
  notifications,
  requests
});

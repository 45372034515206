import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Tab } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { connect } from 'react-redux';
import { IGlobalState } from 'models/global-state';
import { INewsItemSave } from 'screens/News/models/news/newsItemSave';
import {
  fetchNewsByIdRoutine,
  saveNewsRoutine,
  syncNewsForUpdateWithOriginByIdRoutine,
  updateNewsRoutine
} from '../../routines';
import { Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import * as Yup from 'yup';
import LoaderWrapper from 'components/LoaderWrapper';
import { Office } from 'screens/AdminPage/model/enums/office';

import styles from './styles.module.scss';
import { EditorComponent } from 'components/EditorComponent';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required!')
    .min(5, 'Minimal length is 5 characters!')
    .max(60, 'Maximum length is 60 characters!'),
  description: Yup.string()
    .max(200, 'Maximum length is 200 characters!'),
  html: Yup.string().when('isExternal', {
    is: false,
    then: Yup.string()
      .required('Text is required!')
      .min(18, 'Minimal length is 10 required!')
  }),
  externalLink: Yup.string().when('isExternal', {
    is: true,
    then: Yup.string()
      .required('Text is required!')
      .url('Invalid url!')
  })
});

export interface IAddNewsFormProps {
  onSave: IBindingAction;
  saveNews: IBindingCallback1<INewsItemSave>;
  updateNews: IBindingCallback1<INewsItemSave>;
  fetchNewsById: IBindingCallback1<string>;
  newsId?: string;
  newsForUpdate?: INewsItemSave;
  syncNewsForUpdateById: IBindingCallback1<string>;
  requests: any;
}

interface IFormikProps {
  id: string;
  html?: string;
  title: string;
  isExternal: boolean;
  description: string;
  externalLink?: string;
  offices: Office[];
}

const AddNewsFormItem: React.FunctionComponent<IAddNewsFormProps> = ({
  onSave,
  saveNews,
  updateNews,
  fetchNewsById,
  newsId,
  newsForUpdate,
  syncNewsForUpdateById,
  requests
}) => {
  const [loading, toggleLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [news, toggleNews] = useState<INewsItemSave>({
    id: null,
    html: '',
    title: '',
    isExternal: false,
    description: '',
    externalLink: '',
    logo: '',
    offices: [],
    published: false
  });
  const formikRef = useRef<FormikProps<any>>();

  useEffect(() => {
    if (newsId) {
      fetchNewsById(newsId);
    } else {
      toggleLoading(false);
    }
  }, []);

  useEffect(() => {
    if (newsForUpdate) {
      toggleLoading(false);
      toggleNews(newsForUpdate);
      if (formikRef.current) {
        formikRef.current.resetForm();
        // a hack to update html editor
        formikRef.current.setFieldValue('html', newsForUpdate.html);
      }
    }
  }, [newsForUpdate]);

  const onSubmit = data => {
    if (newsId) {
      updateNews(data);
    } else {
      saveNews(data);
    }
    onSave();
  };

  const handleSyncClick = ev => {
    syncNewsForUpdateById(news.id);
    ev.preventDefault();
  };

  const renderForm = (
    values: IFormikProps,
    errors: FormikErrors<IFormikProps>,
    touched: FormikTouched<IFormikProps>,
    setFieldValue,
    handleSubmit
  ) => {
    const panels = [
      {
        menuItem: 'Editor',
        render: () => (
          <>
            {
              !values.externalLink ? (
                <Form.Field
                  error={touched.html && errors.html}
                  control={EditorComponent}
                  defaultValue={values.html}
                  name="html"
                  value={values.html}
                  onEditorChangeState={data => setFieldValue('html', data)}
                />
              )
                : <div className={styles.disabledEditor}>Content editing is disabled for external news</div>
            }
          </>
        )
      },
      {
        menuItem: 'Link',
        render: () => (
          <Form.Group widths="equal">
            <Form.Input
              name="externalLink"
              label={touched.externalLink && errors.externalLink ? errors.externalLink : 'Input externalLink'}
              placeholder="External Link"
              value={values.externalLink}
              error={Boolean(touched.externalLink && errors.externalLink)}
              onChange={(event, o) => {
                setFieldValue('isExternal', !!o.value);
                setFieldValue('externalLink', o.value);
                setFieldValue('html', '');
              }}
            />
          </Form.Group>
        )
      }
    ];
    const officesDict = Object.values(Office).map(o => ({ key: o, value: o, text: o }));

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Input
          name="title"
          label={touched.title && errors.title ? errors.title : 'Input news title'}
          placeholder="Title"
          value={values.title}
          error={Boolean(touched.title && errors.title)}
          onChange={(event, o) => setFieldValue('title', o.value)}
        />
        <Form.Input
          name="description"
          label={touched.description && errors.description ? errors.description : 'Description'}
          placeholder="Input news description"
          value={values.description}
          error={Boolean(touched.description && errors.description)}
          onChange={(event, o) => setFieldValue('description', o.value)}
        />
        <Form.Select
          multiple
          options={officesDict}
          label="Specific offices"
          name="offices"
          error={Boolean(touched.offices && errors.offices)}
          value={values.offices}
          onChange={(event, o) => setFieldValue('offices', o.value)}
          className={styles.multipleSelect}
        />
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panels}
          onTabChange={(event, data) => {
            setActiveTab(Number.parseInt(data.activeIndex.toString(), 10));
          }}
        />
        <Form.Group>
          <Button
            primary
            fluid
            type="submit"
            content={newsId ? 'Save news' : 'Save as draft'}
            id={styles.saveButton}
            className="submitButton"
          />
          {news.externalLink && activeTab === 1 && (
            <Button
              content="Synchronize with origin"
              className="plainButton"
              id={styles.syncButton}
              onClick={handleSyncClick}
              loading={requests.syncNewsForUpdateWithOrigin.loading}
            />
          )}
        </Form.Group>
      </Form>
    );
  };

  return (
    <div className="fullHeightLoader">
      <LoaderWrapper loading={loading}>
        <Formik
          initialValues={news}
          onSubmit={data => onSubmit(data)}
          validationSchema={validationSchema}
          innerRef={formikRef}
        >
          {({ values, errors, setFieldValue, touched, handleSubmit }:
            FormikProps<IFormikProps>) => renderForm(values, errors, touched, setFieldValue, handleSubmit)}
        </Formik>
      </LoaderWrapper>
    </div>
  );
};

const mapStateToProps = (state: IGlobalState) => {
  const { newsForUpdate, syncNewsForUpdateWithOrigin } = state.news.requests;
  return {
    newsForUpdate: state.news.newsForUpdate,
    requests: {
      newsForUpdate,
      syncNewsForUpdateWithOrigin
    }
  };
};

const mapDispatchToProps = {
  saveNews: saveNewsRoutine,
  updateNews: updateNewsRoutine,
  fetchNewsById: fetchNewsByIdRoutine,
  syncNewsForUpdateById: syncNewsForUpdateWithOriginByIdRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewsFormItem);

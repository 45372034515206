import React from 'react';
import { connect } from 'react-redux';
import { Table, Loader, Button, Modal, Input, InputOnChangeData } from 'semantic-ui-react';
import { IAdminUserInfo } from 'screens/AdminPage/model/IAdminUserInfo';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { ISaveUser } from 'screens/AdminPage/model/ISaveUser';
import { INewUser } from 'screens/AdminPage/model/INewUser';
import {
  fetchAdminUserInfoRoutine,
  createUserRoutine,
  openUpdateFormRoutine,
  deleteUserRoutine,
  closeUpdateModalRoutine,
  updateUserRoutine,
  clearAdminPageStateRoutine,
  fetchUserMentorsRoutine
} from '../../routines';
import { UserRow } from '../../components/UserRow';
import SaveUserModal from '../../components/saveUserModal';
import styles from './styles.module.scss';
import FileActionButtons from '../../components/fileActionButtons';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';
import { IImportFromGoogleLink } from '../../model/IImportFromGoogleLink';
import { IUser } from '../../../Authorization/models/IUser';

interface IAdminPageProps {
  adminUsersInfo?: IAdminUserInfo[];
  userInfo?: ISaveUser;
  linkData?: IImportFromGoogleLink;
  openUpdateForm?: boolean;
  fetchAdminUserInfo: IBindingAction;
  createUser: IBindingCallback1<INewUser>;
  updateUser: IBindingCallback1<ISaveUser>;
  clearAdminPageState: IBindingAction;
  usersMentor: IUserMentorData[];

  fetchUsersMentor: IBindingAction;
  onDelete: IBindingCallback1<string>;
  onUpdateFormOpen: IBindingCallback1<string>;
  closeUpdateModal: IBindingAction;
  user: IUser;
}

interface IAdminPageState {
  isCreateModalOpened: boolean;
  users?: IAdminUserInfo[];
}

const userContains = (user: IAdminUserInfo, value: string) => {
  const valueToCheck = value.toLowerCase();

  const checkValue = (valueData: string) => valueData.toLowerCase().includes(valueToCheck);

  return (
    checkValue(user.email)
    || checkValue(user.fullName)
    || checkValue(user.phone)
    || checkValue(user.position)
    || checkValue(user.offices.join(', '))
  );
};

class Users extends React.Component<IAdminPageProps, IAdminPageState> {
  constructor(props) {
    super(props);
    this.state = {
      isCreateModalOpened: false
    };
  }

  componentDidMount() {
    const { fetchAdminUserInfo, fetchUsersMentor } = this.props;
    fetchUsersMentor();
    fetchAdminUserInfo();
  }

  componentWillUnmount() {
    const { clearAdminPageState } = this.props;
    clearAdminPageState();
  }

  handleInputChange(e: any, input: InputOnChangeData, adminUsersInfo: IAdminUserInfo[]) {
    const { value } = input;
    if (!value) {
      this.setState({ users: undefined });
      return;
    }
    this.setState({
      users: adminUsersInfo.filter(u => userContains(u, value))
    });
  }

  onUserSave(user) {
    const { isCreateModalOpened } = this.state;
    const { createUser, updateUser } = this.props;

    if (isCreateModalOpened) {
      createUser(user);
    } else {
      updateUser(user);
    }
    this.closeUserSaveModal();
  }

  openUserSaveModal = () => this.setState({ isCreateModalOpened: true });

  onUpdateOpen = (id: string) => this.props.onUpdateFormOpen(id)

  onDelete = (id: string) => this.props.onDelete(id);

  closeUserSaveModal() {
    const { isCreateModalOpened } = this.state;
    const { closeUpdateModal } = this.props;

    if (isCreateModalOpened) {
      this.setState({ isCreateModalOpened: false });
    } else {
      closeUpdateModal();
    }
  }

  render() {
    const { adminUsersInfo, usersMentor } = this.props;
    const { isCreateModalOpened, users } = this.state;
    const { userInfo, openUpdateForm } = this.props;
    const { user } = this.props;

    const usersToShow = users || adminUsersInfo;

    return adminUsersInfo ? (
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          <div className={styles.userActions}>
            <Button
              primary
              onClick={() => this.openUserSaveModal()}
              content="Create user"
              className={styles.button}
            />
            <Input
              icon="search"
              placeholder="Search..."
              onChange={(e, data) => this.handleInputChange(e, data, adminUsersInfo)}
              className={styles.searchInput}
            />
          </div>
          <FileActionButtons />
        </div>
        <Modal
          size="small"
          open={isCreateModalOpened || openUpdateForm}
          onClose={() => this.closeUserSaveModal()}
        >
          <SaveUserModal
            saveUser={userInfo}
            onSave={(u: any) => this.onUserSave(u)}
            onCancel={() => this.closeUserSaveModal()}
            onDelete={this.onDelete}
            users={adminUsersInfo}
            usersMentor={usersMentor}
            loggedInUser={user}
          />
        </Modal>
        <Table celled fixed className={styles.table} unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine width={1} textAlign="center">Avatar</Table.HeaderCell>
              <Table.HeaderCell singleLine width={3}>Full Name</Table.HeaderCell>
              <Table.HeaderCell singleLine width={4}>Email</Table.HeaderCell>
              <Table.HeaderCell singleLine width={2} className={styles.additionalInfo}>Phone</Table.HeaderCell>
              <Table.HeaderCell singleLine width={3} className={styles.additionalInfo}>Position</Table.HeaderCell>
              <Table.HeaderCell singleLine width={1} className={styles.additionalInfo}>Office</Table.HeaderCell>
              <Table.HeaderCell singleLine width={1} textAlign="center" className={styles.additionalInfo}>
                Intro letter
              </Table.HeaderCell>
              <Table.HeaderCell singleLine width={1} textAlign="right" />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {usersToShow.map(uI => (
              <UserRow key={uI.id} user={uI} loggedInUser={user} onUpdate={(id: string) => this.onUpdateOpen(id)} />
            ))}
          </Table.Body>
        </Table>
      </div>
    ) : (
      <Loader active />
    );
  }
}

const mapStateToProps = state => {
  const { adminPage: { userList } } = state;
  const { user: { user } } = state;
  return {
    adminUsersInfo: userList?.users,
    userInfo: userList?.updatedUser,
    openUpdateForm: Boolean(userList?.updatedUser),
    usersMentor: userList.usersMentor,
    user
  };
};

const mapDispatchToProps = {
  fetchAdminUserInfo: fetchAdminUserInfoRoutine,
  createUser: createUserRoutine,
  onUpdateFormOpen: openUpdateFormRoutine,
  onDelete: deleteUserRoutine,
  closeUpdateModal: closeUpdateModalRoutine,
  updateUser: updateUserRoutine,
  clearAdminPageState: clearAdminPageStateRoutine,
  fetchUsersMentor: fetchUserMentorsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

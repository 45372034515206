import { callNotificationsApi } from 'helpers/webApiHelper';
import { IAnnouncement } from '../models/IAnnouncement';
import { IAnnouncementToSave } from '../models/dto/IAnnouncementToSave';

export const getAllAnnouncements = async (): Promise<IAnnouncement[]> => {
  const response = await callNotificationsApi({
    endpoint: '/api/announcements',
    type: 'GET'
  });

  return response.json();
};

export const getAllAnnouncementById = async (id: string): Promise<IAnnouncement[]> => {
  const response = await callNotificationsApi({
    endpoint: `/api/announcements/${id}/details`,
    type: 'GET'
  });

  return response.json();
};

export const deleteAnnouncementById = async (id: string) => {
  const response = await callNotificationsApi({
    endpoint: `/api/announcements/${id}`,
    type: 'DELETE'
  });

  return response.status;
};

export const saveAnnouncement = async (payload: IAnnouncementToSave) => {
  const response = await callNotificationsApi({
    endpoint: '/api/announcements/',
    type: 'POST',
    requestData: { ...payload }
  });

  return response.json();
};

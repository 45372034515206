import {
  fetchAllTemplatesRoutine,
  templateUpdateRoutine,
  templateDeletedRoutine,
  templateSavedRoutine,
  selectedTemplateChangedRoutine,
  resetTemplateSelectionRoutine
} from '../../routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import * as formsService from '../../services/formsService';

function* templatesRequest({ payload }: Routine<any>): Routine<any> {
  try {
    const result = yield call(formsService.getTemplates, payload);
    console.log('here');
    yield put(fetchAllTemplatesRoutine.success(result));
  } catch (error) {
    yield put(fetchAllTemplatesRoutine.failure(error));
  }
}

function* changedTemplate({ payload }: Routine<any>): Routine<any> {
  if (payload === null) {
    yield put(selectedTemplateChangedRoutine.success(payload));
  } else {
    try {
      const result = yield call(formsService.getForm, payload);

      yield put(selectedTemplateChangedRoutine.success(result));
    } catch (error) {
      yield put(selectedTemplateChangedRoutine.failure(error));
    }
  }
}

function* updateTemplate({ payload }: Routine<any>): Routine<any> {
  try {
    console.log(payload);
    const result = yield call(formsService.updateForm, payload);
    console.log(result);
    yield put(templateUpdateRoutine.success(result.data));
  } catch (error) {
    yield put(templateUpdateRoutine.failure(error));
  }
}

function* saveTemplate({ payload }: Routine<any>): Routine<any> {
  try {
    const result = yield call(formsService.saveForm, payload);
    console.log('here');
    yield put(templateSavedRoutine.success(result.data));
  } catch (error) {
    yield put(templateSavedRoutine.failure(error));
  }
}

function* deleteTemplate({ payload }: Routine<any>): Routine<any> {
  try {
    const id = payload;
    yield call(formsService.deleteForm, payload);

    yield put(templateDeletedRoutine.success(id));
  } catch (error) {
    yield put(templateDeletedRoutine.failure(error));
  }
}
function* resetState(): Routine<any> {
  try {
    yield put(resetTemplateSelectionRoutine.success());
  } catch (error) {
    yield put(resetTemplateSelectionRoutine.failure(error));
  }
}

function* watchTemplatesRequest() {
  yield takeEvery(fetchAllTemplatesRoutine.TRIGGER, templatesRequest);
}

function* watchChangedTemplate() {
  yield takeEvery(selectedTemplateChangedRoutine.TRIGGER, changedTemplate);
}

function* watchUpdateTemplate() {
  yield takeEvery(templateUpdateRoutine.TRIGGER, updateTemplate);
}

function* watchSaveTemplate() {
  yield takeEvery(templateSavedRoutine.TRIGGER, saveTemplate);
}

function* watchDeleteTemplate() {
  yield takeEvery(templateDeletedRoutine.TRIGGER, deleteTemplate);
}

function* watchResetState() {
  yield takeEvery(resetTemplateSelectionRoutine.TRIGGER, resetState);
}

export default function* templatesPageSagas() {
  yield all([
    watchDeleteTemplate(),
    watchSaveTemplate(),
    watchUpdateTemplate(),
    watchChangedTemplate(),
    resetState(),
    watchTemplatesRequest(),
    watchResetState()
  ]);
}

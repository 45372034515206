import * as queryString from 'query-string';
import { logout } from 'screens/Authorization/services/authService';
import { toastr } from 'react-redux-toastr';
import { ApplicationType } from 'models/fetch/enums/ApplicationType';
import { IFetchFormArgsData } from 'models/fetch/IFetchFormArgsData';
import { IFetchFormArgs } from 'models/fetch/IFetchFormArgs';
import { authHeader } from '../screens/Authorization/helpers/authHeader';
import { IFetchArgs, IFetchArgsData } from '../models/fetch';

function getFetchUrl({ endpoint, queryParams }: IFetchArgsData | IFetchFormArgsData) {
  return `${endpoint}${
    queryParams ? `?${queryString.stringify(queryParams)}` : ''
  }`;
}

function getInitHeaders(contentType = 'application/json', hasContent = true) {
  const headers: HeadersInit = new Headers();
  const header = authHeader();
  headers.set('Authorization', header.Authorization);
  if (hasContent) {
    headers.set('Content-Type', contentType);
  }
  return headers;
}

function getFetchArgs(args: IFetchArgsData): IFetchArgs {
  const headers = getInitHeaders();
  if (args.application) {
    headers.set('application', args.application);
  }

  if (args.requestData && args.type === 'GET') {
    throw new Error('GET request does not support request body.');
  }

  return {
    method: args.type,
    headers,
    ...(args.type === 'GET' ? {} : { body: JSON.stringify(args.requestData) })
  };
}

const getFetchFormArgs = (args: IFetchFormArgsData): IFetchFormArgs => {
  const headers = getInitHeaders('', false);
  if (args.application) {
    headers.set('application', args.application);
  }

  return {
    method: 'POST',
    headers,
    body: args.body
  };
};

async function throwIfResponseFailed(res: Response) {
  if (!res.ok) {
    if (res.status === 401) {
      await logout();
    }
    const mockErrorMsg = 'Something went wrong with request!';
    let parsedException = mockErrorMsg;
    try {
      parsedException = await res.text();
      parsedException = JSON.parse(parsedException);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`An error occurred: ${err}`);
    } finally {
      if (parsedException.length > 50 || parsedException.length === 0) {
        parsedException = mockErrorMsg;
      }
      toastr.error('Error!', parsedException);
    }
    throw parsedException;
  }
}

async function callWebApi(args: IFetchArgsData): Promise<Response> {
  const res = await fetch(getFetchUrl(args), getFetchArgs(args));
  await throwIfResponseFailed(res);
  return res;
}

export async function callApiToUploadFile(args: IFetchFormArgsData): Promise<Response> {
  const res = await fetch(getFetchUrl(args), getFetchFormArgs(args));
  await throwIfResponseFailed(res);
  return res;
}

export const callTimeOffTracker = (args: IFetchArgsData) => callWebApi({
  ...args, application: ApplicationType.TimeOffTracker
});
export const callTimeOffTrackerApiToUploadFile = (args: IFetchFormArgsData) => callApiToUploadFile({
  ...args, application: ApplicationType.TimeOffTracker
});
export const callOkrApi = (args: IFetchArgsData) => callWebApi({
  ...args, application: ApplicationType.OKR
});
export const callNotificationsApi = (args: IFetchArgsData) => callWebApi({
  ...args, application: ApplicationType.Notifications
});
export const callUserProfileApi = (args: IFetchArgsData) => callWebApi({
  ...args, application: ApplicationType.UserProfile
});
export const callProjectsApi = (args: IFetchArgsData) => callWebApi({
  ...args, application: ApplicationType.Projects
});
export const callFeedbackApi = (args: IFetchArgsData) => callWebApi({
  ...args, application: ApplicationType.Feedback
});
export const callImportFeedbackApi = (args: IFetchFormArgsData) => callApiToUploadFile({
  ...args, application: ApplicationType.Feedback
});
export const callGatewayApi = (args: IFetchArgsData) => callWebApi({
  ...args
});

export enum KeyResultType {
  achievement = 'Achievement',
  article = 'Article',
  book = 'Book',
  contribution = 'Contribution',
  course = 'Course',
  project = 'Project',
  research = 'Research',
  misc = 'Misc',
  video = 'Video',
  documentation = 'Documentation',
  publicSpeaking = 'Public Speaking'
}

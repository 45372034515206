import { Routine } from 'redux-saga-routines';
import { IUserDaysOff } from 'screens/TimeOffTracker/models/daysOff/userForDaysOff';
import { fetchAllUsersRoutine } from 'screens/TimeOffTracker/routines';

export const users = (state = [], action: Routine<any>): IUserDaysOff[] => {
  switch (action.type) {
    case fetchAllUsersRoutine.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

import { createRoutine } from 'redux-saga-routines';

export const fetchAnnouncementsRoutine = createRoutine('FETCH_ANNOUNCEMENTS');

export const fetchAnnouncementByIdRoutine = createRoutine('FETCH_ANNOUNCEMENT_BY_ID');

export const saveAnnouncementsRoutine = createRoutine('SAVE_ANNOUNCEMENT');

export const removeAnnouncementsRoutine = createRoutine('REMOVE_ANNOUNCEMENT');

export const commentAnnouncementRoutine = createRoutine('COMMENT_ANNOUNCEMENT');

export const removeAnnouncementDetailsRoutine = createRoutine('REMOVE_ANNOUNCEMENT_DETAILS');

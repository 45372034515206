import { Routine } from 'redux-saga-routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  questionsBlockCreateRoutine,
  questionsBlockDeleteRoutine,
  questionsBlockUpdateRoutine,
  selectedQuestionsBlockChangedRoutine,
  fetchQuestionsBlocksRoutine,
  questionsBlockReorderRoutine
} from 'screens/Feedback/routines';
import * as questionsBlockService from '../../services/questionsBlockService';

function* getQuestionsBlocks({ payload }: Routine<any>): Routine<any> {
  if (payload === null) {
    yield put(fetchQuestionsBlocksRoutine.success(payload));
  } else {
    try {
      const result = yield call(questionsBlockService.getBlocks, payload);

      yield put(fetchQuestionsBlocksRoutine.success(result));
    } catch (error) {
      yield put(fetchQuestionsBlocksRoutine.failure(error));
    }
  }
}

function* changeQuestionsBlock({ payload }: Routine<any>): Routine<any> {
  if (payload === null) {
    yield put(selectedQuestionsBlockChangedRoutine.success(payload));
  } else {
    try {
      const result = yield call(questionsBlockService.getBlock, payload);

      yield put(selectedQuestionsBlockChangedRoutine.success(result));
    } catch (error) {
      yield put(selectedQuestionsBlockChangedRoutine.failure(error));
    }
  }
}

function* createQuestionsBlock({ payload }: Routine<any>): Routine<any> {
  try {
    console.log(payload);
    const result = yield call(questionsBlockService.createBlock, payload);
    yield put(questionsBlockCreateRoutine.success(result.data));
  } catch (error) {
    yield put(questionsBlockCreateRoutine.failure(error));
  }
}

function* reorderQuestionsBlock({ payload }: Routine<any>): Routine<any> {
  try {
    const result = yield call(questionsBlockService.reorderBlocks, payload);

    yield put(questionsBlockReorderRoutine.success(result));
  } catch (error) {
    yield put(questionsBlockReorderRoutine.failure(error));
  }
}

function* updateQuestionsBlock({ payload }: Routine<any>): Routine<any> {
  try {
    const result = yield call(questionsBlockService.updateBlock, payload);

    yield put(questionsBlockUpdateRoutine.success(result.data));
  } catch (error) {
    yield put(questionsBlockUpdateRoutine.failure(error));
  }
}

function* deleteQuestionsBlock({ payload }: Routine<any>): Routine<any> {
  try {
    const id = payload;
    yield call(questionsBlockService.deleteBlock, payload);

    yield put(questionsBlockDeleteRoutine.success(id));
  } catch (error) {
    yield put(questionsBlockDeleteRoutine.failure(error));
  }
}
function* watchGetQuestionsBlocks() {
  yield takeEvery(fetchQuestionsBlocksRoutine.TRIGGER, getQuestionsBlocks);
}
function* watchChangedQuestionsBlock() {
  yield takeEvery(selectedQuestionsBlockChangedRoutine.TRIGGER, changeQuestionsBlock);
}

function* watchCreateQuestionsBlock() {
  yield takeEvery(questionsBlockCreateRoutine.TRIGGER, createQuestionsBlock);
}

function* watchUpdateQuestionsBlock() {
  yield takeEvery(questionsBlockUpdateRoutine.TRIGGER, updateQuestionsBlock);
}

function* watchReorderQuestionsBlocks() {
  yield takeEvery(questionsBlockReorderRoutine.TRIGGER, reorderQuestionsBlock);
}

function* watchDeleteQuestionsBlock() {
  yield takeEvery(questionsBlockDeleteRoutine.TRIGGER, deleteQuestionsBlock);
}

export default function* questionsBlocksPageSagas() {
  yield all([
    watchGetQuestionsBlocks(),
    watchChangedQuestionsBlock(),
    watchCreateQuestionsBlock(),
    watchUpdateQuestionsBlock(),
    watchDeleteQuestionsBlock(),
    watchReorderQuestionsBlocks()
  ]);
}

import { callApiToUploadFile, callGatewayApi } from 'helpers/webApiHelper';
import { IImportUsersFromGoogleFormProps } from '../components/ImportFromGoogleLinkModal';

export const uploadUserFile = async (data: FormData) => {
  const response = await callApiToUploadFile({
    endpoint: '/api/csvUsers/upload',
    body: data
  });
  return response.json();
};

export const downloadUserFile = () => callGatewayApi({
  endpoint: '/api/csvUsers/download',
  type: 'GET'
});

export const importUsersFromGoogle = async (linkData: IImportUsersFromGoogleFormProps) => {
  const response = await callGatewayApi({
    endpoint: '/api/csvUsers/upload-from-google-csv',
    type: 'POST',
    requestData: linkData
  });
  return response.json();
};

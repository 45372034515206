import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from 'screens/Authorization/containers/LoginPage';
import { IUser } from 'screens/OKR/models/user/IUser';
import { connect } from 'react-redux';
import Header from 'components/Header';
import LoaderWrapper from 'components/LoaderWrapper';
import ProfilePage from 'screens/UserProfile/containers/ProfilePage';
import OkrView from 'screens/OKR/containers/OkrView';
import SharedOkr from 'screens/OKR/components/SharedOkr';
import Users from 'screens/AdminPage/containers/Users';
import TeamView from 'screens/Team/containers/TeamView';
import FeedbackMenu from 'screens/FeedbackMenu';
import { authService } from '../../screens/Authorization/services/authService';
import { Role } from '../../screens/Authorization/models/role';
import PrivateRoute from '../../components/PrivateRoute';
import OKRMenu from 'screens/OKRMenu';
import { RegisterPage } from 'screens/Authorization/components/RegisterPage';
import ResetPasswordPage from 'screens/Authorization/containers/ResetPasswordPage';
import ForgotPasswordPage from 'screens/Authorization/containers/ForgotPasswordPage';
import NoActivatedEmailPage from 'screens/Authorization/components/NoActivatedEmailPage';
import ConfirmEmailPage from 'screens/Authorization/containers/ConfirmEmailPage';
import PublicRoute from 'components/PublicRoute';
import ResendEmailConfirmationLinkPage from 'screens/Authorization/containers/ResendEmailConfirmationLinkPage';
import NewsItemDetails from 'screens/News/containers/NewsItemDetails';
import NewsMenu from 'screens/News/NewsMenu';
import AnnouncementItemDetails from 'screens/Announcements/containers/AnnouncementItemDetails';
import styles from './styles.module.scss';
import ManagementView from '../../screens/Library/containers/LibraryView';
import ManageRequestsByLink from 'screens/Feedback/containers/ManageRequestsByLink';

export interface IRoutingState {
  currentUser: IUser;
  isAdmin: boolean;
  history: any;
}

export interface IRoutingProps {
  isLoading: boolean;
  isAuthorized: boolean;
}

const Routing: React.FunctionComponent<IRoutingProps> = ({ isLoading, isAuthorized }) => {
  const token = authService.tokenValue;

  return (
    <div>
      {isAuthorized ? <Header /> : ''}
      <Switch>
        <PublicRoute exact path="/login" component={LoginPage} />
        <PublicRoute exact path="/register" component={RegisterPage} />
        <PublicRoute exact path="/register-unconfirmed" component={NoActivatedEmailPage} />
        <PublicRoute exact path="/activate" component={ConfirmEmailPage} />
        <PublicRoute exact path="/forget-password" component={ForgotPasswordPage} />
        <PublicRoute exact path="/reset-password" component={ResetPasswordPage} />
        <PublicRoute exact path="/resend-email-confirmation" component={ResendEmailConfirmationLinkPage} />
        <PublicRoute exact path="/form/:id([-0-9a-fA-F]{36})" component={ManageRequestsByLink} />
        <Route
          title="OKR | Binary Studio"
          exact
          path="/share/:okrId([-0-9a-fA-F]{36})"
          component={SharedOkr}
        />
        <div className={styles.loaderWrapper}>
          <LoaderWrapper loading={isLoading || (token && !isAuthorized)}>
            <Switch>
              <PrivateRoute
                exact
                path="/okr/library"
                roles={[Role.Administrator]}
                component={ManagementView}
              />
              <PrivateRoute
                title="OKR | Binary Studio"
                exact
                path="/okr/:okrId([-0-9a-fA-F]{36})"
                component={OkrView}
              />
              <PrivateRoute
                title="OKR | Binary Studio"
                path="/okr"
                component={OKRMenu}
              />
              <PrivateRoute
                title="Profile | Binary Studio"
                path="/profile"
                component={ProfilePage}
              />
              <PrivateRoute
                title="Profile | Binary Studio"
                roles={[Role.Administrator, Role.Developer, Role.Manager, Role.HR, Role.TopManager]}
                path="/profile/:profileId([-0-9a-fA-F]{36})"
                component={ProfilePage}
              />
              <PrivateRoute
                path="/news/announcements/:announcementId([-0-9a-fA-F]{36})"
                roles={[Role.Administrator, Role.Developer, Role.Manager, Role.HR, Role.TopManager]}
                component={AnnouncementItemDetails}
              />
              <PrivateRoute
                exact
                path="/news/:newsId([-0-9a-fA-F]{36})"
                roles={[Role.Administrator, Role.Developer, Role.Manager, Role.HR, Role.TopManager]}
                component={NewsItemDetails}
              />
              <PrivateRoute
                path="/news"
                roles={[Role.Administrator, Role.Developer, Role.Manager, Role.HR, Role.TopManager]}
                component={NewsMenu}
              />
              <PrivateRoute
                exact
                path="/users"
                roles={[Role.Administrator, Role.HR, Role.TopManager, Role.Manager]}
                component={Users}
              />
              <PrivateRoute
                title="Team | Binary Studio"
                path="/team"
                component={TeamView}
              />
              {/* <PrivateRoute
              exact
              path="/projects"
              roles={[Role.Administrator]}
              component={ProjectsList}
            /> */}
              {/* <PrivateRoute
              exact
              path="/timeoff"
              roles={[Role.Administrator, Role.Manager]}
              component={TimeOffPage}
            /> */}
              <PrivateRoute
                title="Feedback | Binary Studio"
                path="/feedback"
                component={FeedbackMenu}
              />
              <Route path="/*">
                <Redirect to="/news" />
              </Route>
            </Switch>
          </LoaderWrapper>
        </div>
      </Switch>
    </div>
  );
};

const mapToStateProps = state => {
  const { isLoading, isAuthorized } = state.user;
  return {
    isLoading,
    isAuthorized
  };
};

export default connect(mapToStateProps, null)(Routing);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './styles.module.scss';
import { Icon } from 'semantic-ui-react';
import { IQuestionBlock } from 'screens/Feedback/model/Questions/IQuestionBlock';

interface IQuestionsBlocksListProps {
  questionsBlocks: IQuestionBlock[];
  selectedBlock: IQuestionBlock;
  questionsBlockClicked: (block: string) => void;
  questionsBlockDeleted: (blockId: string) => void;
  questionsBlockReorder: (block: IQuestionBlock) => void;
}

const QuestionsBlocksList: React.FunctionComponent<IQuestionsBlocksListProps> = ({
  questionsBlocks,
  selectedBlock,
  questionsBlockClicked,
  questionsBlockDeleted,
  questionsBlockReorder
}: IQuestionsBlocksListProps) => {
  const [blocks, setBlocks] = useState(questionsBlocks.sort((a, b) => (a.index > b.index ? 1 : -1)));
  useEffect(() => {
    setBlocks(questionsBlocks.sort((a, b) => (a.index > b.index ? 1 : -1)));
  }, [questionsBlocks]);
  const reorder = result => {
    if (result?.destination) {
      questionsBlockReorder({
        ...questionsBlocks.find(b => b.id === result.draggableId),
        index: result.destination.index + 1
      });
      const items = Array.from(blocks);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setBlocks(items);
    }
  };

  const getItemStyle = blockId => (`${styles.blockInList}
  ${blockId === selectedBlock?.id ? styles.selectedBlockInList : ''}`);

  return (
    <div className={styles.dragAndDropContainer}>
      <DragDropContext onDragEnd={reorder}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {blocks.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                  {(_provided, _snapshot) => (
                    <div
                      ref={_provided.innerRef}
                      {..._provided.draggableProps}
                      {..._provided.dragHandleProps}
                      className={getItemStyle(item.id)}
                    >
                      <div
                        className={styles.blockTitleContainer}
                        onClick={() => questionsBlockClicked(item.id)}
                      >
                        <span>{item.title}</span>
                      </div>
                      <div
                        className={styles.trashIconContainer}
                        onClick={() => questionsBlockDeleted(item.id)}
                      >
                        <Icon name="trash alternate" />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      questionsBlocks: {
        questionsBlocks,
        selectedQuestionsBlock
      }
    }
  } = state;

  return {
    questionsBlocks,
    selectedBlock: selectedQuestionsBlock
  };
};

export default connect(mapStateToProps, {})(QuestionsBlocksList);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IQuestion } from 'screens/Feedback/model/Questions/IQuestion';
import { updateQuestionRoutine,
  createQuestionRoutine, fetchQuestionsRoutine,
  selectedQuestionsBlockChangedRoutine,
  deleteQuestionRoutine
} from 'screens/Feedback/routines';
import { Form, Button, Checkbox, Icon, List } from 'semantic-ui-react';
import Question from '../Question';
import styles from './styles.module.scss';
import { IBindingCallback1 } from '../../../../models/callback';
import { IQuestionBlock } from 'screens/Feedback/model/Questions/IQuestionBlock';

interface IQuestionsProps {
  selectedQuestionsBlock: IQuestionBlock;
  questions: IQuestion[];
  getQuestions: IBindingCallback1<string>;
  deleteQuestion: IBindingCallback1<string>;
  createQuestion: IBindingCallback1<IQuestion>;
  updateQuestion: IBindingCallback1<IQuestion>;
}

const Questions: React.FunctionComponent<IQuestionsProps> = ({
  selectedQuestionsBlock,
  questions,
  deleteQuestion,
  updateQuestion,
  createQuestion,
  getQuestions
}: IQuestionsProps) => {
  const [isAdd, setIsAdd] = useState(false);
  const [text, setText] = useState('');
  const [type, setType] = useState(undefined);
  const [isNegative, setIsNegative] = useState(false);
  const [radioOptions, setRadioOptions] = useState([]);
  const [tempOption, setTempOption] = useState('');
  const options360 = ['strongly agree', 'agree', 'disagree', 'strongly disagree', 'don\'t know'];
  useEffect(() => {
    getQuestions(selectedQuestionsBlock.id);
  }, [selectedQuestionsBlock]);

  useEffect(() => {
    setText(undefined);
    setType(undefined);
    setRadioOptions([]);
    setIsNegative(false);
  }, [isAdd]);
  useEffect(() => {
    if (type === 'FEEDBACK_360') {
      setRadioOptions(options360);
    } else { setRadioOptions([]); }
  }, [type]);

  const options = [{
    key: 1,
    text: 'Open Answer',
    value: 'OPEN'
  },
  {
    key: 2,
    text: 'Single Choice (custom)',
    value: 'CUSTOM'
  },
  {
    key: 3,
    text: 'Single Choice (360)',
    value: 'FEEDBACK_360'
  }
  ];
  const onCancel = () => {
    setIsAdd(false);
  };
  const onSubmit = () => {
    createQuestion({
      blockId: selectedQuestionsBlock?.id,
      type,
      options: radioOptions,
      description: null,
      text,
      negative: isNegative
    });
    setIsAdd(false);
  };
  const AddOption = () => {
    setRadioOptions([...radioOptions, tempOption]);
    setTempOption('');
  };

  const deleteOption = option => {
    const newOptions = radioOptions.filter(o => o !== option);
    setRadioOptions(newOptions);
  };
  return (
    <div className={styles.questions}>
      <h3>Questions</h3>
      { selectedQuestionsBlock && questions?.map(question => (
        <Question
          question={question}
          deleteQuestion={deleteQuestion}
          updateQuestion={updateQuestion}
          blockId={selectedQuestionsBlock.id}
        />
      ))}
      { !isAdd
        ? (
          <div onClick={() => setIsAdd(true)} className={styles.buttonAdd}>
            <div className={styles.plusContainer}>
              <Icon name="plus square" />
            </div>
            Add new question
          </div>
        )
        : (
          <>
            <h3>Add new question</h3>
            <Form>
              <Form.Group className={styles.questionsRow}>
                <Form.Input
                  width={9}
                  label="Text"
                  placeholder="Enter text"
                  value={text}
                  onChange={e => setText(e.target.value)}
                  className={`fullWidthData ${styles.formInput}`}
                />
                <Form.Dropdown
                  selection
                  label="Type"
                  options={options}
                  value={type}
                  onChange={(e, o) => setType(o.value)}
                />
                {type === 'FEEDBACK_360'
                  && (
                    <Form.Checkbox
                      toggle
                      label="Is Negative"
                      checked={isNegative}
                      onChange={() => setIsNegative(!isNegative)}
                    />
                  )}
              </Form.Group>
              {(type !== 'OPEN' && type !== undefined) && (
                <>
                  <h5>Options</h5>
                  {type === 'CUSTOM' && (
                    <Form.Group>
                      <Form.Input
                        width={9}
                        placeholder="Enter option"
                        name="name"
                        defaultValue={tempOption}
                        value={tempOption}
                        className={`fullWidthData ${styles.formInput}`}
                        onChange={e => setTempOption(e.target.value)}
                      />
                      <Button
                        primary
                        disabled={!tempOption}
                        className={styles.formButton}
                        onClick={() => AddOption()}
                      >
                        Add Option
                      </Button>
                    </Form.Group>
                  )}
                  <Form.Field width={9}>
                    <List
                      relaxed
                      verticalAlign="middle"
                    >
                      {radioOptions.map(option => (
                        <List.Item className={styles.itemAssigned}>
                          <List.Content floated="right">
                            {type === 'CUSTOM'
                              && (
                                <Icon
                                  name="trash alternate"
                                  onClick={() => deleteOption(option)}
                                />
                              )}
                          </List.Content>
                          <List.Content>
                            <Checkbox
                              radio
                              label={option}
                              readOnly
                            />
                          </List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </Form.Field>
                </>
              )}

              <Button
                primary
                className={styles.formButton}
                onClick={onSubmit}
                disabled={!(text && (type === 'OPEN' || (type && radioOptions.length !== 0)))}
              >
                Save
              </Button>
              <Button
                basic
                color="orange"
                className={styles.formButton}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Form>
          </>
        )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      questionsBlocks: {
        selectedQuestionsBlock
      }
    }
  } = state;
  const {
    feedbackPage: {
      questions: {
        blockQuestions
      }
    }
  } = state;
  return {
    selectedQuestionsBlock,
    questions: blockQuestions
  };
};
const mapDispatchToProps = {
  getQuestions: fetchQuestionsRoutine,
  deleteQuestion: deleteQuestionRoutine,
  selectedQuestionsBlockChanged: selectedQuestionsBlockChangedRoutine,
  createQuestion: createQuestionRoutine,
  updateQuestion: updateQuestionRoutine
};
export default connect(mapStateToProps, mapDispatchToProps)(Questions);

import { IRequest } from '../../model/Requests/IRequest';
import { Routine } from 'redux-saga-routines';
import { fetchRequestDetailsRoutine, fetchRequestsRoutine,
  importFeedbacksCsvRoutine, fetchUsersMentorsRoutine,
  clearFeedbackPageStateRoutine } from '../../routines';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';

export interface IRequestsState {
  requests: IRequest[];
  refreshNeeded: boolean;
  detailedRequest: IRequest;
  usersMentor: IUserMentorData[];
}

const initialState: IRequestsState = {
  requests: [],
  refreshNeeded: true,
  detailedRequest: null,
  usersMentor: null
};

export const requests = (
  state = initialState,
  action: Routine<any>
): IRequestsState => {
  switch (action.type) {
    case fetchRequestsRoutine.SUCCESS: {
      return {
        ...state,
        requests: action.payload,
        refreshNeeded: false
      };
    }
    case fetchUsersMentorsRoutine.SUCCESS: {
      return {
        ...state,
        usersMentor: action.payload,
        refreshNeeded: false
      };
    }
    case importFeedbacksCsvRoutine.SUCCESS:
    case importFeedbacksCsvRoutine.FAILURE: {
      return {
        ...state,
        refreshNeeded: true
      };
    }
    case fetchRequestDetailsRoutine.SUCCESS: {
      return {
        ...state,
        detailedRequest: action.payload
      };
    }
    case clearFeedbackPageStateRoutine.TRIGGER:
      return initialState;
    default:
      return state;
  }
};

import { Routine } from 'redux-saga-routines';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import * as userService from '../../services/userService';
import { fetchTeamUsersRoutine } from '../../routines';

function* fetchTeamUsers({ payload }: Routine<any>): Routine<any> {
  try {
    const response = yield call(userService.getAll, payload);
    yield put(fetchTeamUsersRoutine.success(response));
  } catch (error) {
    yield put(fetchTeamUsersRoutine.failure(error.message));
  }
}

function* watchFetchTeamUsers() {
  yield takeEvery(fetchTeamUsersRoutine.TRIGGER, fetchTeamUsers);
}

export default function* teamUserSagas() {
  yield all([
    watchFetchTeamUsers()
  ]);
}

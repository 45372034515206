import React from 'react';
import { Button, Form, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { IBindingCallback1 } from 'models/callback/Callbacks';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { IUserRegister } from 'screens/Authorization/models/IUserRegister';
import 'react-day-picker/lib/style.css';
import { registerRoutine } from 'screens/Authorization/routines';

const initialValues: IRegisterFormikProps = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  offices: [],
  repeatPassword: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required!')
    .max(255, 'Max email length is 255 symbols!')
    .email('Please, enter email, not random string!')
    .matches(/^\S+@binary-studio.com$/, 'Domain name should be @binary-studio'),
  password: Yup.string()
    .min(6, 'Password should have at least 6 symbols!')
    .max(100, 'And who will remember so long string!')
    .required('Password is required!'),
  firstName: Yup.string()
    .required('First name is required!')
    .min(3, 'Minimum first name length is 3 symbols!')
    .max(30, 'Maximum first name length is 30 symbols!'),
  lastName: Yup.string()
    .required('Last name is required!')
    .min(3, 'Minimum last name length is 3 symbols!')
    .max(30, 'Maximum last name length is 30 symbols!'),
  offices: Yup.string()
    .required('At least one office is required!'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Both passwords need to be the same')
    .required('Password confirm is required')
});

export interface IRegisterFormProps {
  register: IBindingCallback1<IUserRegister>;
  loading: boolean;
}

interface IRegisterFormikProps extends IUserRegister {
  repeatPassword: string;
}

const isAllValuesSet = (values: IRegisterFormikProps) => Boolean(
  values.email
  && values.firstName
  && values.lastName
  && values.offices
  && values.password
  && values.repeatPassword
);

const officesDict = ['Lviv', 'Kyiv'].map(o => ({ key: o, value: o, text: o }));

const RegistrationForm: React.FunctionComponent<IRegisterFormProps> = ({
  register,
  loading
}: IRegisterFormProps) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values, { setSubmitting }) => {
      register(values);
      setSubmitting(false);
    }}
    validationSchema={validationSchema}
  >
    {({
      values,
      errors,
      touched,
      handleSubmit,
      handleChange,
      setFieldValue
    }: FormikProps<IRegisterFormikProps>) => (
      <Form onSubmit={handleSubmit} className={styles.container}>
        <Popup
          open={!!(touched.firstName && errors.firstName)}
          content={errors.firstName}
          on={[]}
          trigger={(
            <Form.Input
              name="firstName"
              placeholder="First name"
              label="First name"
              error={Boolean(touched.firstName && errors.firstName)}
              onChange={handleChange}
              value={values.firstName}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <Popup
          open={!!(touched.lastName && errors.lastName)}
          content={errors.lastName}
          on={[]}
          trigger={(
            <Form.Input
              placeholder="Last name"
              name="lastName"
              label="Last name"
              error={Boolean(touched.lastName && errors.lastName)}
              onChange={handleChange}
              value={values.lastName}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <Form.Select
          multiple
          options={officesDict}
          label={touched.offices && errors.offices ? errors.offices : 'Office'}
          name="offices"
          error={Boolean(touched.offices && errors.offices)}
          value={values.offices}
          onChange={(event, o) => setFieldValue('offices', o.value)}
        />
        <Popup
          open={!!(touched.email && errors.email)}
          content={errors.email}
          on={[]}
          trigger={(
            <Form.Input
              name="email"
              label="Email"
              placeholder="Email address"
              error={Boolean(touched.email && errors.email)}
              onChange={handleChange}
              value={values.email}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <Popup
          open={!!(touched.password && errors.password)}
          content={errors.password}
          on={[]}
          trigger={(
            <Form.Input
              placeholder="Password"
              type="password"
              name="password"
              label="Password"
              error={Boolean(touched.password && errors.password)}
              value={values.password}
              onChange={handleChange}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <Popup
          open={!!(touched.repeatPassword && errors.repeatPassword)}
          content={errors.repeatPassword}
          on={[]}
          trigger={(
            <Form.Input
              placeholder="Repeat password"
              type="password"
              name="repeatPassword"
              label="Repeat password"
              error={Boolean(touched.repeatPassword && errors.repeatPassword)}
              value={values.repeatPassword}
              onChange={handleChange}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <div className={styles.actions}>
          <Button
            primary
            fluid
            size="large"
            type="submit"
            loading={loading}
            disabled={loading || !isAllValuesSet(values)}
            content="Register"
          />
        </div>
      </Form>
    )}
  </Formik>
);

const mapStateToProps = state => {
  const {
    auth: {
      requests: { register }
    }
  } = state;
  return {
    loading: register.loading
  };
};

const mapDispatchToProps = {
  register: registerRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);

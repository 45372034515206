/* eslint-disable max-len */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, Icon, Image, List, Menu } from 'semantic-ui-react';
import logo from '../../../../assets/images/company_name.svg';
import LoaderWrapper from 'components/LoaderWrapper';
import { authService } from 'screens/Authorization/services/authService';
import { IBindingCallback1 } from 'models/callback';
import { IKeyResult } from 'screens/OKR/models/key-result/IKeyResult';
import { IGlobalState } from 'models/global-state';
import ObjectiveItem from '../ObjectiveItem';
import {
  IOkrExtended
} from '../../models/okr';
import {
  fetchExtendedOkr
} from '../../routines';
import styles from './styles.module.scss';
import { formatDate } from '../../../../helpers/dateFormatHelper';
import { history } from '../../../../helpers/history';
import { calcOkrsMark, calcObjectivesMarks } from 'screens/OKR/helpers/array.helper';

interface IOkrListProps {
  fetchExtendedOkr: IBindingCallback1<string>;
  error: string | object;
  okr: IOkrExtended;
  loading: boolean;
  history: any;
  match: any;
}

interface IOkrListState {
  notMarkedOkr: IKeyResult[]; // may be deleted
  okrId: string;
  onLoggedUser: boolean;
}

class SharedOkr extends React.Component<IOkrListProps, IOkrListState> {
  constructor(props) {
    super(props);

    this.state = {
      notMarkedOkr: [],
      okrId: '',
      onLoggedUser: false
    };
  }

  componentDidMount() {
    const { match: { params: { okrId } }, fetchExtendedOkr: ftExtendedOkr } = this.props;
    this.setState({ onLoggedUser: Boolean(authService.tokenValue) });
    this.setState({ okrId });
    ftExtendedOkr(okrId);
  }

  render() {
    const {
      match: { params },
      okr,
      loading
    } = this.props;
    const {
      notMarkedOkr,
      okrId,
      onLoggedUser
    } = this.state;

    const isLoading = loading || !okr || okr.id !== params.okrId;
    const marks = calcObjectivesMarks(okr?.objectives);
    const averageMark = calcOkrsMark(marks);

    const handleItemClick = (_, name: string) => {
      history.push(`${window.location.origin}/${name}`);
    };

    return (
      (onLoggedUser) ? (<Redirect to={`/okr/${okrId}`} />)
        : (okr) && (
          <div>
            <Menu secondary className={styles.menu}>
              <Menu.Item
                onClick={e => handleItemClick(e, '/login')}
                className={styles.logoMenuItem}
                content={(
                  <Image src={logo} alt="" className={styles.logo} />
                )}
              />
            </Menu>
            <div style={{ height: 'calc(100vh - 50px)', overflowY: 'auto' }}>
              <LoaderWrapper loading={isLoading}>
                <div className={styles.listContainerWrapper}>
                  <div className={styles.listContainer}>
                    <div
                      className={styles.okrHeader}
                      role="presentation"
                    >
                      <Header className={styles.listHeader}>
                        <Header.Content className={styles.header}>
                          {okr.name}
                        </Header.Content>
                        <Header.Subheader className={styles.listSubHeader}>
                          {okr.description}
                        </Header.Subheader>
                      </Header>
                      <div className={styles.dateAndEdit}>
                        <div>
                          <p>
                            <Icon name="calendar outline" />
                            <span
                              className={`${styles.dates}`}
                            >
                              {`${formatDate(okr.startTime)} - ${formatDate(okr.endTime)}`}
                            </span>
                          </p>
                        </div>
                        <div>
                          {averageMark !== null ? (
                            <div className={`${okr.isClosed ? styles.closed : ''} ${styles.okrMark}`}>
                              {averageMark}
                            </div>
                          ) : ''}
                        </div>
                      </div>
                    </div>
                    {!okr.objectives.length
                      ? (
                        <p className={styles.placeholderText}>
                          This OKR don&apos;t have any objectives.
                        </p>
                      )
                      : (
                        <List size="massive">
                          <List.Item>
                            <List.Content className={styles.listContent}>
                              <List.List>
                                {okr.objectives
                                  .sort((obj1, obj2) => (obj1.name > obj2.name ? 1 : -1))
                                  .map(objective => <ObjectiveItem key={objective.id} objective={objective} okr={okr} notMarkedOkr={notMarkedOkr} />)}
                              </List.List>
                            </List.Content>
                          </List.Item>
                        </List>
                      )}
                  </div>
                </div>
              </LoaderWrapper>
            </div>
          </div>
        )
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const {
    okr: {
      extendedOkr,
      requests: {
        extendedOkr: { loading, error }
      }
    }
  } = state;

  return {
    okr: extendedOkr,
    loading,
    error
  };
};

const mapDispatchToProps = {
  fetchExtendedOkr
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharedOkr);

import React from 'react';
import { Button, Form, Dropdown } from 'semantic-ui-react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { IBindingCallback1 } from 'models/callback';
import { IImportFromGoogleLink } from '../../model/IImportFromGoogleLink';
import styles from './styles.module.scss';
import { importFromGoogleRoutine } from '../../routines';
import { connect } from 'react-redux';
import FormWrapper from 'components/FormWrapper';
import Sidebar from 'components/DataSidebar';

const validationSchema = Yup.object().shape({
  publicLink: Yup.string()
    .required('Link is required!')
    .max(255, 'Max link length is 255 symbols!'),
  sheetName: Yup.string()
    .required('Sheet name is required!')
});

export interface IGoogleLinkDataFormikProps {
  publicLink: string;
  sheetName: string;
}

export interface IImportUsersFromGoogleFormProps {
  importFromGoogle: IBindingCallback1<IImportFromGoogleLink>;
}

export interface IImportUsersFromGoogleState {
  isOpenedImportModal: boolean;
}

const initialValues = {
  publicLink: '',
  sheetName: ''
};

class ImportUsersFromGoogleLinkModal
  extends React.Component<IImportUsersFromGoogleFormProps, IImportUsersFromGoogleState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedImportModal: false
    };
  }

  handleClick = () => {
    this.setState({ isOpenedImportModal: true });
  }

  onClose = () => this.setState({ isOpenedImportModal: false })

  submit = linkData => {
    const { importFromGoogle } = this.props;

    importFromGoogle(linkData);
    this.onClose();
  };

  render() {
    const { isOpenedImportModal } = this.state;

    const sidebar = isOpenedImportModal ? (
      <Sidebar onClose={() => this.onClose()}>
        <FormWrapper headerText="Import users from Google Sheets" onClose={() => this.onClose()}>
          <Formik
            initialValues={initialValues}
            onSubmit={this.submit}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, handleSubmit, handleChange }:
              FormikProps<IImportFromGoogleLink>) => (
                <Form onSubmit={handleSubmit} width={10}>
                  <Form.Input
                    name="publicLink"
                    placeholder="Link"
                    label={touched.publicLink && errors.publicLink ? errors.publicLink : 'Public link'}
                    error={Boolean(touched.publicLink && errors.publicLink)}
                    onChange={handleChange}
                    value={values.publicLink}
                  />
                  <Form.Input
                    placeholder="Sheet name"
                    name="sheetName"
                    label={touched.sheetName && errors.sheetName ? errors.sheetName : 'Sheet name'}
                    error={Boolean(touched.sheetName && errors.sheetName)}
                    onChange={handleChange}
                    value={values.sheetName}
                  />
                  <div className={styles.buttonsContainer}>
                    <Button
                      primary
                      size="large"
                      type="submit"
                      content="Import data"
                      className="submitButton"
                    />
                  </div>
                </Form>
            )}
          </Formik>
        </FormWrapper>
      </Sidebar>
    ) : null;

    return (
      <>
        <Dropdown.Item onClick={() => this.handleClick()}>
          From Google Sheets
        </Dropdown.Item>
        {sidebar}
      </>
    );
  }
}

const mapDispatchToProps = {
  importFromGoogle: importFromGoogleRoutine
};

export default connect(null, mapDispatchToProps)(ImportUsersFromGoogleLinkModal);

import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { IBindingAction } from 'models/callback';
import sabbaticalImage from '../../assets/images/sabbatical.svg';
import { Button } from 'semantic-ui-react';

export interface ISabbaticalOkrContainerSettigns {
  onButtonClick?: IBindingAction;
  buttonText?: string;
  text: string;
  height?: string;
  background?: boolean;
  imageWidth?: string;
  isOkrClosed?: boolean;
}

export const SabbaticalOkrContainer: FunctionComponent<ISabbaticalOkrContainerSettigns> = ({
  onButtonClick,
  text,
  height = 'calc(100vh - 50px)',
  buttonText,
  background,
  imageWidth = '35%',
  isOkrClosed = false
}: ISabbaticalOkrContainerSettigns) => (
  <div
    className={styles.sabbaticalOkrContainer}
    style={{ height, backgroundColor: background ? '#FBFBFB' : '#FFFFFF' }}
  >
    <img src={sabbaticalImage} alt="sabbatical-img" width={imageWidth} />
    <p>{text}</p>
    {(buttonText && !isOkrClosed) && <Button primary content={buttonText} onClick={onButtonClick} />}
  </div>
);

import { Routine } from 'redux-saga-routines';
import { IRequestAssign } from 'screens/Feedback/model/Requests/IRequestAssign';
import { createRequestRoutine, fetchAssignInfoRoutine, generateLinkRoutine } from '../../routines';

export interface IAssignState {
  createdRequest: boolean;
  assign: IRequestAssign;
  feedbackId: string;
}

const initialState: IAssignState = {
  createdRequest: false,
  assign: null,
  feedbackId: null
};
export const createAssign = (
  state = initialState,
  action: Routine<any>
): IAssignState => {
  switch (action.type) {
    case createRequestRoutine.SUCCESS:
      return {
        ...state,
        createdRequest: action.payload?.status && action.payload?.status !== 'OPENED',
        assign: action.payload
      };
    case fetchAssignInfoRoutine.SUCCESS:
      return {
        ...state,
        createdRequest: action.payload?.status && action.payload?.status !== 'OPENED',
        assign: action.payload
      };
    case generateLinkRoutine.SUCCESS:
      return action.payload ? {
        ...state,
        feedbackId: action.payload?.id,
        assign: {
          ...state.assign,
          users: [...state.assign.users, {
            id: null,
            type: action.payload?.authorType,
            name: action.payload?.authorName,
            feedbackId: action.payload?.id
          }]
        }
      } : {
        ...state,
        feedbackId: null
      };
    default:
      return state;
  }
};

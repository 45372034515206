import { call, put, all, takeEvery } from 'redux-saga/effects';
import { fetchAllDaysOffRoutine } from 'screens/TimeOffTracker/routines';
import * as timeOffService from '../../services/timeOffService';

function* getAllDaysOff() {
  try {
    const response = yield call(timeOffService.getAll);

    yield put(fetchAllDaysOffRoutine.success(response));
  } catch (error) {
    yield put(fetchAllDaysOffRoutine.failure(error.message));
  }
}

function* watchGetAllDaysOff() {
  yield takeEvery(fetchAllDaysOffRoutine.TRIGGER, getAllDaysOff);
}

export default function* timeOffAdminPageDaysSaga() {
  yield all([
    watchGetAllDaysOff()
  ]);
}

import { put, takeEvery, all, call } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import { toastr } from 'react-redux-toastr';

import {
  fetchNewsDetailsByIdRoutine,
  addCommentRoutine
} from 'screens/News/routines';
import * as newsService from '../../services/newsService';
import * as commentService from '../../services/commentService';
import { IComment } from 'screens/BaseModels/IComment';

function* newsDetailsRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(newsService.getFullNewsById, payload);
    const comments = response.comments.flatMap(comment => [
      {
        id: comment.id,
        time: comment.date,
        text: comment.text,
        authorName: comment.authorName,
        authorLogo: comment.authorLogo,
        authorId: comment.authorId
      } as IComment
    ]);
    yield put(fetchNewsDetailsByIdRoutine.success({ ...response, comments }));
  } catch (error) {
    toastr.error('Error!', 'This item was already deleted.');
    yield put(
      fetchNewsDetailsByIdRoutine.failure(error.message)
    );
  }
}

function* addCommentRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(commentService.addNewComment, payload);
    const comment: IComment = {
      newsId: response.newsId,
      id: response.id,
      time: response.date,
      text: response.text,
      authorName: response.authorName,
      authorLogo: response.authorLogo,
      authorId: response.authorId
    };

    yield put(addCommentRoutine.success(comment));
  } catch (error) {
    yield put(addCommentRoutine.failure(error.message));
  }
}

export default function* newsDetailsSaga() {
  yield all([
    yield takeEvery(fetchNewsDetailsByIdRoutine.TRIGGER, newsDetailsRequest),
    yield takeEvery(addCommentRoutine.TRIGGER, addCommentRequest)]);
}

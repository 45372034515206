/* eslint-disable no-shadow */
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { connect } from 'react-redux';
import { toggleEditComment } from 'screens/OKR/routines';

const sidebarRoot = document.getElementById('sidebar-root');

interface ISidebarProps {
  children: any;
  onClose: IBindingAction;
  width?: string;
  isEditing: boolean;
  toggleEditComment: IBindingCallback1<boolean>;
  shouldNotBlockOverflow?: boolean;
}

interface ISidebarState {
  element: HTMLElement;
}

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  constructor(props) {
    super(props);
    this.state = {
      element: document.createElement('div')
    };
  }

  componentDidMount() {
    const { element } = this.state;
    sidebarRoot.appendChild(element);

    document.addEventListener('keydown', this.onKeyDownEvent);
  }

  componentWillUnmount() {
    const { element } = this.state;
    const { toggleEditComment } = this.props;
    sidebarRoot.appendChild(element);
    toggleEditComment(false);

    document.removeEventListener('keydown', this.onKeyDownEvent);
  }

  onKeyDownEvent = e => {
    const { onClose, isEditing } = this.props;
    if (isEditing && e.keyCode === 27) return;
    if (e.keyCode === 27) onClose();
  };

  render() {
    const { children, onClose, width, shouldNotBlockOverflow } = this.props;
    const { element } = this.state;

    const data = (
      <div className={styles.sidebar} onMouseDown={onClose} role="presentation">
        <div
          className={styles.sidebarData}
          onMouseDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          role="presentation"
          style={{
            width: width ?? '600px',
            overflow: shouldNotBlockOverflow ? 'visible' : 'auto'
          }}
        >
          {children}
        </div>
      </div>
    );

    return ReactDOM.createPortal(data, element);
  }
}

const mapStateToProps = state => ({
  isEditing: state.okr.editKeyResultComment
});

const mapDispatchToProps = {
  toggleEditComment
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

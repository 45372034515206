import { IOkrsUserStats } from 'screens/Dashboard/models/IOkrsUserStats';
import { Routine } from 'redux-saga-routines';
import { fetchUserOkrsStatsRoutine, clearDashboardPageRoutine } from 'screens/Dashboard/routines';

export interface IDashboardPageState {
  usersStats: IOkrsUserStats[];
}

export const dashboardPage = (state: IDashboardPageState = {
  usersStats: null
}, action: Routine<any>): IDashboardPageState => {
  switch (action.type) {
    case fetchUserOkrsStatsRoutine.SUCCESS:
      const getLastOkr = okr => (okr || { mark: null, id: null });
      const usersStats = action.payload.map(userStats => ({ ...userStats, lastOkr: getLastOkr(userStats.lastOkr) }));
      return { ...state, usersStats };
    case clearDashboardPageRoutine.TRIGGER:
      return { usersStats: null };
    default:
      return state;
  }
};

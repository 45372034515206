import { Routine } from 'redux-saga-routines';
import { put, takeEvery, all, call, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  fetchNewsRoutine,
  publishNewsRoutine,
  removeNewsRoutine,
  saveNewsRoutine,
  setShowDraftsRoutine,
  syncNewsWithOriginByIdRoutine,
  updateNewsRoutine
} from 'screens/News/routines';
import * as newsService from '../../services/newsService';
import { history } from 'helpers/history';

function* fetchNews({ payload }: Routine<any>) {
  try {
    const response = yield call(newsService.getNews, payload);

    yield put(fetchNewsRoutine.success(response));
  } catch (error) {
    yield put(fetchNewsRoutine.failure(error.message));
  }
}

function* saveNewsItem({ payload }: Routine<any>) {
  try {
    const response = yield call(newsService.saveNews, payload);
    history.push(`/news/${response.id}`);
    toastr.success('Successfully saved news!');
    yield put(saveNewsRoutine.success(response));
  } catch (error) {
    toastr.error('Failed to save news!', error.message);
    yield put(saveNewsRoutine.failure(error.message));
  }
}

function* updateNewsItem({ payload }: Routine<any>) {
  try {
    const response = yield call(newsService.updateNews, payload);

    toastr.success('Successfully updated news!');
    yield put(updateNewsRoutine.success(response));
  } catch (error) {
    toastr.error('Failed to update news!', error.message);
    yield put(updateNewsRoutine.failure(error.message));
  }
}

function* deleteNewsRequest({ payload }: Routine<any>) {
  try {
    yield call(newsService.deleteNewsById, payload);
    toastr.success('Successfully removed news!');
    yield put(removeNewsRoutine.success(payload));
  } catch (error) {
    toastr.error('Failed to remove news!', error.message);
    yield put(removeNewsRoutine.failure(error.message));
  }
}

function* trySyncNewsWithOrigin({ payload }: Routine<any>) {
  try {
    const resp = yield call(newsService.syncNewsWithOrigin, payload);
    yield put(syncNewsWithOriginByIdRoutine.success(resp));
    toastr.success('Synchronized successfully!');
  } catch (error) {
    toastr.error('Failed to sync!', error.message);
    yield put(syncNewsWithOriginByIdRoutine.failure({ id: payload, message: error.message }));
  }
}

function* setShowDrafts({ payload }: Routine<any>) {
  yield put(setShowDraftsRoutine.success(payload));
}

function* publishNews({ payload }: Routine<any>) {
  try {
    const response = yield call(newsService.publishNews, payload);

    yield put(publishNewsRoutine.success(response));
  } catch (error) {
    yield put(publishNewsRoutine.failure(error.message));
  }
}

export default function* newsSaga() {
  yield all([
    yield takeLatest(setShowDraftsRoutine.TRIGGER, setShowDrafts),
    yield takeEvery(publishNewsRoutine.TRIGGER, publishNews),
    yield takeEvery(saveNewsRoutine.TRIGGER, saveNewsItem),
    yield takeLatest(updateNewsRoutine.TRIGGER, updateNewsItem),
    yield takeLatest(fetchNewsRoutine.TRIGGER, fetchNews),
    yield takeEvery(removeNewsRoutine.TRIGGER, deleteNewsRequest),
    yield takeEvery(syncNewsWithOriginByIdRoutine.TRIGGER, trySyncNewsWithOrigin)
  ]);
}

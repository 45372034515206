import { Routine } from 'redux-saga-routines';
import {
  fetchNewsByIdRoutine,
  removeNewsFromModalRoutine,
  syncNewsForUpdateWithOriginByIdRoutine
} from 'screens/News/routines';
import { INewsItemSave } from 'screens/News/models/news/newsItemSave';

export const newsForUpdate = (state: INewsItemSave = null, action: Routine<any>): INewsItemSave => {
  switch (action.type) {
    case fetchNewsByIdRoutine.SUCCESS:
      return action.payload;
    case removeNewsFromModalRoutine.TRIGGER:
      return null;
    case syncNewsForUpdateWithOriginByIdRoutine.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

import React from 'react';
import styles from './styles.module.scss';
import { Image } from 'semantic-ui-react';
import { cross } from 'assets/icons';
import { IBindingAction } from 'models/callback';

interface IFormWrapperProps {
  children: any;
  onClose: IBindingAction;
  headerText: string;
}

const FormWrapper: React.FunctionComponent<IFormWrapperProps> = ({
  children,
  onClose,
  headerText
}: IFormWrapperProps) => (
  <div className={styles.formWrapper}>
    <Image src={cross} className={styles.closeButton} onClick={onClose} />
    <p className={styles.header}>
      {headerText}
    </p>
    {children}
  </div>
);

export default FormWrapper;

import { callNotificationsApi } from 'helpers/webApiHelper';
import { INewsItemShort } from '../models/news/newsItemShort';
import { INewsItemDetails } from '../models/news/newsItemDetails';
import { INewsItemSave } from '../models/news/newsItemSave';
import { INewsBounds } from '../models/news/newsBounds';

export const getNews = async (payload: INewsBounds): Promise<INewsItemShort[]> => {
  const response = await callNotificationsApi({
    endpoint: '/api/news',
    type: 'GET',
    queryParams: payload
  });

  return response.json();
};

export const publishNews = async (id: string): Promise<INewsItemShort> => {
  const response = await callNotificationsApi({
    endpoint: `/api/news/${id}/publish`,
    type: 'PUT'
  });

  return response.json();
};

export const getFullNewsById = async (id: string): Promise<INewsItemDetails> => {
  const response = await callNotificationsApi({
    endpoint: `/api/news/${id}/details`,
    type: 'GET'
  });

  return response.json();
};

export const getNewsForUpdateById = async (id: string): Promise<INewsItemSave> => {
  const response = await callNotificationsApi({
    endpoint: `/api/news/${id}`,
    type: 'GET'
  });

  return response.json();
};

export const deleteNewsById = async (id: string) => {
  const response = await callNotificationsApi({
    endpoint: `/api/news/${id}`,
    type: 'DELETE'
  });

  return response.status;
};

export const saveNews = async (payload: INewsItemSave) => {
  const response = await callNotificationsApi({
    endpoint: '/api/news/',
    type: 'POST',
    requestData: { ...payload }
  });

  return response.json();
};

export const updateNews = async (payload: INewsItemSave) => {
  const response = await callNotificationsApi({
    endpoint: '/api/news/',
    type: 'PUT',
    requestData: { ...payload }
  });

  return response.json();
};

export const syncNewsWithOrigin = async (id: string): Promise<INewsItemShort> => {
  const resp = await callNotificationsApi({
    endpoint: `/api/news/${id}/sync`,
    type: 'PUT'
  });

  return resp.json();
};

export const syncNewsForUpdateWithOrigin = async (id: string): Promise<INewsItemSave> => {
  const resp = await callNotificationsApi({
    endpoint: `/api/news/${id}/sync_for_update`,
    type: 'PUT'
  });

  return resp.json();
};

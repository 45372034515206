import { Routine } from 'redux-saga-routines';
import {
  requestDaysOffRoutine,
  fetchAllByUserIdRequestsRoutine,
  uploadProofImageRoutine
} from 'screens/TimeOffTracker/routines';
import { clearUserProfileStateRoutine } from 'screens/UserProfile/routines';

export const daysOffRequests = (state = [], action: Routine<any>) => {
  switch (action.type) {
    case fetchAllByUserIdRequestsRoutine.SUCCESS:
      return action.payload;
    case requestDaysOffRoutine.SUCCESS:
      return [
        action.payload,
        ...state
      ];
    case uploadProofImageRoutine.SUCCESS:
      return state.map(request => (request.id === action.payload.id ? action.payload : request));
    case clearUserProfileStateRoutine.TRIGGER:
      return [];
    default:
      return state;
  }
};


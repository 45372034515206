import React from 'react';
import styles from './styles.module.scss';
import { Confirm } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';

interface IConfirmModalContentProps {
  header: string;
  onConfirm: IBindingAction | IBindingCallback1<React.MouseEvent>;
  onCancel: IBindingAction | IBindingCallback1<React.MouseEvent>;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  opened: boolean;
}

const ConfirmModalContent: React.FunctionComponent<IConfirmModalContentProps> = ({
  opened,
  header,
  description,
  onConfirm,
  onCancel,
  confirmButtonText = 'Yes, delete it!',
  cancelButtonText = 'Cancel'
}: IConfirmModalContentProps) => {
  const onConfirmClicked = e => {
    e.stopPropagation();
    onConfirm(e);
  };

  return (
    <Confirm
      header={header}
      content={description}
      open={opened}
      onCancel={onCancel}
      onConfirm={onConfirmClicked}
      confirmButton={confirmButtonText}
      cancelButton={cancelButtonText}
      className={styles.confirmModal}
      centered
    />
  );
};

export default ConfirmModalContent;

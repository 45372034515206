import { Modal, Button } from 'semantic-ui-react';
import React from 'react';
import Cropper from 'react-easy-crop';
import { DragAndDrop } from 'screens/UserProfile/dragAndDrop';
import { boundMethod } from 'autobind-decorator';
import { ModalHeaderWithCross } from 'components/ModalHeaderWithCross';
import { IBindingCallback3, IBindingAction } from 'models/callback';
import { ICropField } from 'screens/UserProfile/models/ICropField';
import styles from './styles.module.scss';

interface IUpdateLogoModalContentState {
  image: string;
  crop: {
    x: number;
    y: number;
  };
  zoom: number;
  aspect: number;
  isFileSelected: boolean;
  croppedArea: ICropField;
  fileName: string;
}

interface IUpdateLogoModalContentProps {
  onUpdate: IBindingCallback3<string, string, ICropField>;
  onClose: IBindingAction;
}

export class UpdateLogoModalContent extends
  React.Component<IUpdateLogoModalContentProps,
  IUpdateLogoModalContentState> {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      isFileSelected: false,
      croppedArea: null,
      fileName: null
    };
  }

  componentWillUnmount() {
    this.setState(null);
  }

  onCropChange = crop => this.setState({ crop })

  onZoomChange = zoom => this.setState({ zoom })

  onCropComplete = (area, croppedAreaPixels) => this.setState({ croppedArea: croppedAreaPixels });

  @boundMethod
  onFilesSelection(files: File[]) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (event: any) => this.setState({
      image: event.target.result,
      fileName: file.name,
      isFileSelected: true
    });
    reader.readAsDataURL(file);
  }

  render() {
    const { isFileSelected, image, fileName, croppedArea, crop, zoom, aspect } = this.state;
    const { onUpdate, onClose } = this.props;

    return (
      <>
        <ModalHeaderWithCross headerName="Select profile photo" onClose={onClose} />
        <Modal.Content>
          <Modal.Description>
            <div className={styles.cropper}>
              {isFileSelected
                ? (
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                    zoomSpeed={0.5}
                  />
                ) : (
                  <DragAndDrop onDrop={this.onFilesSelection} images />
                )}
            </div>
            <Button
              primary
              disabled={!isFileSelected}
              onClick={() => onUpdate(image, fileName, croppedArea)}
              content="Set as profile photo"
              className={styles.firstButton}
            />
            <Button disabled={!isFileSelected} content="Cancel" onClick={onClose} />
          </Modal.Description>
        </Modal.Content>
      </>
    );
  }
}

import { callOkrApi } from 'helpers/webApiHelper';
import { IFetchFilter } from '../models/IFetchFilter';

export const getAll = async (filter: IFetchFilter) => {
  const response = await callOkrApi({
    endpoint: '/api/users',
    queryParams: filter,
    type: 'GET'
  });
  return response.json();
};

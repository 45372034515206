import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { IBindingCallback1 } from '../../../../models/callback';
import styles from './styles.module.scss';

interface IGenerateLink {
  generateRequest: IBindingCallback1<any>;
  status: string;
  creatorId: string;
  formId: string;
  ownerId: string;
  feedbackId: string;
}

const GenerateLink = ({ creatorId, formId,
  generateRequest, status, ownerId, feedbackId }: IGenerateLink) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState(undefined);
  useEffect(() => {
    generateRequest(null);
    setName('');
    setType(null);
  }, [open]);
  const onGenerate = () => {
    generateRequest({ name, type, formId, ownerId, creatorId, status });
    setName('');
    setType(null);
  };

  const typesOptions = [
    {
      key: 1,
      text: 'Teammate',
      value: 'TEAMMATE'
    },
    {
      key: 2,
      text: 'Team lead',
      value: 'TEAM_LEAD'
    },
    {
      key: 3,
      text: 'Self',
      value: 'SELF'
    },
    {
      key: 4,
      text: 'Customer',
      value: 'CUSTOMER'
    }
  ];

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={<Button className={styles.formButton} basic primary>Generate Link</Button>}
    >
      <Modal.Header>Generate Link</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group className={styles.formGroup}>
            <Form.Input
              label="Name"
              required
              placeholder="Enter name"
              width={9}
              value={name}
              onChange={(e, o) => setName(o.value)}
            />
            <Form.Dropdown
              placeholder="Select type"
              required
              label="Type"
              fluid
              width={9}
              selection
              options={typesOptions}
              value={type}
              onChange={(e, o) => setType(o.value)}
            />
            <Form.Button
              content="Generate"
              primary
              disabled={!(name && type)}
              onClick={() => onGenerate()}
            />
          </Form.Group>
          {feedbackId
            && (
              <Form.Input
                label="Link"
                name="link"
                value={`${window.location.href.split('/feedback/')[0]}/form/${feedbackId}`}
                className="fullWidthData"
                readOnly
              />
            )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { feedbackPage: { createAssign: { feedbackId } } } = state;
  return {
    feedbackId
  };
};

export default connect(mapStateToProps)(GenerateLink);

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Form, Button, Dropdown, List, Icon, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { createRequestRoutine, fetchAssignInfoRoutine, generateLinkRoutine } from '../../routines';
import { IBindingAction, IBindingCallback1 } from '../../../../models/callback';
import { fetchAdminUserInfoRoutine } from '../../../AdminPage/routines';
import { IAdminUserInfo } from '../../../AdminPage/model/IAdminUserInfo';
import styles from './styles.module.scss';
import { IRequestAssign } from 'screens/Feedback/model/Requests/IRequestAssign';
import Avatar from '../../../../components/UserAvatar';
import GenerateLink from '../GenerateLink';
import PopupForLink from '../PopupForLink';

interface IRequestAssignpProps {
  fetchAdminUserInfo?: IBindingAction;
  createRequest: IBindingCallback1<IRequestAssign>;
  users: IAdminUserInfo[];
  fetchRequests?: IBindingAction;
  getAssignInfo: IBindingCallback1<string>;
  assign: IRequestAssign;
  createdRequest: boolean;
  creatorId: string;
  formId: string;
  generateRequest: IBindingCallback1<any>;
}

const RequestAssign = ({ fetchAdminUserInfo, users, creatorId, formId, createdRequest, assign,
  getAssignInfo, createRequest, generateRequest }: IRequestAssignpProps) => {
  const [ownerId, setOwnerId] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [open, setIsOpen] = useState(false);

  const [assigned, setAssigned] = useState([]);
  const [assignedOptions, setOptions] = useState(users?.map(user => ({
    key: user.id,
    text: user.fullName,
    value: user.id
  })) ?? []);

  useEffect(() => {
    fetchAdminUserInfo();
  }, []);
  useLayoutEffect(() => {
    getAssignInfo(formId);
  }, [formId]);

  useLayoutEffect(() => {
    if (assign?.users) {
      setAssigned(assign?.users.map(u => {
        const link = users?.find(a => a.id === u.id)?.logoLink;
        return {
          userId: u.id,
          name: u.name,
          type: u.type,
          feedbackId: u.feedbackId,
          link
        };
      }
      ));
      setOwnerId(assign?.ownerId);
      setOptions(users?.filter(user => !assign.users.map(u => u.name).includes(user.fullName))
        .map(user => ({ key: user.id, text: user.fullName, value: user.id })) ?? []);
    } else {
      setAssigned([]);
      setOwnerId(null);
      setOptions(users?.map(user => ({
        key: user.id,
        text: user.fullName,
        value: user.id
      })) ?? []);
    }
  }, [assign]);
  useEffect(() => {
    if (assigned?.length !== 0 && creatorId && formId) {
      setIsReady(true);
    }
  }, [assigned]);

  const onAssign = (status: string) => {
    const assignedUsers = assigned.map(a => ({ id: a.userId, type: a.type, name: a.name, feedbackId: null }));
    const request = { ownerId, users: assignedUsers, creatorId, formId, status };
    createRequest(request);
  };
  const ownerOptions = users?.map(user => ({
    key: user.id,
    text: user.fullName,
    value: user.id,
    image: user.logoLink
  }));
  const typesOptions = [
    {
      key: 1,
      text: 'Teammate',
      value: 'TEAMMATE'
    },
    {
      key: 2,
      text: 'Team lead',
      value: 'TEAM_LEAD'
    },
    {
      key: 3,
      text: 'Self',
      value: 'SELF'
    }
  ];

  const addAssinged = userId => {
    const user = users?.find(u => u.id === userId);
    setAssigned([...assigned, { userId, name: user?.fullName, link: user.logoLink, type: 'TEAMMATE' }]);
    const options = assignedOptions.filter(u => u.value !== userId);
    setOptions(options);
  };
  const onDeleteAssigned = userId => {
    const newAssigned = assigned.filter(u => u.userId !== userId);
    setAssigned(newAssigned);
    const name = users?.find(u => u.id === userId)?.fullName;
    setOptions([...assignedOptions, { key: userId, text: name, value: userId }]);
  };

  const changeType = (type, userId) => {
    const newAssigned = assigned.forEach((u, i) => { if (u.userId === userId) assigned[i].type = type; });
  };

  const getType = type => (type === 'CUSTOMER' ? 'Customer' : typesOptions.find(o => o.value === type).text);

  return (
    <div className={styles.assignBlock}>
      <h3 className={styles.headerAssigner}>
        {createdRequest ? 'Request information' : 'Create request'}
      </h3>
      <Form className={styles.formAssign}>
        {!createdRequest
          && (
            <Form.Group>
              <Form.Dropdown
                placeholder="Search ..."
                label="Assigned users"
                width={8}
                search
                selection
                required
                value={null}
                options={assignedOptions}
                className={styles.dropdownAssign}
                onChange={(e, o) => addAssinged(o.value)}
              />
              <Form.Dropdown
                placeholder="Select user"
                label="Owner"
                fluid
                width={8}
                search
                selection
                value={ownerId}
                options={ownerOptions}
                onChange={(e, o) => setOwnerId(o.value)}
              />
            </Form.Group>
          )}
        <Form.Group>
          <Form.Field width={8}>
            {createdRequest && <label>Assigned users:</label>}
            <List
              divided
              relaxed
              verticalAlign="middle"
              className={!createdRequest && styles.listAssigned}
            >
              {assigned?.map(user => (
                <List.Item className={styles.itemAssigned}>
                  <List.Content floated="right">
                    {!createdRequest
                      && (
                        <Icon
                          name="trash alternate"
                          onClick={() => onDeleteAssigned(user.userId)}
                        />
                      )}
                  </List.Content>
                  <List.Content>
                    <div className={styles.ownerItem}>
                      <Avatar name={user.name} logoLink={user.link} />
                      {user.name}
                      {user.id}
                      <Label color="blue" className={styles.labelType}>
                        {createdRequest
                          ? getType(user.type)
                          : (
                            <Dropdown
                              inline
                              options={typesOptions}
                              disabled={createdRequest}
                              defaultValue={user.type}
                              onChange={(e, o) => changeType(o.value, user.userId)}
                            />
                          )}
                      </Label>
                      {(user?.feedbackId && user?.userId === null)
                        && <PopupForLink feedbackId={user?.feedbackId} />}
                    </div>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Form.Field>
          {assign?.ownerId && createdRequest
            && (
              <Form.Field width={7}>
                <label>Owner:</label>
                <List relaxed verticalAlign="middle">
                  {users?.filter(u => u.id === assign.ownerId).map(user => (
                    <List.Item className={styles.itemAssigned}>
                      <List.Content>
                        <div className={styles.ownerItem}>
                          <Avatar name={user.fullName} logoLink={user.logoLink} />
                          {user.fullName}
                        </div>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Form.Field>
            )}
        </Form.Group>
        <div className={styles.btnDiv}>
          <div className={styles.btnGroup}>
            <GenerateLink
              ownerId={ownerId}
              creatorId={creatorId}
              formId={formId}
              generateRequest={generateRequest}
              status={assign?.status ?? 'OPENED'}
            />
            {!createdRequest
              && (
                <Button
                  basic
                  color="orange"
                  disabled={!isReady}
                  onClick={() => onAssign('OPENED')}
                  className={styles.formButton}
                >
                  Save
                </Button>
              )}
          </div>
          {!createdRequest
            && (
              <Button
                primary
                disabled={!isReady}
                onClick={() => onAssign('PENDING')}
              >
                Send request
              </Button>
            )}
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = state => {
  const { adminPage: { userList } } = state;
  const { feedbackPage: { importCsv: { uploading } } } = state;
  const { user: { user: { id } } } = state;
  const { feedbackPage: { forms: { selectedForm } } } = state;
  const { feedbackPage: { createAssign: { createdRequest, assign } } } = state;
  return {
    users: userList?.users,
    importing: uploading,
    creatorId: id,
    formId: selectedForm.id,
    createdRequest,
    assign
  };
};

const mapDispatchToProps = {
  createRequest: createRequestRoutine,
  fetchAdminUserInfo: fetchAdminUserInfoRoutine,
  getAssignInfo: fetchAssignInfoRoutine,
  generateRequest: generateLinkRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestAssign);

import { combineReducers } from 'redux';
import { fetchUserRoutine } from 'screens/Authorization/routines/index';
import { fetchUserInfoRoutine } from 'screens/UserProfile/routines/index';
import { reducerCreator } from 'helpers/reducerHelper';
import { reducer as toastrReducer } from 'react-redux-toastr';
import news from '../screens/News/reducers';
import okr from '../screens/OKR/reducers';
import TimeOffTracker from '../screens/TimeOffTracker/reducers';
import adminPage from '../screens/AdminPage/reducers';
import projects from '../screens/Projects/reducers';
import notifications from '../screens/Notifications/reducers';
import dashboard from '../screens/Dashboard/reducers';
import usersOkrs from '../screens/UsersOkrs/reducers';
import auth from '../screens/Authorization/reducers';
import userProfileReducer from '../screens/UserProfile/reducers';
import { user } from '../containers/Routing/reducer';
import socketNotifications from '../containers/Notifications/reducer';
import { teamUsersPage } from 'screens/Team/containers/TeamView/reducer';
import announcements from 'screens/Announcements/reducers';
import feedbackPage from 'screens/Feedback/reducers';

const requests = combineReducers({
  user: reducerCreator([fetchUserRoutine.TRIGGER]),
  userInfo: reducerCreator([fetchUserInfoRoutine.TRIGGER])
});

export default combineReducers({
  okr,
  user,
  news,
  userProfile: userProfileReducer,
  requests,
  adminPage,
  TimeOffTracker,
  notifications,
  projects,
  dashboard,
  usersOkrs,
  socketNotifications,
  auth,
  announcements,
  toastr: toastrReducer,
  teamUsersPage,
  feedbackPage
});

import { put, takeEvery, all, call } from 'redux-saga/effects';
import {
  fetchNotificationsRoutine,
  fetchMoreNotificationsRoutine,
  readNotificationsRoutine,
  updateNotificationsRoutine
} from 'screens/Notifications/routines';
import { Routine } from 'redux-saga-routines';
import * as notificationsService from '../../services/notificationsService';

function* getLastNotifications() {
  try {
    const response = yield call(notificationsService.getLastNotifications);

    yield put(fetchNotificationsRoutine.success(response));
  } catch (error) {
    yield put(fetchNotificationsRoutine.failure(error.message));
  }
}

function* getAllNotifications() {
  try {
    const response = yield call(notificationsService.getAllNotifications);

    yield put(fetchMoreNotificationsRoutine.success(response));
  } catch (error) {
    yield put(fetchMoreNotificationsRoutine.failure(error.message));
  }
}

function* markNotifications({ payload }: Routine<any>) {
  try {
    const response = yield call(notificationsService.markNotificationsAsRead, payload);
    yield put(readNotificationsRoutine.success(response));
    yield put(updateNotificationsRoutine.success(response.length));
  } catch (error) {
    yield put(readNotificationsRoutine.failure(error.message));
  }
}

function* watchGetLastNotifications() {
  yield takeEvery(fetchNotificationsRoutine.TRIGGER, getLastNotifications);
}

function* watchGetAllNotifications() {
  yield takeEvery(fetchMoreNotificationsRoutine.TRIGGER, getAllNotifications);
}

function* watchMarkNotifications() {
  yield takeEvery(readNotificationsRoutine.TRIGGER, markNotifications);
}

export default function* notificationsSaga() {
  yield all([
    watchMarkNotifications(),
    watchGetAllNotifications(),
    watchGetLastNotifications()
  ]);
}

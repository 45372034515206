import React, { SyntheticEvent } from 'react';
import debounce from 'debounce';
import {
  Button,
  Input,
  InputOnChangeData,
  Modal,
  Header,
  Radio,
  Icon
} from 'semantic-ui-react';
import Select from 'react-select';
import { boundMethod } from 'autobind-decorator';
import { ActionMeta } from 'react-select/src/types';
import { IKeyResult, IKeyResultFilter } from 'screens/OKR/models/key-result';
import { IBindingCallback1 } from 'models/callback';
import addIfNotExisted from 'helpers/array.helper';
import KeyResultTable from '../../components/KeyResultTable';
import { KeyResultType } from '../../enums/KeyResultType';
import { Complexity } from '../../enums/Complexity';
import { ITag, ITagFilter } from '../../models/tag';
import { getTags } from '../../services/tagService';
import { getKeyResultsByUserId } from '../../services/keyResultService';
import styles from './styles.module.scss';
import KeyResultSaveForm from 'screens/OKR/components/KeyResultSaveForm';

interface IKeyResultsListProps {
  selectedResults?: string[];
  onAddKeyResults: IBindingCallback1<string[]>;
  onKeyResultCreate: IBindingCallback1<IKeyResult>;
  toggleSidebar: IBindingCallback1<string>;
  loading: boolean;
  headerText?: string;
}

interface IKeyResultsListState {
  selectedKeyResults: IKeyResult[];
  keyResults: IKeyResult[];
  hasMoreKeyResults: boolean;
  tags: ITag[];
  loadingTags: boolean;
  wasFirstTimeLoaded: boolean;
  selectMode: boolean;
  activeItem: string;
  isFilterActive: boolean;
  responsiveFilterRender: boolean;
  isNarrowScreen: boolean;
}

class KeyResultsList extends React.Component<IKeyResultsListProps, IKeyResultsListState> {
  defaultKeyResultCount = 15;

  defaultTagCount = 10;

  keyResultFilter: IKeyResultFilter = {
    from: 0,
    count: this.defaultKeyResultCount,
    types: [],
    complexities: [],
    tags: [],
    name: ''
  };

  tagFilter: ITagFilter = {
    from: 0,
    count: this.defaultTagCount,
    name: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      selectMode: true,
      selectedKeyResults: [],
      keyResults: [],
      hasMoreKeyResults: true,
      tags: [],
      loadingTags: true,
      activeItem: 'all',
      wasFirstTimeLoaded: false,
      isFilterActive: true,
      responsiveFilterRender: false,
      isNarrowScreen: false
    };
  }

  componentDidMount() {
    this.loadKeyResults();
    this.loadTags();
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  @boundMethod
  async handleSubmit(data: IKeyResult) {
    const { onKeyResultCreate } = this.props;
    onKeyResultCreate(data);
  }

  @boundMethod
  onChangeTags(data, actionMeta: ActionMeta<any>) {
    this.keyResultFilter.tags = this.toggleChange(actionMeta, this.keyResultFilter.tags);
    this.loadKeyResults();
  }

  @boundMethod
  onChangeKeyResultName(event: SyntheticEvent, data: InputOnChangeData) {
    this.keyResultFilter.name = data.value.trim();
    this.loadKeyResults();
  }

  @boundMethod
  onChangeTagName(value: string) {
    this.tagFilter.name = value;
    this.loadTags();
  }

  @boundMethod
  onChangeType(data, actionMeta: ActionMeta<any>) {
    this.keyResultFilter.types = this.toggleChange(actionMeta, this.keyResultFilter.types);
    this.loadKeyResults();
  }

  @boundMethod
  onSelectKeyResult(keyResult: IKeyResult) {
    const { selectedKeyResults } = this.state;
    const newSelectedKeyResults = !selectedKeyResults.some(kr => kr.id === keyResult.id)
      ? [...selectedKeyResults, keyResult]
      : selectedKeyResults.filter(kr => kr.id !== keyResult.id);

    this.setState({ selectedKeyResults: newSelectedKeyResults });
  }

  @boundMethod
  onChangeComplexity(data, actionMeta: ActionMeta<any>) {
    this.keyResultFilter.complexities = this.toggleChange(actionMeta, this.keyResultFilter.complexities);
    this.loadKeyResults();
  }

  @boundMethod
  onFilterOpen() {
    const { isFilterActive } = this.state;
    this.setState({
      isFilterActive: !isFilterActive
    });
  }

  setActiveItem = activeItem => this.setState({ activeItem });

  setDefaultSelectedOption = list => list.map(item => ({
    label: item,
    value: item
  }))

  resize() {
    const isNarrowScreen = window.innerWidth <= 768;
    this.setState({
      responsiveFilterRender: isNarrowScreen,
      isNarrowScreen
    });
  }

  toggleChange<T>(actionMeta: any, array: T[]): T[] {
    const { action } = actionMeta;

    switch (action) {
      case 'select-option':
        return [...array, actionMeta.option.value];
      case 'remove-value':
        return array.filter(type => type !== actionMeta.removedValue.value);
      case 'clear':
        return [];
      default:
        return array;
    }
  }

  @boundMethod
  toggleMode() {
    const { selectMode } = this.state;
    this.setState({ selectMode: !selectMode, selectedKeyResults: [] });
  }

  @boundMethod
  async loadKeyResults(fetchMore?: boolean) {
    const { keyResults } = this.state;
    if (!fetchMore) {
      this.keyResultFilter.from = 0;
    }

    const currentKeyResults = await getKeyResultsByUserId(this.keyResultFilter);

    this.setState({
      keyResults: fetchMore ? addIfNotExisted<IKeyResult>(keyResults, currentKeyResults) : currentKeyResults,
      hasMoreKeyResults: Boolean(currentKeyResults.length)
        || Boolean(currentKeyResults.length % this.defaultKeyResultCount),
      wasFirstTimeLoaded: true
    });
    this.keyResultFilter.from += this.keyResultFilter.count;
  }

  @boundMethod
  loadTags(fetchMore?: boolean) {
    if (!fetchMore) {
      this.tagFilter.from = 0;
    }

    this.setState({ loadingTags: true }, async () => {
      const tags = await getTags(this.tagFilter);
      const { tags: stateTags } = this.state;
      this.setState({
        tags: fetchMore ? addIfNotExisted<ITag>(stateTags, tags) : tags,
        loadingTags: false
      });

      this.tagFilter.from += this.tagFilter.count;
    });
  }

  @boundMethod
  addKeyResults() {
    const { onAddKeyResults } = this.props;
    const { selectedKeyResults } = this.state;

    onAddKeyResults(selectedKeyResults.map(kr => kr.id));
  }

  renderInput = () => {
    const { name } = this.keyResultFilter;
    return (
      <div className={styles.searchBar}>
        <Input
          placeholder="Search By Name"
          icon="search"
          onChange={debounce(this.onChangeKeyResultName, 500)}
          defaultValue={name}
        />
        <div className={styles.filterBtnContainer}>
          <Button onClick={this.onFilterOpen} className={styles.filterBtn}>
            <Icon name="filter" />
          </Button>
        </div>
      </div>
    );
  }

  renderFilters = () => {
    const { tags: tagsOpt, loadingTags, isFilterActive } = this.state;
    const { complexities, types, tags } = this.keyResultFilter;

    const complexityOptions = Object.values(Complexity).map(compl => ({ label: compl, value: compl }));
    const typeOptions = Object.values(KeyResultType).map(type => ({ label: type, value: type }));
    const tagOptions = tagsOpt.map(({ name }) => ({ label: name, value: name }));

    return (
      <div className={!isFilterActive ? styles.filters : styles.filters_hidden}>
        <Header content="Filters" className={styles.filtersHeader} />
        <div>
          <span>Complexity</span>
          <Select
            placeholder="Complexity"
            options={complexityOptions}
            isSearchable
            isMulti
            closeMenuOnSelect={false}
            onChange={this.onChangeComplexity}
            className={styles.select}
            defaultValue={this.setDefaultSelectedOption(complexities)}
          />
        </div>
        <div>
          <span>Type</span>
          <Select
            placeholder="Type"
            options={typeOptions}
            isSearchable
            isMulti
            closeMenuOnSelect={false}
            onChange={this.onChangeType}
            className={styles.select}
            defaultValue={this.setDefaultSelectedOption(types)}
          />
        </div>
        <div>
          <span>Tags</span>
          <Select
            placeholder="Tag"
            options={tagOptions}
            isSearchable
            isMulti
            isLoading={loadingTags}
            closeMenuOnSelect={false}
            onInputChange={this.onChangeTagName}
            onChange={this.onChangeTags}
            onMenuScrollToBottom={() => this.loadTags(true)}
            className={styles.select}
            defaultValue={this.setDefaultSelectedOption(tags)}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      selectedKeyResults,
      keyResults,
      hasMoreKeyResults,
      selectMode,
      activeItem,
      wasFirstTimeLoaded,
      responsiveFilterRender,
      isNarrowScreen
    } = this.state;
    const { selectedResults, toggleSidebar, loading, headerText } = this.props;

    return (
      <>
        <Modal.Header className={styles.headerWrapper}>
          <p className={styles.header}>
            {selectMode
              ? `${headerText ?? 'Add'} from existing key results`
              : `${headerText ?? 'Create'} new key result` }
          </p>
          <Button
            className={styles.openCreateModal}
            onClick={this.toggleMode}
            content={selectMode ? 'Create new' : 'Select from existing'}
          />
        </Modal.Header>
        <Modal.Content className={styles.modalContent}>
          <div className={styles.contentContainer}>
            {selectMode ? (
              <>
                <div className={styles.krTableContainer}>
                  {responsiveFilterRender ? null : this.renderFilters()}
                  <div className={styles.table}>
                    <div className={styles.menuData}>
                      <div className={styles.menu}>
                        <Radio
                          label="All"
                          name="keyResultsMenu"
                          checked={activeItem === 'all'}
                          onChange={() => this.setActiveItem('all')}
                        />
                        <Radio
                          label={`Selected (${selectedKeyResults.length})`}
                          name="keyResultsMenu"
                          disabled={!selectedKeyResults.length}
                          checked={activeItem === 'selected'}
                          onChange={() => this.setActiveItem('selected')}
                        />
                      </div>
                      {responsiveFilterRender ? this.renderFilters() : null}
                      {this.renderInput()}
                    </div>
                    {activeItem === 'all' && (
                      <div className={styles.krAllTableContainer}>
                        <KeyResultTable
                          toggleSidebar={toggleSidebar}
                          keyResults={keyResults}
                          alreadySelectedResults={selectedResults}
                          selectedKeyResults={selectedKeyResults}
                          onSelect={this.onSelectKeyResult}
                          loadMore={() => this.loadKeyResults(true)}
                          hasMore={hasMoreKeyResults}
                          loading={!wasFirstTimeLoaded}
                          isNarrowScreen={isNarrowScreen}
                        />
                      </div>
                    )}
                    {activeItem === 'selected' && (
                      <div className={styles.krSelectedTableContainer}>
                        <KeyResultTable
                          toggleSidebar={toggleSidebar}
                          keyResults={selectedKeyResults}
                          selectedKeyResults={selectedKeyResults}
                          onSelect={this.onSelectKeyResult}
                          loading={false}
                          isNarrowScreen={isNarrowScreen}
                        />
                      </div>
                    )}
                    <div className={styles.buttonContainer}>
                      <Button
                        primary
                        disabled={loading || !selectedKeyResults.length}
                        loading={loading}
                        onClick={this.addKeyResults}
                        className={styles.addSelectedButton}
                      >
                        {`Select (${selectedKeyResults.length})`}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.inputWrap}>
                <KeyResultSaveForm
                  onSave={this.handleSubmit}
                />
              </div>
            )}
          </div>
        </Modal.Content>
      </>
    );
  }
}

export default KeyResultsList;

import { Routine } from 'redux-saga-routines';
import {
  adFetchOkrStatsRoutine,
  adFetchTopKeyResultsRoutine,
  adFetchTopObjectivesRoutine,
  adFetchTopMarkedOkrRoutine,
  adminDashboardClearPageRoutine
} from '../routines';
import { ITopMarkedOkr } from 'screens/OKR/models/okr/ITopMarkedOkr';
import { IObjectiveTop } from 'screens/OKR/models/objective/IObjectiveTop';
import { IKeyResultBase } from 'screens/OKR/models/key-result/IKeyResultBase';

export interface IAdminDashboardState {
  avgMark: number;
  activeOkrsCount: number;
  keyResultsThisYearCount: number;
  peopleJoinedThisYearCount: number;
  topMarkedOkr: ITopMarkedOkr;
  topKeyResults: IKeyResultBase[];
  topObjectives: IObjectiveTop[];
}

const initialState: IAdminDashboardState = {
  activeOkrsCount: null,
  avgMark: null,
  topKeyResults: [],
  keyResultsThisYearCount: null,
  topObjectives: [],
  peopleJoinedThisYearCount: null,
  topMarkedOkr: null
};

export const adminDashboard = (state = initialState, action: Routine<any>): IAdminDashboardState => {
  const { type, payload } = action;

  switch (type) {
    case adFetchOkrStatsRoutine.SUCCESS:
      return { ...state, ...payload };
    case adFetchTopKeyResultsRoutine.SUCCESS:
      return { ...state, topKeyResults: payload };
    case adFetchTopObjectivesRoutine.FAILURE:
      return { ...state, topObjectives: payload };
    case adFetchTopMarkedOkrRoutine.SUCCESS:
      return { ...state, topMarkedOkr: payload };
    case adminDashboardClearPageRoutine.TRIGGER:
      return initialState;
    default:
      return state;
  }
};

import React, { SyntheticEvent } from 'react';
import { Button, Input, InputOnChangeData } from 'semantic-ui-react';
import { boundMethod } from 'autobind-decorator';
import { IBindingCallback1, IBindingFunction } from 'models/callback';
import debounce from 'debounce';
import styles from './styles.module.scss';
import ObjectiveTable from '../../components/ObjectiveTable';
import { ISelectOption } from '../../../../models/autocomplete/ISelectOption';
import CustomObjectiveInput from '../../components/CustomObjectiveInput';
import { IObjectiveFilter } from '../../models/objective/IObjectiveFilter';

interface IObjectivesListProps {
  onClose?: Function;
  selectedObjectives?: string[];
  addSelectedObjectives: IBindingCallback1<string[]>;
  onObjectivesLoad: IBindingFunction<IObjectiveFilter, Promise<ISelectOption[]>>;
  createObjective: IBindingFunction<any, void>;
  loading: boolean;
}

interface IObjectivesListState {
  selectMode: boolean;
  selectedObjectives: string[];
  objectives: ISelectOption[];
  hasMore: boolean;
  filter: IObjectiveFilter;
  wasFirstTimeLoaded: boolean;
}

class ObjectivesList extends React.Component<IObjectivesListProps, IObjectivesListState> {
  getObjectivesDeb: IBindingCallback1<IObjectiveFilter> & { clear(): void };

  defaultCount = 15;

  constructor(props) {
    super(props);
    this.state = {
      selectMode: true,
      selectedObjectives: [],
      objectives: [],
      hasMore: false,
      wasFirstTimeLoaded: false,
      filter: {
        from: 0,
        count: this.defaultCount,
        name: ''
      }
    };

    this.getObjectivesDeb = debounce(this.getObjectives, 500);
  }

  componentDidMount() {
    const { filter } = this.state;
    this.getObjectives({ ...filter });
  }

  componentWillUnmount() {
    this.getObjectivesDeb.clear();
  }

  @boundMethod
  onSelectObjective(objectiveId: string) {
    const { selectedObjectives } = this.state;
    const newSelectedObjectives = selectedObjectives.some(objId => objId === objectiveId)
      ? selectedObjectives.filter(objId => objId !== objectiveId)
      : [...selectedObjectives, objectiveId];

    this.setState({ selectedObjectives: newSelectedObjectives });
  }

  @boundMethod
  async onChangeObjectiveName(event: SyntheticEvent, data: InputOnChangeData) {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, name: data.value, from: 0 } });
    this.getObjectivesDeb({ name: data.value.trim(), from: 0, count: 15 });
  }

  @boundMethod
  async getObjectives({ name, from, count }: IObjectiveFilter, loadMore?: boolean) {
    const { onObjectivesLoad } = this.props;
    let objectives: ISelectOption[];
    const loaded = await onObjectivesLoad({ name, from, count });

    const { filter, objectives: stateObj } = this.state;
    if (!loadMore) {
      this.setState({ filter: { ...filter, from: 0 } });
      objectives = loaded;
    } else {
      objectives = [
        ...stateObj,
        ...loaded
      ];
    }
    this.setState({
      wasFirstTimeLoaded: true,
      objectives,
      hasMore: Boolean(loaded.length) && !(loaded.length % this.defaultCount),
      filter: {
        ...filter,
        from: filter.from + count
      }
    });

    return objectives;
  }

  @boundMethod
  toggleMode() {
    const { selectMode } = this.state;
    this.setState({ selectMode: !selectMode, selectedObjectives: [] });
  }

  render() {
    const {
      selectMode,
      selectedObjectives: newSelectedObjectives,
      objectives,
      filter,
      hasMore,
      wasFirstTimeLoaded
    } = this.state;
    const { addSelectedObjectives, selectedObjectives, createObjective, loading, onClose } = this.props;
    return (
      <div className={styles.contentContainer}>
        <div className={styles.headerWrapper}>
          <p className={styles.header}>Add objectives</p>
          <Button className={styles.openCreateModal} primary onClick={this.toggleMode}>
            {selectMode ? 'Create new' : 'Select from existing'}
          </Button>
        </div>
        {
          selectMode ? (
            <>
              <div className={styles.filterBar}>
                <Input
                  placeholder="Search By Name"
                  icon="search"
                  iconPosition="left"
                  onChange={this.onChangeObjectiveName}
                  value={filter.name}
                />
              </div>
              <ObjectiveTable
                objectives={objectives}
                alreadySelected={selectedObjectives}
                newSelected={newSelectedObjectives}
                onSelect={this.onSelectObjective}
                hasMore={hasMore}
                filter={filter}
                getObjectives={this.getObjectives}
                isLoaded={wasFirstTimeLoaded}
              />
              <div className={styles.buttonContainer}>
                <Button
                  primary
                  loading={loading}
                  disabled={loading || !newSelectedObjectives.length}
                  className={styles.addSelectedButton}
                  onClick={() => addSelectedObjectives(newSelectedObjectives)}
                  content={`Select (${newSelectedObjectives.length})`}
                />
                {onClose ? (
                  <Button
                    primary
                    loading={loading}
                    disabled={loading}
                    className={styles.cancelButton}
                    onClick={() => onClose()}
                    content="Cancel"
                  />
                ) : null}
              </div>
            </>
          ) : (
            <div className={styles.inputWrap}>
              <CustomObjectiveInput
                onSubmit={createObjective}
              />
            </div>
          )
        }
      </div>
    );
  }
}

export default ObjectivesList;

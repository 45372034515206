import React, { useState } from 'react';
import { IBindingCallback1 } from 'models/callback';
import styles from './styles.module.scss';
import { suggestOKRActionRoutine } from 'screens/OKR/routines';
import { connect } from 'react-redux';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { Image } from 'semantic-ui-react';
import KeyResultsListModalContent from '../KeyResultsListModalContent';
import { IKeyResultUpdatingRequest } from 'screens/OKR/models/okr';
import { KeyResultActionType } from 'screens/OKR/enums/KeyResultActionType';
import Sidebar from 'components/DataSidebar';
import { cross } from 'assets/icons';

interface ISuggestAdditionFormProps {
  confirmAdding: IBindingCallback1<IKeyResultUpdatingRequest>;
  toggleSidebar: IBindingCallback1<string>;
  currentKeyResults: IKeyResult[];
  objectiveId: string;
  okrId: string;
}

const SuggestAdditionForm: React.FunctionComponent<ISuggestAdditionFormProps> = ({
  confirmAdding,
  toggleSidebar,
  objectiveId,
  okrId,
  currentKeyResults
}: ISuggestAdditionFormProps) => {
  const [isModalOpened, toggleModal] = useState(false);

  const onAdding = (keyResultIds: string[]) => {
    toggleModal(false);
    confirmAdding({
      keyResultsToAdd: keyResultIds,
      action: KeyResultActionType.AddKeyResult,
      objectiveId,
      okrId
    });
  };

  const onCreating = (keyResult: IKeyResult) => {
    toggleModal(false);
    confirmAdding({
      newKeyResult: keyResult,
      action: KeyResultActionType.AddKeyResult,
      objectiveId,
      okrId
    });
  };

  const sidebar = isModalOpened ? (
    <Sidebar
      onClose={() => toggleModal(false)}
      width="100%"
    >
      <Image src={cross} className={styles.closeButton} onClick={() => toggleModal(false)} />
      <KeyResultsListModalContent
        headerText="Suggest"
        loading={false}
        toggleSidebar={toggleSidebar}
        selectedResults={currentKeyResults.map(kr => kr.id)}
        onAddKeyResults={keyResults => onAdding(keyResults)}
        onKeyResultCreate={keyResult => onCreating(keyResult)}
      />
    </Sidebar>
  ) : '';

  return (
    <>
      {sidebar}
      {/* <Modal
        className={styles.modalWrap}
        open={isModalOpened}
        onClose={() => toggleModal(false)}
      >
        <KeyResultsListModalContent
          headerText="Suggest"
          loading={false}
          toggleSidebar={toggleSidebar}
          selectedResults={currentKeyResults.map(kr => kr.id)}
          onAddKeyResults={keyResults => onAdding(keyResults)}
          onKeyResultCreate={keyResult => onCreating(keyResult)}
        />
      </Modal> */}
      <div className={styles.addButton} onClick={() => toggleModal(true)} role="presentation">
        Suggest key result
      </div>
    </>
  );
};

const mapDispatchToProps = {
  confirmAdding: suggestOKRActionRoutine
};

export default connect(null, mapDispatchToProps)(SuggestAdditionForm);

import { callFeedbackApi } from '../../../helpers/webApiHelper';

export const getDetailedRequest = async id => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/bylink/',
    type: 'GET',
    queryParams: { id }
  });
  return response.json().then(res => res.data);
};

export const sendAnswers = async answer => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/bylink/',
    type: 'POST',
    requestData: answer
  });
  return response;
};

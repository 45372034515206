import moment from 'moment';
import pluralize from 'pluralize';

export const calcDatesDiff = (from: string | number | Date, to = moment()) => {
  const start = moment(from);

  const years = to.diff(start, 'year');
  start.add(years, 'years');

  const months = to.diff(start, 'months');
  start.add(months, 'months');

  const days = to.diff(start, 'days');

  return `${years ? pluralize('year', years, true) : ''}
    ${months ? pluralize('month', months, true) : ''}
    ${pluralize('day', days, true)}`;
};

export const calcDatesDiffDays = (from: string | number | Date, to = moment()) => moment(from).diff(to, 'days');

export const formatDateToFormat = (date: string | number | Date, format = 'MMM d, YYYY') => moment(date).format(format);

import { IBindingAction, IBindingCallback1 } from 'models/callback';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IForm } from 'screens/Feedback/model/Forms/IForm';
import { fetchTemplatesRoutine, selectedFormChangedRoutine } from 'screens/Feedback/routines';
import { Button, Form, Modal } from 'semantic-ui-react';
import ModalCreateTemplate from '../ModalCreateTemplate';
import styles from './styles.module.scss';

interface IFormTemplateProps {
  selectedFormChanged: IBindingCallback1<IForm>;
  getTemplates: IBindingCallback1<string>;
  title: string;
  form?: IForm;
  formSaved: (form: IForm) => void;
  formSavedTemplate?: (form: IForm) => void;
  formCanceled: () => void;
  userId: string;
  templates: IForm[];
}

const FormTemplate: React.FunctionComponent<IFormTemplateProps> = ({
  title,
  form,
  templates,
  formSaved,
  formSavedTemplate,
  formCanceled,
  userId,
  getTemplates
}: IFormTemplateProps) => {
  const [init, setInit] = useState(true);
  const [formName, setFormName] = useState('');
  const [description, setDescription] = useState<string | undefined>();
  const [template, setTemplate] = useState(null);
  useEffect(() => {
    if (title === 'Create new form') {
      getTemplates(userId);
    }
    if (init) {
      setInit(false);
    }
  }, []);

  useLayoutEffect(() => {
    setFormName(form?.name ?? '');
    setDescription(form?.description ?? '');
  }, [form]);

  const saveFormName = e => {
    setFormName(e.target.value);
  };
  const saveDescription = e => {
    setDescription(e.target.value);
  };

  const saveForm = () => {
    const newForm = template ?? form;
    formSaved({
      ...newForm,
      name: formName,
      description,
      isTemplate: false,
      creatorId: userId
    });
  };
  const savedTemplate = (name, isPublic) => {
    formSavedTemplate({
      ...form,
      name,
      description,
      isTemplate: true,
      isPublic,
      creatorId: userId
    });
  };
  const templateOptions = templates?.map(t => ({
    key: t.id,
    text: t.name,
    value: t.id
  }));
  return (
    <div className={styles.formContainer}>
      <div className={styles.formStyling}>
        <h3>{title}</h3>
        <Form>
          <Form.Input
            label="Name"
            placeholder="Form name"
            name="name"
            onChange={e => saveFormName(e)}
            value={formName}
            className="fullWidthData"
            required
          />
          <Form.TextArea
            style={{ minHeight: 100, maxHeight: 100 }}
            label="Description"
            placeholder="Form description"
            name="description"
            className="fullWidthData"
            onChange={e => saveDescription(e)}
            value={description}
          />
          {title === 'Create new form'
            && (
              <Form.Dropdown
                label="Based on template"
                placeholder="Select template"
                className="fullWidthData"
                fluid
                width={8}
                search
                selection
                options={templateOptions}
                onChange={(e, o) => setTemplate(templates.find(t => t.id === o.value))}
              />
            )}
          <Button
            primary
            onClick={() => saveForm()}
            className={styles.formButton}
            disabled={!formName}
          >
            Save
          </Button>
          {title === 'Edit form'
            && (
              <ModalCreateTemplate
                saveTemplate={savedTemplate}
                formName={formName}
              />
            )}
          {(title === 'Create new form' || title === 'Create new template')
            && (
              <Button
                basic
                color="orange"
                onClick={() => formCanceled()}
                className={styles.formButton}
              >
                Cancel
              </Button>
            )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      forms: {
        selectedForm,
        templates
      }
    }
  } = state;
  const { user: { user: { id } } } = state;
  return {
    form: selectedForm,
    userId: id,
    templates
  };
};

const mapDispatchToProps = {
  selectedFormChanged: selectedFormChangedRoutine,
  getTemplates: fetchTemplatesRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(FormTemplate);

import { callGatewayApi } from 'helpers/webApiHelper';
import { handleResponse } from '../helpers/handleResponse';

export const getAll = async () => {
  const response = await callGatewayApi({
    endpoint: '/api/users/',
    type: 'GET'
  });
  const processedResponse = await handleResponse(response);
  return processedResponse;
};

export const getById = async (id: string) => {
  const response = await callGatewayApi({
    endpoint: `/api/users/${id}`,
    type: 'GET'
  });
  const processedResponse = await handleResponse(response);
  return processedResponse;
};

export const getUserDetails = async () => {
  const response = await callGatewayApi({
    endpoint: '/api/users/details',
    type: 'GET'
  });
  const processedResponse = await handleResponse(response);
  return processedResponse;
};

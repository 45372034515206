import { callGatewayApi } from 'helpers/webApiHelper';
import { IUsersFilter } from '../models/IUsersFilter';

export const getAll = async (filter: IUsersFilter) => {
  const response = await callGatewayApi({
    endpoint: '/api/users/team',
    type: 'GET',
    queryParams: filter
  });
  return response.json();
};

import { combineReducers } from 'redux';
import { projectsList } from 'screens/Projects/containers/ProjectsList/reducers';
import { reducerCreator } from 'helpers/reducerHelper';
import { saveProjectRoutine, fetchProjectRoutine, fetchProjectsDataRoutine } from '../routines';
import { saveProject } from '../components/SaveProjectModal/reducer';

const requests = combineReducers({
  saveProject: reducerCreator([saveProjectRoutine.TRIGGER]),
  fetchProject: reducerCreator([fetchProjectRoutine.TRIGGER]),
  getData: reducerCreator([fetchProjectsDataRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  projectsList,
  saveProject
});

import React, { FunctionComponent } from 'react';
import { IBindingAction } from 'models/callback';
import { Icon } from 'semantic-ui-react';
import { history } from '../../../../helpers/history';
import { formatDate } from '../../../../helpers/dateFormatHelper';
import styles from './styles.module.scss';
import moment from 'moment';
import { IUserOkrStats } from '../../../OKR/models/okr/IUserOkrStats';

export interface IOkrItemProps {
  okr: IUserOkrStats;
  onClick: IBindingAction;
}
const OkrItem: FunctionComponent<IOkrItemProps> = ({
  okr: {
    isClosed,
    startTime,
    endTime,
    id,
    name,
    description,
    averageMark,
    objectivesCount,
    keyResultsCount
  },
  onClick
}) => {
  const onUpdateClick = event => {
    event.stopPropagation();
    onClick();
  };
  const isCurrent = moment().isBetween(startTime, endTime);
  const isDatePassed = date => moment(date).isBefore();
  const isNotFinishedTillEndDate = !isClosed && isDatePassed(endTime);

  return (
    <div
      onClick={() => history.push(`/okr/${id}`)}
      role="presentation"
      className={`
        ${styles.card} ${isClosed ? styles.cardClosed : styles.cardActive}
        ${isCurrent ? styles.current : ''} ${isNotFinishedTillEndDate ? styles.notFinished : ''}`}
    >
      {isClosed ? <p className={styles.mark}>{averageMark}</p> : ''}
      <div className={styles.cardContent}>
        <div className={styles.cardHeader}>
          <div className={styles.ellipsis}>{name}</div>
        </div>
        <div className={styles.keyResultsCountContainer}>
          <div>
            {`Objectives: ${objectivesCount}, KeyResults: ${keyResultsCount}`}
          </div>
        </div>
        <div className={styles.cardDescription}>
          {description}
        </div>
        <div className={styles.cardMeta}>
          <div className={styles.dates}>
            <Icon name="calendar outline" className={styles.calendarIcon} />
            <div className={styles.datesWrapper}>
              <span className={styles.beginDate}>
                {`${formatDate(startTime)} - `}
              </span>
              <span className={styles.endDate}>
                {formatDate(endTime)}
              </span>
            </div>
          </div>
          <span onClick={onUpdateClick} role="presentation" className={styles.editButton}>
            <Icon name="pencil alternate" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default OkrItem;

import React from 'react';
import { Button, Form, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { IBindingCallback1, IBindingAction } from 'models/callback/Callbacks';
import { connect } from 'react-redux';
import { resendEmailConfirmationLinkRoutine } from 'screens/Authorization/routines';
import styles from '../../containers/LoginPage/styles.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail is not valid!')
    .required('E-mail is required!')
});

export interface IResendEmailConfirmationLinkFormProps {
  sendEmailConfirmationLink: IBindingCallback1<string>;
  isLoading: boolean;
  toggleLogin: IBindingAction;
}

export interface IResendEmailConfirmationLinkFormikProps {
  email: string;
}

const ResendEmailConfirmationLinkForm: React.FunctionComponent<IResendEmailConfirmationLinkFormProps> = ({
  sendEmailConfirmationLink,
  isLoading,
  toggleLogin
}: IResendEmailConfirmationLinkFormProps) => (
  <Formik
    initialValues={{
      email: ''
    }}
    onSubmit={(values, { setSubmitting }) => {
      sendEmailConfirmationLink(values.email);
      setSubmitting(false);
    }}
    validationSchema={validationSchema}
  >
    {({ values, errors, touched, handleSubmit, handleChange }:
      FormikProps<IResendEmailConfirmationLinkFormikProps>) => (
        <Form onSubmit={handleSubmit}>
          <Popup
            open={!!(touched.email && errors.email)}
            content={errors.email}
            on={[]}
            trigger={(
              <Form.Input
                fluid
                onChange={handleChange}
                name="email"
                placeholder="Email address"
                error={errors.email && touched.email}
              />
            )}
            inverted
            size="tiny"
            flowing
            pinned
            hideOnScroll
          />
          <div className={styles.actions}>
            <div className={styles.textWrapper}>
              <div className={styles.text} onClick={toggleLogin} role="presentation">
                Get back to login
              </div>
            </div>
            <Button
              primary
              type="submit"
              disabled={!values.email || isLoading}
              loading={isLoading}
              content="Send email confirmation link"
            />
          </div>
        </Form>
    )}
  </Formik>
);

const mapStateToProps = state => {
  const { auth: { requests: { resendLink } } } = state;

  return {
    isLoading: resendLink.loading
  };
};

const mapDispatchToProps = {
  sendEmailConfirmationLink: resendEmailConfirmationLinkRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmailConfirmationLinkForm);

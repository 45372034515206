import React from 'react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { Popup, Icon, Button } from 'semantic-ui-react';
import styles from './styles.module.scss';

interface IEditPopupProps {
  onEditPress: IBindingAction;
  onSyncPress: IBindingAction;
  onPublishPress: IBindingAction;
  onDeletePress: IBindingAction;
  isPopupOpened: boolean;
  syncLoading: boolean;
  canBeEdited: boolean;
  canBeDeleted: boolean;
  canBePublished: boolean;
  hasLink: boolean;
  popupStateAction: IBindingCallback1<boolean>;
}

export const EditPopup: React.FunctionComponent<IEditPopupProps> = ({
  onEditPress,
  onSyncPress,
  onPublishPress,
  onDeletePress,
  popupStateAction,
  isPopupOpened,
  canBeEdited,
  canBeDeleted,
  canBePublished,
  syncLoading,
  hasLink
}: IEditPopupProps) => (
  <Popup
    trigger={(canBeEdited || canBeDeleted) && <Icon name="ellipsis vertical" className={styles.icon} />}
    on="click"
    className={styles.popup}
    position="bottom right"
    open={isPopupOpened}
    onOpen={e => {
      e.stopPropagation();
      popupStateAction(true);
    }}
    onClose={() => popupStateAction(false)}
    basic
  >
    {canBePublished && (
      <Button
        icon="send"
        onClick={e => {
          e.stopPropagation();
          onPublishPress();
        }}
        content="Publish"
        disabled={syncLoading}
      />
    )}
    {canBeEdited && (
      <Button
        icon="pencil"
        onClick={e => {
          e.stopPropagation();
          onEditPress();
        }}
        content="Edit"
        disabled={syncLoading}
      />
    )}
    {hasLink && (
      <Button
        icon="sync alternate"
        onClick={e => {
          e.stopPropagation();
          onSyncPress();
        }}
        content="Sync with origin"
        loading={syncLoading}
      />
    )}
    {canBeDeleted && (
      <Button
        icon="remove"
        content="Delete"
        onClick={e => {
          e.stopPropagation();
          onDeletePress();
        }}
        disabled={syncLoading}
      />
    )}
  </Popup>
);

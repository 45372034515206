import React, { useEffect, useState } from 'react';
import { IRequest } from '../../model/Requests/IRequest';
import { IBindingAction } from '../../../../models/callback';
import styles from './styles.module.scss';
import { NoDataContainer } from '../../../../components/NoDataContainer';
import {
  fetchRequestsRoutine, fetchUsersMentorsRoutine,
  clearFeedbackPageStateRoutine
} from '../../routines';
import { connect } from 'react-redux';
import CsvImportButton from '../../components/CsvImportButton';
import ReportDetails from '../../components/ReportDetails';
import RequestTable from 'screens/Feedback/components/RequestTable';
import { Role } from 'screens/Authorization/models/role';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';
import LoaderWrapper from '../../../../components/LoaderWrapper';

interface IRequestsProps {
  fetchRequests: IBindingAction;
  fetchUsersMentor: IBindingAction;
  clearFeedbackPageState: IBindingAction;
  usersMentor: IUserMentorData[];
  requests?: IRequest[];
  refreshNeeded?: boolean;
  detailedRequest?: IRequest;
  loading: boolean;
  roles: Role[];
  id: string;
}

const FeedbackRequest: React.FunctionComponent<IRequestsProps> = (
  { fetchRequests, fetchUsersMentor, clearFeedbackPageState, refreshNeeded, requests, loading,
    detailedRequest, usersMentor, roles, id }: IRequestsProps
) => {
  const [init, setInit] = useState(false);
  const [detailedRequestId, setDetailedRequestId] = useState(null);

  useEffect(() => {
    if (refreshNeeded !== undefined && refreshNeeded) {
      fetchUsersMentor();
      fetchRequests();
      setInit(true);
    }
  }, [refreshNeeded]);

  useEffect(() => {
    if (detailedRequest !== undefined && detailedRequest && detailedRequestId === null) {
      setDetailedRequestId(detailedRequest.id);
    }
  }, [detailedRequest]);

  const closeRequestDetails = (): void => {
    clearFeedbackPageState();
    setDetailedRequestId(null);
  };

  useEffect(() => () => closeRequestDetails(), []);

  const openRequestDetails = (requestId: string): void => {
    if (requestId.length !== 0) setDetailedRequestId(requestId);
  };

  const availableRequests = (): IRequest[] => {
    const requiredRoles = [Role.Administrator, Role.HR, Role.TopManager, Role.Manager];
    return roles.some(userRole => requiredRoles.includes(userRole))
      ? requests
      : requests.filter(request => request.owner.userId === id
        || usersMentor.find(user => user.userId === request.owner.userId)?.mentorId === id
        || request.creator.userId === id);
  };

  return (

    <div className={styles.sectionContainer}>
      <div className={styles.formsViewContainer}>
        <div className={styles.sectionHeader}>
          <h1>Requests</h1>
          <CsvImportButton fetchRequests={fetchRequests} setRequest={openRequestDetails} />
        </div>
        <LoaderWrapper loading={loading || !init}>
          {availableRequests().length !== 0
            ? (
              <div className={`${styles.formListAndActionContainer} ${styles.fullHeightData}`}>
                <div className={styles.formListContainer}>
                  <RequestTable
                    requests={availableRequests()}
                    openRequestDetails={openRequestDetails}
                    currentRequestId={detailedRequestId}
                  />
                </div>
                <div className={styles.formContainer}>
                  {detailedRequestId !== null
                    ? (
                      <ReportDetails
                        detailedRequestId={detailedRequestId}
                        userId={id}
                        roles={roles}
                        usersMentor={usersMentor}
                      />
                    ) : <NoDataContainer text="There is not request choosed ..." />}
                </div>
              </div>
            )
            : (
              <div className={styles.fullHeightData}>
                <NoDataContainer text="There are not requests created ..." />
              </div>
            )}
        </LoaderWrapper>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { feedbackPage: { requests: { requests, detailedRequest, refreshNeeded, usersMentor } } } = state;
  const { feedbackPage: { reducers: { getRequests: { loading } } } } = state;
  const { user: { user: { roles, id } } } = state;
  return {
    requests, refreshNeeded, detailedRequest, roles, id, usersMentor, loading
  };
};

const mapDispatchToProps = {
  fetchRequests: fetchRequestsRoutine,
  fetchUsersMentor: fetchUsersMentorsRoutine,
  clearFeedbackPageState: clearFeedbackPageStateRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackRequest);

import { Routine } from 'redux-saga-routines';
import { updateUserPasswordRoutine } from 'screens/UserProfile/routines';

export interface IUpdatePasswordRequest {
  loading: boolean;
  error: boolean;
}

const updatePassword = (state: IUpdatePasswordRequest = {
  loading: false,
  error: false
}, action: Routine<any>): IUpdatePasswordRequest => {
  switch (action.type) {
    case updateUserPasswordRoutine.TRIGGER:
      return { loading: true, error: false };
    case updateUserPasswordRoutine.SUCCESS:
      return { loading: false, error: false };
    case updateUserPasswordRoutine.FAILURE:
      return { loading: false, error: true };
    default:
      return state;
  }
};

export default updatePassword;

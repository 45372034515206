import { call, put, all, takeEvery } from 'redux-saga/effects';
import {
  requestDaysOffRoutine,
  fetchAllByUserIdRequestsRoutine,
  uploadProofImageRoutine,
  fetchDaysOffForUserRoutine
} from 'screens/TimeOffTracker/routines';
import { Routine } from 'redux-saga-routines';
import * as timeOffService from '../../services/timeOffService';

function* daysOffRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(timeOffService.addDaysOffRequest, payload);

    yield put(requestDaysOffRoutine.success(response));
  } catch (error) {
    yield put(requestDaysOffRoutine.failure(error.message));
  }
}

function* getAllDaysOffRequests({ payload }: Routine<any>) {
  try {
    const response = yield call(timeOffService.getAllByUserIdRequests, payload);

    yield put(fetchAllByUserIdRequestsRoutine.success(response));
  } catch (error) {
    yield put(fetchAllByUserIdRequestsRoutine.failure(error.message));
  }
}

function* getDaysOffForUser({ payload }: Routine<any>) {
  try {
    const response = yield call(timeOffService.getDaysOffByUserId, payload);

    yield put(fetchDaysOffForUserRoutine.success(response));
  } catch (error) {
    yield put(fetchDaysOffForUserRoutine.failure(error.message));
  }
}

function* uploadProofImage({ payload }: Routine<any>) {
  try {
    const response = yield call(timeOffService.uploadProofImage, payload);

    yield put(uploadProofImageRoutine.success(response));
  } catch (error) {
    yield put(uploadProofImageRoutine.failure(error.message));
  }
}

function* watchDaysOffRequest() {
  yield takeEvery(requestDaysOffRoutine.TRIGGER, daysOffRequest);
}

function* watchGetAllDaysOffRequests() {
  yield takeEvery(fetchAllByUserIdRequestsRoutine.TRIGGER, getAllDaysOffRequests);
}

function* watchGetDaysOffForUser() {
  yield takeEvery(fetchDaysOffForUserRoutine.TRIGGER, getDaysOffForUser);
}

function* watchUploadProofImage() {
  yield takeEvery(uploadProofImageRoutine.TRIGGER, uploadProofImage);
}

export default function* requestsSaga() {
  yield all([
    watchDaysOffRequest(),
    watchUploadProofImage(),
    watchGetDaysOffForUser(),
    watchGetAllDaysOffRequests()
  ]);
}

import React from 'react';
import LoginForm from 'screens/Authorization/components/LoginForm';
import styles from './styles.module.scss';
import PageLayout from 'screens/Authorization/components/PagesLayout';
import { Button } from 'semantic-ui-react';

export interface ILoginPageProps {
  loading: boolean;
  history: any;
}

export const LoginPage: React.FunctionComponent<ILoginPageProps> = ({
  loading,
  history
}: ILoginPageProps) => {
  const toggleForgetPassword = () => history.push('/forget-password');
  const toggleResendEmailConfirm = () => history.push('/resend-email-confirmation');
  const toggleRegister = () => history.push('/register');

  return (
    <PageLayout loading={loading}>
      <p className="header">
        Login
      </p>
      <div className={styles.form}>
        <LoginForm onPasswordForget={toggleForgetPassword} />
      </div>
      <p className={styles.redirect}>
        <span>Need an Account?</span>
        <Button onClick={toggleRegister} content="Register" />
      </p>
      <div className={`${styles.textWrapper} ${styles.resend}`}>
        <div className={styles.text} onClick={toggleResendEmailConfirm} role="presentation">
          Resend email confirmation link
        </div>
      </div>
    </PageLayout>
  );
};

export default LoginPage;

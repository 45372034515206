import { Routine } from 'redux-saga-routines';
import { IOkr } from '../../models/okr';
import {
  fetchOkrs,
  updateOKRStatusRoutine,
  saveOkrRoutine,
  deleteOkrRoutine,
  clearOkrsStateRoutine,
  fetchOkrDatesRoutine
} from '../../routines';
import { removeUserRoutine } from 'screens/Authorization/routines';

export const okrs = (state = [], action: Routine<any>): IOkr[] => {
  switch (action.type) {
    case fetchOkrs.SUCCESS:
      return action.payload;
    case saveOkrRoutine.SUCCESS:
      const { id: okrId, editing } = action.payload;
      if (editing && !state.length) {
        return [];
      }
      if (!state.some(({ id }) => id === okrId)) {
        return [...state, action.payload];
      }

      return state.map((okr: IOkr) => (okr.id === okrId ? action.payload : okr));
    case updateOKRStatusRoutine.SUCCESS:

      const updatedOkr = state.find(({ id }) => id === action.payload.id);
      if (updatedOkr) {
        updatedOkr.isClosed = !updatedOkr.isClosed;
        updatedOkr.averageMark = action.payload.averageMark;

        return state.map(okr => (okr.id === action.payload.id ? updatedOkr : okr));
      }
      return state;
    case deleteOkrRoutine.SUCCESS:
      return state.filter(okr => okr.id !== action.payload.id);
    case clearOkrsStateRoutine.TRIGGER:
      return [];
    case removeUserRoutine.TRIGGER:
      return [];
    default:
      return state;
  }
};

export const okrDates = (state = null, action: Routine<any>) => {
  switch (action.type) {
    case fetchOkrs.SUCCESS:
      return action.payload.map(okr => ({ id: okr.id, endTime: okr.endTime, startTime: okr.startTime }));
    case fetchOkrDatesRoutine.SUCCESS:
      return action.payload.map(okr => ({ id: okr.id, endTime: okr.endTime, startTime: okr.startTime }));
    case saveOkrRoutine.SUCCESS:
      const { id, startTime, endTime } = action.payload;
      const isNew = !state.map(date => date.id).includes(id);
      return isNew
        ? [...state.concat({ id, startTime, endTime })]
        : state.map(date => (date.id === id ? { id, startTime, endTime } : date));
    case deleteOkrRoutine.SUCCESS:
      const { id: okrId } = action.payload;
      return state.filter(date => date.id !== okrId);
    case clearOkrsStateRoutine.TRIGGER:
      return [];
    default:
      return state;
  }
};

import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { boundMethod } from 'autobind-decorator';
import styles from './styles.module.scss';
import { IBindingCallback1, IBindingFunction } from '../../../../models/callback';
import ObjectivesList from '../../containers/ObjectivesList';
import { IObjective } from '../../models/objective';
import { ISearch } from '../../../../models/autocomplete/ISearch';
import { ISelectOption } from '../../../../models/autocomplete/ISelectOption';
import Sidebar from 'components/DataSidebar';
import { cross } from 'assets/icons';

export interface IAddObjectiveModalProps {
  addSelectedObjectives: IBindingCallback1<string[]>;
  selectedObjectives: IObjective[];
  onObjectivesLoad: IBindingFunction<ISearch, Promise<ISelectOption[]>>;
  saving: boolean;
  onObjectiveCreate: (obj) => Promise<{key: string; label: string; value: { id: string }}>;
}

export interface IAddObjectiveModalState {
  isOpened: boolean;
}

class AddObjectiveModal extends React.Component<IAddObjectiveModalProps, IAddObjectiveModalState> {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false
    };
  }

  componentDidUpdate(prevProps) {
    const { saving } = this.props;
    if (prevProps.saving && !saving) {
      this.closeModal();
    }
  }

  @boundMethod
  handleSubmit(selected) {
    const { addSelectedObjectives: addSelectedObj } = this.props;
    addSelectedObj(selected);
  }

  openModal = e => {
    e.stopPropagation();
    this.setState(prevState => ({ isOpened: !prevState.isOpened }));
  }

  closeModal = () => this.setState({ isOpened: false });

  @boundMethod
  async createObjective(inputValue: string) {
    const { onObjectiveCreate } = this.props;
    const created = await onObjectiveCreate(inputValue);
    this.handleSubmit([created.key]);
  }

  render() {
    const { isOpened } = this.state;
    const { selectedObjectives, onObjectivesLoad, saving } = this.props;
    const selectedObjectivesId = selectedObjectives.map(obj => obj.id);

    const modal = isOpened ? (
      <Sidebar onClose={this.closeModal}>
        <Image src={cross} className={styles.closeButton} onClick={this.closeModal} />
        <ObjectivesList
          onClose={this.closeModal}
          selectedObjectives={selectedObjectivesId}
          addSelectedObjectives={(selected => this.handleSubmit(selected))}
          onObjectivesLoad={onObjectivesLoad}
          createObjective={this.createObjective}
          loading={saving}
        />
      </Sidebar>
    ) : null;

    return (
      <>
        <Button className={styles.openModal} onClick={this.openModal} content="Add objective" icon="plus" />
        {modal}
      </>
    );
  }
}

export default AddObjectiveModal;

import React from 'react';
import { Radio, CheckboxProps, Table, Label } from 'semantic-ui-react';
import LinksReplacer from 'components/LinksReplacer/linksReplacer';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { IBindingCallback2 } from 'models/callback';
import { IFormEvent } from 'models/events';
import styles from './styles.module.scss';

interface IProps {
  keyResult: IKeyResult;
  checkedId: string | null;
  onChange: IBindingCallback2<IFormEvent<HTMLInputElement>, CheckboxProps>;
}

const ItemMerge = ({ keyResult, checkedId, onChange }: IProps) => {
  const { id, name } = keyResult;

  return (
    <Table.Row>
      <Table.Cell width={2}>
        <Radio
          className={styles.mergeItemRadio}
          checked={checkedId === id}
          onChange={onChange}
          value={id}
          label={name}
        />
      </Table.Cell>
      <Table.Cell>
        <LinksReplacer showDomain data={keyResult.details} />
      </Table.Cell>
      <Table.Cell>
        {keyResult.tags.map(t => (
          <Label
            className={styles.tagLabel}
            content={t.name}
            key={t.id}
          />
        ))}
      </Table.Cell>
    </Table.Row>
  );
};

export default ItemMerge;

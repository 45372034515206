import React, { useState } from 'react';
import parse from 'html-react-parser';
import moment from 'moment';

import ConfirmModalContent from 'components/ConfirmModalContent';
import Avatar from 'components/UserAvatar';
import { EditPopup } from '../EditPopup';

import { IAnnouncement } from 'screens/Announcements/models/IAnnouncement';
import { IBindingAction } from 'models/callback';

import styles from './styles.module.scss';
import { history } from '../../../../helpers/history';

export interface IListItemProps {
  announcement: IAnnouncement;
  onUpdate: IBindingAction;
  onDelete: IBindingAction;
  onSelect: IBindingAction;
}

const AnnouncementItem: React.FunctionComponent<IListItemProps> = ({
  announcement,
  onUpdate,
  onDelete,
  onSelect
}) => {
  const [isPopupOpen, togglePopupState] = useState(false);
  const [isConfirmOpen, toggleConfirmState] = useState(false);

  const handleUpdate = () => {
    togglePopupState(false);
    onUpdate();
  };

  const handleDelete = () => {
    toggleConfirmState(true);
    togglePopupState(false);
  };

  const handleConfirm = () => {
    onDelete();
    toggleConfirmState(false);
  };

  const renderPopup = () => (
    <EditPopup
      isPopupOpened={isPopupOpen}
      onDeletePress={handleDelete}
      onEditPress={handleUpdate}
      popupStateAction={togglePopupState}
    />
  );

  const { title, author, text, date } = announcement;

  return (
    <>
      <ConfirmModalContent
        opened={isConfirmOpen}
        description={`Would you like to delete announcement "${title}"?`}
        header="Delete news"
        onConfirm={handleConfirm}
        onCancel={() => toggleConfirmState(false)}
      />
      <div className={styles.container}>
        <div className={styles.dateContainer}>
          <div className={styles.dateContentContainer}>
            <p className={styles.date}>{moment(date).format('MMMM D')}</p>
            <p className={styles.date}>{moment(date).format('YYYY')}</p>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.contentHeaderContainer}>
            <h4 className={`${styles.contentHeaderTitle} ${styles.textWrapper}`} onClick={onSelect} role="presentation">
              {title}
            </h4>
            <div className={styles.dateContentContainer}>
              <p className={styles.date}>{moment(date).format('MMMM D YYYY')}</p>
            </div>
          </div>
          <div className={styles.contentDescriptionContainer}>
            <div className={`${styles.contentDescription} ${styles.textWrapper}`}>
              {text.length ? parse(text) : (
                <p>
                  <span>No description.</span>
                </p>
              )}
            </div>
          </div>
          <div className={styles.contentFooterContainer}>
            <div
              role="presentation"
              className={styles.authorContentContainer}
              onKeyDown={() => history.push(`../profile/${author.id}`)}
              onClick={() => history.push(`../profile/${author.id}`)}
            >
              <div className={styles.authorLogoContainer}>
                <Avatar
                  name={author.authorName}
                  width="30px"
                  logoLink={author.logoLink}
                  height="30px"
                />
              </div>
              <p className={styles.authorName}>{author.authorName}</p>
            </div>
          </div>
        </div>
        <div className={styles.popupMenu}>{renderPopup()}</div>
      </div>
    </>
  );
};

export default AnnouncementItem;

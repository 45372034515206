import { all, takeEvery, call, put } from 'redux-saga/effects';
import { fetchUserOkrsStatsRoutine } from 'screens/Dashboard/routines';
import * as okrService from '../../services/okrService';
import { IOkrsUserStats } from 'screens/Dashboard/models/IOkrsUserStats';

function* fetchUsersOkrsStats() {
  try {
    const response: IOkrsUserStats[] = yield call(okrService.getAll);
    yield put(fetchUserOkrsStatsRoutine.success(response));
  } catch (error) {
    yield put(fetchUserOkrsStatsRoutine.failure(error.message));
  }
}

function* watchFetchUsersOkrsStats() {
  yield takeEvery(fetchUserOkrsStatsRoutine.TRIGGER, fetchUsersOkrsStats);
}

export default function* dashboardSagas() {
  yield all([
    watchFetchUsersOkrsStats()
  ]);
}

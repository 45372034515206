import React from 'react';
import { Button, Container, Dropdown, DropdownItemProps, Form } from 'semantic-ui-react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { IBindingAction, IBindingCallback1, IBindingFunction } from 'models/callback';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Office } from 'screens/AdminPage/model/enums/office';
import { ISaveUser } from 'screens/AdminPage/model/ISaveUser';
import { ModalHeaderWithCross } from 'components/ModalHeaderWithCross';
import overridingSemanticUIStyles from './overridingSemanticUIStyles/styles.module.scss';
import styles from './styles.module.scss';
import { IAdminUserInfo } from 'screens/AdminPage/model/IAdminUserInfo';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';
import ConfirmModal from 'components/ConfirmModal';
import InputMask from 'react-input-mask';
import { Role } from 'screens/Authorization/models/role';
import RoleToEditRightsMap from '../../model/maps/RoleToEditRightsMap';
import { IUser } from '../../../Authorization/models/IUser';
import { roleWeights } from '../../../../helpers/roles.helper';
import { toastr } from 'react-redux-toastr';

function checkIfMentorNotMentee(this: any, mentorId) {
  const { menteeIds } = this.parent;
  return !menteeIds.includes(mentorId);
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter correct Email!')
    .required('Email is required!')
    .max(255, 'Max email length is 255 symbols!'),
  password: Yup.string()
    .min(6, 'Password should have at least 6 symbols!')
    .max(100, 'And who will remember so long string!')
    .required('Password is required!'),
  firstName: Yup.string()
    .required('First name is required!')
    .min(3, 'Minimum first name length is 3 symbols!')
    .max(30, 'Maximum first name length is 30 symbols!'),
  lastName: Yup.string()
    .required('Last name is required!')
    .min(3, 'Minimum last name length is 3 symbols!')
    .max(30, 'Maximum last name length is 30 symbols!'),
  birthDate: Yup.mixed()
    .required('Enter birthday!'),
  technology: Yup.string()
    .min(2, 'Minimum length is 2 symbols!')
    .max(200, 'Maximum length is 200 symbols!'),
  position: Yup.string()
    .min(2, 'Minimum length is 2 symbols!')
    .max(200, 'Maximum length is 200 symbols!'),
  slack: Yup.string()
    .min(2, 'Minimum slack length is 2 symbols!')
    .max(100, 'Maximum slack length is 100 symbols!'),
  skype: Yup.string()
    .min(2, 'Minimum skype length is 2 symbols!')
    .max(100, 'Maximum skype length is 100 symbols!'),
  phone: Yup.string()
    .matches(/^\+38 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/, 'Enter correct phone number!'),
  roles: Yup.array()
    .min(1, 'Select at least one role!'),
  offices: Yup.array()
    .min(1, 'Select at least one office!'),
  introLetterLink: Yup.string()
    .url('Enter url')
    .min(10, 'Minimum length is 10 symbols!')
    .max(200, 'Maximum length is 200 symbols!'),
  bsaGraduatedYearInfo: Yup.string()
    .min(4, 'Minimum length is 4 symbols!')
    .max(200, 'Maximum length is 200 symbols!'),
  address: Yup.string()
    .min(4, 'Minimum length is 4 symbols!')
    .max(200, 'Maximum length is 200 symbols!'),
  bsaRoles: Yup.string()
    .min(4, 'Minimum length is 4 symbols!')
    .max(200, 'Maximum length is 200 symbols!'),
  mentorId: Yup.mixed().test('mentor-not-mentee', 'Mentor can\'t be mentee at the same time', checkIfMentorNotMentee)
});

export interface IUserCreateFormikProps {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  offices: Office[];
  roles: Role[];
  birthDate: Date;
  startDate?: Date;
  technology: string;
  position: string;
  slack: string;
  skype: string;
  phone: string;
  address?: string;
  introLetterLink?: string;
  bsaGraduatedYearInfo?: string;
  bsaRoles?: string;
  mentorId?: string;
  menteeIds: string[];
  isActive: boolean;
}

export interface ISaveUserFormProps {
  onSave: IBindingFunction<IUserCreateFormikProps, void>;
  onCancel: IBindingAction;
  onDelete: IBindingCallback1<string>;
  saveUser?: ISaveUser;
  loading?: boolean;
  users: IAdminUserInfo[];
  usersMentor: IUserMentorData[];
  loggedInUser: IUser;
}

const initializeValues = (newUser: boolean, saveUser?: ISaveUser) => (newUser
  ? {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    roles: [],
    birthDate: new Date(2000, 1, 1),
    startDate: new Date(),
    technology: '',
    position: '',
    offices: [Office.Kyiv],
    slack: '',
    skype: '',
    phone: '',
    address: '',
    introLetterLink: '',
    bsaGraduatedYearInfo: new Date().getFullYear().toString(),
    bsaRoles: '',
    menteeIds: [],
    isActive: false
  }
  : {
    ...saveUser,
    birthDate: new Date(saveUser.birthDate),
    address: saveUser.address ?? '',
    introLetterLink: saveUser.introLetterLink ?? '',
    bsaGraduatedYearInfo: saveUser.bsaGraduatedYearInfo ?? '',
    bsaRoles: saveUser.bsaRoles ?? '',
    password: '12345678'
  });

const SaveUserModal: React.FunctionComponent<ISaveUserFormProps> = ({
  onSave,
  onCancel,
  onDelete,
  saveUser,
  users,
  usersMentor,
  loggedInUser: {
    roles
  }
}: ISaveUserFormProps) => {
  const confirmDeleteModal = () => {
    onDelete(saveUser.id);
    onCancel();
  };
  const newUser = !saveUser?.id;
  const officesDict = ['Lviv', 'Kyiv'].map(o => ({ key: o, value: o, text: o }));
  const rolesToEdit = RoleToEditRightsMap
    .get(roles
      .map(r => [r, roleWeights[r]])
      .reduce((first, second) => (first[1] > second[1] ? first : second))[0]);
  const userRolesDict = Object.values(Role)
    .map(type => ({
      key: type,
      text: type,
      value: type,
      disabled: !rolesToEdit.includes(type)
    }));
  const usersDict: DropdownItemProps[] = users?.filter(u => u.id !== saveUser?.id).map(u => (
    { key: u.id, value: u.id, text: u.fullName }
  ));
  const menteeDict: DropdownItemProps[] = users?.filter(u => u.id !== saveUser?.id).map(u => {
    const mentor = usersMentor.find(um => um.userId === u.id);
    return (
      {
        key: u.id,
        value: u.id,
        text: u.fullName,
        disabled: mentor?.mentorId !== saveUser?.id && mentor?.mentorName !== null,
        description: mentor?.mentorId && mentor?.mentorId !== saveUser?.id ? `Mentor: ${mentor?.mentorName}` : ''
      }
    );
  });
  const canSetMentorAndMentees = roles.includes(Role.Administrator) || roles.includes(Role.Manager);
  return (
    <>
      <ModalHeaderWithCross onClose={onCancel} headerName={newUser ? 'Create user' : 'Update User'} />
      <Formik
        initialValues={initializeValues(newUser, saveUser)}
        onSubmit={u => onSave(u)}
        validationSchema={validationSchema}
      >
        {({ values, errors, setFieldValue, touched, handleSubmit, handleChange }:
          FormikProps<IUserCreateFormikProps>) => (
            <Container className={styles.container}>
              <Form onSubmit={handleSubmit} width={10}>
                <Form.Input
                  icon="user outline"
                  iconPosition="left"
                  name="firstName"
                  placeholder="First name"
                  label={touched.firstName && errors.firstName ? errors.firstName : 'First name'}
                  error={Boolean(touched.firstName && errors.firstName)}
                  onChange={handleChange}
                  value={values.firstName}
                />
                <Form.Input
                  icon="user"
                  iconPosition="left"
                  placeholder="Last name"
                  name="lastName"
                  label={touched.lastName && errors.lastName ? errors.lastName : 'Last name'}
                  error={Boolean(touched.lastName && errors.lastName)}
                  onChange={handleChange}
                  value={values.lastName}
                />
                <Form.Input
                  icon="mail"
                  iconPosition="left"
                  type="email"
                  name="email"
                  placeholder="Email address"
                  label={touched.email && errors.email ? errors.email : 'Email'}
                  error={Boolean(touched.email && errors.email)}
                  onChange={handleChange}
                  value={values.email}
                />
                {newUser ? (
                  <Form.Input
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    name="password"
                    label={touched.password && errors.password ? errors.password : 'Password'}
                    error={Boolean(touched.password && errors.password)}
                    value={values.password}
                    onChange={handleChange}
                  />
                ) : (
                  ''
                )}
                <Form.Group>
                  <Form.Input
                    width={5}
                    name="slack"
                    placeholder="Slack"
                    label={touched.slack && errors.slack ? errors.slack : 'Slack'}
                    error={Boolean(touched.slack && errors.slack)}
                    onChange={handleChange}
                    value={values.slack}
                  />
                  <Form.Input
                    name="skype"
                    width={6}
                    placeholder="Skype"
                    label={touched.skype && errors.skype ? errors.skype : 'Skype'}
                    error={Boolean(touched.skype && errors.skype)}
                    onChange={handleChange}
                    value={values.skype}
                  />
                  <Form.Field
                    control={InputMask}
                    mask="+38 (999) 999-99-99"
                    label={touched.phone && errors.phone ? errors.phone : 'Phone'}
                    name="phone"
                    width={5}
                    placeholder="Phone"
                    error={Boolean(touched.phone && errors.phone)}
                    onChange={handleChange}
                    value={values.phone}
                  />
                </Form.Group>
                <Form.Input
                  icon="home"
                  iconPosition="left"
                  width={16}
                  label={touched.address && errors.address ? errors.address : 'Address'}
                  name="address"
                  placeholder="Address"
                  onChange={handleChange}
                  value={values.address}
                  error={Boolean(touched.address && errors.address)}
                />
                <Form.Input
                  icon="info"
                  iconPosition="left"
                  width={16}
                  name="introLetterLink"
                  placeholder="Intro Letter"
                  onChange={handleChange}
                  error={touched.introLetterLink && errors.introLetterLink}
                  value={values.introLetterLink}
                />
                <Form.Input
                  icon="graduation"
                  iconPosition="left"
                  width={16}
                  name="bsaGraduatedYearInfo"
                  error={touched.bsaGraduatedYearInfo && errors.bsaGraduatedYearInfo}
                  placeholder="Graduate BSA at, as:"
                  onChange={handleChange}
                  value={values.bsaGraduatedYearInfo}
                />
                <Form.Input
                  icon="book"
                  iconPosition="left"
                  width={16}
                  name="bsaRoles"
                  placeholder="Role in BSA:"
                  onChange={handleChange}
                  value={values.bsaRoles}
                  error={touched.bsaRoles && errors.bsaRoles}
                />
                <Form.Input
                  label={touched.position && errors.position ? errors.position : 'Position'}
                  icon="road"
                  iconPosition="left"
                  width={16}
                  name="position"
                  placeholder="Position"
                  onChange={handleChange}
                  value={values.position}
                  error={errors.position && touched.position}
                />
                <Form.Input
                  label={touched.technology && errors.technology ? errors.technology : 'Technology'}
                  icon="cogs"
                  iconPosition="left"
                  width={16}
                  name="technology"
                  placeholder="Technology"
                  error={Boolean(touched.technology && errors.technology)}
                  onChange={handleChange}
                  value={values.technology}
                />
                <Form.Group>
                  {newUser ? (
                    <Form.Field
                      width={16}
                      className={styles.datepicker}
                      control={DatePicker}
                      popperPlacement="top"
                      name="startDate"
                      error={Boolean(errors.startDate && touched.startDate)}
                      selected={values.startDate}
                      onChange={date => setFieldValue('startDate', date)}
                      label={touched.startDate && errors.startDate ? errors.startDate : 'First working day'}
                    />
                  ) : (
                    ''
                  )}
                  <Form.Field
                    className={styles.datepicker}
                    control={DatePicker}
                    popperPlacement="top"
                    width={16}
                    name="birthDate"
                    error={Boolean(touched.birthDate && errors.birthDate)}
                    selected={values.birthDate}
                    onChange={date => setFieldValue('birthDate', date)}
                    label={touched.birthDate && errors.birthDate ? errors.birthDate : 'Birthday'}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    width={10}
                    className={overridingSemanticUIStyles.dropdown}
                    control={Dropdown}
                    label={touched.roles && errors.roles ? errors.roles : 'Roles'}
                    name="roles"
                    value={values.roles}
                    onChange={(event, o) => {
                      if (values.roles.length > o.value.length) {
                        const deletedRoles = values.roles.filter(r => !o.value.includes(r));
                        if (deletedRoles && !rolesToEdit.includes(deletedRoles[0])) {
                          toastr.warning('Access denied', 'You don\'t have permissions to manage this role');
                          return;
                        }
                      }
                      setFieldValue('roles', o.value);
                    }}
                    error={Boolean(touched.roles && errors.roles)}
                    fluid
                    multiple
                    selection
                    options={userRolesDict}
                  />
                  <Form.Select
                    multiple
                    width={6}
                    className={overridingSemanticUIStyles.dropdown}
                    options={officesDict}
                    label={touched.offices && errors.offices ? errors.offices : 'Office'}
                    name="offices"
                    error={Boolean(touched.offices && errors.offices)}
                    value={values.offices}
                    onChange={(event, o) => setFieldValue('offices', o.value)}
                  />
                </Form.Group>
                <Form.Select
                  disabled={!canSetMentorAndMentees}
                  selectOnBlur={false}
                  selectOnNavigation={false}
                  options={usersDict}
                  label={(touched.mentorId && errors.mentorId) || 'Mentor'}
                  name="mentorId"
                  error={Boolean(errors.mentorId && touched.mentorId)}
                  clearable
                  value={values.mentorId}
                  onChange={(event, o) => setFieldValue('mentorId', o.value || null)}
                />
                <Form.Field
                  disabled={!canSetMentorAndMentees}
                  className={overridingSemanticUIStyles.dropdown}
                  control={Dropdown}
                  options={menteeDict}
                  label="Mentees"
                  name="menteeIds"
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  value={values.menteeIds}
                  onChange={(event, o) => setFieldValue('menteeIds', o.value)}
                />
                <Form.Checkbox
                  name="isActive"
                  width={5}
                  toggle
                  checked={values.isActive}
                  label="Active"
                  className={styles.checkboxToggle}
                  onClick={() => setFieldValue('isActive', !values.isActive)}
                />
                <div className={styles.buttonsContainer}>
                  <Button
                    primary
                    size="large"
                    type="submit"
                    content={newUser ? 'Create User' : 'Update User'}
                  />
                  {!newUser ? (
                    <ConfirmModal
                      description={`Would you like to delete "${saveUser?.firstName} ${saveUser?.lastName}"?`}
                      header="Delete user"
                      onConfirm={confirmDeleteModal}
                    >
                      <Button secondary size="large" type="button" content="Delete User" />
                    </ConfirmModal>
                  ) : ''}
                </div>
              </Form>
            </Container>
        )}
      </Formik>
    </>
  );
};

export default SaveUserModal;

import {
  checkCodeRoutine,
  resetPasswordRoutine,
  updatePasswordRoutine,
  resendEmailConfirmationLinkRoutine,
  registerRoutine
} from 'screens/Authorization/routines';
import { combineReducers } from 'redux';
import { reducerCreator } from 'helpers/reducerHelper';
import { authData } from '../containers/LoginPage/reduces';

export const requests = combineReducers({
  resetPassword: reducerCreator([resetPasswordRoutine.TRIGGER]),
  checkPasswordCode: reducerCreator([checkCodeRoutine.TRIGGER]),
  updatePassword: reducerCreator([updatePasswordRoutine.TRIGGER]),
  resendLink: reducerCreator([resendEmailConfirmationLinkRoutine.TRIGGER]),
  register: reducerCreator([registerRoutine.TRIGGER])
});

const auth = combineReducers({
  auth: authData,
  requests
});

export default auth;

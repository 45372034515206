import { checkCodeRoutine } from 'screens/Authorization/routines';
import { Routine } from 'redux-saga-routines';

type State = {
  resetPasswordMessage: string;
};

const initialState: State = {
  resetPasswordMessage: null
};

export const authData = (state: State = initialState, action: Routine<any>): State => {
  switch (action.type) {
    case checkCodeRoutine.SUCCESS:
      return {
        ...state,
        resetPasswordMessage: action.payload
      };
    default:
      return state;
  }
};

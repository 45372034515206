import React from 'react';
import { Button, Form, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { IBindingCallback1 } from 'models/callback/Callbacks';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .max(30, 'Password has not to be longer than 30 characters!')
    .required('Password is required!'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Both passwords need to be the same')
    .required('Password confirm is required')
});

export interface IForgetPasswordFormProps {
  onSubmit: IBindingCallback1<string>;
  loading: boolean;
}

export interface IForgetPasswordFormikProps {
  password: string;
  repeatPassword: string;
}

const ResetPasswordForm: React.FunctionComponent<IForgetPasswordFormProps> = ({
  onSubmit,
  loading
}: IForgetPasswordFormProps) => (
  <Formik
    initialValues={{
      password: '',
      repeatPassword: ''
    }}
    onSubmit={(values, { setSubmitting }) => {
      onSubmit(values.password);
      setSubmitting(false);
    }}
    validationSchema={validationSchema}
  >
    {({ values, errors, touched, handleSubmit, handleChange }: FormikProps<IForgetPasswordFormikProps>) => (
      <Form onSubmit={handleSubmit}>
        <Popup
          open={!!(touched.password && errors.password)}
          content={errors.password}
          on={[]}
          trigger={(
            <Form.Input
              fluid
              onChange={handleChange}
              icon="lock"
              iconPosition="left"
              placeholder="Enter new password"
              type="password"
              name="password"
              error={errors.password && touched.password}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <Popup
          open={!!(touched.repeatPassword && errors.repeatPassword)}
          content={errors.repeatPassword}
          position="right center"
          on={[]}
          trigger={(
            <Form.Input
              fluid
              onChange={handleChange}
              icon="lock"
              iconPosition="left"
              placeholder="Repeat password"
              type="password"
              name="repeatPassword"
              error={errors.repeatPassword && touched.repeatPassword}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <Button
          primary
          fluid
          size="large"
          type="submit"
          disabled={!values.password || loading}
          loading={loading}
          content="Change password"
        />
      </Form>
    )}
  </Formik>
);

export default ResetPasswordForm;

import { Routine } from 'redux-saga-routines';
import { clearRatingRoutine, getRatingRoutine, updateRatingRoutine } from 'screens/OKR/routines';

export const keyResultRating = (state = null, action: Routine<any>) => {
  switch (action.type) {
    case clearRatingRoutine.TRIGGER:
      return {};
    case getRatingRoutine.SUCCESS:
      return action.payload;
    case updateRatingRoutine.SUCCESS:
      return state;
    default:
      return state;
  }
};

import { put, takeEvery, all, call } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';

import * as announcementsService from '../../services/announcementsService';
import * as commentService from '../../services/commentService';
import {
  fetchAnnouncementsRoutine,
  removeAnnouncementsRoutine,
  commentAnnouncementRoutine
} from 'screens/Announcements/routines';
import { IComment } from 'screens/BaseModels/IComment';

function* fetchAnnouncementsRequest() {
  try {
    const response = yield call(announcementsService.getAllAnnouncements);

    const announcements = response.map(item => ({
      ...item,
      author: {
        id: item.author.id,
        authorName: `${item.author.firstName} ${item.author.lastName}`,
        logoLink: item.author.logoLink
      },
      comments: item.comments.flatMap(comment => [
        {
          id: comment.id,
          time: comment.time,
          text: comment.comment,
          authorId: comment.authorId,
          authorName: comment.authorName,
          authorLogo: comment.authorLogo
        } as IComment
      ])
    }));

    yield put(fetchAnnouncementsRoutine.success(announcements));
  } catch (error) {
    yield put(fetchAnnouncementsRoutine.failure(error.message));
  }
}

function* removeAnnouncementsRequest({ payload }: Routine<any>) {
  try {
    yield call(announcementsService.deleteAnnouncementById, payload);
    yield put(removeAnnouncementsRoutine.success(payload));
  } catch (error) {
    yield put(removeAnnouncementsRoutine.failure(error.message));
  }
}

function* commentAnnouncementRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(commentService.addComment, payload);
    const comment: IComment = {
      announcementId: response.announcementId,
      id: response.id,
      time: response.date,
      text: response.text,
      authorId: response.authorId,
      authorName: response.authorName,
      authorLogo: response.authorLogo
    };

    yield put(commentAnnouncementRoutine.success(comment));
  } catch (error) {
    yield put(commentAnnouncementRoutine.failure(error.message));
  }
}

function* watchCommentAnnouncementRequest() {
  yield takeEvery(commentAnnouncementRoutine.TRIGGER, commentAnnouncementRequest);
}

function* watchFetchAnnouncementsRequest() {
  yield takeEvery(fetchAnnouncementsRoutine.TRIGGER, fetchAnnouncementsRequest);
}

function* watchRemoveAnnouncementRequest() {
  yield takeEvery(removeAnnouncementsRoutine.TRIGGER, removeAnnouncementsRequest);
}

export default function* announcementsSaga() {
  yield all([
    watchFetchAnnouncementsRequest(),
    watchRemoveAnnouncementRequest(),
    watchCommentAnnouncementRequest()
  ]);
}

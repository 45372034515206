import { callFeedbackApi } from 'helpers/webApiHelper';

export const getBlocks = async formId => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/blocks',
    type: 'GET',
    queryParams: { formId }
  });
  return response.json();
};
export const getBlock = async id => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/blocks/block',
    type: 'GET',
    queryParams: { id }
  });
  return response.json().then(res => res.data);
};
export const reorderBlocks = async block => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/blocks/reorder',
    type: 'PUT',
    requestData: block
  });

  return response.json();
};

export const createBlock = async block => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/blocks',
    type: 'POST',
    requestData: block
  });

  return response.json();
};

export const updateBlock = async block => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/blocks',
    type: 'PUT',
    requestData: block
  });

  return response.json();
};

export const deleteBlock = async id => {
  await callFeedbackApi({
    endpoint: '/api/feedback/blocks',
    type: 'DELETE',
    queryParams: { blockId: id }
  });
};

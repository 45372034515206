import { takeEvery, put, call, all } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import * as authService from 'screens/Authorization/services/authService';
import { history } from 'helpers/history';
import { registerRoutine, confirmEmailRoutine, fetchUserRoutine } from 'screens/Authorization/routines';

function* registerRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(authService.register, payload);

    yield put(registerRoutine.success(response));

    history.push('/register-unconfirmed');
  } catch (error) {
    yield put(registerRoutine.failure(error.message));
  }
}

function* confirmEmail({ payload }: Routine<any>) {
  try {
    const response = yield call(authService.confirmEmail, payload);

    yield put(confirmEmailRoutine.success(response));

    yield put(fetchUserRoutine.trigger());
    history.push('');
  } catch (error) {
    yield put(registerRoutine.failure(error.message));
  }
}

function* watchRegisterRequest() {
  yield takeEvery(registerRoutine.TRIGGER, registerRequest);
}

function* watchConfirmEmail() {
  yield takeEvery(confirmEmailRoutine.TRIGGER, confirmEmail);
}

export default function* userSaga() {
  yield all([
    watchConfirmEmail(),
    watchRegisterRequest()
  ]);
}

import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';

import 'react-dates/lib/css/_datepicker.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './styles/reset.scss';
import './styles/common.scss';
import './styles/_variables.scss';
import './styles/components/main.scss';
import App from './containers/App';

ReactDOM.render(<App />, document.getElementById('root'));

import React from 'react';
import { Checkbox, Table, Loader } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import { IBindingCallback1 } from 'models/callback';
import { ISelectOption } from '../../../../models/autocomplete/ISelectOption';
import { IObjectiveFilter } from '../../models/objective/IObjectiveFilter';
import { NoDataContainer } from 'components/NoDataContainer';
import LoaderWrapper from 'components/LoaderWrapper';
import styles from './styles.module.scss';

export interface IOkrGridItemProps {
  newSelected: string[];
  alreadySelected: string[];
  onSelect: IBindingCallback1<string>;
  hasMore: boolean;
  objectives: ISelectOption[];
  filter: IObjectiveFilter;
  isLoaded: boolean;
  getObjectives: (IObjectiveFilter, boolean?) => void;
}

const ObjectiveTable: React.FunctionComponent<IOkrGridItemProps> = ({
  newSelected,
  onSelect,
  alreadySelected,
  objectives,
  hasMore,
  getObjectives,
  filter,
  isLoaded
}) => {
  const isAlreadySelected = id => alreadySelected.some(obj => obj === id);
  const isSelected = id => newSelected.includes(id);
  const filteredObjectivesHasUpdates = objectives.filter(({ key }) => !isAlreadySelected(key)).length !== 0;

  return (
    <div className={styles.table}>
      <LoaderWrapper loading={!isLoaded}>
        <InfiniteScroll
          pageStart={0}
          loader={<Loader style={{ padding: '20px' }} key={0} active inline="centered" />}
          loadMore={() => (hasMore ? getObjectives({ ...filter }, true) : null)}
          hasMore={hasMore}
          useWindow={false}
        >
          {!objectives.length || (!filteredObjectivesHasUpdates && Boolean(objectives.length))
            ? (
              <div className={styles.noDataContainer}>
                <NoDataContainer
                  text="There are no objectives to select, may you add a new one?)"
                  imageWidth="50%"
                />
              </div>
            )
            : null }
          {(objectives.length && filteredObjectivesHasUpdates) ? (
            <Table size="large">
              <Table.Body>
                {objectives.map(({ key, label }) => !isAlreadySelected(key) && (
                  <Table.Row
                    key={key}
                    warning={isSelected(key)}
                    onClick={() => onSelect(key)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Table.Cell verticalAlign="middle">
                      <Checkbox
                        checked={isSelected(key)}
                        label={label}
                        onChange={() => onSelect(key)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : ''}
        </InfiniteScroll>
      </LoaderWrapper>
    </div>
  );
};

export default ObjectiveTable;

import React from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { fetchUserReviewsRoutine } from 'screens/UserProfile/routines';
import { IBindingCallback1 } from 'models/callback';
import { IUserReviews } from 'screens/UserProfile/models/reviews/IUserReviews';
import ReviewTab from '../tabs/reviewsTab';
import LoaderWrapper from 'components/LoaderWrapper';
import { NoDataContainer } from 'components/NoDataContainer';
import SidebarWrapper from 'components/Sidebar';
import KeyResultDataSidebar from 'screens/OKR/containers/KeyResultDataSidebar';

interface IIReviewsProps {
  fetchReviews: IBindingCallback1<string>;
  userId: string;
  loading: boolean;
  reviews: IUserReviews[];
}

interface IUsersOkrsPageState {
  selectedKeyResult: string;
}

class Reviews extends React.Component<IIReviewsProps, IUsersOkrsPageState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeyResult: ''
    };
  }

  componentDidMount() {
    const { fetchReviews, userId } = this.props;
    fetchReviews(userId);
  }

  selectKeyResult = id => this.setState({ selectedKeyResult: id });

  render() {
    const { reviews, loading } = this.props;
    const { selectedKeyResult } = this.state;
    return (
      <div className={styles.loaderWrapper}>
        <LoaderWrapper loading={loading}>
          <SidebarWrapper onClose={() => this.selectKeyResult(null)} isOpened={Boolean(selectedKeyResult)}>
            <KeyResultDataSidebar keyResultId={selectedKeyResult} />
          </SidebarWrapper>
          {reviews.length ? (
            <div className={styles.container}>
              <ReviewTab selectKeyResult={this.selectKeyResult} reviews={reviews} />
            </div>
          ) : (
            <div className={styles.noDataContainer}>
              <NoDataContainer
                text="This user has not commented on anything"
              />
            </div>
          )}
        </LoaderWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { userProfile: { userInfo, requests } } = state;
  const { reviews, userInfo: { id } } = userInfo;
  const { userReviews: { loading } } = requests;

  return {
    userId: id,
    reviews,
    loading
  };
};

const mapDispatchToProps = {
  fetchReviews: fetchUserReviewsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);

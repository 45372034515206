import React from 'react';
import { IBindingAction } from 'models/callback';
import styles from './styles.module.scss';
import ConfirmModal from 'components/ConfirmModal';
import { Button } from 'semantic-ui-react';

interface IOKRDeleteModalProps {
  onConfirm: IBindingAction;
}

const OKRDeleteModal: React.FunctionComponent<IOKRDeleteModalProps> = ({ onConfirm }: IOKRDeleteModalProps) => (
  <ConfirmModal
    header="Would you like to delete OKR?"
    description="This action cannot be undone"
    onConfirm={onConfirm}
  >
    <Button className={styles.deleteButton} content="Delete OKR" />
  </ConfirmModal>
);

export default OKRDeleteModal;

import { callFeedbackApi } from '../../../helpers/webApiHelper';
import { IForm } from '../model/Forms/IForm';

export const getForms = async () => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/forms',
    type: 'GET'
  });
  return response.json();
};

export const getTemplates = async id => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/forms/templates',
    type: 'GET',
    queryParams: { id }
  });
  return response.json();
};
export const getForm = async id => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/forms/form',
    type: 'GET',
    queryParams: { id }
  });
  return response.json().then(res => res.data);
};

export const saveForm = async (form: IForm) => {
  console.log('form');
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/forms',
    type: 'POST',
    requestData: form
  });
  return response.json();
};

export const saveTemplate = async (form: IForm) => {
  console.log('template');
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/forms/template',
    type: 'POST',
    requestData: form
  });
  return response.json();
};

export const updateForm = async (form: IForm) => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/forms',
    type: 'PUT',
    requestData: form
  });
  return response.json();
};

export const deleteForm = async id => {
  await callFeedbackApi({
    endpoint: '/api/feedback/forms',
    type: 'DELETE',
    queryParams: { id }
  });
};

import React from 'react';
import { Popup, Button } from 'semantic-ui-react';
import styles from './styles.module.scss';
import itemStyles from '../../../components/KeyResultItem/styles.module.scss';
import { getComplexityColors } from 'screens/OKR/helpers/complexityColorHelper';
import { DateType } from '../AdminDashboard';
import { IBindingCallback1 } from 'models/callback';

interface IAdminDashboadrTopTableData {
  keyResults?: boolean;
  loadResults: IBindingCallback1<DateType>;
  data: any[];
  activeItem: DateType;
  elementsCount: number;
}

const AdminDashboadrTopTable = (
  {
    keyResults = false,
    loadResults,
    data,
    activeItem,
    elementsCount
  }: IAdminDashboadrTopTableData
) => (
  <>
    <div className={styles.header}>
      <div className={styles.contentWrapper}>
        {`Top 5 ${keyResults ? 'key results' : 'objectives'}`}
        <div>
          <Button
            basic
            size="mini"
            style={{ margin: '10px' }}
            onClick={() => { loadResults(DateType.year); }}
            active={activeItem === DateType.year}
            content="Year"
          />
          <Button
            basic
            size="mini"
            onClick={() => { loadResults(DateType.month); }}
            active={activeItem === DateType.month}
            content="Month"
          />
        </div>
      </div>
    </div>
    <div className={styles.table}>
      <div className={styles.tableHeader}>
        {keyResults ? (
          <>
            <div className={styles.name}>Name</div>
            <div className={styles.second}>
              <div className={styles.complexity}>Complexity</div>
              <div className={styles.type}>Type</div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.name}>Name</div>
            <div className={styles.occurrence}>Occurrence</div>
          </>
        )}
      </div>
      <div className={styles.tableBody}>
        {[...Array(elementsCount).keys()].map(index => {
          const item = data[index];

          return (
            <div className={styles.tableRow} key={index} style={{ height: '45px' }}>
              {item ? (
                <>
                  <div className={styles.name}>
                    {item.name}
                  </div>
                  {keyResults ? (
                    <div className={styles.second}>
                      <div className={styles.complexity}>
                        <Popup
                          position="bottom center"
                          content={item.complexity}
                          trigger={(
                            <div
                              className={itemStyles.complexity}
                              style={{ ...getComplexityColors(item.complexity), width: '18px' }}
                            />
                              )}
                        />
                      </div>
                      <div className={styles.type}>
                        {item.keyResultType}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.occurrence}>
                      {item.occurrence}
                    </div>
                  )}
                </>
              ) : <div />}
            </div>
          );
        })}
      </div>
    </div>
  </>
);

export default AdminDashboadrTopTable;

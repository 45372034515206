import { all } from 'redux-saga/effects';
import adminPageSagas from '../containers/Users/sagas';
import loadFileSagas from '../components/fileActionButtons/sagas';

export default function* adminSagas() {
  yield all([
    loadFileSagas(),
    adminPageSagas()
  ]);
}

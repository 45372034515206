import { takeEvery, put, call, all } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import { IImageData } from 'screens/UserProfile/models/IImageData';
import moment from 'moment';
import {
  fetchUserInfoRoutine,
  updateUserInfoRoutine,
  updateUserPasswordRoutine,
  updateAvatarRoutine,
  fetchUserOKRsRoutine,
  fetchUserReviewsRoutine,
  fetchUserFeedbackListRoutine
} from '../../routines';
import * as profileService from '../../services/profile.service';
// import * as projectsService from '../../services/projects.service';
import * as okrService from '../../services/okr.service';
import { toastr } from 'react-redux-toastr';
import { IUserOkrStats } from '../../../OKR/models/okr/IUserOkrStats';
import { IUserFeedbackSummary } from '../../../Feedback/model/IUserFeedbackSummary';
import * as feedbackService from '../../services/feedback.service';

function* userInfoRequest({ payload }: Routine<any>): Routine<any> {
  try {
    // const [userInfo, projects] = yield all([
    //   call(profileService.getUserInfo, payload),
    //   call(projectsService.loadUserProjects, payload)
    // ]);
    const userInfo = yield call(profileService.getUserInfo, payload);
    yield put(fetchUserInfoRoutine.success({ userInfo }));
  } catch (error) {
    yield put(fetchUserInfoRoutine.failure(error.message));
  }
}

function* userUpdateRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(profileService.editUserInfo, payload);
    yield put(updateUserInfoRoutine.success({
      payload,
      response
    }));
  } catch (error) {
    yield put(updateUserInfoRoutine.failure(error.message));
  }
}

function* updatePasswordRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(profileService.updatePasswordRequest, payload);
    yield put(updateUserPasswordRoutine.success({
      payload,
      response
    }));
    toastr.success('Success', 'Password was successfully changed');
  } catch (error) {
    yield put(updateUserPasswordRoutine.failure(error.message));
  }
}

function* updateAvatar({ payload }: Routine<any>) {
  try {
    const response: IImageData = yield call(profileService.uploadAvatar, payload);
    yield put(updateAvatarRoutine.success({ avatarPreviewPath: response.previewLink }));
  } catch (error) {
    yield put(updateAvatarRoutine.failure(error.message));
  }
}

function* fetchOKRs({ payload }: Routine<any>) {
  try {
    const response: IUserOkrStats[] = yield call(okrService.getOkrs, payload);

    const filteredOkrs = response.sort((firstOkr, secondOkr) => {
      if (firstOkr.isClosed === secondOkr.isClosed) {
        return moment(firstOkr.startTime).diff(secondOkr.startTime) > 0 ? -1 : 1;
      }

      return firstOkr.isClosed ? 1 : -1;
    });

    yield put(fetchUserOKRsRoutine.success(filteredOkrs));
  } catch (error) {
    yield put(fetchUserOKRsRoutine.failure(error.message));
  }
}

function* fetchUserReviews({ payload }: Routine<any>) {
  try {
    const response = yield call(profileService.getUserReviews, payload);

    const filteredOkrs = response.sort((firstOkr, secondOkr) => {
      if (firstOkr.isClosed === secondOkr.isClosed) {
        return moment(firstOkr.startTime).diff(secondOkr.startTime) > 0 ? -1 : 1;
      }

      return firstOkr.isClosed ? 1 : -1;
    });

    yield put(fetchUserReviewsRoutine.success(filteredOkrs));
  } catch (error) {
    yield put(fetchUserReviewsRoutine.failure(error.message));
  }
}

function* fetchFeedbackList({ payload }: Routine<any>) {
  try {
    const response: IUserFeedbackSummary[] = yield call(
      feedbackService.getFeedbackList, payload
    );
    yield put(fetchUserFeedbackListRoutine.success(response));
  } catch (error) {
    yield put(fetchUserFeedbackListRoutine.failure(error.message));
  }
}

function* watchUserInfoRequest() {
  yield takeEvery(fetchUserInfoRoutine.TRIGGER, userInfoRequest);
}

function* watchUserUpdateRequest() {
  yield takeEvery(updateUserInfoRoutine.TRIGGER, userUpdateRequest);
}

function* watchUpdatePasswordRequest() {
  yield takeEvery(updateUserPasswordRoutine.TRIGGER, updatePasswordRequest);
}

function* watchUpdateAvatarRequest() {
  yield takeEvery(updateAvatarRoutine.TRIGGER, updateAvatar);
}

function* watchUserOKRsStatsRequest() {
  yield takeEvery(fetchUserOKRsRoutine.TRIGGER, fetchOKRs);
}

function* watchUserReviewsRequest() {
  yield takeEvery(fetchUserReviewsRoutine.TRIGGER, fetchUserReviews);
}

function* watchUserFeedbackListRequest() {
  yield takeEvery(fetchUserFeedbackListRoutine.TRIGGER, fetchFeedbackList);
}

export default function* userProfileSagas() {
  yield all([
    watchUserInfoRequest(),
    watchUserUpdateRequest(),
    watchUpdatePasswordRequest(),
    watchUpdateAvatarRequest(),
    watchUserOKRsStatsRequest(),
    watchUserReviewsRequest(),
    watchUserFeedbackListRequest()
  ]);
}

import { callNotificationsApi } from 'helpers/webApiHelper';
import { IPostComment } from '../models/comment/postComment';

export const addNewComment = async (payload: IPostComment) => {
  const response = await callNotificationsApi({
    endpoint: '/api/news/comment',
    type: 'POST',
    requestData: { ...payload }
  });
  return response.json();
};

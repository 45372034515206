import React from 'react';
import { Header, Table, Divider, Progress, Grid, Label } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { IQuestion } from '../../model/Questions/IQuestion';
import { IFeedbackAnswer } from '../../model/Questions/IFeedbackAnswer';
import { IQuestionAnsweByBlock } from '../../model/Questions/IQuestionAnsweByBlock';
import TotalChart from '../TotalChart';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface IFeedbackHeatMapProps {
  currentBlock: IQuestionAnsweByBlock;
  isMentee: boolean;
}

const FeedbackHeatMap: React.FunctionComponent<IFeedbackHeatMapProps> = (
  { currentBlock, isMentee }: IFeedbackHeatMapProps
) => {
  const chooseColor = (option: number) => {
    switch (option) {
      case 1: return styles.cellOne;
      case 2: return styles.cellTwo;
      case 3: return styles.cellThree;
      case 4: return styles.cellFour;
      default: return styles.cellZero;
    }
  };
  const chooseColorForProgress = (option: number) => {
    switch (option) {
      case 1: return '#d97c6e';
      case 2: return '#e4b7b0';
      case 3: return '#daead4';
      case 4: return '#b8d7ab';
      default: return '#fef2ce';
    }
  };

  const chooseAnswer = (isNegative: boolean, option: number) => {
    if (isNegative) {
      switch (option) {
        case 1: return 'strongly agree';
        case 2: return 'agree';
        case 3: return 'disagree';
        case 4: return 'strongly disagree';
        default: return 'don`t know';
      }
    } else {
      switch (option) {
        case 4: return 'strongly agree';
        case 3: return 'agree';
        case 2: return 'disagree';
        case 1: return 'strongly disagree';
        default: return 'don`t know';
      }
    }
  };
  const chooseOption = (isNegative: boolean, option: string): number => {
    if (isNegative) {
      switch (option) {
        case 'strongly agree': return 1;
        case 'agree': return 2;
        case 'disagree': return 3;
        case 'strongly disagree': return 4;
        default: return 0;
      }
    } else {
      switch (option) {
        case 'strongly agree': return 4;
        case 'agree': return 3;
        case 'disagree': return 2;
        case 'strongly disagree': return 1;
        default: return 0;
      }
    }
  };
  const avgByAnswer = (withZero: boolean, isNegative: boolean, questionId: string, feedbacks: IFeedbackAnswer[]) => {
    const sumOptions = feedbacks.map(fb => fb.answers)
      .map(answers => chooseOption(isNegative, answers
        .find(a => a.questionId === questionId).answer.toLowerCase())).reduce((a, b) => a + b, 0);
    if (withZero) return sumOptions / feedbacks.length;
    const feedbacksWithoutZero = feedbacks.map(fb => fb?.answers)
      .map(answers => chooseOption(isNegative, answers.find(a => a.questionId === questionId).answer.toLowerCase()))
      .filter(option => option !== 0).length;
    return sumOptions / feedbacksWithoutZero;
  };

  const avgByBlock = (withZero: boolean, questions: IQuestion[], feedbacks: IFeedbackAnswer[]) => (
    questions.map(q => avgByAnswer(withZero, q.negative, q.id, feedbacks))
      .reduce((a, b) => a + b, 0) / questions.length
  );

  const dontKnowByAnswer = (isNegative: boolean, questionId: string, feedbacks: IFeedbackAnswer[]) => {
    const sumDontKnow = feedbacks.map(fb => fb.answers)
      .map(answers => chooseOption(isNegative, answers.find(a => a.questionId === questionId).answer.toLowerCase()))
      .filter(option => option === 0).length;
    return sumDontKnow;
  };
  const getDataforChart = withDontKnow => (
    currentBlock.questions.map(q => ({
      question: q.text,
      avg: Number(avgByAnswer(withDontKnow, q.negative, q.id, currentBlock.feedbacks).toFixed(2))
    })));

  return (
    <div className={styles.total}>
      <h3>Answers</h3>
      <div className={styles.table}>
        <Table celled definition size="small" textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={styles.column} />
              {currentBlock.questions.map(qa => (
                <Table.HeaderCell className={styles.column} key={qa.id} content={`${qa.text}`} />
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {currentBlock.feedbacks.map(fb => (
              <Table.Row>
                <Table.Cell className={styles.cellTable}>
                  <Header as="h5" content={`${fb.authorName} ${fb.authorType}`} />
                </Table.Cell>
                {fb.answers.filter(a => a.answer !== undefined).map(a => (
                  <Table.Cell className={styles.cellTable}>
                    <div className={styles.cell}>
                      <Label
                        className={chooseColor(chooseOption(currentBlock.questions.find(q => q.id === a.questionId)
                          .negative, a.answer.toLowerCase()))}
                      >
                        {a.answer.toLowerCase()}
                      </Label>
                    </div>
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className={styles.totalInfo}>
        <h3>Total information</h3>
        <Grid columns="equal" textAlign="center">
          <Grid.Row>
            <Grid.Column>
              Total with Dont know:
            </Grid.Column>
            <Grid.Column>
              Total without Dont know:
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className={styles.progressColumn}>
              <div className={styles.progress}>
                <CircularProgressbar
                  value={avgByBlock(false, currentBlock.questions, currentBlock.feedbacks)}
                  maxValue={4}
                  text={`${avgByBlock(false, currentBlock.questions, currentBlock.feedbacks).toFixed(2)}/5`}
                  styles={buildStyles({
                    pathColor: chooseColorForProgress(Math.round(avgByBlock(false, currentBlock.questions,
                      currentBlock.feedbacks))),
                    textColor: '#93a1a9',
                    trailColor: '#d6d6d6'
                  })}
                />
              </div>
            </Grid.Column>
            <Grid.Column className={styles.progressColumn}>
              <div className={styles.progress}>
                <CircularProgressbar
                  value={avgByBlock(true, currentBlock.questions, currentBlock.feedbacks)}
                  maxValue={4}
                  text={`${Number(avgByBlock(true, currentBlock.questions, currentBlock.feedbacks).toFixed(2))}/4`}
                  styles={buildStyles({
                    pathColor: chooseColorForProgress(Math.round(avgByBlock(true, currentBlock.questions,
                      currentBlock.feedbacks))),
                    textColor: '#93a1a9',
                    trailColor: '#d6d6d6'
                  })}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className={styles.chart}>
              <TotalChart dataChart={getDataforChart(true)} title="Avg with Don't know" />
            </Grid.Column>
            <Grid.Column className={styles.chart}>
              <TotalChart dataChart={getDataforChart(false)} title="Avg without Don't know" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default FeedbackHeatMap;

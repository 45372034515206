import { createRoutine } from 'redux-saga-routines';

export const fetchUsersRoutine = createRoutine('FETCH_USERS');
export const fetchUserOkrsRoutine = createRoutine('FETCH_USER_OKRS_DATA');
export const fetchOkrRoutine = createRoutine('FETCH_OKR_DATA');
export const clearUsersOkrsPageRoutine = createRoutine('OKRS_USERS_CLEAR_PAGE');

export const saveOkrRoutine = createRoutine('SAVE_OKR:USERS_OKR');

export const addObjectiveToOkr = createRoutine('ADD_OBJECTIVE::USERS_OKR');
export const addNoteToOkr = createRoutine('ADD_NOTE::USERS_OKR');

import { all } from 'redux-saga/effects';

import okrsSaga from '../containers/OkrsGridView/sagas';
import extendedOkrSagas from '../containers/OkrView/sagas';
import keyResultRatingSagas from '../containers/AddRatingForm/sagas';
import extendedKeyResultSagas from '../containers/KeyResultDataSidebar/sagas';
import addToMyOkrSagas from '../containers/AddToMyOkrForm/sagas';
import adminDashboardSagas from '../containers/AdminDashboard/AdminDashboard/sagas';
import suggestionsSagas from '../containers/SuggestAdditionForm/sagas';

export default function* rootSaga() {
  yield all([
    okrsSaga(),
    extendedOkrSagas(),
    extendedKeyResultSagas(),
    keyResultRatingSagas(),
    addToMyOkrSagas(),
    adminDashboardSagas(),
    suggestionsSagas()
  ]);
}

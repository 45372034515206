import { Routine } from 'redux-saga-routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as questionService from '../../services/questionService';
import { updateQuestionRoutine, createQuestionRoutine,
  fetchQuestionsRoutine, deleteQuestionRoutine } from '../../routines';

function* getQuestions({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(questionService.getQuestions, payload);

    yield put(fetchQuestionsRoutine.fulfill(res));
  } catch (error) {
    yield put(fetchQuestionsRoutine.failure(error));
  }
}

function* createQuestion({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(questionService.createQuestion, payload);
    yield put(createQuestionRoutine.fulfill(res));
  } catch (error) {
    yield put(createQuestionRoutine.failure(error));
  }
}

function* updateQuestion({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(questionService.createQuestion, payload);
    yield put(updateQuestionRoutine.fulfill(res));
  } catch (error) {
    yield put(updateQuestionRoutine.failure(error));
  }
}

function* deleteQuestion({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(questionService.deleteQuestion, payload);
    yield put(deleteQuestionRoutine.success(payload));
  } catch (error) {
    yield put(deleteQuestionRoutine.failure(error));
  }
}

function* watchGetQuestions() {
  yield takeEvery(fetchQuestionsRoutine.TRIGGER, getQuestions);
}

function* watchCreateQuestion() {
  yield takeEvery(createQuestionRoutine.TRIGGER, createQuestion);
}

function* watchUpdateQuestion() {
  yield takeEvery(updateQuestionRoutine.TRIGGER, updateQuestion);
}

function* watchDeleteQuestion() {
  yield takeEvery(deleteQuestionRoutine.TRIGGER, deleteQuestion);
}
export default function* questionsSagas() {
  yield all([
    watchCreateQuestion(),
    watchGetQuestions(),
    watchUpdateQuestion(),
    watchDeleteQuestion()
  ]);
}

import { all } from 'redux-saga/effects';
import keyResultsSagas from '../containers/KeyResultsAdminPage/sagas';
import objectivesSagas from '../containers/ObjectivesAdminPage/sagas';

export default function* rootSaga() {
  yield all([
    objectivesSagas(),
    keyResultsSagas()
  ]);
}

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { authService } from '../screens/Authorization/services/authService';

export const defaultTitle = 'My | BinaryStudio';

const PrivateRoute = ({ component: Component, title = defaultTitle, roles = null, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = authService.tokenValue;
      if (!token) {
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }
      if (rest.currentUser && roles && !roles.some(value => rest.currentUser.roles.includes(value))) {
        return <Redirect to={{ pathname: '/404' }} />;
      }
      document.title = title;
      return <Component {...props} />;
    }}
  />
);

const mapToStateProps = state => ({
  currentUser: state.user.user
});

export default connect(mapToStateProps)(PrivateRoute);

import React from 'react';
import { Button, Form, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { IBindingCallback1, IBindingAction } from 'models/callback/Callbacks';
import { connect } from 'react-redux';
import { resetPasswordRoutine } from 'screens/Authorization/routines';
import styles from '../../containers/LoginPage/styles.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail is not valid!')
    .required('E-mail is required!')
});

export interface IForgetPasswordFormProps {
  resetPassword: IBindingCallback1<string>;
  isLoading: boolean;
  toggleLogin: IBindingAction;
}

export interface IForgetPasswordFormikProps {
  email: string;
}

const ForgetPasswordForm: React.FunctionComponent<IForgetPasswordFormProps> = ({
  resetPassword,
  isLoading,
  toggleLogin
}: IForgetPasswordFormProps) => (
  <Formik
    initialValues={{
      email: ''
    }}
    onSubmit={(values, { setSubmitting }) => {
      resetPassword(values.email);
      setSubmitting(false);
    }}
    validationSchema={validationSchema}
  >
    {({ values, errors, touched, handleSubmit, handleChange }: FormikProps<IForgetPasswordFormikProps>) => (
      <Form onSubmit={handleSubmit}>
        <Popup
          open={!!(touched.email && errors.email)}
          content={errors.email}
          on={[]}
          trigger={(
            <Form.Input
              fluid
              onChange={handleChange}
              name="email"
              placeholder="Email address"
              error={errors.email && touched.email}
            />
          )}
          inverted
          size="tiny"
          flowing
          pinned
          hideOnScroll
        />
        <div className={styles.actions}>
          <div className={styles.textWrapper}>
            <div className={styles.text} onClick={toggleLogin} role="presentation">
              Back to login
            </div>
          </div>
          <Button
            primary
            type="submit"
            disabled={!values.email || isLoading}
            content="Send password reset email"
          />
        </div>
      </Form>
    )}
  </Formik>
);

const mapStateToProps = state => {
  const { auth: { requests: { resetPassword } } } = state;

  return {
    isLoading: resetPassword.loading
  };
};

const mapDispatchToProps = {
  resetPassword: resetPasswordRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordForm);

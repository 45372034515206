import React from 'react';
import styles from './styles.module.scss';
import { getUserLogoIfExists } from 'helpers/fileUrl.helper';
import ImageWrapper from 'components/ImageWrapper';

interface IAvatarProps {
  name: string;
  width?: string;
  height?: string;
  logoLink?: string;
  fontSize?: string;
}

const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];

const numberFromText = (text: string) => text
  .split('')
  .map(char => char.charCodeAt(0))
  .join('');

const generateBackgroundColor = (letters: string) => {
  const colorNumber = Number(numberFromText(letters)) % 7;
  if (colorNumber >= 0 && colorNumber <= 7) {
    return colors[colorNumber];
  }
  return '#fff';
};

const Avatar: React.FunctionComponent<IAvatarProps> = ({
  name,
  height = '30px',
  width = '30px',
  logoLink,
  fontSize = '1em'
}: IAvatarProps) => {
  if (!logoLink) {
    const letter = name[0];
    const background = generateBackgroundColor(name);
    return (
      <div className={styles.avatar} style={{ width, height, background, fontSize }}>
        <p className={styles.name}>{letter.toUpperCase()}</p>
      </div>
    );
  }
  return (
    <ImageWrapper
      src={getUserLogoIfExists(logoLink)}
      width={width}
      height={height}
    />
  );
};

export default Avatar;

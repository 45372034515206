import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Loader, ModalContent, Icon, Popup } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { IGlobalState } from 'models/global-state';
import { IUser } from 'screens/OKR/models/user';
import styles from './styles.module.scss';
import { getRatingRoutine, updateRatingRoutine } from '../../routines';
import { IGetRating } from 'screens/OKR/models/rating/IGetRating';
import { IRatingNew } from 'screens/OKR/models/rating';
import Sidebar from 'components/DataSidebar';
import FormWrapper from 'components/FormWrapper';
import { KeyResultType } from 'screens/OKR/enums';

const formInitialValues = { comment: '', isLike: false, isDislike: false };
const requiredType: string[] = ['Book', 'Course', 'Article'];

const commentValidation = (minLength: number) => Yup.string()
  .trim('Please, not only spaces')
  .min(minLength, `Min comment length is ${minLength} characters)`)
  .max(512, 'Max comment length is 512 characters');

const validationSchema = Yup.object().shape({
  comment: commentValidation(2)
});

const requiredValidationSchema = Yup.object().shape({
  comment: commentValidation(10).required('Comment is required!')
});

interface IFormikProps {
  id?: string;
  comment?: string;
  isLike: boolean;
  isDislike: boolean;
}

export interface IAddRatingFormProps {
  keyResultId: string;
  keyResultType: KeyResultType;
  user: IUser;
  isOpened: boolean;
  handleAction: IBindingAction;
  getRating: IBindingCallback1<IGetRating>;
  updateRating: IBindingCallback1<IRatingNew>;
  keyResultRating: IFormikProps;
}

const AddRatingForm: React.FunctionComponent<IAddRatingFormProps> = ({
  getRating,
  keyResultId,
  user: { id: userId },
  handleAction,
  isOpened,
  keyResultRating,
  keyResultType,
  updateRating
}) => {
  useEffect(() => {
    if (userId) {
      getRating({ keyResultId, userId });
    }
  }, []);

  const onSave = (values: IFormikProps) => {
    updateRating({ ...values, userId, keyResultId });
    handleAction();
  };

  const setLike = (setFieldValue, like: boolean) => {
    setFieldValue('isLike', !like);
    setFieldValue('isDislike', false);
  };

  const setDislike = (setFieldValue, disLike: boolean) => {
    setFieldValue('isDislike', !disLike);
    setFieldValue('isLike', false);
  };

  const mapRating = (rating: IFormikProps) => ({ ...rating, comment: rating.comment || '' });

  const initialValues = keyResultRating?.id ? mapRating(keyResultRating) : formInitialValues;
  const isCommentRequired = requiredType.includes(keyResultType);

  return isOpened ? (
    <Sidebar onClose={handleAction}>
      <FormWrapper headerText="Please, leave your feedback!" onClose={handleAction}>
        <ModalContent>
          {!keyResultRating ? (
            <div className={styles.spinnerWrapper}>
              <Loader active inline="centered" size="massive" />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={values => onSave(values)}
              enableReinitialize
              validationSchema={isCommentRequired ? requiredValidationSchema : validationSchema}
            >
              {({
                handleSubmit,
                setFieldValue,
                values: { comment, isLike, isDislike },
                errors,
                touched
              }: FormikProps<IFormikProps>) => (
                <Form onSubmit={handleSubmit}>
                  <Form.TextArea
                    label={errors.comment && touched.comment ? errors.comment : 'Leave comment'}
                    name="comment"
                    placeholder={!isCommentRequired
                      ? 'Write your comment here(optional)'
                      : 'Enter comment (nessesary)'}
                    value={comment || ''}
                    error={Boolean(errors.comment && touched.comment)}
                    onChange={(_, data) => setFieldValue('comment', data.value)}
                  />
                  <div className={`${styles.recommend}`}>
                    <div
                      onClick={() => setLike(setFieldValue, isLike)}
                      role="presentation"
                      className={`${styles.recommend} ${isLike ? styles.liked : ''}`}
                    >
                      <Popup
                        hoverable
                        basic
                        content="I definitely recommend this resource"
                        trigger={<Icon name="thumbs up outline" />}
                      />
                    </div>
                    <div
                      role="presentation"
                      onClick={() => setDislike(setFieldValue, isDislike)}
                      className={`${styles.recommend} ${isDislike ? styles.disLike : null}`}
                    >
                      <Popup
                        className="like-dislike"
                        basic
                        hoverable
                        content="This resource is not worth spending time on it"
                        trigger={<Icon name="thumbs down outline" />}
                      />
                    </div>
                  </div>
                  <div className={styles.formButtons}>
                    <div>
                      <Button className={styles.skipButton} type="button" onClick={handleAction} content="Skip" />
                      <Button
                        primary
                        type="submit"
                        disabled={!comment?.length && !isLike && !isDislike}
                        content="Save"
                        className="submitButtom"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </ModalContent>
      </FormWrapper>
    </Sidebar>
  ) : null;
};

const mapStateToProps = (state: IGlobalState) => {
  const { user: { user }, okr: { keyResultRating } } = state;

  return {
    user,
    keyResultRating
  };
};

const mapDispatchToProps = {
  updateRating: updateRatingRoutine,
  getRating: getRatingRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRatingForm);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IForm } from 'screens/Feedback/model/Forms/IForm';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { Icon } from 'semantic-ui-react';

interface IFormInListProps {
  form: IForm;
  isSelected: boolean;
  onClickHandler: (formId: string) => void;
  onDeleteHandler: (formId: string) => void;
}

export const FormInList: React.FunctionComponent<IFormInListProps> = ({
  form,
  isSelected,
  onClickHandler,
  onDeleteHandler
}: IFormInListProps) => (
  <div
    className={`${styles.formInList} ${isSelected ? styles.selected : ''}`}
  >
    <div className={`${styles.formContainer} ${isSelected ? styles.selected : ''}`}>
      <div className={styles.spanWrapper} onClick={() => onClickHandler(form.id)}>
        <span>{form.name}</span>
      </div>
      <Icon
        name="trash alternate"
        onClick={() => onDeleteHandler(form.id)}
      />
    </div>
  </div>
);

export default connect()(FormInList);

import { Routine } from 'redux-saga-routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as summaryService from '../../services/summaryService';
import { createSummaryRoutine, updateSummaryRoutine, fetchSummaryRoutine } from '../../routines';

function* createSummary({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(summaryService.createSummary, payload);
    yield put(createSummaryRoutine.success(res));
  } catch (error) {
    yield put(createSummaryRoutine.failure(error));
  }
}
function* updateSummary({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(summaryService.updateSummary, payload);
    yield put(createSummaryRoutine.success(res));
  } catch (error) {
    yield put(createSummaryRoutine.failure(error));
  }
}
function* fetchSummary({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(summaryService.getSummary, payload);
    yield put(createSummaryRoutine.success(res));
  } catch (error) {
    yield put(createSummaryRoutine.failure(error));
  }
}
function* watchFetchSummary() {
  yield takeEvery(fetchSummaryRoutine.TRIGGER, fetchSummary);
}
function* watchCreateSummary() {
  yield takeEvery(createSummaryRoutine.TRIGGER, createSummary);
}
function* watchUpdateSummary() {
  yield takeEvery(updateSummaryRoutine.TRIGGER, updateSummary);
}

export default function* saveSummarySagas() {
  yield all([
    watchCreateSummary(),
    watchUpdateSummary(),
    watchFetchSummary()
  ]);
}

import React, { useState, useEffect } from 'react';
import ItemMerge from './components/ItemMerge';
import SideBar from 'components/Sidebar';
import { Button, Icon, CheckboxProps, Table } from 'semantic-ui-react';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { IFormEvent } from 'models/events';
import { IMergeAction } from 'screens/Library/model';
import { getKeyResultsMergeIds } from './helpers';
import styles from './styles.module.scss';

const MIN_KEY_RESULT_COUNT = 2;
const WARN_MESSAGE = `Dude, you need at least ${MIN_KEY_RESULT_COUNT} key results to merge them. Think about it...`;
const TOOLTIP_MESSAGE = 'Please mark the subject, which will be the original for each.';

interface IProps {
  keyResults: IKeyResult[];
  isOpened: boolean;
  mergeKeyResults: IBindingCallback1<IMergeAction>;
  onClose: IBindingAction;
}

const SidebarMerge = ({ keyResults, isOpened, mergeKeyResults, onClose }: IProps) => {
  const [checkedId, changeCheckedId] = useState<null | string>(null);

  useEffect(() => {
    changeCheckedId(null);
  }, [isOpened]);

  const onChangeCheckedId = (
    _evt: IFormEvent<HTMLInputElement>,
    checkboxProps: CheckboxProps
  ) => {
    changeCheckedId(checkboxProps.value as string);
  };

  const onSubmit = (evt: IFormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const keyResultsMergeIds = getKeyResultsMergeIds(checkedId, keyResults);

    mergeKeyResults({
      originId: checkedId,
      keyResultIds: keyResultsMergeIds
    });

    onClose();
  };

  return (
    <SideBar onClose={onClose} isOpened={isOpened} width="850px">
      <section>
        <h2 className={styles.title}>Merge Key Results</h2>
        {keyResults.length >= MIN_KEY_RESULT_COUNT ? (
          <>
            <p className={styles.toolTip}>
              <Icon className={styles.toolTipIcon} name="lightbulb" />
              {TOOLTIP_MESSAGE}
            </p>
            <form className={styles.form} onSubmit={onSubmit}>
              <Table className={styles.mergeTable}>
                <Table.Header className={styles.mergeTableHeader}>
                  <Table.Row>
                    <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Details</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Tags</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {keyResults.map(it => (
                    <ItemMerge
                      keyResult={it}
                      checkedId={checkedId}
                      onChange={onChangeCheckedId}
                      key={it.id}
                    />
                  ))}
                </Table.Body>
              </Table>
              {checkedId && <Button primary type="submit" content="Merge" />}
            </form>
          </>
        ) : (
          <p className={styles.warnMessage}>
            <Icon className={styles.toolTipIcon} name="lightbulb" />
            {WARN_MESSAGE}
          </p>
        )}
      </section>
    </SideBar>
  );
};

export default SidebarMerge;

import { put, takeEvery, all, call } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import * as announcementsService from '../../services/announcementsService';
import {
  saveAnnouncementsRoutine,
  fetchAnnouncementByIdRoutine
} from 'screens/Announcements/routines';

function* announcementForUpdateRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(
      announcementsService.getAllAnnouncementById,
      payload
    );
    const announcement = {
      ...response,
      comments: response.comments.flatMap(comment => [
        {
          id: comment.id,
          time: comment.time,
          text: comment.comment,
          author: {
            id: comment.author.id,
            authorName: `${comment.author.firstName} ${comment.author.lastName}`,
            logoLink: comment.author.logoLink
          }
        }
      ]),
      author: {
        id: response.author.id,
        authorName: `${response.author.firstName} ${response.author.lastName}`,
        logoLink: response.author.logoLink
      }
    };
    yield put(fetchAnnouncementByIdRoutine.success(announcement));
  } catch (error) {
    yield put(fetchAnnouncementByIdRoutine.failure(error.message));
  }
}

function* announcementSaveRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(announcementsService.saveAnnouncement, payload);
    const announcement = {
      ...response,
      author: {
        id: response.author.id,
        authorName: `${response.author.firstName} ${response.author.lastName}`,
        logoLink: response.author.logoLink
      }
    };

    yield put(saveAnnouncementsRoutine.success(announcement));
  } catch (error) {
    yield put(saveAnnouncementsRoutine.failure(error.message));
  }
}

function* watchAnnouncementForUpdateRequest() {
  yield takeEvery(
    fetchAnnouncementByIdRoutine.TRIGGER,
    announcementForUpdateRequest
  );
}

function* watchAnnouncementSaveRequest() {
  yield takeEvery(saveAnnouncementsRoutine.TRIGGER, announcementSaveRequest);
}

export default function* updateAnnouncementSagas() {
  yield all([
    watchAnnouncementForUpdateRequest(),
    watchAnnouncementSaveRequest()
  ]);
}

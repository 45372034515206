import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IUser } from 'screens/Authorization/models/IUser';
import { IUserInfo } from 'screens/UserProfile/models/IUserInfo';
import { IBindingCallback1 } from 'models/callback';
import { Loader, Icon, Header, Modal, Image } from 'semantic-ui-react';
import { cropImage } from 'screens/UserProfile/helpers/image.cropper';
import { ICropField } from 'screens/UserProfile/models/ICropField';
import { IFileData } from 'screens/UserProfile/models/IFileData';
import styles from './styles.module.scss';
import EditProfileModal from '../modals/EditProfileModal';
import ResetPasswordModal from '../modals/ResetPasswordModal';
import { calcDatesDiff } from '../../helpers/date.helpers';
import { IUserInfoState } from './reducer';
import { updateAvatarRoutine } from '../../routines';
import { UpdateLogoModalContent } from '../modals/UpdateLogoModal';
import { IUpdatePasswordRequest } from '../modals/ResetPasswordModal/reducer';
import { slack, phone, email, skype } from 'assets/icons';
import Avatar from 'components/UserAvatar';

export interface IInfoProps {
  uploadAvatar: IBindingCallback1<IFileData>;
  passwordRequestLoadingData: IUpdatePasswordRequest;
  userId: string;
  user: IUser;
  userInfo: IUserInfo;
}

const Info: React.FunctionComponent<IInfoProps> = ({
  userInfo,
  uploadAvatar,
  passwordRequestLoadingData,
  userId
}: IInfoProps) => {
  const [updateImageFormOpened, toggleUpdateImageForm] = useState(false);

  const onModalClose = () => toggleUpdateImageForm(false);

  const updateAvatar = async (img: string, fileName: string, cropField: ICropField) => {
    const croppedImage = await cropImage(img, cropField);
    uploadAvatar({ fileName, base64File: croppedImage });
    onModalClose();
  };

  const renderModal = () => (
    <Modal
      open={updateImageFormOpened}
      onClose={onModalClose}
      content={<UpdateLogoModalContent onUpdate={updateAvatar} onClose={onModalClose} />}
    />
  );
  const noDataString = <span className={styles.noData}> Not set yet </span>;

  return userInfo ? (
    <div className={styles.mainInfoContainer}>
      <div className={styles.imageContainer}>
        <div className={styles.imageWrapper}>
          <Avatar
            name={`${userInfo.firstName} ${userInfo.lastName}`}
            logoLink={userInfo.avatarPreviewPath}
            fontSize="36px"
            width="100%"
            height="100%"
          />
          {renderModal()}
          {!userId ? (
            <div className={styles.logoIconContainer} onClick={() => toggleUpdateImageForm(true)} role="presentation">
              <Icon
                name="photo"
                size="huge"
                className={styles.logoIcon}
              />
            </div>
          ) : ''}
        </div>
      </div>
      <div className={styles.leftBox}>
        <Header
          as="h1"
          title={`${userInfo.firstName} ${userInfo.lastName}`}
          style={{ color: '#232735', fontSize: '32px' }}
          content={`${userInfo.firstName} ${userInfo.lastName}`}
        />
        <p className={styles.inCompany}>
          <span>In company:</span>
          <span>{calcDatesDiff(userInfo.startDate)}</span>
        </p>
      </div>
      <div className={styles.rightBox}>
        <div className={styles.contacts}>
          <div>
            <Image src={email} className={styles.iconP} />
            <p>{userInfo.email || noDataString}</p>
          </div>
          <div>
            <Image src={slack} className={styles.iconP} />
            <p>{userInfo.slack || noDataString}</p>
          </div>
          <div>
            <Image src={skype} className={styles.iconP} />
            <p>{userInfo.skype || noDataString}</p>
          </div>
          <div>
            <Image src={phone} className={styles.iconP} />
            <p>{userInfo.phone || noDataString}</p>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        {!userId ? (
          <>
            <EditProfileModal userInfo={userInfo} />
            <ResetPasswordModal userInfo={userInfo} confirmLoadingData={passwordRequestLoadingData} />
          </>
        ) : ''}

      </div>
    </div>
  ) : <Loader active />;
};

const mapStateToProps = state => {
  const { user: { user }, userProfile: { userInfo, requests } } = state;
  const userInfoState = userInfo as IUserInfoState;
  return {
    user,
    userInfo: userInfoState.userInfo,
    passwordRequestLoadingData: requests.updatePassword
  };
};

const mapDispatchToProps = {
  uploadAvatar: updateAvatarRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);

/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { IUserInfo } from 'screens/UserProfile/models/IUserInfo';
import { Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { IBindingCallback1 } from 'models/callback';
import { updateUserInfoRoutine } from '../../../routines/index';
import Sidebar from 'components/DataSidebar';
import FormWrapper from 'components/FormWrapper';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { IUpdateUserModel } from 'screens/UserProfile/models/IUpdateUserModel';
import { DateInput } from 'semantic-ui-calendar-react';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required!')
    .trim()
    .min(3, 'Minimum first name length is 3 symbols!')
    .max(30, 'Maximum first name length is 30 symbols!'),
  lastName: Yup.string()
    .required('Last name is required!')
    .trim()
    .min(3, 'Minimum last name length is 3 symbols!')
    .max(30, 'Maximum last name length is 30 symbols!'),
  slack: Yup.string()
    .required('Enter slack!')
    .trim()
    .min(2, 'Minimum slack length is 2 symbols!')
    .max(100, 'Maximum slack length is 100 symbols!'),
  skype: Yup.string()
    .trim()
    .min(2, 'Minimum skype length is 2 symbols!')
    .max(100, 'Maximum skype length is 100 symbols!'),
  hobbies: Yup.string()
    .trim()
    .max(500, 'Maximum hobbies length is 500 symbols!'),
  phone: Yup.string()
    .required('Enter phone!')
    .matches(/^\+38 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/, 'Enter correct phone number!'),
  startDate: Yup.string()
    .required('Start date is required!')
});

const formatDate = (date: Date) => moment(date).format('DD MMM YYYY');

export interface IEditProfileModalProps {
  userInfo: IUserInfo;
  updateUserInfo: IBindingCallback1<IUpdateUserModel>;
}

export interface IEditProfileModalState {
  isOpened: boolean;
  isManager: boolean;
}

export interface IFormikProps {
  firstName: string;
  lastName: string;
  hobbies: string;
  slack: string;
  phone: string;
  skype: string;
  startDate: string;
}

const EditProfileModal: React.FunctionComponent<IEditProfileModalProps> = ({
  userInfo,
  updateUserInfo
}: IEditProfileModalProps) => {
  const [opened, toggleModal] = useState(false);

  const editInfo = (values: IFormikProps) => {
    const updateModel: IUpdateUserModel = {
      id: userInfo.id,
      ...values,
      slack: values.slack.trim(),
      skype: values.skype.trim(),
      hobbies: values.hobbies.trim(),
      startDate: new Date(values.startDate)
    };
    toggleModal(false);
    updateUserInfo(updateModel);
  };

  const sidebar = opened ? (
    <Sidebar onClose={() => toggleModal(false)}>
      <FormWrapper headerText="Edit profile" onClose={() => toggleModal(false)}>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            hobbies: userInfo.hobbies ?? '',
            phone: userInfo.phone ?? '',
            skype: userInfo.skype ?? '',
            slack: userInfo.slack ?? '',
            startDate: formatDate(userInfo.startDate)
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            editInfo(values);
          }}
        >
          {({ values, handleSubmit, errors, touched, handleChange, setFieldValue }: FormikProps<IFormikProps>) => (
            <Form onSubmit={handleSubmit}>
              <Form.Input
                name="firstName"
                placeholder="First name"
                label={touched.firstName && errors.firstName ? errors.firstName : 'First name'}
                error={Boolean(touched.firstName && errors.firstName)}
                onChange={handleChange}
                value={values.firstName}
              />
              <Form.Input
                name="lastName"
                placeholder="Last name"
                label={touched.lastName && errors.lastName ? errors.lastName : 'Last name'}
                error={Boolean(touched.lastName && errors.lastName)}
                onChange={handleChange}
                value={values.lastName}
              />
              <Form.Input
                name="slack"
                placeholder="Slack"
                label={touched.slack && errors.slack ? errors.slack : 'Slack'}
                error={Boolean(touched.slack && errors.slack)}
                onChange={handleChange}
                value={values.slack}
              />
              <Form.Input
                name="skype"
                placeholder="Skype"
                label={touched.skype && errors.skype ? errors.skype : 'Skype'}
                error={Boolean(touched.skype && errors.skype)}
                onChange={handleChange}
                value={values.skype}
              />
              <Form.Field
                control={InputMask}
                mask="+38 (999) 999-99-99"
                label={touched.phone && errors.phone ? errors.phone : 'Phone'}
                name="phone"
                placeholder="Phone"
                error={Boolean(touched.phone && errors.phone)}
                onChange={handleChange}
                value={values.phone}
              />
              <Form.Field
                className={`singleDatepickerGlobal ${touched.startDate && errors.startDate ? 'error' : ''}`}
              >
                <span>
                  {touched.startDate && errors.startDate ? 'Start date is required!' : 'First working day'}
                </span>
                <DateInput
                  closable
                  popupPosition="top center"
                  animation="glow"
                  startMode="year"
                  name="startDate"
                  placeholder="Date"
                  value={values.startDate}
                  iconPosition="left"
                  onChange={(_, data) => setFieldValue('startDate', data.value)}
                  dateFormat="DD MMM YYYY"
                />
              </Form.Field>
              <Form.TextArea
                name="hobbies"
                placeholder="Hobbies"
                label={touched.hobbies && errors.hobbies ? errors.hobbies : 'Hobbies'}
                error={Boolean(touched.hobbies && errors.hobbies)}
                onChange={handleChange}
                value={values.hobbies}
              />
              <Button primary type="submit" className="submitButton">Submit</Button>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Sidebar>
  ) : null;

  return (
    <>
      <Button
        primary
        onClick={() => toggleModal(true)}
        content="Edit profile"
      />
      {sidebar}
    </>
  );
};

const mapDispatchToProps = {
  updateUserInfo: updateUserInfoRoutine
};

export default connect(null, mapDispatchToProps)(EditProfileModal);

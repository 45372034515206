import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authService } from 'screens/Authorization/services/authService';
import { defaultTitle } from './PrivateRoute';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = authService.tokenValue;
      if (token) {
        return (
          <Redirect
            to={{ pathname: '/okr', state: { from: props.location } }}
          />
        );
      }
      document.title = defaultTitle;
      return <Component {...props} />;
    }}
  />
);

export default PublicRoute;

import {
  fetchProjectDocumentsRoutine,
  deleteDocumentRoutine,
  deleteProjectRoutine
} from 'screens/Projects/routines';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import * as documentsService from '../../services/documents.service';
import * as projectsService from '../../services/projects.service';

function* deleteDocument({ payload }: Routine<any>) {
  try {
    yield call(documentsService.deleteDocument, payload);
    yield put(deleteDocumentRoutine.success(payload));
  } catch (error) {
    yield put(deleteDocumentRoutine.failure(error));
  }
}

function* fetchDocuments({ payload }: Routine<any>) {
  try {
    const response = yield call(projectsService.fetchProjectDocuments, payload);
    yield put(fetchProjectDocumentsRoutine.success(response));
  } catch (error) {
    yield put(fetchProjectDocumentsRoutine.failure(error));
  }
}

function* watchDeleteDocument() {
  yield takeEvery(deleteDocumentRoutine.TRIGGER, deleteDocument);
}

function* watchFetchDocuments() {
  yield takeEvery(fetchProjectDocumentsRoutine.TRIGGER, fetchDocuments);
}

function* deleteProject({ payload }: Routine<any>) {
  try {
    yield call(projectsService.deleteProject, payload);
    yield put(deleteProjectRoutine.success(payload));
  } catch (error) {
    yield put(deleteProjectRoutine.failure(error));
  }
}

function* watchDeleteProject() {
  yield takeEvery(deleteProjectRoutine.TRIGGER, deleteProject);
}

export default function* saveProjectSagas() {
  yield all([
    watchDeleteDocument(),
    watchFetchDocuments(),
    watchDeleteProject()
  ]);
}

import { Routine } from 'redux-saga-routines';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as requestsService from '../../services/requestsService';
import { createRequestRoutine, fetchAssignInfoRoutine, generateLinkRoutine } from '../../routines';
import { Console } from 'console';

function* getAssignInfo({ payload }: Routine<any>): Routine<any> {
  try {
    const result = yield call(requestsService.getAssignInfo, payload);

    yield put(fetchAssignInfoRoutine.success(result));
  } catch (error) {
    yield put(fetchAssignInfoRoutine.failure(error));
  }
}

function* generateLink({ payload }: Routine<any>): Routine<any> {
  try {
    const res = payload ? yield call(requestsService.generateLink, payload) : null;

    yield put(generateLinkRoutine.success(res));
  } catch (error) {
    yield put(generateLinkRoutine.failure(error));
  }
}

function* createRequest({ payload }: Routine<any>): Routine<any> {
  try {
    const res = yield call(requestsService.createRequest, payload);

    yield put(createRequestRoutine.success(res));
  } catch (error) {
    yield put(createRequestRoutine.failure(error));
  }
}
function* watchCreateRequest() {
  yield takeEvery(createRequestRoutine.TRIGGER, createRequest);
}

function* watchGetAssignInfo() {
  yield takeEvery(fetchAssignInfoRoutine.TRIGGER, getAssignInfo);
}
function* watchGenerateLink() {
  yield takeEvery(generateLinkRoutine.TRIGGER, generateLink);
}

export default function* createAssign() {
  yield all([
    watchCreateRequest(),
    watchGetAssignInfo(),
    watchGenerateLink()
  ]);
}

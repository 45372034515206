import React from 'react';
import { Container, Grid, Header, Segment } from 'semantic-ui-react';
import LoadingWrapper from 'components/LoaderWrapper';
import styles from './styles.module.scss';
import { IBindingCallback1 } from 'models/callback';
import { checkCodeRoutine, updatePasswordRoutine } from 'screens/Authorization/routines';
import { connect } from 'react-redux';
import ResetPasswordForm from 'screens/Authorization/components/ResetPasswordForm';

const getUrlSearchParamFromLocation = (location: any, searchParam: string) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(searchParam);
};

export interface IResetPasswordPageProps {
  loading: boolean;
  location: any;
  checkCode: IBindingCallback1<string>;
  updatePassword: IBindingCallback1<IPasswordData>;
  isLoading: boolean;
  message: string;

  passwordChangesLoading: boolean;
}

export interface IPasswordData {
  password: string;
  code: string;
}

class ResetPasswordPage extends React.Component<IResetPasswordPageProps> {
  componentDidMount() {
    const { location, checkCode } = this.props;
    checkCode(getUrlSearchParamFromLocation(location, 'code'));
  }

  updatePassword = (password: string) => {
    const { updatePassword, location } = this.props;
    const code = getUrlSearchParamFromLocation(location, 'code');
    updatePassword({ password, code });
  }

  render() {
    const { isLoading, message, passwordChangesLoading } = this.props;

    const renderIncorrectCodeMessage = () => (
      <div>
        Your code is incorrect, check your link or try to reset one more time
      </div>
    );

    const renderResetPasswordForm = () => (
      <div>
        <Header>
          {`Change password for "${message}"`}
        </Header>
        <div className={styles.description}>
          Fill the form below to change your password
        </div>
        <Segment className={styles.resetPasswordForm}>
          <ResetPasswordForm onSubmit={this.updatePassword} loading={passwordChangesLoading} />
        </Segment>
      </div>
    );

    return (
      <LoadingWrapper loading={isLoading}>
        <Container>
          <Grid
            centered
            columns={2}
            className="centered"
            style={{ height: '100vh' }}
          >
            <Grid.Column verticalAlign="middle">
              {message ? renderResetPasswordForm() : renderIncorrectCodeMessage()}
            </Grid.Column>
          </Grid>
        </Container>
      </LoadingWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { auth: { requests: { checkPasswordCode, updatePassword }, auth: { resetPasswordMessage } } } = state;

  return {
    isLoading: checkPasswordCode.loading,
    message: resetPasswordMessage,
    passwordChangesLoading: updatePassword.loading
  };
};

const mapDispatchToProps = {
  checkCode: checkCodeRoutine,
  updatePassword: updatePasswordRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

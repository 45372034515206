import { takeEvery, put, call, all } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import * as okrService from '../../services/okrService';
import * as objectiveService from '../../services/objectiveService';
import * as keyResultService from '../../services/keyResultService';
import {
  fetchExtendedOkr,
  removeKeyResultFromOkr,
  addKeyResultToOkr,
  saveMark,
  addObjectiveToOkr,
  removeObjectiveFromOkr,
  saveObjectiveRoutine,
  saveKeyResultRoutine,
  updateOKRStatusRoutine,
  fetchOkrDatesRoutine,
  moveKeyResultRoutine
} from '../../routines';

function* extendedOkrRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.getExtendedOkr, payload);
    yield put(fetchExtendedOkr.success(response));
  } catch (error) {
    yield put(fetchExtendedOkr.failure(error.message));
  }
}

function* removeKeyResultFromOkrRequest({ payload }: Routine<any>) {
  try {
    yield call(okrService.removeKeyResultFromOkr, payload);

    yield put(removeKeyResultFromOkr.success(payload));
  } catch (error) {
    yield put(removeKeyResultFromOkr.failure(error.message));
  }
}

function* addKeyResultToOkrRequest({ payload }: Routine<any>) {
  const { objectiveId } = payload;

  try {
    const keyResults = yield call(okrService.addKeyResultToOkr, payload);
    const data = { keyResults, objectiveId };

    yield put(addKeyResultToOkr.success(data));
  } catch (error) {
    yield put(addKeyResultToOkr.failure(error.message));
  }
}

function* saveMarkRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(keyResultService.saveMark, payload);

    yield put(
      saveMark.success({
        ...payload,
        mark: response
      })
    );
  } catch (error) {
    yield put(saveMark.failure(error.message));
  }
}

function* updateOKRStatusRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.updateOKRStatus, payload);
    yield put(updateOKRStatusRoutine.success(response));
  } catch (error) {
    yield put(updateOKRStatusRoutine.failure(error.message));
  }
}

function* addObjectiveToOkrRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.addObjectiveToOkr, payload);

    yield put(addObjectiveToOkr.success(response));
  } catch (error) {
    yield put(addObjectiveToOkr.failure(error.message));
  }
}

function* removeObjectiveFromOkrRequest({ payload }: Routine<any>) {
  try {
    yield call(okrService.removeObjectiveFromOkr, payload);

    yield put(removeObjectiveFromOkr.success(payload));
  } catch (error) {
    yield put(removeObjectiveFromOkr.failure(error.message));
  }
}

function* saveObjective({ payload }: Routine<any>) {
  try {
    yield call(objectiveService.saveObjective, payload);

    yield put(saveObjectiveRoutine.success(payload));
  } catch (error) {
    yield put(saveObjectiveRoutine.failure(error.message));
  }
}

function* saveKeyResult({ payload }: Routine<any>) {
  try {
    yield call(keyResultService.saveKeyResult, payload);

    yield put(saveKeyResultRoutine.success(payload));
  } catch (error) {
    yield put(saveKeyResultRoutine.failure(error.message));
  }
}

function* fetchOkrDates({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.fetchDates, payload);

    yield put(fetchOkrDatesRoutine.success(response));
  } catch (error) {
    yield put(fetchOkrDatesRoutine.failure(error.message));
  }
}

function* moveKeyResult({ payload }: Routine<any>) {
  try {
    yield call(okrService.moveKeyResult, payload);

    yield put(moveKeyResultRoutine.success(payload));
  } catch (error) {
    yield put(moveKeyResultRoutine.failure(error.message));
  }
}

function* moveKeyResultRequest() {
  yield takeEvery(moveKeyResultRoutine, moveKeyResult);
}

function* watchFetchDatesRequest() {
  yield takeEvery(fetchOkrDatesRoutine, fetchOkrDates);
}

function* watchExtendedOkrRequest() {
  yield takeEvery(fetchExtendedOkr.TRIGGER, extendedOkrRequest);
}

function* watchRemoveKeyResultFromOkrRequest() {
  yield takeEvery(removeKeyResultFromOkr.TRIGGER, removeKeyResultFromOkrRequest);
}

function* watchAddKeyResultToOkrRequest() {
  yield takeEvery(addKeyResultToOkr.TRIGGER, addKeyResultToOkrRequest);
}

function* watchSaveMarkRequest() {
  yield takeEvery(saveMark.TRIGGER, saveMarkRequest);
}

function* watchUpdateOKRStatusRequest() {
  yield takeEvery(updateOKRStatusRoutine.TRIGGER, updateOKRStatusRequest);
}

function* watchAddObjectiveToOkrRequest() {
  yield takeEvery(addObjectiveToOkr.TRIGGER, addObjectiveToOkrRequest);
}

function* watchRemoveObjectiveFromOkrRequest() {
  yield takeEvery(removeObjectiveFromOkr.TRIGGER, removeObjectiveFromOkrRequest);
}

function* watchSaveObjective() {
  yield takeEvery(saveObjectiveRoutine.TRIGGER, saveObjective);
}

function* watchSaveKeyResult() {
  yield takeEvery(saveKeyResultRoutine.TRIGGER, saveKeyResult);
}

export default function* extendedOkrSagas() {
  yield all([
    watchExtendedOkrRequest(),
    watchRemoveKeyResultFromOkrRequest(),
    watchAddKeyResultToOkrRequest(),
    watchSaveMarkRequest(),
    watchUpdateOKRStatusRequest(),
    watchAddObjectiveToOkrRequest(),
    watchRemoveObjectiveFromOkrRequest(),
    watchSaveObjective(),
    watchSaveKeyResult(),
    watchFetchDatesRequest(),
    moveKeyResultRequest()
  ]);
}

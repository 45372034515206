/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IForm } from 'screens/Feedback/model/Forms/IForm';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import QuestionsBlocksList from 'screens/Feedback/components/QuestionsBlocksList';
import { IBindingCallback1 } from 'models/callback';
import {
  fetchQuestionsBlocksRoutine,
  questionsBlockCreateRoutine,
  questionsBlockDeleteRoutine,
  questionsBlockUpdateRoutine,
  selectedQuestionsBlockChangedRoutine,
  questionsBlockReorderRoutine
} from 'screens/Feedback/routines';
import { IQuestionBlock } from 'screens/Feedback/model/Questions/IQuestionBlock';
import { Icon } from 'semantic-ui-react';
import { getBlocksPageFromIsCreating, getEmptyBlocksPageFromIsCreating } from './elements';

interface IQuestionsBlocksProps {
  formId: string;
  questionsBlocks: IQuestionBlock[];
  getQuestionBlocks: IBindingCallback1<string>;
  selectedQuestionsBlockChanged: IBindingCallback1<string>;
  createQuestionsBlock: IBindingCallback1<IQuestionBlock>;
  updateQuestionsBlock: IBindingCallback1<IQuestionBlock>;
  deleteQuestionsBlock: IBindingCallback1<string>;
  reorderQuestionBlocks: IBindingCallback1<IQuestionBlock>;
}

const QuestionsBlocks: React.FunctionComponent<IQuestionsBlocksProps> = ({
  formId,
  questionsBlocks,
  selectedQuestionsBlockChanged,
  createQuestionsBlock,
  updateQuestionsBlock,
  deleteQuestionsBlock,
  getQuestionBlocks,
  reorderQuestionBlocks
}: IQuestionsBlocksProps) => {
  const [isEdit, setEdit] = useState(false);
  const [isCreate, setCreate] = useState(false);
  useEffect(() => {
    setCreate(false);
    setEdit(false);
    getQuestionBlocks(formId);
    selectedQuestionsBlockChanged(null);
  }, [formId]);

  const getBlocks = () => { getQuestionBlocks(formId); };
  const questionsBlockClicked = (blockId: string) => {
    setCreate(false);
    setEdit(true);
    selectedQuestionsBlockChanged(blockId);
  };

  const addNewQuestionsBlockClicked = () => {
    setEdit(false);
    setCreate(true);
    selectedQuestionsBlockChanged(null);
  };

  const questionsBlockCreateFormSaved = (block: IQuestionBlock) => {
    setCreate(false);
    createQuestionsBlock({ ...block, formId });
    selectedQuestionsBlockChanged(null);
  };

  const questionsBlockCreateFormCanceled = () => {
    setCreate(false);
    selectedQuestionsBlockChanged(null);
  };

  const questionsBlockEditFormSaved = (block: IQuestionBlock) => {
    updateQuestionsBlock(block);
  };

  const questionsBlockEditFormCanceled = () => {
    setEdit(false);
  };

  return (
    <div className={styles.questionBlocksContainer}>
      <h3>
        Question Blocks
        <div
          className={styles.plusContainer}
          onClick={addNewQuestionsBlockClicked}
        >
          <Icon name="plus square" />
        </div>
      </h3>
      {questionsBlocks?.length
        ? (
          <div className={styles.nonEmptyQuestionBlocksContainer}>
            <div className={styles.questionsBlocksListContainer}>
              <QuestionsBlocksList
                questionsBlockClicked={questionsBlockClicked}
                questionsBlockDeleted={deleteQuestionsBlock}
                questionsBlockReorder={reorderQuestionBlocks}
              />
            </div>
            <div className={styles.questionsContainer}>
              {
                getBlocksPageFromIsCreating(
                  isCreate,
                  questionsBlockCreateFormSaved,
                  questionsBlockCreateFormCanceled,
                  isEdit,
                  questionsBlockEditFormSaved,
                  questionsBlockEditFormCanceled
                )
              }
            </div>
          </div>
        )
        : (
          getEmptyBlocksPageFromIsCreating(
            isCreate,
            questionsBlockCreateFormSaved,
            questionsBlockCreateFormCanceled
          )
        )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    feedbackPage: {
      questionsBlocks: {
        questionsBlocks
      }
    }
  } = state;
  return {
    questionsBlocks
  };
};

const mapDispatchToProps = {
  selectedQuestionsBlockChanged: selectedQuestionsBlockChangedRoutine,
  createQuestionsBlock: questionsBlockCreateRoutine,
  updateQuestionsBlock: questionsBlockUpdateRoutine,
  deleteQuestionsBlock: questionsBlockDeleteRoutine,
  getQuestionBlocks: fetchQuestionsBlocksRoutine,
  reorderQuestionBlocks: questionsBlockReorderRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsBlocks);

import { connect } from 'react-redux';
import React from 'react';
import { Header, Button, Modal } from 'semantic-ui-react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import ObjectivesAdminPage from '../ObjectivesAdminPage';
import KeyResultsAdminPage from '../KeyResultsAdminPage';
import styles from './styles.module.scss';
import SidebarWrapper from 'components/Sidebar';
import KeyResultData from '../../../OKR/containers/KeyResultDataSidebar';
import CustomObjectiveInput from 'screens/OKR/components/CustomObjectiveInput';
import KeyResultSaveForm from 'screens/OKR/components/KeyResultSaveForm';
import SidebarMerge from '../SidebarMerge';
import { boundMethod } from 'autobind-decorator';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { ModalHeaderWithCross } from 'components/ModalHeaderWithCross';
import { IObjectiveStrict } from 'screens/OKR/models/objective/IObjectiveStrict';
import { IBindingCallback1 } from 'models/callback';
import { IKeyResultBase } from 'screens/OKR/models/key-result/IKeyResultBase';
import {
  saveKeyResultAdminRoutine,
  saveObjectiveAdminRoutine,
  mergeKeyResultsRoutine
} from 'screens/Library/routines';
import { IMergeAction } from 'screens/Library/model';

interface IManagementViewState {
  isModalOpen: boolean;
  isKRSelected: boolean;
  isSidebarOpen: boolean;
  isScanModeOn: boolean;
  isMergeSidebarOpen: boolean;
  selectedKeyResult: string;
  editKeyResult: IKeyResult;
  editObjective: IObjectiveStrict;
}

interface IManagementViewProps {
  keyResultsToMerge: IKeyResult[];
  saveKeyResult: IBindingCallback1<IKeyResultBase>;
  saveObjective: IBindingCallback1<IObjectiveStrict>;
  mergeKeyResults: IBindingCallback1<IMergeAction>;
}

class ManagementView extends React.Component<IManagementViewProps, IManagementViewState> {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isKRSelected: false,
      isSidebarOpen: false,
      isScanModeOn: false,
      isMergeSidebarOpen: false,
      selectedKeyResult: '',
      editKeyResult: null,
      editObjective: null
    };
  }

  handleToggleChange = () => this.setState(prevState => ({ isKRSelected: !prevState.isKRSelected }));

  @boundMethod
  handleSubmit(data: IKeyResultBase | IObjectiveStrict) {
    const { isKRSelected } = this.state;
    const { saveKeyResult, saveObjective } = this.props;
    if (isKRSelected) {
      saveKeyResult(data);
    } else {
      saveObjective(data);
    }
    this.closeModal();
  }

  openModal = () => this.setState({ isModalOpen: true });

  closeModal = () => this.setState({ isModalOpen: false, editKeyResult: null, editObjective: null });

  toggleScanMode = () => this.setState(prevState => ({ isScanModeOn: !prevState.isScanModeOn }));

  selectKeyResult = (data: IKeyResult) => {
    this.setState({ editKeyResult: data, isModalOpen: true });
  };

  selectObjective = (data: IObjectiveStrict) => {
    this.setState({ editObjective: data, isModalOpen: true });
  };

  toggleSidebar = (id: string = null) => this.setState(prevState => ({
    isSidebarOpen: !prevState.isSidebarOpen,
    selectedKeyResult: id
  }));

  toggleMergeSideBar = () => {
    this.setState(({ isMergeSidebarOpen }) => ({
      isMergeSidebarOpen: !isMergeSidebarOpen
    }));
  }

  renderModal = (isKRSelected: boolean, isModalOpen: boolean, editKeyResult, editObjective) => (
    <Modal
      open={isModalOpen}
      onClose={this.closeModal}
    >
      <ModalHeaderWithCross
        headerName={`Save ${isKRSelected ? 'Key result' : 'Objective'}`}
        onClose={this.closeModal}
      />
      <Modal.Content>
        { isKRSelected
          ? (
            <KeyResultSaveForm
              defaultGlobal
              keyResult={editKeyResult || null}
              onSave={this.handleSubmit}
            />
          )
          : (
            <CustomObjectiveInput
              defaultGlobal
              objective={editObjective || null}
              onSubmit={this.handleSubmit}
            />
          )}
      </Modal.Content>
    </Modal>
  );

  render() {
    const {
      isModalOpen,
      isKRSelected,
      isSidebarOpen,
      isScanModeOn,
      isMergeSidebarOpen,
      selectedKeyResult,
      editKeyResult,
      editObjective
    } = this.state;
    const { keyResultsToMerge, mergeKeyResults } = this.props;

    return (
      <div>
        {this.renderModal(isKRSelected, isModalOpen, editKeyResult, editObjective)}
        <SidebarWrapper
          isOpened={isSidebarOpen}
          onClose={() => this.toggleSidebar()}
        >
          <KeyResultData keyResultId={selectedKeyResult} />
        </SidebarWrapper>
        <div className={styles.okrContainer}>
          <div className={styles.header}>
            <Header content="Library " size="huge" />
            <div className={styles.controls}>
              <span className={styles.toggleLabel}>{!isKRSelected ? 'Objectives' : 'Key-results'}</span>
              <Toggle
                icons={false}
                defaultChecked={isKRSelected}
                onChange={this.handleToggleChange}
                disabled={isScanModeOn}
              />
              {!isScanModeOn && (
                <Button
                  icon="plus"
                  primary
                  onClick={() => this.openModal()}
                  content="Add"
                  className={styles.button}
                />
              )}
              {isKRSelected && (
                <Button
                  icon="object group"
                  primary
                  onClick={() => this.toggleScanMode()}
                  content={`Turn Scan ${isScanModeOn ? 'Off' : 'On'}`}
                  className={isScanModeOn ? styles.toggleScanOffBtn : styles.toggleScanOnBtn}
                />
              )}
              <p className={styles.mergeBtnWrapper}>
                <Button
                  className={styles.button}
                  onClick={this.toggleMergeSideBar}
                  icon="random"
                  content="Merge"
                  primary
                />
                <output className={styles.mergeBtnOutput}>{keyResultsToMerge.length || ''}</output>
              </p>
            </div>
          </div>
          <div className={styles.content}>
            { !isKRSelected
              ? <ObjectivesAdminPage updateObjective={this.selectObjective} />
              : (
                <KeyResultsAdminPage
                  isScanMode={isScanModeOn}
                  updateKeyResult={this.selectKeyResult}
                  toggleSidebar={this.toggleSidebar}
                />
              ) }
          </div>
        </div>
        <SidebarMerge
          keyResults={keyResultsToMerge}
          isOpened={isMergeSidebarOpen}
          onClose={this.toggleMergeSideBar}
          mergeKeyResults={mergeKeyResults}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { keyResults: { keyResultsToMerge } } = state.okr.library;

  return {
    keyResultsToMerge
  };
};

const mapDispatchToProps = {
  saveKeyResult: saveKeyResultAdminRoutine,
  saveObjective: saveObjectiveAdminRoutine,
  mergeKeyResults: mergeKeyResultsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagementView);

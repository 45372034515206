import React from 'react';
import { IBindingCallback1 } from 'models/callback';
import { Button, Modal, Form } from 'semantic-ui-react';
import { IUserInfo } from 'screens/UserProfile/models/IUserInfo';
import { Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { updateUserPasswordRoutine } from '../../../routines/index';
import { IUpdatePasswordRequest } from './reducer';
import FormWrapper from 'components/FormWrapper';
import Sidebar from 'components/DataSidebar';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Old password is required!')
    .min(6, 'Password has to be at least 6 characters long!')
    .max(30, 'Maximum password length is 30 characters!'),
  newPassword: Yup.string()
    .required('New password is required!')
    .min(6, 'Password has to be at least 6 characters long!')
    .max(30, 'Maximum password length is 30 characters!'),
  copyNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Both passwords need to be the same')
    .required('Password confirmation is required')
});

const initialValues = {
  oldPassword: '',
  newPassword: '',
  copyNewPassword: ''
};

export interface IFormikProps {
  oldPassword: string;
  newPassword: string;
  copyNewPassword: string;
}

export interface IResetPasswordProps {
  userInfo: IUserInfo;
  updateUserPassword: IBindingCallback1<any>;
  confirmLoadingData: IUpdatePasswordRequest;
}

export interface IResetPasswordState {
  isOpened: boolean;
}

class ResetPasswordModal extends React.Component<IResetPasswordProps, IResetPasswordState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false
    };
  }

  componentDidUpdate(prevProps: IResetPasswordProps) {
    const { confirmLoadingData } = this.props;
    if (prevProps.confirmLoadingData.loading && !confirmLoadingData.loading && !confirmLoadingData.error) {
      this.onClose();
    }
  }

  handleClick = () => this.setState({ isOpened: true });

  onClose = () => this.setState({ isOpened: false })

  editPasswordInfo(oldPassword: string, newPassword: string) {
    const { userInfo, updateUserPassword } = this.props;
    const updateModel = {
      userId: userInfo.id,
      oldPassword,
      newPassword
    };
    updateUserPassword(updateModel);
  }

  render() {
    const { isOpened } = this.state;
    const { confirmLoadingData: { loading } } = this.props;
    const keysCount = object => Object.keys(object).length;

    const modal = isOpened ? (
      <Sidebar onClose={this.onClose}>
        <FormWrapper headerText="Change password" onClose={this.onClose}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              this.editPasswordInfo(values.oldPassword, values.newPassword);
              setSubmitting(false);
            }}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, handleSubmit, handleChange }: FormikProps<IFormikProps>) => (
              <Modal.Content>
                <Form onSubmit={handleSubmit}>
                  <Form.Input
                    type="password"
                    onChange={handleChange}
                    value={values.oldPassword}
                    label={touched.oldPassword && errors.oldPassword
                      ? errors.oldPassword
                      : 'Old password'}
                    name="oldPassword"
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                  />
                  <Form.Input
                    type="password"
                    onChange={handleChange}
                    value={values.newPassword}
                    name="newPassword"
                    label={touched.newPassword && errors.newPassword
                      ? errors.newPassword
                      : 'New Password'}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                  />
                  <Form.Input
                    type="password"
                    onChange={handleChange}
                    value={values.copyNewPassword}
                    name="copyNewPassword"
                    label={touched.copyNewPassword && errors.copyNewPassword
                      ? errors.copyNewPassword
                      : 'Repeat new password'}
                    error={Boolean(touched.copyNewPassword && errors.copyNewPassword)}
                  />
                  <Button
                    primary
                    type="submit"
                    disabled={keysCount(touched) > 0 && keysCount(errors) > 0}
                    loading={loading}
                    className="submitButton"
                  >
                    Submit
                  </Button>
                </Form>
              </Modal.Content>
            )}
          </Formik>
        </FormWrapper>
      </Sidebar>
    ) : null;

    return (
      <>
        <Button
          primary
          onClick={this.handleClick}
          content="Change password"
        />
        {modal}
      </>
    );
  }
}

const mapDispatchToProps = {
  updateUserPassword: updateUserPasswordRoutine
};

export default connect(null, mapDispatchToProps)(ResetPasswordModal);

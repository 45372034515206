import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { IGlobalState } from 'models/global-state';
import { Route } from 'react-router-dom';
import styles from './styles.module.scss';
import { Role } from 'screens/Authorization/models/role';
import { fetchRequestDetailsRoutine } from '../Feedback/routines';
import ManageForms from '../Feedback/containers/NewManageForms';
import { history } from 'helpers/history';
import FeedbackRequests from '../Feedback/containers/FeedbackRequests';
import { IBindingCallback1 } from '../../models/callback';
import ManageRequests from 'screens/Feedback/containers/ManageRequests';
import ManageTemplates from 'screens/Feedback/containers/ManageTemplates';

interface IFeedbackMenuProps {
  roles: Role[];
  location: any;
  fetchRequestDetails?: IBindingCallback1<string>;
}

const routes = [
  {
    name: 'forms',
    menuText: 'Manage Forms',
    path: '/feedback/forms',
    component: ManageForms,
    roles: [Role.Administrator, Role.HR, Role.TopManager, Role.Manager],
    exact: true
  },
  {
    name: 'templates',
    menuText: 'Manage Templates',
    path: '/feedback/templates',
    component: ManageTemplates,
    roles: [Role.Administrator, Role.HR, Role.TopManager, Role.Manager],
    exact: true
  },
  {
    name: 'feedbacks',
    menuText: 'Manage Feedbacks',
    path: '/feedback/feedbacks',
    component: FeedbackRequests,
    roles: [Role.Administrator, Role.HR, Role.TopManager, Role.Manager, Role.Developer],
    exact: true
  },
  {
    name: 'requests',
    menuText: 'Manage Requests',
    path: '/feedback/requests',
    component: ManageRequests,
    roles: [Role.Administrator, Role.HR, Role.TopManager, Role.Manager, Role.Developer],
    exact: true
  }
];

const FeedbackMenu = ({
  location,
  roles,
  fetchRequestDetails
}: IFeedbackMenuProps) => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/feedback/feedbacks/')) {
      const feedbackId = path.replace('/feedback/feedbacks/', '');
      fetchRequestDetails(feedbackId);
      history.push('/feedback/feedbacks');
    }
    const active = routes.find(i => path.match(i.path)) ?? history.push('/feedback/feedbacks');
    setActiveSection(active ? active.name : null);
  }, [location.pathname]);

  const handleMenuClick = (e, data) => {
    const { pathname } = location;
    if (pathname !== activeSection) {
      const path = routes.find(route => (route.exact
        ? pathname === route.path
        : pathname.startsWith(route.path)))?.name;
      if (!path) history.push('/feedback');
      setActiveSection(activeSection ?? '/feedback');
    }
    setActiveSection(data.name);
    history.push(routes.find(route => route.name === data.name)?.path);
  };

  const renderMenu = () => {
    const userRoles = roles;
    const userRolesIncludes = (routeRoles: Role[]) => userRoles.some(userRole => routeRoles.includes(userRole));

    return (
      <div className={styles.menu}>
        <Menu pointing secondary>
          {routes.map(({ name, roles: routeRoles, menuText }) => (
            !routeRoles || userRolesIncludes(routeRoles)
              ? (
                <Menu.Item
                  key={name}
                  active={activeSection === name}
                  onClick={handleMenuClick}
                  name={name}
                >
                  {menuText}
                </Menu.Item>
              ) : ''
          ))}
        </Menu>
      </div>
    );
  };

  return (
    <div>
      {renderMenu()}
      {routes.map(({ path, component, exact }) => (
        <Route exact={exact} path={path} component={component} key={path} />
      ))}
    </div>
  );
};

const mapStateToProps = (state: IGlobalState) => {
  const {
    user: { user: { roles } }
  } = state;

  return {
    roles
  };
};
const mapDispatchToProps = {
  fetchRequestDetails: fetchRequestDetailsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackMenu);

import { createRoutine } from 'redux-saga-routines';

export const fetchQuestionsRoutine = createRoutine('GET_QUESTIONS');
export const updateQuestionRoutine = createRoutine('UPDATE_QUESTION');
export const deleteQuestionRoutine = createRoutine('DELETE_QUESTION');
export const fetchCategoriesRoutine = createRoutine('GET_CATEGORIES');
export const addQuestionRoutine = createRoutine('ADD_QUESTION');
export const deleteExistingQuestionRoutine = createRoutine('DELETE_EXISING');
export const saveChangesRoutine = createRoutine('SAVE_CHANGES');
export const cancelChangesRoutine = createRoutine('CANCEL_CHANGES');
export const setQuestionAsEditedRoutine = createRoutine('SET_QUESTION_AS_EDITED');
export const saveChangesWasRequestedRoutine = createRoutine('SAVE_CHANGES_REQUESTED');
export const requestUpdatedPayloadForSavingRoutine = createRoutine('REQUESTING_UPDATED_PAYLOAD_TO_SAVE');

export const fetchFormsRoutine = createRoutine('GET_FORMS');
export const fetchTemplatesRoutine = createRoutine('GET_TEMPLATES');
export const questionSelectedRoutine = createRoutine('QUESTION_SELECTED');
export const questionUnselectedRoutine = createRoutine('QUESTION_UNSELECTED');
export const questionRemovedRoutine = createRoutine('QUESTION_REMOVED');
export const createQuestionRoutine = createRoutine('QUESTION_CREATE');
export const formSavedRoutine = createRoutine('SAVE_FORM');
export const formSavedTemplateRoutine = createRoutine('SAVE_TEMPLATE_FORM');
export const formDeletedRoutine = createRoutine('DELETE_FORM');
export const formUpdateRoutine = createRoutine('UPDATE_FORM');
export const selectedFormChangedRoutine = createRoutine('CHANGE_FORM');

export const selectedQuestionsBlockChangedRoutine = createRoutine('CHANGE_SELECTED_QUESTIONS_BLOCK');
export const questionsBlockCreateRoutine = createRoutine('CREATE_BLOCK');
export const questionsBlockUpdateRoutine = createRoutine('UPDATE_BLOCK');
export const questionsBlockDeleteRoutine = createRoutine('DELETE_BLOCK');
export const questionsBlockReorderRoutine = createRoutine('REORDER_BLOCKS');
export const fetchQuestionsBlocksRoutine = createRoutine('GET_BLOCKS');

export const resetFormsSelectionRoutine = createRoutine('FORMS_SELECTION_RESET');
export const searchRoutine = createRoutine('SEARCH');

export const fetchRequestsRoutine = createRoutine('GET_REQUESTS');
export const importFeedbacksCsvRoutine = createRoutine('IMPORT_FEEDBACKS_CSV');
export const fetchRequestDetailsRoutine = createRoutine('GET_REQUEST_DETAILS');
export const fetchUsersMentorsRoutine = createRoutine('GET_USERS_MENTORS');
export const clearFeedbackPageStateRoutine = createRoutine('CLEAR_FEEDBACK_STATE');
export const createSummaryRoutine = createRoutine('CREATE_SUMMARY');
export const updateSummaryRoutine = createRoutine('UPDATE_SUMMARY');
export const fetchSummaryRoutine = createRoutine('GET_SUMMARY');

export const createRequestRoutine = createRoutine('CREATE_REQUEST');
export const fetchAssignInfoRoutine = createRoutine('GET_ASSIGN_INFO');
export const generateLinkRoutine = createRoutine('GENERATE_LINK');
export const fetchAssignRequestsRoutine = createRoutine('GET_ASSIGN_REQUESTS');
export const fetchAssignDetailRequestRoutine = createRoutine('GET_ASSIGN_DETAIL_REQUEST');
export const sendAnswersRoutine = createRoutine('SEND_ANSWERS');
export const fetchAssignDetailRequestByLinkRoutine = createRoutine('GET_REQUEST_BY_LINK');
export const sendAnswersByLinkRoutine = createRoutine('SEND_ANSWERS_BY_LINK');

export const selectedTemplateChangedRoutine = createRoutine('CHANGE_TEMPLATE');
export const templateSavedRoutine = createRoutine('SAVE_TEMPLATE');
export const templateDeletedRoutine = createRoutine('DELETE_TEMPLATE');
export const templateUpdateRoutine = createRoutine('UPDATE_TEMPLATE');
export const fetchAllTemplatesRoutine = createRoutine('GET_ALL_TEMPLATES');
export const resetTemplateSelectionRoutine = createRoutine('TEMPLATE_SELECTION_RESET');

import { put, takeEvery, all, call } from 'redux-saga/effects';
import { fetchNewsByIdRoutine, syncNewsForUpdateWithOriginByIdRoutine } from 'screens/News/routines';
import { Routine } from 'redux-saga-routines';
import { toastr } from 'react-redux-toastr';
import * as newsService from '../../services/newsService';

function* newsForUpdateRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(newsService.getNewsForUpdateById, payload);
    yield put(fetchNewsByIdRoutine.success(response));
  } catch (error) {
    yield put(fetchNewsByIdRoutine.failure(error.message));
  }
}

function* watchNewsForUpdateRequest() {
  yield takeEvery(fetchNewsByIdRoutine.TRIGGER, newsForUpdateRequest);
}

function* tryFetchFromOrigin({ payload }: Routine<any>) {
  try {
    const resp = yield call(newsService.syncNewsForUpdateWithOrigin, payload);
    yield put(syncNewsForUpdateWithOriginByIdRoutine.success(resp));
    toastr.success('Synchronized successfully!');
  } catch (error) {
    toastr.error('Failed to sync!', error.message);
    yield put(syncNewsForUpdateWithOriginByIdRoutine.failure(error.message));
  }
}

function* watchFetchOriginRequests() {
  yield takeEvery(syncNewsForUpdateWithOriginByIdRoutine.TRIGGER, tryFetchFromOrigin);
}

export default function* newsForUpdateSaga() {
  yield all([
    watchNewsForUpdateRequest(),
    watchFetchOriginRequests()
  ]);
}

import React, { FunctionComponent } from 'react';
import { IOkr } from 'screens/OKR/models/okr';
import { IBindingAction } from 'models/callback';
import { Icon } from 'semantic-ui-react';
import { history } from '../../../../helpers/history';
import DateLabel from '../DateLabel';
import styles from './styles.module.scss';
import moment from 'moment';

export interface IOkrGridItemProps {
  okr: IOkr;
  onClick: IBindingAction;
}
const OkrGridItem: FunctionComponent<IOkrGridItemProps> = ({
  okr: {
    isClosed,
    startTime,
    endTime,
    id,
    name,
    description,
    averageMark
  },
  onClick
}) => {
  const onUpdateClick = event => {
    event.stopPropagation();
    onClick();
  };
  const isCurrent = moment().isBetween(startTime, endTime);
  const isDatePassed = date => moment(date).isBefore();
  const isNotFinishedTillEndDate = !isClosed && isDatePassed(endTime);

  return (
    <div
      onClick={() => history.push(`/okr/${id}`)}
      role="presentation"
      className={`
        ${styles.card} ${isClosed ? styles.cardClosed : styles.cardActive}
        ${isCurrent ? styles.current : ''} ${isNotFinishedTillEndDate ? styles.notFinished : ''}`}
    >
      <div className={styles.cardContent}>
        <div className={styles.cardHeader}>
          <div title={name} className={styles.ellipsis}>{name}</div>
          {isClosed ? <div className={styles.mark}>{averageMark}</div> : ''}
        </div>
        <div className={styles.cardDescription}>
          {description}
        </div>
        <div className={styles.cardMeta}>
          <div className={styles.dateContainer}>
            <Icon name="calendar outline" className={styles.calendarIcon} />
            <DateLabel
              startTime={startTime}
              endTime={endTime}
              styles={styles}
            />
          </div>
          <span onClick={onUpdateClick} role="presentation" className={styles.editButton}>
            <Icon name="pencil alternate" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default OkrGridItem;

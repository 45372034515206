import React, { FunctionComponent } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './styles.module.scss';
import { toastr } from 'react-redux-toastr';

const maxFileSize = 4000000;

interface IDragAndDropProps {
  onDrop: Function;
  height?: string;
  images?: boolean;
  multiple?: boolean;
  children?: any;
  hasData?: boolean;
  fileSize?: number;
}

export const DragAndDrop: FunctionComponent<IDragAndDropProps> = ({
  onDrop,
  height,
  images,
  multiple,
  children,
  hasData,
  fileSize = maxFileSize
}: IDragAndDropProps) => {
  const mbSize = fileSize / 1000000;
  const onDroppingFiles = (files, rejectedFiles, onDropData) => {
    if (files.length === 0 && rejectedFiles === 0) {
      toastr.error('Error!', `Max available file size is ${mbSize}MB`);
      return;
    }
    if (files.length === 0 && rejectedFiles !== 0) {
      toastr.error('Error!', 'Available file type is image');
      return;
    }
    if (rejectedFiles.length > 0) {
      toastr.error(
        'Error!',
        `Files ${rejectedFiles.map(f => `${f.name} `)} were rejected, as their size is more than ${mbSize}MB`
      );
      return;
    }
    onDropData(files);
  };

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    multiple: multiple ?? false,
    onDrop: (files, rejectedFiles) => onDroppingFiles(files, rejectedFiles, onDrop),
    maxSize: fileSize,
    accept: images ? 'image/jpeg, image/png' : ''
  });
  return (
    <section className="container">
      <div
        className={hasData ? styles.containerWithData : styles.container}
        {...getRootProps()}
        style={{ height: (height ?? '75vh') }}
      >
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
        </div>
        {hasData
          ? children
          : <p>Drag &apos;n&apos; drop some files here, or click to select files</p>}
      </div>
    </section>
  );
};

import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as okrService from 'screens/OKR/services/okrService';
import * as objectiveService from 'screens/OKR/services/objectiveService';
import * as keyResultsService from 'screens/OKR/services/keyResultService';
import { Routine } from 'redux-saga-routines';
import {
  adFetchOkrStatsRoutine,
  adFetchTopKeyResultsRoutine,
  adFetchTopMarkedOkrRoutine,
  adFetchTopObjectivesRoutine
} from '../routines';
import moment from 'moment';
import { DateType } from '.';

const dateFilter = {
  thisYear: moment().startOf('year').format('YYYY-MM-DD'),
  thisMonth: moment().startOf('month').format('YYYY-MM-DD')
};

const getMomentByDateType = (dateType: DateType) => (
  dateType === DateType.month ? dateFilter.thisMonth : dateFilter.thisYear
);

function* fetchAdminDashboardData() {
  try {
    const response = yield call(okrService.getOkrsStats, { date: dateFilter.thisYear });

    yield put(adFetchOkrStatsRoutine.success(response));
  } catch (error) {
    yield put(adFetchOkrStatsRoutine.failure(error.message));
  }
}

function* fetchTopMarkedOkrs({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.getTopMarkedOkr, { date: getMomentByDateType(payload) });

    yield put(adFetchOkrStatsRoutine.success(response));
  } catch (error) {
    yield put(adFetchOkrStatsRoutine.failure(error.message));
  }
}

function* fetchTopObjectives({ payload }: Routine<any>) {
  try {
    const response = yield call(objectiveService.getTopObjectives, getMomentByDateType(payload));

    yield put(adFetchTopObjectivesRoutine.success(response));
  } catch (error) {
    yield put(adFetchTopObjectivesRoutine.failure(error.message));
  }
}

function* fetchTopKeyResults({ payload }: Routine<any>) {
  try {
    const response = yield call(keyResultsService.getTopMarkedKeyResults, getMomentByDateType(payload));

    yield put(adFetchOkrStatsRoutine.success(response));
  } catch (error) {
    yield put(adFetchOkrStatsRoutine.failure(error.message));
  }
}

function* watchFetchAdminDashboardData() {
  yield takeEvery(adFetchOkrStatsRoutine, fetchAdminDashboardData);
}

function* watchFetchTopMarkedOkrs() {
  yield takeEvery(adFetchTopMarkedOkrRoutine, fetchTopMarkedOkrs);
}

function* watchFetchTopObjectives() {
  yield takeEvery(adFetchTopObjectivesRoutine, fetchTopObjectives);
}

function* watchFetchTopKeyResults() {
  yield takeEvery(adFetchTopKeyResultsRoutine, fetchTopKeyResults);
}

export default function* adminDashboardSagas() {
  yield all([
    watchFetchAdminDashboardData(),
    watchFetchTopMarkedOkrs(),
    watchFetchTopObjectives(),
    watchFetchTopKeyResults()
  ]);
}

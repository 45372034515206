import { Routine } from 'redux-saga-routines';
import {
  scanKeyResultsForDuplicatesRoutine,
  fetchMoreKeyResultsRoutine,
  updateKeyResultsFiltersRoutine,
  deleteKeyResultRoutine,
  toggleIsCustomKeyResultStatusRoutine,
  saveKeyResultAdminRoutine,
  removeKeyResultsRoutine,
  toggleIsHiddenKeyResultStatusRoutine,
  toggleIsMergeStatusRoutine,
  mergeKeyResultsRoutine
} from '../../routines';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { IBindingFunction } from 'models/callback';
import { IToggleStatus } from 'screens/Library/model/IToggleStatus';
import { IMergeActionPayload } from 'screens/Library/model';

interface IKeyResultsState {
  keyResults: IKeyResult[];
  keyResultDuplicates: IKeyResult[][];
  keyResultsToMerge: IKeyResult[];
  hasMoreKeyResults: boolean;
  skip: number;
  limit: number;
}

const initialState: IKeyResultsState = {
  keyResults: [],
  keyResultDuplicates: [],
  keyResultsToMerge: [],
  hasMoreKeyResults: true,
  skip: 0,
  limit: 25
};

const updateKeyResultsStatus = (
  keyResultsList: IKeyResult[],
  mapKeyResult: IBindingFunction<IKeyResult, IKeyResult>,
  payload: IToggleStatus
) => (
  payload.filterStatus !== null && payload.filterStatus !== payload.status
    ? keyResultsList.filter(o => o.id !== payload.id)
    : keyResultsList.map(o => (o.id === payload.id ? mapKeyResult(o) : o))
);

export const keyResults = (state = initialState, action: Routine<any>): IKeyResultsState => {
  switch (action.type) {
    case fetchMoreKeyResultsRoutine.SUCCESS:
      return {
        ...state,
        keyResults: [...state.keyResults, ...action.payload],
        hasMoreKeyResults: Boolean(action.payload.length),
        skip: state.skip + state.limit
      };
    case scanKeyResultsForDuplicatesRoutine.SUCCESS:
      return {
        ...state,
        keyResultDuplicates: [...action.payload],
        hasMoreKeyResults: Boolean(action.payload.length),
        skip: state.skip + state.limit
      };
    case removeKeyResultsRoutine.TRIGGER:
      return initialState;
    case updateKeyResultsFiltersRoutine.TRIGGER:
      return {
        ...initialState,
        keyResultsToMerge: state.keyResultsToMerge
      };
    case toggleIsCustomKeyResultStatusRoutine.SUCCESS:
      const customPayload = action.payload as IToggleStatus;

      return {
        ...state,
        keyResults: updateKeyResultsStatus(
          state.keyResults,
          kr => ({ ...kr, isCustom: customPayload.status }),
          customPayload
        )
      };
    case toggleIsHiddenKeyResultStatusRoutine.SUCCESS:
      const hiddenPayload = action.payload as IToggleStatus;

      return {
        ...state,
        keyResults: updateKeyResultsStatus(
          state.keyResults,
          kr => ({ ...kr, isHidden: hiddenPayload.status }),
          hiddenPayload
        )
      };
    case saveKeyResultAdminRoutine.SUCCESS:
      const isExists = state.keyResults.find(kr => kr.id === action.payload.id);
      return {
        ...state,
        keyResults: !isExists
          ? [...state.keyResults, action.payload]
          : state.keyResults.map(kr => (kr.id === action.payload.id ? action.payload : kr)),
        keyResultsToMerge: state.keyResultsToMerge.map(it => (it.id === action.payload.id ? action.payload : it))
      };
    case deleteKeyResultRoutine.SUCCESS:
      return {
        ...state,
        keyResults: [...state.keyResults.filter(kr => kr.id !== action.payload)]
      };
    case toggleIsMergeStatusRoutine.SUCCESS: {
      const keyResult = action.payload as IKeyResult;
      const { keyResultsToMerge } = state;

      const isIncluded = keyResultsToMerge.some(it => it.id === keyResult.id);

      return {
        ...state,
        keyResultsToMerge: isIncluded
          ? keyResultsToMerge.filter(it => it.id !== keyResult.id)
          : [...keyResultsToMerge, keyResult]
      };
    }
    case mergeKeyResultsRoutine.SUCCESS: {
      const mergeActionPayload = action.payload as IMergeActionPayload;

      return {
        ...state,
        keyResultsToMerge: initialState.keyResultsToMerge,
        keyResults: state.keyResults.reduce((acc, keyResult) => {
          const { originKeyResult } = mergeActionPayload;
          const { id } = keyResult;

          if (mergeActionPayload.keyResultIds.includes(id)) {
            return acc;
          }

          if (originKeyResult.id === id) {
            return [...acc, originKeyResult];
          }

          return [...acc, keyResult];
        }, [] as IKeyResult[])
      };
    }
    default:
      return state;
  }
};

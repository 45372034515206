import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchOkrRoutine, saveOkrRoutine, addNoteToOkr, addObjectiveToOkr } from 'screens/UsersOkrs/routines';
import styles from './styles.module.scss';
import { Loader, List, Header, Icon, Dropdown, Button } from 'semantic-ui-react';
import { IOkrExtended, IOkrToSave, IOkrNoteToSave, IObjectivesToOkrsIds } from 'screens/OKR/models/okr';
import { IUsersOkrsState } from 'screens/UsersOkrs/containers/UsersOkrsPage/reducer';
import { NoDataContainer } from 'components/NoDataContainer';
import { IBindingCallback1 } from 'models/callback';
import LinksReplacer from 'components/LinksReplacer/linksReplacer';
import { getComplexityColors } from 'screens/OKR/helpers/complexityColorHelper';
import LoaderWrapper from 'components/LoaderWrapper';
import AddToMyOkrWrapper from 'screens/OKR/components/AddToMyOkrWrapper';
import { calcOkrsMark, calcObjectivesMarks } from 'screens/OKR/helpers/array.helper';
import SuggestAdditionForm from 'screens/OKR/containers/SuggestAdditionForm';
import SuggestDeletionForm from 'screens/OKR/containers/SuggestDeletionForm';
import AddNoteModal from 'screens/OKR/components/AddNoteModal';
import DateLabel from 'screens/OKR/components/DateLabel';
import AddObjectiveModal from 'screens/OKR/components/AddObjectiveModal';
import * as objectiveService from 'screens/OKR/services/objectiveService';
import SaveOkrForm, { IFormProps as IOkrDataToSave } from 'screens/OKR/containers/SaveOkrForm';
import { IKeyResult } from 'screens/OKR/models/key-result';
import { KeyResultActionType } from 'screens/OKR/enums/KeyResultActionType';
import SuggestionDiscardForm from 'screens/OKR/containers/SuggestionDiscardForm';
import { history } from 'helpers/history';
import { IUser } from 'screens/UsersOkrs/models/IUser';
import { IObjectiveFilter } from 'screens/OKR/models/objective/IObjectiveFilter';

const defaultRoute = new RegExp('^/okr/users/[-0-9a-f]{36}$');
const okrRoute = new RegExp('^(/okr/users/[-0-9a-f]{36}/okr/)[-0-9a-f]{36}$');

interface IOkrListProps {
  fetchOkr: IBindingCallback1<string>;
  onKRSelecting: IBindingCallback1<string>;
  okrs: { id: string; name: string }[];
  currentOkr: IOkrExtended;
  loading: boolean;
  okrLoading: boolean;
  currentOkrIndex: number;
  isMentor: boolean;
  isObjectiveSaving: boolean;
  user: IUser;
  location: Location;
  addNote: IBindingCallback1<IOkrNoteToSave>;
  saveOkr: IBindingCallback1<IOkrToSave>;
  addObjToOkr: IBindingCallback1<IObjectivesToOkrsIds>;
  toggleExpandedUserSearch: IBindingCallback1<boolean>;
  isUserSearchCollapsed: boolean;
}

const OkrList: React.FunctionComponent<IOkrListProps> = ({
  okrs,
  currentOkr,
  okrLoading,
  loading,
  currentOkrIndex,
  fetchOkr,
  onKRSelecting,
  isMentor,
  isObjectiveSaving,
  user,
  location: { pathname },
  addNote,
  saveOkr,
  addObjToOkr,
  toggleExpandedUserSearch,
  isUserSearchCollapsed
}: IOkrListProps) => {
  const noteTextLength = 200;
  const [isOkrSidebarOpen, toggleOkrSidebar] = useState<boolean>(false);
  const isNoteLong = currentOkr?.note && currentOkr.note.length > noteTextLength;
  const [isNoteTextShortened, toggleShortenedNoteText] = useState<boolean>(isNoteLong);

  const noteText: string = currentOkr?.note && (
    isNoteTextShortened
      ? currentOkr.note.substring(0, noteTextLength).concat('...')
      : currentOkr.note
  );

  const marks = calcObjectivesMarks(currentOkr?.objectives);
  const averageMark = calcOkrsMark(marks);
  const isSuggestionAvailable = isMentor && !currentOkr?.isClosed;
  const isNoteAdditionAvailable = isMentor && currentOkr?.isClosed && !currentOkr?.note;

  useEffect(() => {
    if (!currentOkr) return;
    if (defaultRoute.test(pathname)) {
      history.push(`${pathname}/okr/${currentOkr.id}`);
      return;
    }
    history.push(pathname.replace(okrRoute, `$1${currentOkr.id}`));
  }, [currentOkr?.id, pathname]);

  const onToggleOkrSidebar = () => {
    toggleOkrSidebar(!isOkrSidebarOpen);
  };

  const onSaveOkr = (okr: IOkrDataToSave) => {
    const { startTime, endTime, name, description, isSabbatical } = okr;

    const newOkr = {
      userId: user.id,
      createdBy: user.mentorId,
      name: name.trim(),
      description: description.trim(),
      startTime: startTime.toDate(),
      endTime: endTime.toDate(),
      isSabbatical
    };

    saveOkr(newOkr);

    onToggleOkrSidebar();
  };

  const onObjectiveCreate = async inputValue => {
    const objective = await objectiveService.getObjectiveByName(inputValue.name);
    const objectiveId = objective ? objective.id : (await objectiveService.saveObjective({
      name: inputValue.name,
      isCustom: inputValue.isCustom,
      createdBy: user.mentorId
    })).id;

    const objectiveOption = {
      key: objectiveId,
      label: inputValue.name,
      value: {
        id: objectiveId
      }
    };

    return objectiveOption;
  };

  const onObjectivesLoad = async ({ name, from, count }: IObjectiveFilter) => {
    const objectives = await objectiveService.getObjectivesByUserIdAndFilter({
      name,
      from,
      count,
      userId: user.id
    });

    const mappedObjectiveOptions = objectives.map(({ id, name: localName }) => ({
      key: id,
      label: localName,
      value: { id }
    }));

    return mappedObjectiveOptions;
  };

  const onObjectiveSelect = arrayId => {
    arrayId.forEach(id => {
      const objectiveExists = currentOkr.objectives.some(it => it.id === id);

      if (!objectiveExists) {
        addObjToOkr({
          okrId: currentOkr.id,
          objectiveId: id,
          userId: user.id
        });
      }
    });
  };

  const renderNoData = (text: string) => (
    <div className={styles.noDataContainer}>
      <NoDataContainer text={text} />
    </div>
  );
  const renderLoading = () => (
    <div className={styles.loading}>
      <Loader active />
    </div>
  );
  const toggleArrowClick = (previous = true) => {
    const nextId = okrs[currentOkrIndex + (previous ? 1 : -1)]?.id;
    if (nextId) fetchOkr(nextId);
  };
  const toggleDropdownChange = (e, data) => fetchOkr(data.value);

  const okrsOptions = okrs?.map(o => ({ key: o.id, value: o.id, text: o.name, active: o.id === currentOkr.id })) ?? [];

  const isDeleting = (keyResult: IKeyResult) => keyResult.actionType === KeyResultActionType.DeleteKeyResult;
  const isAdding = (keyResult: IKeyResult) => keyResult.actionType === KeyResultActionType.AddKeyResult;

  const renderSuggestDelete = (objectiveId: string, keyResult: IKeyResult) => (
    isSuggestionAvailable ? (
      <SuggestDeletionForm
        keyResult={keyResult}
        objectiveId={objectiveId}
        okrId={currentOkr.id}
      />
    ) : ''
  );

  const renderCurrentOkr = () => (
    <div className={styles.listContainer}>
      <div className={styles.okrHeader}>
        <Header className={styles.listHeader}>
          <div className={styles.okrUserName}>
            {user.fullName}
            <span
              className={
                isUserSearchCollapsed ? styles.toggleSearchContainer : styles.hiddenToggleSearchContainer
              }
            >
              <Icon
                className={styles.toggleSearchBtn}
                name="search"
                onClick={toggleExpandedUserSearch}
              />
            </span>
          </div>
          <Header.Subheader className={styles.listSubHeader}>
            <div className={styles.okrNavList}>
              <div className={styles.okrNavItem}>
                <Dropdown
                  className={styles.dropdown}
                  selection
                  options={okrsOptions}
                  value={currentOkr.id}
                  onChange={toggleDropdownChange}
                />
              </div>
              <div className={styles.okrNavItem}>
                <Button
                  className={styles.addBtn}
                  onClick={onToggleOkrSidebar}
                  content="Add OKR"
                  icon="plus"
                />
              </div>
              <div className={styles.okrNavItem}>
                {`Okr ${okrs.length - currentOkrIndex} of ${okrs.length}`}
              </div>
            </div>
          </Header.Subheader>
          <Header.Content>
            <div>{currentOkr.name}</div>
          </Header.Content>
          <Header.Subheader className={styles.listSubHeader}>
            {currentOkr.description}
          </Header.Subheader>
        </Header>
        <div className={styles.spaceBetween}>
          <div className={styles.datesAndMark}>
            <div className={styles.okrDate}>
              <p>
                <Icon name="calendar outline" className={currentOkr.isClosed ? styles.closeCal : ''} />
                <DateLabel
                  startTime={currentOkr.startTime}
                  endTime={currentOkr.endTime}
                  styles={styles}
                />
              </p>
              {isMentor && (
                <AddObjectiveModal
                  saving={isObjectiveSaving}
                  selectedObjectives={currentOkr.objectives}
                  addSelectedObjectives={selectedKeyResults => onObjectiveSelect(selectedKeyResults)}
                  onObjectiveCreate={onObjectiveCreate}
                  onObjectivesLoad={({
                    name,
                    from,
                    count,
                    searchable
                  }: IObjectiveFilter) => onObjectivesLoad({ name, from, count, searchable })}
                />
              )}
            </div>
            <div className={styles.okrMark}>{currentOkr.averageMark || averageMark}</div>
          </div>
          {currentOkr.isClosed ? <div className={styles.closedLabel}>Closed </div> : ''}
        </div>
        {(isMentor && currentOkr.isClosed && currentOkr.note) && (
          <div className={styles.note}>
            <div className={styles.noteMessage}>
              <p className={styles.noteHeader}>Note</p>
              <div className={styles.noteText}>
                {noteText}
                <div className={styles.showMore_container}>
                  <span
                    className={styles.note_showMore}
                    onClick={() => toggleShortenedNoteText(!isNoteTextShortened)}
                    role="presentation"
                  >
                    {isNoteLong && (isNoteTextShortened ? 'show more' : 'show less')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {currentOkr.objectives?.length
        ? (
          <List size="massive">
            <List.Item>
              <List.Content className={styles.listContent}>
                <List.List>
                  {currentOkr.objectives?.map(objective => (
                    <List.Item key={objective.id}>
                      <List.Content>
                        <List.Header className={styles.objectiveHeader}>
                          <div className={styles.name}>
                            {objective.name}
                          </div>
                          <div className={styles.objMark}>
                            {marks.find(m => m.id === objective.id).mark}
                          </div>
                        </List.Header>
                        {objective.keyResults.length
                          ? (
                            <List.List className={styles.list}>
                              <List.Item>
                                <List.Content>
                                  {objective.keyResults.length > 0 && (
                                    objective.keyResults.map(keyResult => (
                                      <div
                                        className={styles.keyResultItem}
                                        key={keyResult.id}
                                      >
                                        <div
                                          className={`${styles.container}
                                          ${isDeleting(keyResult) ? styles.delete : ''}
                                          ${isAdding(keyResult) ? styles.add : ''}`}
                                          onClick={() => onKRSelecting(keyResult.id)}
                                          role="presentation"
                                        >
                                          <div className={styles.firstBlock}>
                                            <div className={styles.ellipsis}>
                                              {keyResult.name}
                                            </div>
                                            {keyResult.details ? (
                                              <div className={`${styles.ellipsis} ${styles.details}`}>
                                                <LinksReplacer showDomain data={keyResult.details} />
                                              </div>
                                            ) : ''}
                                          </div>
                                          <div className={styles.secondBlock}>
                                            <div className={styles.complexity}>
                                              <div style={getComplexityColors(keyResult.complexity)} />
                                              {keyResult.complexity}
                                            </div>
                                            <div className={styles.comments}>
                                              <Icon name="comment outline" size="small" />
                                              <div className={styles.ratings}>
                                                {keyResult.ratingsCount}
                                              </div>
                                            </div>
                                            <div className={styles.mark}>
                                              {keyResult.mark}
                                            </div>
                                          </div>
                                        </div>
                                        <div className={styles.actions}>
                                          <AddToMyOkrWrapper keyResult={keyResult} fromOkrId={currentOkr.id} />
                                          {(isDeleting(keyResult) || isAdding(keyResult)) && isSuggestionAvailable
                                            ? (
                                              <SuggestionDiscardForm
                                                keyResult={keyResult}
                                                objectiveId={objective.id}
                                                okrId={currentOkr.id}
                                              />
                                            ) : renderSuggestDelete(objective.id, keyResult)}
                                        </div>
                                      </div>
                                    )))}
                                </List.Content>
                              </List.Item>
                            </List.List>
                          ) : (
                            <div className={styles.noData}>
                              <p>There are no key results at this objective</p>
                            </div>
                          )}
                        {isSuggestionAvailable ? (
                          <SuggestAdditionForm
                            currentKeyResults={objective.keyResults}
                            okrId={currentOkr.id}
                            objectiveId={objective.id}
                            toggleSidebar={id => onKRSelecting(id)}
                          />
                        ) : ''}
                        {isNoteAdditionAvailable && (
                          <AddNoteModal
                            currentOkr={currentOkr}
                            addNote={addNote}
                          />
                        )}
                      </List.Content>
                    </List.Item>
                  ))}
                </List.List>
              </List.Content>
            </List.Item>
          </List>
      ) : (
        <div className={styles.noDataContainerSmall}>
          <NoDataContainer
            text="This OKR has no objectives now"
            imageWidth="300px"
          />
        </div>
      )}
    </div>
  );

  if (loading) return renderLoading();
  if (okrs === null) return renderNoData('No OKR\'s selected');
  if (!okrs.length) return renderNoData(`${user.fullName} has no OKRs now...`);

  return (
    <>
      <div className={styles.container}>
        <div
          className={`${styles.arrow} ${currentOkrIndex === okrs.length - 1 ? styles.lastOkr : ''} ${styles.prevArrow}`}
          role="presentation"
          onClick={() => toggleArrowClick()}
        >
          <div className={`${styles.lineBase} ${styles.line1}`} />
          <div className={`${styles.lineBase} ${styles.line2}`} />
        </div>
        <div className={styles.currentOkr}>
          <LoaderWrapper loading={okrLoading}>
            {renderCurrentOkr()}
          </LoaderWrapper>
        </div>
        <div
          className={`${styles.arrow} ${!currentOkrIndex ? styles.lastOkr : ''} ${styles.nextArrow}`}
          role="presentation"
          onClick={() => toggleArrowClick(false)}
        >
          <div className={`${styles.lineBase} ${styles.line3}`} />
          <div className={`${styles.lineBase} ${styles.line4}`} />
        </div>
      </div>
      {isOkrSidebarOpen && (
        <SaveOkrForm
          isOpened={isOkrSidebarOpen}
          onSave={onSaveOkr}
          onCancel={onToggleOkrSidebar}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { usersOkrs: { usersOkrs, requests } } = state;
  const usersData = usersOkrs as IUsersOkrsState;
  const currentOkrIndex = usersData.okrs?.findIndex(okr => okr.id === usersData.currentOkr.id);

  return {
    okrs: usersData.okrs,
    currentOkr: usersData.currentOkr,
    loading: requests.currentOkr.loading,
    okrLoading: requests.userOkr.loading,
    isObjectiveSaving: requests.objectives.loading,
    currentOkrIndex
  };
};

const mapDispatchToProps = {
  fetchOkr: fetchOkrRoutine,
  saveOkr: saveOkrRoutine,
  addNote: addNoteToOkr,
  addObjToOkr: addObjectiveToOkr
};

export default connect(mapStateToProps, mapDispatchToProps)(OkrList);

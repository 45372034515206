import React, { useLayoutEffect, useState } from 'react';
import { IBindingAction, IBindingCallback1 } from '../../../../models/callback';
import { IRequest } from '../../model/Requests/IRequest';
import { fetchRequestDetailsRoutine } from '../../routines';
import { connect } from 'react-redux';
import LoaderWrapper from '../../../../components/LoaderWrapper';
import FeedbackReport from '../FeedbackReport';
import { Role } from 'screens/Authorization/models/role';
import { IUserMentorData } from 'screens/AdminPage/model/IUserMentorData';

interface IRequestDetailsProps {
  detailedRequestId: string;
  fetchRequestDetails?: IBindingCallback1<string>;
  detailedRequest?: IRequest;
  roles: Role[];
  userId: string;
  usersMentor: IUserMentorData[];
}

const RequestDetails: React.FunctionComponent<IRequestDetailsProps> = (
  { fetchRequestDetails, detailedRequest, detailedRequestId, roles, userId, usersMentor }: IRequestDetailsProps
) => {
  useLayoutEffect(() => {
    fetchRequestDetails(detailedRequestId);
  }, [detailedRequestId]);

  return (
    <>
      <LoaderWrapper loading={detailedRequest === null}>
        <FeedbackReport
          detailedRequest={detailedRequest}
          userId={userId}
          roles={roles}
          usersMentor={usersMentor}
        />
      </LoaderWrapper>
    </>
  );
};

const mapStateToProps = state => {
  const { feedbackPage: { requests: { detailedRequest } } } = state;
  return {
    detailedRequest
  };
};

const mapDispatchToProps = {
  fetchRequestDetails: fetchRequestDetailsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails);

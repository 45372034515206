import { call, put, takeEvery, all, takeLatest } from 'redux-saga/effects';
import * as keyResultService from '../../../OKR/services/keyResultService';
import {
  scanKeyResultsForDuplicatesRoutine,
  fetchMoreKeyResultsRoutine,
  deleteKeyResultRoutine,
  toggleIsCustomKeyResultStatusRoutine,
  saveKeyResultAdminRoutine,
  toggleIsHiddenKeyResultStatusRoutine,
  toggleIsMergeStatusRoutine,
  mergeKeyResultsRoutine
} from '../../routines';
import { Routine } from 'redux-saga-routines';
import { IMergeAction, IMergeActionPayload } from '../../model';
import { IKeyResult } from 'screens/OKR/models/key-result';

function* fetchMoreKR({ payload }: Routine<any>) {
  try {
    const response = yield call(keyResultService.getKeyResults, payload);

    yield put(fetchMoreKeyResultsRoutine.success(response));
  } catch {
    yield put(fetchMoreKeyResultsRoutine.failure());
  }
}

function* scanKRsForDuplicates() {
  try {
    const response = yield call(keyResultService.getKeyResultDuplicates);
    yield put(scanKeyResultsForDuplicatesRoutine.success(response));
  } catch {
    yield put(scanKeyResultsForDuplicatesRoutine.failure());
  }
}

function* toggleIsCustomStatus({ payload }: Routine<any>) {
  try {
    yield call(keyResultService.toggleIsCustomStatus, { keyResultId: payload.id, isCustom: payload.status });

    yield put(toggleIsCustomKeyResultStatusRoutine.success(payload));
  } catch {
    yield put(toggleIsCustomKeyResultStatusRoutine.failure());
  }
}

function* toggleIsHiddenStatus({ payload }: Routine<any>) {
  try {
    yield call(keyResultService.toggleIsHiddenStatus, { keyResultId: payload.id, isHidden: payload.status });

    yield put(toggleIsHiddenKeyResultStatusRoutine.success(payload));
  } catch {
    yield put(toggleIsHiddenKeyResultStatusRoutine.failure());
  }
}

function* toggleIsMergeStatus({ payload }: Routine<any>) {
  try {
    const ketResult = payload as IKeyResult;

    yield put(toggleIsMergeStatusRoutine.success(ketResult));
  } catch {
    yield put(toggleIsCustomKeyResultStatusRoutine.failure());
  }
}

function* mergeKeyResults({ payload }: Routine<any>) {
  try {
    const mergeAction = payload as IMergeAction;

    const originKeyResult = yield call(keyResultService.mergeKeyResults, mergeAction);

    const mergeActionPayload: IMergeActionPayload = {
      ...mergeAction,
      originKeyResult
    };

    yield put(mergeKeyResultsRoutine.success(mergeActionPayload));
  } catch {
    yield put(mergeKeyResultsRoutine.failure());
  }
}

function* saveKR({ payload }: Routine<any>) {
  try {
    const response = yield call(keyResultService.saveKeyResult, payload);

    yield put(saveKeyResultAdminRoutine.success(response));
  } catch {
    yield put(saveKeyResultAdminRoutine.failure());
  }
}

function* deleteKR({ payload }: Routine<any>) {
  try {
    yield call(keyResultService.deleteKeyResult, payload);

    yield put(deleteKeyResultRoutine.success(payload));
  } catch {
    yield put(deleteKeyResultRoutine.failure());
  }
}

function* watchToggleIsCustomStatus() {
  yield takeEvery(toggleIsCustomKeyResultStatusRoutine.TRIGGER, toggleIsCustomStatus);
}

function* watchToggleIsHiddenStatus() {
  yield takeEvery(toggleIsHiddenKeyResultStatusRoutine.TRIGGER, toggleIsHiddenStatus);
}

function* watchToggleIsMergeStatus() {
  yield takeEvery(toggleIsMergeStatusRoutine.TRIGGER, toggleIsMergeStatus);
}

function* watchFetchMoreKR() {
  yield takeLatest(fetchMoreKeyResultsRoutine.TRIGGER, fetchMoreKR);
}

function* watchScanKRsForDuplicates() {
  yield takeLatest(scanKeyResultsForDuplicatesRoutine.TRIGGER, scanKRsForDuplicates);
}

function* watchSaveKR() {
  yield takeEvery(saveKeyResultAdminRoutine.TRIGGER, saveKR);
}

function* watchDeleteKR() {
  yield takeEvery(deleteKeyResultRoutine.TRIGGER, deleteKR);
}

function* watchMergeKR() {
  yield takeEvery(mergeKeyResultsRoutine.TRIGGER, mergeKeyResults);
}

export default function* keyResultsSagas() {
  yield all([
    watchSaveKR(),
    watchDeleteKR(),
    watchFetchMoreKR(),
    watchScanKRsForDuplicates(),
    watchToggleIsCustomStatus(),
    watchToggleIsHiddenStatus(),
    watchToggleIsMergeStatus(),
    watchMergeKR()
  ]);
}

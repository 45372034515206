import { callFeedbackApi, callImportFeedbackApi } from '../../../helpers/webApiHelper';
import { IShortRequest } from '../model/Requests/IShortRequest';
import { IRequest } from '../model/Requests/IRequest';

export const getSummary = async (requestId): Promise<IRequest> => {
  const response = await callFeedbackApi({
    endpoint: `/api/feedback/summary/${requestId}`,
    type: 'GET'
  });
  return response.json().then(res => res.data);
};

export const createSummary = async (summary): Promise<IRequest> => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/summary/create',
    type: 'POST',
    requestData: summary
  });
  return response.json().then(res => res.data);
};

export const updateSummary = async (summary): Promise<IRequest> => {
  const response = await callFeedbackApi({
    endpoint: '/api/feedback/summary/update',
    type: 'PUT',
    requestData: summary
  });
  return response.json().then(res => res.data);
};

import { callNotificationsApi } from 'helpers/webApiHelper';
import { INotificationModel } from '../models/notificationModel';

export const getLastNotifications = async (): Promise<INotificationModel[]> => {
  const response = await callNotificationsApi({
    endpoint: '/api/notifications/last',
    type: 'GET'
  });
  return response.json();
};

export const getAllNotifications = async (): Promise<INotificationModel[]> => {
  const response = await callNotificationsApi({
    endpoint: '/api/notifications/all',
    type: 'GET'
  });
  return response.json();
};

export const markNotificationsAsRead = async (payload: string[]) => {
  const response = await callNotificationsApi({
    endpoint: '/api/notifications/mark',
    type: 'PUT',
    requestData: payload
  });
  return response.json();
};

import { callTimeOffTracker, callTimeOffTrackerApiToUploadFile } from 'helpers/webApiHelper';
import { IUserDaysOffDTO } from '../models/daysOff/userDaysOffDTO';
import { IRequestDTO } from '../models/requests/requestDTO';
import { IRequestsWithUserAndDaysDTO } from '../models/requests/requestWithUsersAndDaysDTO';
import { IUpdateRequestStatusDTO } from '../models/requests/updateRequestStatusDTO';
import { IUploadProofImageDTO } from '../models/requests/uploadProofImageDTO';
import { IDay } from '../models/daysOff/day';
import { IUserDaysOff } from '../models/daysOff/userForDaysOff';
import { IAddManyRequestsDTO } from '../models/requests/AddManyRequestsDTO';

export const getAll = async (): Promise<IUserDaysOffDTO[]> => {
  const response = await callTimeOffTracker({
    endpoint: '/api/daysoff/all',
    type: 'GET'
  });

  return response.json();
};

export const getAllByUserIdRequests = async (payload: string): Promise<IRequestDTO[]> => {
  const response = await callTimeOffTracker({
    endpoint: `/api/request/${payload}`,
    type: 'GET'
  });

  return response.json();
};

export const getNewRequestsWithUserAndDays = async (): Promise<IRequestsWithUserAndDaysDTO[]> => {
  const response = await callTimeOffTracker({
    endpoint: '/api/request/new',
    type: 'GET'
  });

  return response.json();
};

export const addDaysOffRequest = async (payload: IUserDaysOffDTO) => {
  const response = await callTimeOffTracker({
    endpoint: '/api/request',
    type: 'POST',
    requestData: { ...payload }
  });

  return response.json();
};
export const updateRequestStatus = async (payload: IUpdateRequestStatusDTO) => {
  const response = await callTimeOffTracker({
    endpoint: '/api/request/status',
    type: 'POST',
    requestData: { ...payload }
  });

  return response.json();
};

export const uploadProofImage = async (payload: IUploadProofImageDTO) => {
  const response = await callTimeOffTrackerApiToUploadFile({
    endpoint: `/api/request/proof/${payload.requestId}`,
    body: payload.data
  });

  return response.json();
};

export const getDaysOffByUserId = async (payload: string): Promise<IDay[]> => {
  const response = await callTimeOffTracker({
    endpoint: `/api/daysoff/foruser/${payload}`,
    type: 'GET'
  });

  return response.json();
};

export const getAllUsers = async (): Promise<IUserDaysOff> => {
  const response = await callTimeOffTracker({
    endpoint: '/api/user/all',
    type: 'GET'
  });

  return response.json();
};

export const addManyRequests = async (payload: IAddManyRequestsDTO) => {
  const response = await callTimeOffTracker({
    endpoint: '/api/request/many',
    type: 'POST',
    requestData: { ...payload }
  });

  return response.status;
};

import React from 'react';
import { boundMethod } from 'autobind-decorator';
import { Form } from 'semantic-ui-react';
import { IBindingCallback1 } from 'models/callback';
import styles from './styles.module.scss';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { IObjectiveStrict } from 'screens/OKR/models/objective/IObjectiveStrict';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Objective name should contain at least 2 symbols!')
    .max(100, 'Objective name should contain less than 50 symbols!')
    .required('Objective name is required!')
});

interface ICustomObjectiveInputProps {
  onSubmit: IBindingCallback1<IObjectiveStrict>;
  objective?: IObjectiveStrict;
  defaultGlobal?: boolean;
}

class CustomObjectiveInput extends React.Component<ICustomObjectiveInputProps> {
  @boundMethod
  handleSubmit(objective: IObjectiveStrict) {
    const { onSubmit } = this.props;
    onSubmit({ ...objective, name: objective.name.trim() });
  }

  initialValues(objective: IObjectiveStrict) {
    const { defaultGlobal } = this.props;

    return objective || { name: '', isCustom: !defaultGlobal };
  }

  render() {
    const { objective } = this.props;
    return (
      <Formik
        onSubmit={obj => this.handleSubmit(obj)}
        validationSchema={validationSchema}
        initialValues={this.initialValues(objective)}
      >
        {({ values, handleChange, errors, touched, handleSubmit }:
          FormikProps<any>) => (
            <Form onSubmit={handleSubmit} className={styles.objectiveForm}>
              <Form.Input
                label={errors.name && touched.name ? errors.name : 'Objective name'}
                name="name"
                value={values.name}
                placeholder="Please input the objective name"
                width={16}
                onChange={handleChange}
                error={errors.name && touched.name}
              />
              <Form.Button
                primary
                content="Save"
                type="submit"
                disabled={Boolean(!values.name.length)}
              />
            </Form>
        )}
      </Formik>
    );
  }
}

export default CustomObjectiveInput;

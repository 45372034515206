import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { DateRangePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import { Form, Button, Image } from 'semantic-ui-react';
import { boundMethod } from 'autobind-decorator';
import { parseDateIntoMoment } from 'helpers/dateFormatHelper';
import { IBindingAction, IBindingFunction, IBindingCallback1 } from 'models/callback';
import { IOkrToSave } from '../../models/okr';
import styles from './styles.module.scss';
import { fetchOkrDatesRoutine } from 'screens/OKR/routines';
import { connect } from 'react-redux';
import Sidebar from 'components/DataSidebar';
import { cross } from 'assets/icons';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Min name length is 2 symbols!')
    .max(50, 'Max name length is 50 symbols!')
    .required('Name is required'),
  description: Yup.string()
    .max(150, 'Max description length is 50 symbols'),
  startTime: Yup.date().required('Time period is required'),
  endTime: Yup.date().required('Time period is required')
});

export interface IFormProps {
  startTime: Moment;
  endTime: Moment;
  name: string;
  description: string;
  isSabbatical: boolean;
}

export interface ISaveOkrFormProps {
  fetchOkrDates: IBindingCallback1<string>;
  okr?: IOkrToSave;
  onSave: IBindingFunction<IFormProps, void>;
  onCancel: IBindingAction;
  okrStartDates: moment.Moment[];
  okrEndDates: moment.Moment[];
  userId: string;
  isOpened: boolean;
}

export interface ISaveOkrFormState {
  focusedInput: React.ReactNode;
}

class SaveOkrForm extends React.Component<
  ISaveOkrFormProps,
  ISaveOkrFormState
> {
  constructor(props: ISaveOkrFormProps) {
    super(props);

    this.state = {
      focusedInput: null
    };
  }

  componentDidMount() {
    const { okrStartDates, fetchOkrDates, userId } = this.props;
    if (!okrStartDates.length) {
      fetchOkrDates(userId);
    }
  }

  @boundMethod
  setFocusedInput(focusedInput: React.ReactNode) {
    this.setState({ focusedInput });
  }

  render() {
    const { okr, onSave, onCancel, okrEndDates, isOpened } = this.props;
    const { focusedInput } = this.state;
    const minStartDate = okrEndDates.slice(-1)[0];
    const isOkrClosed = okr?.isClosed;
    const sidebar = isOpened ? (
      <Sidebar onClose={onCancel} shouldNotBlockOverflow>
        <Formik
          initialValues={{
            startTime: moment(okr?.startTime ?? minStartDate),
            endTime: okr?.endTime
              ? parseDateIntoMoment(okr.endTime)
              : moment(minStartDate).add(30, 'days'),
            name: okr && okr?.name,
            description: okr?.description ?? '',
            isSabbatical: okr?.isSabbatical ?? false
          }}
          onSubmit={onSave}
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            setFieldValue,
            values: { startTime, endTime, name, description, isSabbatical },
            errors,
            touched
          }: FormikProps<IFormProps>) => {
            const timePeriodError = (errors.startTime && touched.startTime)
              || (errors.endTime && touched.endTime);
            return (
              <>
                <Image src={cross} className={styles.closeButton} onClick={onCancel} />
                <p className={styles.header}>
                  {okr ? 'Edit OKR' : 'Add OKR'}
                </p>
                <div className={styles.formContainer}>
                  <Form onSubmit={handleSubmit}>
                    <Form.Input
                      value={name || ''}
                      fluid
                      label={errors.name && touched.name ? errors.name : 'Name'}
                      name="name"
                      placeholder="OKR name"
                      disabled={isOkrClosed}
                      onChange={(_, data) => setFieldValue('name', data.value)}
                      error={errors.name && touched.name}
                    />
                    <Form.TextArea
                      label={
                        errors.description && touched.description
                          ? errors.description
                          : 'Description'
                      }
                      placeholder="Some description"
                      name="description"
                      disabled={isOkrClosed}
                      value={description || ''}
                      onChange={(_, data) => setFieldValue('description', data.value)}
                      error={errors.description && touched.description}
                    />
                    <Form.Field className={styles.datepicker}>
                      <p className={`${timePeriodError ? styles.labelError : ''} ${styles.calendarName}`}>
                        {timePeriodError
                          ? 'Time period is required'
                          : 'Time period'}
                      </p>
                      <DateRangePicker
                        startDate={startTime}
                        startDateId="startTime"
                        endDate={endTime}
                        endDateId="endTime"
                        onDatesChange={({ startDate, endDate }) => {
                          setFieldValue('endTime', endDate);
                          setFieldValue('startTime', startDate);
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={this.setFocusedInput}
                        noBorder
                        isOutsideRange={() => false}
                        minDate={moment().add(-2, 'year')}
                        maxDate={moment().add(1, 'year')}
                        disabled={isOkrClosed}
                        openDirection="down"
                        minimumNights={2}
                      />
                    </Form.Field>
                    <Form.Checkbox
                      name="isSabbatical"
                      width={5}
                      toggle
                      checked={isSabbatical}
                      label="Sabbatical"
                      onClick={() => setFieldValue('isSabbatical', !isSabbatical)}
                    />
                    {!isOkrClosed && (
                      <div className={styles.buttonContainer}>
                        <Button type="button" content="Cancel" onClick={onCancel} />
                        <Button primary type="submit" content="Save" />
                      </div>
                    )}
                  </Form>
                </div>
              </>
            );
          }}
        </Formik>
      </Sidebar>
    ) : null;

    return sidebar;
  }
}

const mapStateToProps = ({
  user: { user: { id } },
  okr: {
    okrDates
  }
}) => {
  const okrStartDates = okrDates?.map(okrDate => moment(okrDate.startTime)).sort((t1, t2) => moment(t1).diff(t2)) || [];
  const okrEndDates = okrDates?.map(okrDate => moment(okrDate.endTime)).sort((t1, t2) => moment(t1).diff(t2)) || [];
  return {
    okrStartDates,
    okrEndDates,
    userId: id
  };
};

const mapDispatchToProps = {
  fetchOkrDates: fetchOkrDatesRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveOkrForm);

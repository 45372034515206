import { Routine } from 'redux-saga-routines';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  suggestOKRActionRoutine,
  confirmKeyResultSuggestionRoutine,
  discardOKRActionRoutine
} from 'screens/OKR/routines';
import * as okrService from '../../services/okrService';

function* suggestActionRequest({ payload }: Routine<any>) {
  try {
    const response = yield call(okrService.proposeUpdating, payload);
    const keyResults = response.map(kr => ({ ...kr, ratingsCount: kr.ratings?.length ?? 0 }));
    yield put(suggestOKRActionRoutine.success({
      action: payload.action,
      keyResults,
      objId: payload.objectiveId
    }));
  } catch (error) {
    yield put(suggestOKRActionRoutine.failure(error.message));
  }
}

function* confirmSuggestion({ payload }: Routine<any>) {
  try {
    yield call(okrService.confirmUpdating, payload);
    yield put(confirmKeyResultSuggestionRoutine.success({
      objectiveId: payload.objectiveId,
      keyResultId: payload.keyResultId,
      isAgreed: payload.confirmed
    }));
  } catch (error) {
    yield put(confirmKeyResultSuggestionRoutine.failure(error.message));
  }
}

function* removeSuggestion({ payload }: Routine<any>) {
  try {
    yield call(okrService.removeSuggestion, payload);
    yield put(discardOKRActionRoutine.success(payload));
  } catch (error) {
    yield put(discardOKRActionRoutine.failure(error.message));
  }
}

function* watchSuggestActionRequest() {
  yield takeEvery(suggestOKRActionRoutine.TRIGGER, suggestActionRequest);
}

function* watchConfirmSuggestionRequest() {
  yield takeEvery(confirmKeyResultSuggestionRoutine.TRIGGER, confirmSuggestion);
}

function* watchRemoveSuggestionRequest() {
  yield takeEvery(discardOKRActionRoutine.TRIGGER, removeSuggestion);
}

export default function* suggestionsSagas() {
  yield all([
    watchSuggestActionRequest(),
    watchConfirmSuggestionRequest(),
    watchRemoveSuggestionRequest()
  ]);
}

import { createRoutine } from 'redux-saga-routines';

export const fetchOkrs = createRoutine('OKRS');
export const fetchExtendedOkr = createRoutine('EXTENDED_OKR');
export const updateOKRStatusRoutine = createRoutine('UPDATE_OKR_STATUS');
export const saveOkrRoutine = createRoutine('SAVE_OKR');
export const deleteOkrRoutine = createRoutine('DELETE_OKR');
export const removeKeyResultFromOkr = createRoutine('REMOVE_KEY_RESULT');
export const addKeyResultToOkr = createRoutine('ADD_KEY_RESULT');
export const saveMark = createRoutine('SAVE_MARK');
export const closeOkr = createRoutine('CLOSE_OKR');
export const addObjectiveToOkr = createRoutine('ADD_OBJECTIVE');
export const removeObjectiveFromOkr = createRoutine('REMOVE_OBJECTIVE');
export const fetchExtendedKeyResultRoutine = createRoutine('EXTENDED_KEY_RESULT');
export const clearOkrsStateRoutine = createRoutine('CLEAR_OKRS_STATE');

export const saveObjectiveRoutine = createRoutine('SAVE_OBJECTIVE');
export const saveKeyResultRoutine = createRoutine('SAVE_KEY_RESULT');
export const moveKeyResultRoutine = createRoutine('MOVE_KEY_RESULT');

export const getRatingRoutine = createRoutine('GET_RATING');
export const updateRatingRoutine = createRoutine('UPDATE_RATING');
export const clearRatingRoutine = createRoutine('CLEAR_RATING');
export const updateCommentRoutine = createRoutine('UPDATE_KEY_RESULT_COMMENT');
export const fetchOkrDatesRoutine = createRoutine('FETCH_DATES');

export const fetchUserOkrsRoutine = createRoutine('FETCH_USER_OKRS_TO_ADD');
export const fetchOkrObjectivesRoutine = createRoutine('FETCH_OKR_OBJECTIVES');
export const addObjKrToOkrRoutine = createRoutine('ADD_OBJ_KR_TO_OKR');

export const suggestOKRActionRoutine = createRoutine('SUGGEST_OKR_ACTION');
export const confirmKeyResultSuggestionRoutine = createRoutine('CONFIRM_KEY_RESULT_SUGGESTION');
export const discardOKRActionRoutine = createRoutine('DISCARD_KEY_RESULT_SUGGESTION');

export const toggleEditComment = createRoutine('TOGGLE_EDIT_COMMENT', (isEditing: boolean) => isEditing);

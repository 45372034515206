import React from 'react';
import { IBindingAction, IBindingCallback1 } from 'models/callback';
import { Popup, Icon, Button } from 'semantic-ui-react';
import styles from './styles.module.scss';

interface IEditPopupProps {
  onEditPress: IBindingAction;
  onDeletePress: IBindingAction;
  isPopupOpened: boolean;
  popupStateAction: IBindingCallback1<boolean>;
}

export const EditPopup: React.FunctionComponent<IEditPopupProps> = ({
  onEditPress,
  onDeletePress,
  popupStateAction,
  isPopupOpened
}: IEditPopupProps) => (
  <Popup
    trigger={
      <Icon name="ellipsis vertical" className={styles.icon} />
    }
    on="click"
    className={styles.popup}
    position="bottom right"
    open={isPopupOpened}
    onOpen={() => popupStateAction(true)}
    onClose={() => popupStateAction(false)}
    basic
  >
    <Button
      icon="pencil"
      onClick={onEditPress}
      content="Edit"
    />
    <Button
      icon="remove"
      content="Delete"
      onClick={onDeletePress}
    />
  </Popup>
);

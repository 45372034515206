import { ISelectedItem, IObjectiveDataToSave } from 'models/autocomplete/ISelectedItem';
import { callOkrApi } from 'helpers/webApiHelper';
import { IObjectiveFilter } from '../models/objective/IObjectiveFilter';
import { IObjectiveStrict } from '../models/objective/IObjectiveStrict';
import { IObjectiveTop } from '../models/objective/IObjectiveTop';

export const getObjectivesByFilter = async (filters: IObjectiveFilter): Promise<ISelectedItem[]> => {
  const response = await callOkrApi({
    endpoint: '/api/objectives',
    type: 'GET',
    queryParams: filters
  });
  return response.json();
};

export const getObjectivesByUserIdAndFilter = async (filters: IObjectiveFilter): Promise<ISelectedItem[]> => {
  const response = await callOkrApi({
    endpoint: '/api/objectives/byuserid',
    type: 'GET',
    queryParams: filters
  });
  return response.json();
};

export const getObjectiveByName = async (name: string): Promise<IObjectiveStrict> => {
  const response = await callOkrApi({
    endpoint: '/api/objectives/find/',
    type: 'GET',
    queryParams: {
      name
    }
  });
  return (response.status === 200) ? response.json() : null;
};

export const saveObjective = async (requestData: IObjectiveDataToSave): Promise<IObjectiveStrict> => {
  const response = await callOkrApi({
    endpoint: '/api/objectives',
    type: 'POST',
    requestData
  });
  return response.json();
};

export const getTopObjectives = async (date: string): Promise<IObjectiveTop[]> => {
  const response = await callOkrApi({
    endpoint: '/api/objectives/top',
    type: 'GET',
    queryParams: {
      date
    }
  });
  return response.json();
};

export const toggleObjectiveIsCustomStatus = async ({ objectiveId, isCustom }): Promise<string> => {
  const response = await callOkrApi({
    endpoint: `/api/objectives/${objectiveId}/toggleIsCustom`,
    type: 'PUT',
    requestData: {
      objectiveId,
      isCustom
    }
  });
  return response.json();
};

export const toggleObjectiveIsHiddenStatus = async ({ objectiveId, isHidden }): Promise<string> => {
  const response = await callOkrApi({
    endpoint: `/api/objectives/${objectiveId}/toggleIsHidden`,
    type: 'PUT',
    requestData: {
      objectiveId,
      isHidden
    }
  });
  return response.json();
};

export const deleteObjective = async (objectiveId: string) => {
  const response = await callOkrApi({
    endpoint: `/api/objectives/${objectiveId}`,
    type: 'DELETE'
  });
  return response.json();
};

import React from 'react';

interface IImageWrapperProps {
  width?: string;
  height?: string;
  src: string;
  borderRadius?: string;
}

const ImageWrapper: React.FunctionComponent<IImageWrapperProps> = ({
  width = '30px',
  height = '30px',
  borderRadius = '50%',
  src
}) => (
  <div style={{ width, height }}>
    <img src={src} width="100%" height="100%" alt="" style={{ borderRadius }} />
  </div>
);

export default ImageWrapper;
